import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import helpService from "./helpService";

interface helpSlice {
  fetchHelpVideoData: {
    helpVideoData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  retrieveVideo: {
    retrieveVideoData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addHelpVideo: {
    addhelpVideoData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  editHelpVideo: {
    editHelpVideoData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  deleteHelpVideo: {
    deleteHelpVideoData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  changeHelpVideoOrder: {
    changeHelpVideoOrderData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: helpSlice = {
  fetchHelpVideoData: {
    helpVideoData: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  retrieveVideo: {
    retrieveVideoData: null,
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addHelpVideo: {
    addhelpVideoData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  editHelpVideo: {
    editHelpVideoData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  deleteHelpVideo: {
    deleteHelpVideoData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  changeHelpVideoOrder: {
    changeHelpVideoOrderData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

export const fetchHelpVideoData = createAsyncThunk(
  "help/fetchHelpVideoData",
  async (
    payload: any,
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await helpService.fetchHelpVideoData(
        payload,
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const retrieveVideo = createAsyncThunk(
  "help/retrieveVideo",
  async (
    payload: any,
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await helpService.retrieveVideo(
        payload,
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addHelpVideo = createAsyncThunk(
  "help/addHelpVideo",
  async (
    payload:any,
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await helpService.addHelpVideo(
        payload,
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editHelpVideo = createAsyncThunk(
  "help/editHelpVideo",
  async (
    payload:any,
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await helpService.editHelpVideo(
        payload,
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteHelpVideo = createAsyncThunk(
  "help/deleteHelpVideo",
  async (
    payload:any,
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await helpService.deleteHelpVideo(
        payload,
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changeHelpVideoOrder = createAsyncThunk(
  "help/changeHelpVideoOrder",
  async (
    payload:any,
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await helpService.changeHelpVideoOrder(
        payload,
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const helpSlice = createSlice({
    name: "help",
    initialState,
    reducers: {
      resetFetchHelpVideoData: (state) => {
        state.fetchHelpVideoData = initialState.fetchHelpVideoData;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchHelpVideoData.pending, (state, action: PayloadAction<any>) => {
          state.fetchHelpVideoData.isLoading = true;
        })
        .addCase(fetchHelpVideoData.fulfilled, (state, action: PayloadAction<any>) => {
          state.fetchHelpVideoData.isLoading = false;
          state.fetchHelpVideoData.isSuccess = true;
          state.fetchHelpVideoData.helpVideoData = action.payload.result;
        })
        .addCase(fetchHelpVideoData.rejected, (state, action: PayloadAction<any>) => {
          state.fetchHelpVideoData.isLoading = false;
          state.fetchHelpVideoData.isError = true;
          state.fetchHelpVideoData.message = action.payload.result;
        })
        .addCase(retrieveVideo.pending, (state, action: PayloadAction<any>) => {
          state.retrieveVideo.isLoading = true;
        })
        .addCase(retrieveVideo.fulfilled, (state, action: PayloadAction<any>) => {
          state.retrieveVideo.isLoading = false;
          state.retrieveVideo.isSuccess = true;
          state.retrieveVideo.message = action.payload.message;
          state.retrieveVideo.retrieveVideoData = action.payload.result;
        })
        .addCase(retrieveVideo.rejected, (state, action: PayloadAction<any>) => {
          state.retrieveVideo.isLoading = false;
          state.retrieveVideo.isError = true;
          state.retrieveVideo.message = action.payload.message;
        })
        .addCase(addHelpVideo.pending, (state, action: PayloadAction<any>) => {
          state.addHelpVideo.isLoading = true;
        })
        .addCase(addHelpVideo.fulfilled, (state, action: PayloadAction<any>) => {
          state.addHelpVideo.isLoading = false;
          state.addHelpVideo.isSuccess = true;
          state.addHelpVideo.addhelpVideoData = action.payload.result;
          state.addHelpVideo.message = action.payload.message;
        })
        .addCase(addHelpVideo.rejected, (state, action: PayloadAction<any>) => {
          state.addHelpVideo.isLoading = false;
          state.addHelpVideo.isError = true;
          state.addHelpVideo.message = action.payload.message;
        })
        .addCase(editHelpVideo.pending, (state, action: PayloadAction<any>) => {
          state.editHelpVideo.isLoading = true;
        })
        .addCase(editHelpVideo.fulfilled, (state, action: PayloadAction<any>) => {
          state.editHelpVideo.isLoading = false;
          state.editHelpVideo.isSuccess = true;
          state.editHelpVideo.editHelpVideoData = action.payload.result;
          state.editHelpVideo.message = action.payload.message;
        })
        .addCase(editHelpVideo.rejected, (state, action: PayloadAction<any>) => {
          state.editHelpVideo.isLoading = false;
          state.editHelpVideo.isError = true;
          state.editHelpVideo.message = action.payload.message;
        })
        .addCase(deleteHelpVideo.pending, (state, action: PayloadAction<any>) => {
          state.deleteHelpVideo.isLoading = true;
        })
        .addCase(deleteHelpVideo.fulfilled, (state, action: PayloadAction<any>) => {
          state.deleteHelpVideo.isLoading = false;
          state.deleteHelpVideo.isSuccess = true;
          state.deleteHelpVideo.deleteHelpVideoData = action.payload.result;
          state.deleteHelpVideo.message = action.payload.message;
        })
        .addCase(deleteHelpVideo.rejected, (state, action: PayloadAction<any>) => {
          state.deleteHelpVideo.isLoading = false;
          state.deleteHelpVideo.isError = true;
          state.deleteHelpVideo.message = action.payload.message;
        })
        .addCase(changeHelpVideoOrder.pending, (state, action: PayloadAction<any>) => {
          state.changeHelpVideoOrder.isLoading = true;
        })
        .addCase(changeHelpVideoOrder.fulfilled, (state, action: PayloadAction<any>) => {
          state.changeHelpVideoOrder.isLoading = false;
          state.changeHelpVideoOrder.isSuccess = true;
          state.changeHelpVideoOrder.changeHelpVideoOrderData = action.payload.result;
        })
        .addCase(changeHelpVideoOrder.rejected, (state, action: PayloadAction<any>) => {
          state.changeHelpVideoOrder.isLoading = false;
          state.changeHelpVideoOrder.isError = true;
          state.changeHelpVideoOrder.message = action.payload.result;
        })
    },
  });

  export const {
    resetFetchHelpVideoData
  } = helpSlice.actions;
  
  export default helpSlice.reducer;