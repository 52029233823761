import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import emsReportManagementService from "./emsReportManagementService";
import { payloadType } from "../../pages/emsReportManagement/EmsReportManagement";

interface emsReportManagementState {
  fetchEMSList: {
    emsListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchCaseTypeList: {
    caseTypeList: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchEmergencyUserList: {
    emergencyUserList: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchEMTUsersList: {
    emtUsersList: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  generateEMSReport: {
    generatedReport: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  generateCSV: {
    generatedCSVResult:any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  downloadCSV: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
}

const initialState: emsReportManagementState = {
  fetchEMSList: {
    emsListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchCaseTypeList: {
    caseTypeList: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchEmergencyUserList: {
    emergencyUserList: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchEMTUsersList: {
    emtUsersList: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  generateEMSReport: {
    generatedReport: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  generateCSV: {
    generatedCSVResult:[],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  downloadCSV: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
};

export const fetchEMSList = createAsyncThunk(
  "emsReportManagement/fetchEMSList",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await emsReportManagementService.fetchEMSList(token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchCaseTypeList = createAsyncThunk(
  "emsReportManagement/fetchCaseTypeList",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await emsReportManagementService.fetchCaseTypeList(
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchEmergencyUserList = createAsyncThunk(
  "emsReportManagement/fetchEmergencyUserList",
  async (siteID:any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response =
        await emsReportManagementService.fetchEmergencyUserList(siteID,token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchEMTUsersList = createAsyncThunk(
  "emsReportManagement/fetchEMTUsersList",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response =
        await emsReportManagementService.fetchEMTUsersList(token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const generateEMSReport = createAsyncThunk(
  "emsReportManagement/generateEMSReport",
  async (payload:payloadType, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response =
        await emsReportManagementService.generateEMSReport(payload,token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const generateCSV = createAsyncThunk(
  "emsReportManagement/generateCSV",
  async (payload:payloadType, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response =
        await emsReportManagementService.generateCSV(payload,token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const downloadCSV = createAsyncThunk(
  "emsReportManagement/downloadCSV",
  async (payload:payloadType, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response =
        await emsReportManagementService.downloadCSV(payload,token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const emsReportManagementSlice = createSlice({
  name: "emsReportManagement",
  initialState,
  reducers: {
    resetFetchEMSList: (state) => {
      state.fetchEMSList = initialState.fetchEMSList;
    },
    resetCaseTypeList: (state) => {
      state.fetchCaseTypeList = initialState.fetchCaseTypeList;
    },
    resetEmergencyUserList: (state) => {
      state.fetchEmergencyUserList =
        initialState.fetchEmergencyUserList;
    },
    resetEMTUsersList: (state) => {
      state.fetchEMTUsersList =
        initialState.fetchEMTUsersList;
    },
    resetGeneratedReport: (state) => {
      state.generateEMSReport =
        initialState.generateEMSReport;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEMSList.pending, (state, action: PayloadAction<any>) => {
        state.fetchEMSList.isLoading = true;
      })
      .addCase(fetchEMSList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchEMSList.isLoading = false;
        state.fetchEMSList.isSuccess = true;
        state.fetchEMSList.emsListData = action.payload.result;
      })
      .addCase(fetchEMSList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchEMSList.isLoading = false;
        state.fetchEMSList.isError = true;
        state.fetchEMSList.message = action.payload;
      })
      .addCase(
        fetchCaseTypeList.pending,
        (state, action: PayloadAction<any>) => {
          state.fetchCaseTypeList.isLoading = true;
        }
      )
      .addCase(
        fetchCaseTypeList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.fetchCaseTypeList.isLoading = false;
          state.fetchCaseTypeList.isSuccess = true;
          state.fetchCaseTypeList.caseTypeList = action.payload.result;
        }
      )
      .addCase(
        fetchCaseTypeList.rejected,
        (state, action: PayloadAction<any>) => {
          state.fetchCaseTypeList.isLoading = false;
          state.fetchCaseTypeList.isError = true;
          state.fetchCaseTypeList.message = action.payload;
        }
      )
      .addCase(
        fetchEmergencyUserList.pending,
        (state, action: PayloadAction<any>) => {
          state.fetchEmergencyUserList.isLoading = true;
        }
      )
      .addCase(
        fetchEmergencyUserList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.fetchEmergencyUserList.isLoading = false;
          state.fetchEmergencyUserList.isSuccess = true;
          state.fetchEmergencyUserList.emergencyUserList =
            action.payload.result;
        }
      )
      .addCase(
        fetchEmergencyUserList.rejected,
        (state, action: PayloadAction<any>) => {
          state.fetchEmergencyUserList.isLoading = false;
          state.fetchEmergencyUserList.isError = true;
          state.fetchEmergencyUserList.message = action.payload;
        }
      )
      .addCase(
        fetchEMTUsersList.pending,
        (state, action: PayloadAction<any>) => {
          state.fetchEMTUsersList.isLoading = true;
        }
      )
      .addCase(
        fetchEMTUsersList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.fetchEMTUsersList.isLoading = false;
          state.fetchEMTUsersList.isSuccess = true;
          state.fetchEMTUsersList.emtUsersList =
            action.payload.result;
        }
      )
      .addCase(
        fetchEMTUsersList.rejected,
        (state, action: PayloadAction<any>) => {
          state.fetchEMTUsersList.isLoading = false;
          state.fetchEMTUsersList.isError = true;
          state.fetchEMTUsersList.message = action.payload;
        }
      )
      .addCase(
        generateEMSReport.pending,
        (state, action: PayloadAction<any>) => {
          state.generateEMSReport.isLoading = true;
        }
      )
      .addCase(
        generateEMSReport.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.generateEMSReport.isLoading = false;
          state.generateEMSReport.isSuccess = true;
          state.generateEMSReport.generatedReport =
            action.payload.result;
        }
      )
      .addCase(
        generateEMSReport.rejected,
        (state, action: PayloadAction<any>) => {
          state.generateEMSReport.isLoading = false;
          state.generateEMSReport.isError = true;
          state.generateEMSReport.message = action.payload;
        }
      )
      .addCase(
        generateCSV.pending,
        (state, action: PayloadAction<any>) => {
          state.generateCSV.isLoading = true;
        }
      )
      .addCase(
        generateCSV.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.generateCSV.isLoading = false;
          state.generateCSV.isSuccess = true;
          state.generateCSV.generatedCSVResult =
            action.payload.result;
        }
      )
      .addCase(
        generateCSV.rejected,
        (state, action: PayloadAction<any>) => {
          state.generateCSV.isLoading = false;
          state.generateCSV.isError = true;
          state.generateCSV.message = action.payload;
        }
      )
      .addCase(
        downloadCSV.pending,
        (state, action: PayloadAction<any>) => {
          state.downloadCSV.isLoading = true;
        }
      )
      .addCase(
        downloadCSV.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.downloadCSV.isLoading = false;
          state.downloadCSV.isSuccess = true;
          // state.downloadCSV.generatedCSVResult =
          //   action.payload.result;
        }
      )
      .addCase(
        downloadCSV.rejected,
        (state, action: PayloadAction<any>) => {
          state.downloadCSV.isLoading = false;
          state.downloadCSV.isError = true;
          state.downloadCSV.message = action.payload;
        }
      )
  },
});

export const {
  resetFetchEMSList,
  resetCaseTypeList,
  resetEmergencyUserList,
  resetEMTUsersList,
  resetGeneratedReport
} = emsReportManagementSlice.actions;

export default emsReportManagementSlice.reducer;
