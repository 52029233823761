import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useGroup } from "../../../../data/groupContextApi";
import "./lineGraph.scss";
import { useEffect, useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface lineGraphPropsTypes {
  data: any;
  chartRef: any;
  isClicked: boolean;
  selectedReportID: any;
  typeOfReportID: any;
}

export const LineGraph = ({
  data,
  chartRef,
  isClicked,
  selectedReportID,
  typeOfReportID,
}: lineGraphPropsTypes) => {

  const [dataForGraph, setDataForGraph] = useState<any>();
  const [graphOptions,setGraphOptions] = useState<any>()
  const reg_code = localStorage.getItem("reg_code") ?? "";
  const { isDarkMode } = useGroup();

  function getRandomColor() {
    // Generate random values for red, green, and blue
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Combine the values to form a CSS-friendly color string
    const color = `rgb(${red}, ${green}, ${blue})`;

    return color;
  }

 

  // Graph options general
  const optionsForOneAndTwo:any = {
    maintainAspectRatio: false,
    height:700,
    responsive: true,
    scales: {
      x: {
        grid: {
          color: (isDarkMode && !isClicked) ?  "rgba(221, 209, 215, 0.3)": "#969595",
        },
        ticks: {
          color:(isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
      y: {
        grid: {
          color:(isDarkMode && !isClicked) ?  "rgba(221, 209, 215, 0.3)": "#969595",
        },
        ticks: {
          color:(isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
    },
  }

  // Graph options for
  // Cases in the Accept-to-Transfer Time Range &
  // Cases in the Timer Duration Range
  const optionsForThreeAndFour: any = {
    maintainAspectRatio: false,
    height: 700,
    responsive: true,
    scales: {
      x: {
        grid: {
          color:
            (isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.3)" : "#969595",
        },
        ticks: {
          callback: (val:any, index:any, tick:any) => {
            const dataLenghtEven:boolean = data?.data.length % 2 ===0
            const value = dataLenghtEven ? (data?.data.length/2)-1 : ((data?.data.length-1)/2)
            // Use arrow function to avoid 'this' binding issues
            return index === value  ? data?.labels[0] : '';
          },
          color:
            (isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
      y: {
        grid: {
          color:
            (isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.3)" : "#969595",
        },
        ticks: {
          color:
            (isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
    },
  };

  useEffect(() => {

    
    // Graph data for
    // Cases in the Accept-to-Transfer Time Range &
    // Cases in the Timer Duration Range
    if ((selectedReportID === 3||selectedReportID === 4) && data && (reg_code==="5067"||reg_code==="0010")) {
      const color = getRandomColor();
      setGraphOptions(optionsForThreeAndFour)
      setDataForGraph({
        labels: data?.data?.map((value:any,index:any)=>{
          return data?.labels[0]
        }),
        datasets: [
          {
            label:selectedReportID === 3 ? "Cases in the accept to transfer range" : "Cases in the time duration range",
            data: data?.data,
            borderColor: color,
            backgroundColor: color,
            tension: 0.4,
          },
        ],
      });
    }else if(Object.keys(data).length !== 0){
      const objToArrayData: any = Object.entries(data);
  
      const labels = objToArrayData[0][1].map((data: any, index: any) => {
        return data.key;
      });
  
      const dataSet = objToArrayData.map(
        ([caseType, data]: any, index: any) => {
          const color = getRandomColor();
          return {
            label: caseType,
            data: data.map((data: any, index: any) => {
              return data.value;
            }),
            borderColor: color,
            backgroundColor: color,
            tension: 0.4,
          };
        }
      );
  
      setGraphOptions(optionsForOneAndTwo)
  
      setDataForGraph({
        labels,
        datasets: dataSet,
      });
    }

  }, [data,isClicked,isDarkMode]);

  return (
    <div className="graph-wrapper">
      {dataForGraph && (
        <Line ref={chartRef} options={graphOptions} data={dataForGraph} />
      )}
    </div>
  );
};
