import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined, } from "@ant-design/icons";
import { Button, Col, Divider, Input, Modal, Row, Select, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { LiaSearchSolid } from "react-icons/lia";
import { MdOutlineSubject } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RootState, appDispatch } from "../../app/store";
import SimpleButton from "../../components/buttons/simpleButton/SimpleButton";
import { CareTeamOptions } from "../../constants";
import { deleteCareTeam, fetchCareTeamList, resetDeleteCareTeam, resetFetchCareTeamList } from "../../features/careTeam/careTeamSlice";
import { fetchSiteList, resetFetchSiteList } from "../../features/unitDepartment/unitDepartmentSlice";
import { AddCareTeamCard } from "./addCareTeamCard/AddCareTeamCard";
import { CareTeamTable } from "./careTeamTable/CareTeamTable";
import "./careTeams.scss";

const { confirm } = Modal
export const CareTeams: React.FC = () => {


  const { careTeamListData, isLoading } = useSelector((state: RootState) => state.careTeam.fetchCareTeamList)
  const { isLoading: isLoadingDeleteCareTeam, isSuccess: isSuccessDeleteCareTeam, isError: isErrorDeleteCareTeam, message: messageDeleteCareTeam } = useSelector((state: RootState) => state.careTeam.deleteCareTeam)
  const { siteListData, isLoading: isLoadingFetchSiteList } = useSelector((state: RootState) => state.unitDepartment.fetchSiteList);

  const dispatch = useDispatch<appDispatch>()

  const [searchQuery, setSearchQuery] = useState<string>("")
  const [careTeamTableDataApi, setCareTeamTableDataApi] = useState([])
  const [selectedSiteOption, setSelectedSiteOption] = useState("")
  const [selectedCategoryOption, setSelectedCategoryOption] = useState("")

  const [selectedTableRowKeys, setSelectedTableRowKeys] = useState<React.Key[]>([])
  const [selectedTableRows, setSelectedTableRows] = useState<any>([]);

  const [isAddCareTeamModalVisible, setIsAddCareTeamModalVisible] = useState<boolean>(false)
  const [isFilterModalVisible, setIsFilterModalVisible] = useState<boolean>(false)
  const [isIpad, setIsIpad] = useState<boolean>(false)
  const [isMobile, setIsmobile] = useState<boolean>(window.innerWidth < 478)

  const [isFirstTimeFiltering, setIsFirstTimeFiltering] = useState(true)
  const [lastFilterValues, setLastFilterValues] = useState({
    siteId: "",
    categoryId: "",
  });

  const handleResize = () => {
    setIsmobile(window.innerWidth < 478)
    setIsIpad(window.innerWidth >= 768 && window.innerWidth <= 1024 && (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth))
  }

  // checking device is mobile or not
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.addEventListener("resize", handleResize)
    }
  }, [])


  //to fetch care team list from server when page initially loads
  useEffect(() => {
    dispatch(fetchCareTeamList({ searchQuery, categoryId: selectedCategoryOption, siteId: selectedSiteOption }))
    dispatch(fetchSiteList());

    return () => {
      dispatch(resetFetchCareTeamList())
      dispatch(resetFetchSiteList())
    }
  }, [])

  //handle api response for delete care team api
  useEffect(() => {
    if (isSuccessDeleteCareTeam) {
      toast.success(messageDeleteCareTeam)
      clearSelectedTableRows()
      dispatch(fetchCareTeamList({ searchQuery, categoryId: selectedCategoryOption, siteId: selectedSiteOption }))

    } else if (isErrorDeleteCareTeam) {
      toast.error(messageDeleteCareTeam)
    }
    return () => {
      dispatch(resetDeleteCareTeam())
    }
  }, [isSuccessDeleteCareTeam, isErrorDeleteCareTeam, messageDeleteCareTeam])

  //to set care team table by default
  useEffect(() => {
    setCareTeamTableDataApi(careTeamListData)
  }, [careTeamListData])


  //handle care team search
  const handleCareTeamSearch = () => {
    dispatch(fetchCareTeamList({ searchQuery, categoryId: selectedCategoryOption, siteId: selectedSiteOption }))
  }

  //handle add care team
  const handleAddCareTeam = () => {
    clearSelectedTableRows()
    setIsAddCareTeamModalVisible(true)
  }

  //handle modal close for add/edit care team
  const handleAddCareTeamClose = () => {
    setIsAddCareTeamModalVisible(false)
  }

  //handle delete button is disabled or not
  const isDeleteButtonDisabled = () => {
    return (
      (selectedTableRowKeys && selectedTableRowKeys.length === 0) ||
      selectedTableRowKeys === undefined
    );
  };

  //handle delete selected care team
  const handleDeleteSelectedCareTeam = () => {

    // Extract names from selected Support Staff Rows
    const selectedCareTeamNames = selectedTableRows.map((row: any) => row.name).join(', ');

    // Display confirmation modal
    confirm({
      title: "Delete  Care Team confirmation",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: (<p>Are you sure you want to delete  <b>{selectedCareTeamNames}</b>  Care Team ? </p>),
      onOk() {
        const payload = {
          care_team_id: selectedTableRowKeys
        }
        dispatch(deleteCareTeam(payload))
      },
      // Do nothing if user cancels
      onCancel() { },
    });
  };

  //handle to clear selected table rows
  const clearSelectedTableRows = () => {
    if (selectedTableRowKeys) {
      setSelectedTableRowKeys([]);
      setSelectedTableRows([]);
    }
  };

  //handle clear filters
  const handleClearFilters = () => {
    setSelectedSiteOption("")
    setSelectedCategoryOption("")
  }

  //hanlde to clear last filter values
  const handleClearLastFilterValues = () => {
    setLastFilterValues({
      siteId: "",
      categoryId: "",
    })
  }
  //handle site change in filter modal
  const handleSiteChange = (value: string) => {
    setSelectedSiteOption(value)
  }

  //handle category change in filter modal
  const handleCategoryChange = (value: string) => {
    setSelectedCategoryOption(value)
  }

  //handle cancel filter modal
  const handleCancelFilterModal = () => {
    setIsFilterModalVisible(false)
    setSelectedCategoryOption(lastFilterValues.categoryId)
    setSelectedSiteOption(lastFilterValues.siteId)
  }

  //handle submit filter modal
  const handleFilterModalSubmit = () => {
    setIsFilterModalVisible(false)
    dispatch(fetchCareTeamList({ searchQuery, categoryId: selectedCategoryOption, siteId: selectedSiteOption }))
    setLastFilterValues({
      categoryId: selectedCategoryOption, siteId: selectedSiteOption,
    });
  }

  return (
    <div className="Care-team-container">
      {/* Search header */}
      <Row>
        <Col xs={24} md={24}>
          <div className="Care-team-header">Search by name</div>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {/* Search bar & button*/}
        <Col xs={24} sm={15} md={12} lg={13} xl={15}>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
              }}
            >
              <Col xs={16} md={18} lg={24} span={10}>
                <span className="search-bar">
                  <Input
                    placeholder="Search"
                    prefix={<LiaSearchSolid className="search-bar-icon" />}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </span>
              </Col>
              <Col xs={8} md={6} lg={4}>
                <span>
                  <SimpleButton
                    text={isMobile ? "" : "Search"}
                    color="primary"
                    icon={<SearchOutlined />}
                    onClick={handleCareTeamSearch}
                  />
                </span>
              </Col>
            </div>
          </Row>
        </Col>

        {/* Add, Delete & Filter button*/}
        <Col xs={24} sm={9} md={12} lg={11} xl={9}>
          <Row justify="end" gutter={10} className="add-Care-team-sec ">
            <Space direction="horizontal" align="center">
              {/* Add new status board button */}
              <Tooltip title={isMobile || isIpad ? "" : "Add New Care Team"} placement="bottomRight">
                <span>
                  <SimpleButton
                    icon={<PlusOutlined className="add-Care-team-icon" />}
                    text={isMobile ? "" : "Add"}
                    color="secondary"
                    onClick={handleAddCareTeam}
                  />
                </span>
              </Tooltip>
              <AddCareTeamCard
                open={isAddCareTeamModalVisible}
                onClose={handleAddCareTeamClose}
                isEditMode={false}
                handleClearFilters={handleClearFilters}
                handleClearLastFilterValues={handleClearLastFilterValues}
                searchQuery={searchQuery}
              />

              {/*Delete button */}
              <SimpleButton
                text={isMobile ? "" : "Delete"}
                color={
                  isDeleteButtonDisabled()
                    ? "danger-disabled"
                    : "danger-default"
                }
                disabled={isDeleteButtonDisabled()}
                icon={
                  <RiDeleteBin6Line
                    color={
                      isDeleteButtonDisabled()
                        ? "danger-disabled"
                        : "danger-default"
                    }
                  />
                }
                onClick={handleDeleteSelectedCareTeam}
              />

              {/*filter button */}
              <Button
                icon={<MdOutlineSubject />}
                className="filter-button"
                onClick={() => {
                  setIsFilterModalVisible(true);
                  clearSelectedTableRows()
                }}
              >
                {isMobile ? "" : "Filter"}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>

      <Divider />

      {/* Filter modal */}
      <div style={{ paddingBottom: "10px" }}>
        <Modal
          width={300}
          centered={true}
          closeIcon={false}
          open={isFilterModalVisible}
          className="ct-filter-modal"
          footer={[
            <Button key="cancel" onClick={handleCancelFilterModal}>
              Cancel
            </Button>,
            <Button
              key="apply"
              type="primary"
              // style={{ margin: "0 1rem 1rem 0" }}
              onClick={handleFilterModalSubmit}
            >
              OK
            </Button>,
          ]}
        >
          <div className="popover-content" style={{ padding: "5px" }}>
            <div className="popover-header">
              <h4>Filter</h4>
              <Button onClick={handleClearFilters} className="clear-all-button">
                Clear All
              </Button>
            </div>
            <div className="popover-item">
              <label className="label-text">By Site</label>
              <Select
                value={selectedSiteOption}
                onChange={handleSiteChange}
                style={{ width: "100%" }}
                virtual={false}
                loading={isLoadingFetchSiteList}
                options={[{ value: "", label: "All" }, ...siteListData?.map((option: any) => ({
                  value: option?.id,
                  label: option?.name
                }))]}
              />

            </div>
            <div className="popover-item">
              <label className="label-text">By Category</label>
              <Select
                value={selectedCategoryOption}
                onChange={handleCategoryChange}
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                virtual={false}
                options={[{ value: "", label: "All" }, ...CareTeamOptions]}
              />
            </div>
          </div>
        </Modal>
      </div >

      {/* Status board table */}
      < CareTeamTable
        careTeamTableDataApi={careTeamTableDataApi}
        selectedTableRowKeys={selectedTableRowKeys}
        setSelectedTableRowKeys={setSelectedTableRowKeys}
        isMobile={isMobile}
        setSelectedTableRows={setSelectedTableRows}
        handleClearFilters={handleClearFilters}
        handleClearLastFilterValues={handleClearLastFilterValues}
        selectedSiteOption={selectedSiteOption}
        selectedCategoryOption={selectedCategoryOption}
        searchQuery={searchQuery}
      />
    </div >
  )
}
