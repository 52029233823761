import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import loginService from "./loginService";

interface PrivacyPolicy {
  privacyData: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: PrivacyPolicy = {
  privacyData: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//to get privacy policy
export const privacyPolicy = createAsyncThunk("auth/privacy-policy", async (_, thunkAPI) => {
  try {
    const response = await loginService.getPrivacyPolicy();
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const privacyPolicySlice = createSlice({
  name: "privacyPolicy",
  initialState,
  reducers: {
    resetPrivacyPolicy: (state) => {
      state.privacyData = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(privacyPolicy.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(privacyPolicy.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.privacyData = action.payload;
      })
      .addCase(privacyPolicy.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetPrivacyPolicy } = privacyPolicySlice.actions;
