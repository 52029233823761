import { Layout } from "antd";
import { Suspense, lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../app/store";
import Spinner from "../../components/spinner/Spinner";
import { THEMEVALUE } from "../../constants";
import "./pageRouter.scss";

const Navbar = lazy(() => import("../../components/layout/navbar/Navbar"));
const Sidebar = lazy(() => import("../../components/layout/sidebar/Sidebar"));
const MainContent = lazy(
  () => import("../../components/layout/mainContent/MainContent")
);

const PageRouter = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    document.body.className =
      localStorage.getItem("theme") &&
        JSON.parse(localStorage.getItem("theme") || "")
        ? THEMEVALUE.DARK_THEME
        : THEMEVALUE.LIGHT_THEME;
  }, []);

  // useEffect(() => {
  //   if (!user) {
  //     navigate("/login");
  //   }
  // }, [user, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 478 || (window.innerWidth >= 728 && window.innerWidth <= 1200 && (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth)));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout className="main-container">
      <Suspense>
        <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      </Suspense>
      <Layout>
        <Suspense>
          <Navbar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        </Suspense>
        <Suspense fallback={<Spinner />}>
          <MainContent />
        </Suspense>
      </Layout>
    </Layout>
  );
};

export default PageRouter;
