import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  Tooltip,
  Empty,
} from "antd";
import SimpleButton from "../../components/buttons/simpleButton/SimpleButton";
import { AddHelp } from "./components/addHelp/AddHelp";
import { HelpVideo } from "./components/helpVideo/HelpVideo";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import { LiaSearchSolid } from "react-icons/lia";
import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RootState, appDispatch } from "../../app/store";
import { closestCenter, DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import VirtualList from "rc-virtual-list";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHelpVideoData,
  changeHelpVideoOrder,
  deleteHelpVideo,
  resetFetchHelpVideoData,
} from "../../features/help/helpSlice";
import helpService from "../../features/help/helpService";
import "./help.scss";

export const Help = () => {
  const dispatch = useDispatch<appDispatch>();

  const { confirm } = Modal;

  const { helpVideoData, isLoading } = useSelector(
    (state: RootState) => state.help.fetchHelpVideoData
  );

  const { fetchSidebarAndCardItemsData } = useSelector(
    (state: RootState) => state.home.fetchSidebarAndCardItems
  );

  const { token } = useSelector((state: RootState) => state.auth.user);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedVideos, setSelectedVideos] = useState<any>([]);
  const [isShowHiddenVideos, setIsShowHiddenVideos] = useState<boolean>(false);
  const [fetchedVideosListData, setFecthedVideosListData] = useState<any>([]);
  const [isAddHelpModalOpen, setIsAddHelpModalOpen] = useState<boolean>(false);
  const [maxOrder, setMaxOrder] = useState<number>();
  const [notModifiedData, setNotModifiedData] = useState<any>([]);
  const [userPermissions, setUserPermissions] = useState<any>({});

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isIpad, setIsIpad] = useState<boolean>(false);
  const [isCheckedSelectAll,setIsCheckedSelectAll] = useState<boolean>(false)

  const MAX_HEIGHT = isIpad ? 1000 : 500;

  const handleFeatureSearch = () => {
    const payload = {
      searchquery: searchQuery,
      hide: !isShowHiddenVideos,
      pageNumber: 1,
    };
    setPageNumber(1);
    dispatch(fetchHelpVideoData(payload));
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 478);
    setIsIpad(window.innerWidth >= 768 && window.innerWidth <= 900);
  };

  const handleVideoDelete = async (id: any) => {
    confirm({
      title: "Delete Video confirmation",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: <p>Do you want to delete this video ?</p>,
      async onOk() {
        const payload = {
          help_ids: id,
        };
        const response = await dispatch(deleteHelpVideo(payload));
        const fetchPayload = {
          searchquery: "",
          hide: true,
          pageNumber: 1,
        };
        dispatch(fetchHelpVideoData(fetchPayload));
        if (response?.payload?.isSuccess) {
          toast.success(response?.payload?.message);
          setSelectedVideos([]);
        } else {
          toast.error(response?.payload?.message);
        }
      },
      onCancel() {},
    });
  };

  const isDeleteButtonDisabled = () => {
    return selectedVideos?.length === 0;
  };

  const handleAddHelp = () => {
    setIsAddHelpModalOpen(true);
  };

  const handleAddHelpModalClose = () => {
    setIsAddHelpModalOpen(false);
  };

  const handleVideoSelection = (event: any, value: any) => {
    setIsCheckedSelectAll(event.target.checked)
    if (
      event.target.checked &&
      !selectedVideos?.find((data: any) => data === value)
      ) {
      setSelectedVideos([...selectedVideos, value]);
    } else if (
      !event.target.checked &&
      selectedVideos?.find((data: any) => data === value)
    ) {
      setSelectedVideos(selectedVideos?.filter((data: any) => data !== value));
    }
  };

  const handleSelectAll = (event: any) => {
    if (event?.target?.checked) {
      setSelectedVideos(notModifiedData?.map((video: any) => video?.id));
    } else {
      setSelectedVideos([]);
    }
  };

  const handleFilterToggle = (value: any) => {
    setIsShowHiddenVideos(value);
    const payload = {
      searchquery: searchQuery,
      hide: !value,
      pageNumber: 1,
    };
    dispatch(fetchHelpVideoData(payload));
  };

  // To handle drop event when drop the element in position
  const onDragEnd = (event: any) => {
    const { over, active } = event;

    if (active?.id !== over?.id) {
      setFecthedVideosListData((prevData: any) => {
        const oldIndex = prevData?.findIndex(
          (data: any) => data?.id === active?.id
        );
        const newIndex = prevData?.findIndex(
          (data: any) => data?.id === over?.id
        );

        const arrayChanged = arrayMove(
          fetchedVideosListData,
          oldIndex,
          newIndex
        );

        const modified: any = arrayChanged.map((data: any, index) => {
          return [
            {
              id: data?.data[0]?.id,
              order: index + 1,
            },
            {
              id: data?.data[1]?.id,
              order: index + 1,
            },
          ];
        });

        // const modified: any = arrayChanged.map((data: any, index) => {
        //   return [
        //     {
        //       id: data?.web.id,
        //       order: index + 1,
        //     },
        //     {
        //       id: data?.mobile.id,
        //       order: index + 1,
        //     },
        //   ];
        // });

        const payload = [].concat(...modified);

        const dndPayload = payload.filter((data: any) => data?.id);

        dispatch(changeHelpVideoOrder(dndPayload));
        return arrayChanged.map((data: any, index) => {
          return {
            ...data,
            order: index + 1,
          };
        });
      });
    }
  };

  // To handle the pagination
  // fetching data and appending it in state
  const appendData = async (pageNumberN: number) => {
    const payload = {
      searchquery: "",
      hide: true,
      pageNumber: pageNumberN,
    };
    const response = await helpService.fetchHelpVideoData(payload, token);
    if (response?.result?.data) {
      setFecthedVideosListData((prevData: any) =>
        modifyFetchedData([...notModifiedData, ...response?.result?.data])
      );
      setNotModifiedData((prevData: any) => [
        ...prevData,
        ...response?.result?.data,
      ]);
    }
  };

  // To handle scroll action in virtual list
  const onScroll = (e: any) => {
    // checking if the scroll height is higher than max height and
    // fetched data count is less than the total count
    if (
      Math.abs(
        e.currentTarget.scrollHeight - e.currentTarget.scrollTop - MAX_HEIGHT
      ) <= 1 &&
      notModifiedData.length < helpVideoData?.count
    ) {
      setPageNumber((prevNumber) => prevNumber + 1);
      appendData(pageNumber + 1);
    }
  };

  const modifyFetchedData = (data: any) => {
    const featureTitles = data?.map((video: any) => {
      return video?.caption;
    });

    const withOutDuplicates: any = Array.from(new Set(featureTitles));

    const mobileHelpVideos = data?.filter(
      (data: any) => data?.visibility === 1
    );
    const webHelpVideos = data?.filter((data: any) => data?.visibility === 2);
    const modified = withOutDuplicates.map((title: any, index: number) => {
      return {
        id: index + 1,
        data: [
          webHelpVideos.find(
            (mobilevideo: any) => mobilevideo?.caption === title
          ),
          mobileHelpVideos.find(
            (mobilevideo: any) => mobilevideo?.caption === title
          ),
        ],
      };
    });

    // const temp = data.map((data:any,index:number)=>{
    //   return {
    //     ...data,
    //     id:index
    //   }
    // })

    return modified;
  };

  // setting user permissions on help page in a state
  useEffect(() => {
    if (fetchSidebarAndCardItemsData) {
      const helpPermissions = fetchSidebarAndCardItemsData?.cards?.find(
        (sideBar: any) => sideBar?.key === "help"
      );
      setUserPermissions(helpPermissions?.actions);
    }
  }, [fetchSidebarAndCardItemsData]);

  // fetching help videos in the first load
  useEffect(() => {
    const payload = {
      searchquery: "",
      hide: true,
      pageNumber: 1,
    };
    dispatch(fetchHelpVideoData(payload));
  }, []);

  // modifying api response of help video data for better format to
  // use in drag and drop and listing videos
  useEffect(() => {
    if (helpVideoData) {
      setMaxOrder(helpVideoData?.max_oder);
      // for getting exact count of the fecthed data to control pagination
      setNotModifiedData(helpVideoData?.data);
      setFecthedVideosListData(modifyFetchedData(helpVideoData?.data));
      setPageNumber(1);
      setSelectedVideos([])
      setIsCheckedSelectAll(false)
    }
  }, [helpVideoData]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(()=>{
    if(notModifiedData.length!==0&&isCheckedSelectAll){
      setSelectedVideos(notModifiedData?.map((video: any) => video?.id));
    }
  },[notModifiedData])

  return (
    <div className="help-page-container">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h2>Help</h2>
        </Col>
      </Row>
      <p className="help-search-header">Search by feature</p>
      <Row gutter={[12, 16]}>
        {/* Search section */}
        <Col xs={24} sm={24} md={24} lg={13} xl={15}>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
              }}
            >
              <Col xs={16} md={18} lg={24} span={10}>
                <span className="search-bar">
                  <Input
                    placeholder="Search"
                    prefix={<LiaSearchSolid className="search-bar-icon" />}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </span>
              </Col>
              <Col xs={8} md={6} lg={4}>
                <span>
                  <SimpleButton
                    // text={isMobile ? "" : "Search"}
                    text="Search"
                    color="primary"
                    icon={<SearchOutlined />}
                    onClick={handleFeatureSearch}
                  />
                </span>
              </Col>
            </div>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} lg={11} xl={9}>
          <Row justify="end" gutter={10} className="add-statusBoard-sec ">
            <Space direction="horizontal" align="center">
              {/* Add new status board button */}
              {userPermissions?.add && (
                <>
                  <Tooltip
                    title={isMobile || isIpad ? "" : "Add New Help"}
                    placement="bottomRight"
                  >
                    <span>
                      <SimpleButton
                        icon={<PlusOutlined className="add-statusBoard-icon" />}
                        text={isMobile ? "" : "Add"}
                        color="secondary"
                        onClick={handleAddHelp}
                      />
                    </span>
                  </Tooltip>
                  <AddHelp
                    totalVideos={maxOrder}
                    isOpen={isAddHelpModalOpen}
                    isEditMode={false}
                    onCancel={handleAddHelpModalClose}
                  />
                </>
              )}

              {/*Delete button */}
              {userPermissions?.delete && (
                <SimpleButton
                  text={isMobile ? "" : "Delete"}
                  color={
                    isDeleteButtonDisabled()
                      ? "danger-disabled"
                      : "danger-default"
                  }
                  disabled={isDeleteButtonDisabled()}
                  icon={
                    <RiDeleteBin6Line
                      color={
                        isDeleteButtonDisabled()
                          ? "danger-disabled"
                          : "danger-default"
                      }
                    />
                  }
                  onClick={() => handleVideoDelete(selectedVideos)}
                />
              )}

              {/*filter button */}
              {userPermissions?.add && (
                <div className="filter-container">
                  <p className="show-text">Hidden Videos</p>
                  <Switch
                    onChange={handleFilterToggle}
                    checkedChildren="Hide"
                    unCheckedChildren="Show"
                  />
                </div>
              )}
            </Space>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider></Divider>
        </Col>
      </Row>
      {/* Select all checkbox section only show when any of the checkbox in video checked */}
      {selectedVideos?.length !== 0 && (
        <Row style={{ textAlign: "left", margin: ".5rem 0 1rem 0" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Checkbox
              onChange={handleSelectAll}
              checked={selectedVideos?.length === notModifiedData?.length}
            >
              {selectedVideos?.length === notModifiedData?.length
                ? "Unselect All"
                : "Select All"}
            </Checkbox>
          </Col>
        </Row>
      )}
      {isLoading ? (
        <Row>
          <Col span={24}>
            <div style={{ height: "60vh" }}>
              <Spinner />
            </div>
          </Col>
        </Row>
      ) : fetchedVideosListData.length !== 0 ? (
        <>
          <Row
            gutter={[24, 54]}
            style={{
              maxHeight: `${isIpad ? "45rem" : "35rem"}`,
              overflowX: "hidden",
              overflowY:"hidden"
            }}
          >
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={onDragEnd}
            >
              <SortableContext
                items={fetchedVideosListData}
                strategy={rectSortingStrategy}
              >
                <VirtualList
                  data={fetchedVideosListData}
                  height={MAX_HEIGHT}
                  itemKey="id"
                  onScroll={onScroll}
                  className="help-video-virtual-list"
                >
                  {(videoData: any, i: number) => (
                    <HelpVideo
                      video={videoData}
                      handleVideoSelection={handleVideoSelection}
                      handleVideoDelete={handleVideoDelete}
                      key={videoData?.id}
                      totalVideos={maxOrder}
                      selectedVideos={selectedVideos}
                      userPermissions={userPermissions}
                    />
                  )}
                </VirtualList>
                {/* {fetchedVideosListData?.map((videoData: any, i: number) => {
                return (
                  <HelpVideo
                    video={videoData}
                    handleVideoSelection={handleVideoSelection}
                    handleVideoDelete={handleVideoDelete}
                    key={videoData?.id}
                    totalVideos={totalVideos}
                    selectedVideos={selectedVideos}
                  />
                );
              })} */}
              </SortableContext>
            </DndContext>
          </Row>
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"No videos found"}
        />
      )}
    </div>
  );
};
