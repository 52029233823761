import { API_ENDPOINTS, API_URL } from "../../constants";
import { User } from "../../pages/login/login.model";
import axiosInstance from "../../core/apiService/webApiService";
import { StorageService } from "../../core/storageService/storageService";

//Login user
const authService = async (userData: User): Promise<any> => {
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.AUTH.AUTH_LOGIN}`, userData);
  return response.data;
};

//Logout user
const logout = (): void => {
  localStorage.removeItem("user");
  localStorage.removeItem("reg_code");
};

//login recover service - for forgot password
const loginRecoverService = async (userData: User): Promise<any> => {
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.AUTH.RECOVER_LOGIN}`, userData);
  return response.data;
};

//forgot username service
const forgotUsernameService = async (userData: User): Promise<any> => {
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.AUTH.FORGOT_USERNAME}`, userData);
  return response.data;
};

//reset password service
const resetPasswordService = async (userData: User): Promise<any> => {
  const response = await axiosInstance.put(`${API_URL}${API_ENDPOINTS.AUTH.RESET_PASSWORD}`, userData);
  return response.data;
};

//to get privacy policy
const getPrivacyPolicy = async (): Promise<any> => {
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.AUTH.GET_PRIVACY_POLICY}`);
  return response.data;
};

//to get terms and conditons
const getTermsCondition = async (): Promise<any> => {
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.AUTH.GET_TERMS_CONDTIONS}`);
  return response.data;
};

const loginService = {
  authService,
  logout,
  loginRecoverService,
  resetPasswordService,
  forgotUsernameService,
  getPrivacyPolicy,
  getTermsCondition,
};

export default loginService;
