import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import scheduleReportService from "./scheduleReportService";

interface scheduleReportState {
  fetchOnCallLookupHistoryGroupList: {
    OnCallLookupHistoryGroupListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleList: {
    scheduleListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchSiteList: {
    siteListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUserShiftScheduleReport: {
    userShiftScheduleReportData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleHistoryReport: {
    scheduleHistoryReportData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchOnCallLookupHistoryReport: {
    onCallLookupHistoryReportData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: scheduleReportState = {
  fetchOnCallLookupHistoryGroupList: {
    OnCallLookupHistoryGroupListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleList: {
    scheduleListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchSiteList: {
    siteListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUserShiftScheduleReport: {
    userShiftScheduleReportData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleHistoryReport: {
    scheduleHistoryReportData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchOnCallLookupHistoryReport: {
    onCallLookupHistoryReportData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

export const fetchScheduleList = createAsyncThunk(
  "scheduleReport/fetchScheduleList",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await scheduleReportService.fetchScheduleList(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUserShiftScheduleReport = createAsyncThunk(
  "scheduleReport/fetchUserShiftScheduleReport",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await scheduleReportService.fetchUserShiftScheduleReport(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchScheduleHistoryReport = createAsyncThunk(
  "scheduleReport/fetchScheduleHistoryReport",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await scheduleReportService.fetchScheduleHistoryReport(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchOnCallLookupHistoryReport = createAsyncThunk(
  "scheduleReport/fetchOnCallLookupHistoryReport",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await scheduleReportService.fetchOnCallLookupHistoryReport(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//to fetch group list by site id to display in "ON Call Lookup History" filter modal
export const fetchOnCallLookupHistoryGroupList = createAsyncThunk(
  "scheduleReport/fetchOnCallLookupHistoryGroupList",
  async (siteId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await scheduleReportService.fetchOnCallLookupHistoryGroupList(siteId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const scheduleReportSlice = createSlice({
  name: "scheduleReport",
  initialState,
  reducers: {
    resetFetchOnCallLookupHistoryGroupList: (state) => {
      state.fetchOnCallLookupHistoryGroupList = initialState.fetchOnCallLookupHistoryGroupList;
    },
    resetFetchScheduleList: (state) => {
      state.fetchScheduleList = initialState.fetchScheduleList;
    },
    resetFetchSiteList: (state) => {
      state.fetchSiteList = initialState.fetchSiteList;
    },
    resetfetchUserShiftScheduleReport: (state) => {
      state.fetchUserShiftScheduleReport = initialState.fetchUserShiftScheduleReport;
    },
    resetfetchScheduleHistoryReport: (state) => {
      state.fetchScheduleHistoryReport = initialState.fetchScheduleHistoryReport;
    },
    resetfetchOnCallLookupHistoryReport: (state) => {
      state.fetchOnCallLookupHistoryReport = initialState.fetchOnCallLookupHistoryReport;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchScheduleList.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = true;
      })
      .addCase(fetchScheduleList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = false;
        state.fetchScheduleList.isSuccess = true;
        state.fetchScheduleList.scheduleListData = action.payload.result;
      })
      .addCase(fetchScheduleList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = false;
        state.fetchScheduleList.isError = true;
        state.fetchScheduleList.message = action.payload;
      })

      .addCase(fetchUserShiftScheduleReport.pending, (state, action: PayloadAction<any>) => {
        state.fetchUserShiftScheduleReport.isLoading = true;
      })
      .addCase(fetchUserShiftScheduleReport.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUserShiftScheduleReport.isLoading = false;
        state.fetchUserShiftScheduleReport.isSuccess = true;
        state.fetchUserShiftScheduleReport.userShiftScheduleReportData = action.payload.result;
      })
      .addCase(fetchUserShiftScheduleReport.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUserShiftScheduleReport.isLoading = false;
        state.fetchUserShiftScheduleReport.isError = true;
        state.fetchUserShiftScheduleReport.message = action.payload;
      })
      .addCase(fetchScheduleHistoryReport.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleHistoryReport.isLoading = true;
      })
      .addCase(fetchScheduleHistoryReport.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleHistoryReport.isLoading = false;
        state.fetchScheduleHistoryReport.isSuccess = true;
        state.fetchScheduleHistoryReport.scheduleHistoryReportData = action.payload.result;
      })
      .addCase(fetchScheduleHistoryReport.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleHistoryReport.isLoading = false;
        state.fetchScheduleHistoryReport.isError = true;
        state.fetchScheduleHistoryReport.message = action.payload;
      })
      .addCase(fetchOnCallLookupHistoryReport.pending, (state, action: PayloadAction<any>) => {
        state.fetchOnCallLookupHistoryReport.isLoading = true;
      })
      .addCase(fetchOnCallLookupHistoryReport.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchOnCallLookupHistoryReport.isLoading = false;
        state.fetchOnCallLookupHistoryReport.isSuccess = true;
        state.fetchOnCallLookupHistoryReport.onCallLookupHistoryReportData = action.payload.result;
      })
      .addCase(fetchOnCallLookupHistoryReport.rejected, (state, action: PayloadAction<any>) => {
        state.fetchOnCallLookupHistoryReport.isLoading = false;
        state.fetchOnCallLookupHistoryReport.isError = true;
        state.fetchOnCallLookupHistoryReport.message = action.payload;
      })

      .addCase(fetchOnCallLookupHistoryGroupList.pending, (state, action: PayloadAction<any>) => {
        state.fetchOnCallLookupHistoryGroupList.isLoading = true;
      })
      .addCase(fetchOnCallLookupHistoryGroupList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchOnCallLookupHistoryGroupList.isLoading = false;
        state.fetchOnCallLookupHistoryGroupList.isSuccess = true;
        state.fetchOnCallLookupHistoryGroupList.OnCallLookupHistoryGroupListData = action.payload.result;
      })
      .addCase(fetchOnCallLookupHistoryGroupList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchOnCallLookupHistoryGroupList.isLoading = false;
        state.fetchOnCallLookupHistoryGroupList.isError = true;
        state.fetchOnCallLookupHistoryGroupList.message = action.payload;
      });
  },
});

export const {
  resetFetchOnCallLookupHistoryGroupList,
  resetFetchScheduleList,
  resetFetchSiteList,
  resetfetchUserShiftScheduleReport,
  resetfetchScheduleHistoryReport,
  resetfetchOnCallLookupHistoryReport,
} = scheduleReportSlice.actions;

export default scheduleReportSlice.reducer;
