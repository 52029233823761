import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";
import { payloadType } from "../../pages/emsReportManagement/EmsReportManagement";

const fetchEMSList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.EMS_REPORT_MANAGEMENT.FETCH_EMS_LIST}`,
    config
  );
  return response.data;
};

const fetchCaseTypeList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.EMS_REPORT_MANAGEMENT.FETCH_CASE_TYPE_LIST}`,
    config
  );
  return response.data;
};

const fetchEmergencyUserList = async (siteID:any,token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.EMS_REPORT_MANAGEMENT.FETCH_EMERGENCY_USER_LIST}?site=${siteID}`,
    config
  );
  return response.data;
};

const fetchEMTUsersList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.EMS_REPORT_MANAGEMENT.FETCH_EMT_USERS_LIST}`,
    config
  );
  return response.data;
};

const generateEMSReport = async (
  {
    patientType,
    site,
    emtUsers,
    ems,
    caseType,
    emergencyUser,
    startTime,
    endTime,
    startDate,
    endDate,
    reportID,
    timeZone,
  }: payloadType,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.EMS_REPORT_MANAGEMENT.GENERATE_EMS_REPORT}?report_id=${reportID}&timezone=${timeZone}&start_time=${startTime}&end_time=${endTime}&start_date=${startDate}&end_date=${endDate}&patient_type=${patientType}&ems=${ems}&site=${site}&emergency_users=${emergencyUser}&case_types=${caseType}&emt_users=${emtUsers}`,
    config
  );
  return response.data;
};

const generateCSV = async (
  {
    patientType,
    site,
    emtUsers,
    ems,
    caseType,
    emergencyUser,
    startTime,
    endTime,
    startDate,
    endDate,
    reportID,
    timeZone,
  }: payloadType,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response:any = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.EMS_REPORT_MANAGEMENT.GENERATE_CSV_REPORT}?report_id=${reportID}&timezone=${timeZone}&start_time=${startTime}&end_time=${endTime}&start_date=${startDate}&end_date=${endDate}&patient_type=${patientType}&ems=${ems}&site=${site}&emergency_users=${emergencyUser}&case_types=${caseType}&emt_users=${emtUsers}`,
    config
  );

  return response.data;
};
const downloadCSV = async (
  {
    patientType,
    site,
    emtUsers,
    ems,
    caseType,
    emergencyUser,
    startTime,
    endTime,
    startDate,
    endDate,
    reportID,
    timeZone,
    reportName
  }: payloadType,
  token: string
): Promise<any> => {
  const config:any = {
    responseType: 'blob',
    headers: {
      Authorization: `Token ${token}`, 
    },
  };
  const response:any = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.EMS_REPORT_MANAGEMENT.DOWNLOAD_CSV}?report_id=${reportID}&timezone=${timeZone}&start_time=${startTime}&end_time=${endTime}&start_date=${startDate}&end_date=${endDate}&patient_type=${patientType}&ems=${ems}&site=${site}&emergency_users=${emergencyUser}&case_types=${caseType}&emt_users=${emtUsers}`,
    config,
  );

  const blob = new Blob([response.data], { type: 'text/csv' });

  // Create a download link
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${reportName}.csv`;

  // Append the link to the document and trigger the download
  document.body.appendChild(a);
  a.click();

  // Clean up: remove the link and revoke the object URL
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
  return response.data;
};

const emsReportManagementService = {
  fetchEMSList,
  fetchCaseTypeList,
  fetchEmergencyUserList,
  fetchEMTUsersList,
  generateEMSReport,
  generateCSV,
  downloadCSV
};

export default emsReportManagementService;
