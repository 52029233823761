
export const LogoSvg = () => {
  return (
    <>
      <svg
        width="149"
        height="149"
        viewBox="0 0 149 149"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M128.641 60.0001H106V40.5001C106 36.5218 104.42 32.7065 101.607 29.8935C98.7937 27.0804 94.9783 25.5001 91.0001 25.5001H92.0001C88.0219 25.5001 84.2065 27.0804 81.3935 29.8935C78.5804 32.7065 77.0001 36.5218 77.0001 40.5001V60.0001H58.9541C54.988 60.0004 51.1843 61.5761 48.38 64.3807C45.5756 67.1852 44.0001 70.989 44.0001 74.9551V74.0451C44.0001 78.0112 45.5756 81.8149 48.38 84.6195C51.1843 87.4241 54.988 88.9998 58.9541 89.0001H77.0001V108C77.0001 111.978 78.5804 115.794 81.3935 118.607C84.2065 121.42 88.0219 123 92.0001 123H91.0001C94.9783 123 98.7937 121.42 101.607 118.607C104.42 115.794 106 111.978 106 108V89.0001H128.5C131.194 90.7327 133.492 93.0145 135.244 95.6964C136.996 98.3783 138.161 101.4 138.665 104.563C131.833 119.055 120.269 130.787 105.878 137.827C91.4867 144.868 75.1266 146.797 59.4925 143.296C43.8585 139.796 29.883 131.074 19.8682 118.569C9.85334 106.064 4.39648 90.5207 4.39648 74.4996C4.39648 58.4785 9.85334 42.9351 19.8682 30.4299C29.883 17.9247 43.8585 9.20356 59.4925 5.70301C75.1266 2.20247 91.4867 4.13132 105.878 11.1719C120.269 18.2124 131.833 29.9447 138.665 44.4361C137.052 54.4541 129.729 59.9821 128.641 60.0001Z"
          fill="#21C278"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M128.641 59.9999H106V40.4999C106 36.5217 104.42 32.7063 101.607 29.8933C98.7937 27.0803 94.9784 25.4999 91.0001 25.4999H92.0001C88.0219 25.4999 84.2066 27.0803 81.3935 29.8933C78.5805 32.7063 77.0001 36.5217 77.0001 40.4999V59.9999H58.9541C54.988 60.0002 51.1844 61.5759 48.38 64.3805C45.5756 67.185 44.0001 70.9888 44.0001 74.9549V74.0449C44.0001 78.011 45.5756 81.8148 48.38 84.6193C51.1844 87.4239 54.988 88.9996 58.9541 88.9999H77.0001V108C77.0001 111.978 78.5805 115.793 81.3935 118.607C84.2066 121.42 88.0219 123 92.0001 123H91.0001C94.9784 123 98.7937 121.42 101.607 118.607C104.42 115.793 106 111.978 106 108V88.9999H128.5C133.096 92.2933 136.497 96.9902 138.193 102.384C132.009 113.582 122.279 122.407 110.532 127.47C98.7848 132.534 85.6879 133.549 73.3009 130.356C60.9139 127.163 49.9396 119.943 42.1042 109.832C34.2687 99.7208 30.0166 87.2918 30.0166 74.4999C30.0166 61.708 34.2687 49.279 42.1042 39.1677C49.9396 29.0565 60.9139 21.8366 73.3009 18.6437C85.6879 15.4507 98.7848 16.4659 110.532 21.5296C122.279 26.5932 132.009 35.418 138.193 46.6159C135.948 54.5319 129.644 59.9829 128.641 59.9999Z"
          fill="#00A5B4"
        />
      </svg>
    </>
  );
};
