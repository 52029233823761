import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

//api call to fetch support staff list from server
const fetchSupportStaffList = async (
  { searchQuery, siteId, specialityId }: { searchQuery: string; siteId: any; specialityId: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SUPPORT_STAFF.FETCH_SUPPORT_STAFF_LIST}?q=${searchQuery}&site=${siteId}&specialisation=${specialityId}`,
    config
  );
  return response.data;
};

//api call to delete support staff
const deleteSupportStaff = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.SUPPORT_STAFF.DELETE_SUPPORT_STAFF}`, {
    ...config,
    data: payload,
  });
  return response.data;
};

//api call to add new support staff
const createSupportStaff = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SUPPORT_STAFF.ADD_SUPPORT_STAFF}`,
    payload,
    config
  );
  return response.data;
};

//api call to fetch  support staff details for editing
const fetchSupportStaffDetails = async (supportStaffId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SUPPORT_STAFF.ADD_SUPPORT_STAFF}${supportStaffId}/`,
    config
  );
  return response.data;
};

//api call to update (edit) added support staff details
const updateSupportStaff = async (
  { payload, supportStaffId }: { payload: any; supportStaffId: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.SUPPORT_STAFF.ADD_SUPPORT_STAFF}${supportStaffId}/`,
    payload,
    config
  );
  return response.data;
};

const supportStaffService = {
  fetchSupportStaffList,
  deleteSupportStaff,
  createSupportStaff,
  fetchSupportStaffDetails,
  updateSupportStaff,
};

export default supportStaffService;
