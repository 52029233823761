import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import groupService from "./groupsService";

interface GroupsSlice {
  fetchGroupList: {
    groupListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchGroupUserList: {
    groupUserListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUsersBySpeciality: {
    usersBySpecialityData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchSchedulesByGroup: {
    scheduleListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  fetchUnAssignedShiftList: {
    UnAssignedListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleDetails: {
    scheduleDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchGroupDetails: {
    groupDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  fetchUnAssignedShiftUserList: {
    UnAssignedUserListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUnAssignedShiftUser: {
    UnAssignedUserListsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  changeScheduleName: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
  };
  fetchUnAssignedRequestCover: {
    UnAssignedReqCoverData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: GroupsSlice = {
  fetchGroupList: {
    groupListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchGroupUserList: {
    groupUserListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUsersBySpeciality: {
    usersBySpecialityData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchSchedulesByGroup: {
    scheduleListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUnAssignedShiftList: {
    UnAssignedListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleDetails: {
    scheduleDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchGroupDetails: {
    groupDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUnAssignedShiftUserList: {
    UnAssignedUserListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUnAssignedShiftUser: {
    UnAssignedUserListsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  changeScheduleName: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
  fetchUnAssignedRequestCover: {
    UnAssignedReqCoverData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

//action to fetch  group list and searching group
export const fetchGroupList = createAsyncThunk(
  "groups/fetchGroupList",
  async ({ searchquery, guId }: { searchquery: string; guId: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchGroupList({ searchquery, guId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to delete group
export const deleteGroup = createAsyncThunk("groups/deleteGroup", async (groupId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await groupService.deleteGroup(groupId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch list of users belonging to a particular group.
export const fetchGroupUserList = createAsyncThunk(
  "groups/fetchGroupUserList",
  async ({ groupId, selectedSiteFromSchedule }: { groupId: any; selectedSiteFromSchedule?: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchGroupUserList({ groupId, selectedSiteFromSchedule }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to create a new group
export const createGroup = createAsyncThunk("groups/createGroup", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await groupService.createGroup(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch list of users belonging to a specaility group.
export const fetchUsersBySpeciality = createAsyncThunk(
  "groups/fetchUsersBySpeciality",
  async ({ groupId, searchQuery }: { groupId: number; searchQuery: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchUsersBySpeciality({ groupId, searchQuery }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action  to update users on the group
export const updateGroupUsers = createAsyncThunk("groups/updateGroupUsers", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await groupService.updateGroupUsers(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to  fetch group details
export const fetchGroupDetails = createAsyncThunk(
  "groups/fetchGroupDetails",
  async ({ groupId, guId }: { groupId: number; guId: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchGroupDetails({ groupId, guId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch schedule list
export const fetchSchedulesByGroup = createAsyncThunk(
  "groups/fetchSchedulesByGroup",
  async (groupId: number, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchSchedulesByGroup(groupId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUnAssignedShiftList = createAsyncThunk(
  "groups/fetchUnAssignedShiftList",
  async (groupId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchUnAssignedShiftList(groupId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUnAssignedShiftUserList = createAsyncThunk(
  "groups/fetchUnAssignedShiftUserList",
  async ({ id, is_rule_present, date }: { id: any; is_rule_present: any; date: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchUnAssignedShiftUserList(id, is_rule_present, date, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUnAssignedShiftUser = createAsyncThunk(
  "groups/fetchUnAssignedShiftUser",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchUnAssignedShiftUser(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUnAssignedRequestCover = createAsyncThunk(
  "groups/fetchUnAssignedRequestCover",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchUnAssignedRequestCover(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to create a new schedule
export const createSchedule = createAsyncThunk("groups/createSchedule", async (payload: object, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await groupService.createSchedule(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to add sites to newly created schedule
export const addSitesToSchedule = createAsyncThunk(
  "groups/addSitesToSchedule",
  async ({ newScheduleId, payload }: { newScheduleId: any; payload: object }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.addSitesToSchedule({ newScheduleId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// //action to add users to newly created schedule
// export const addUsersToSchedule = createAsyncThunk(
//   "groups/addUsersToSchedule",

//   async ({ newScheduleId, payload }: { newScheduleId: any; payload: any }, thunkAPI) => {
//     try {
//       const state = thunkAPI.getState() as RootState;
//       const token = state.auth.user.token;

//       const response = await groupService.addUsersToSchedule({ newScheduleId, payload }, token);
//       if (response.isSuccess) {
//         return response;
//       } else {
//         throw new Error(response.message);
//       }
//     } catch (error: any) {
//       const message =
//         (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

//action to add users to newly created schedule
export const addUsersToSchedule = createAsyncThunk(
  "groups/addUsersToSchedule",
  async ({ newScheduleId, payload }: { newScheduleId: any; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.addUsersToSchedule({ newScheduleId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch schedule details
export const fetchScheduleDetails = createAsyncThunk(
  "groups/fetchScheduleDetails",
  async ({ scheduleId, groupId }: { scheduleId: any; groupId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.fetchScheduleDetails({ scheduleId, groupId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to delete schedule
export const deleteSchedule = createAsyncThunk(
  "groups/deleteSchedule",
  async ({ scheduleId, groupId }: { scheduleId: number; groupId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.deleteSchedule({ scheduleId, groupId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to remove site from schedule list
export const removeSiteFromSchedule = createAsyncThunk(
  "groups/removeSiteFromSchedule",
  async ({ scheduleId, siteId }: { scheduleId: string; siteId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.removeSiteFromSchedule({ scheduleId, siteId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to remove user from schedule list
export const removeUserFromSchedule = createAsyncThunk(
  "groups/removeSiteFromSchedule",
  async ({ scheduleId, userId }: { scheduleId: string; userId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.removeUserFromSchedule({ scheduleId, userId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to update group details
export const updateGroupDetails = createAsyncThunk(
  "groups/updateGroupDetails",
  async ({ groupId, payload }: { groupId: any; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.updateGroupDetails({ groupId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to change schedule name
export const changeScheduleName = createAsyncThunk(
  "groups/changeScheduleName",
  async ({ scheduleId, groupId, payload }: { scheduleId: number; groupId: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await groupService.changeScheduleName({ scheduleId, groupId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    resetFetchGroupList: (state) => {
      state.fetchGroupList = initialState.fetchGroupList;
    },
    resetFetchGroupUserList: (state) => {
      state.fetchGroupUserList = initialState.fetchGroupUserList;
    },
    resetFetchUsersBySpeciality: (state) => {
      state.fetchUsersBySpeciality = initialState.fetchUsersBySpeciality;
    },
    resetfetchSchedulesByGroup: (state) => {
      state.fetchSchedulesByGroup = initialState.fetchSchedulesByGroup;
    },
    resetfetchUnAssignedShiftList: (state) => {
      state.fetchUnAssignedShiftList = initialState.fetchUnAssignedShiftList;
    },
    resetFetchScheduleDetails: (state) => {
      state.fetchScheduleDetails = initialState.fetchScheduleDetails;
    },
    resetFetchGroupDetails: (state) => {
      state.fetchGroupDetails = initialState.fetchGroupDetails;
    },
    resetfetchUnAssignedShiftUserList: (state) => {
      state.fetchUnAssignedShiftUserList = initialState.fetchUnAssignedShiftUserList;
    },
    resetfetchUnAssignedShiftUser: (state) => {
      state.fetchUnAssignedShiftUser = initialState.fetchUnAssignedShiftUser;
    },
    resetchangeScheduleName: (state) => {
      state.changeScheduleName = initialState.changeScheduleName;
    },
    resetFetchUnAssignedReqCover: (state) => {
      state.fetchUnAssignedRequestCover = initialState.fetchUnAssignedRequestCover;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGroupList.pending, (state, action: PayloadAction<any>) => {
        state.fetchGroupList.isLoading = true;
      })
      .addCase(fetchGroupList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchGroupList.isLoading = false;
        state.fetchGroupList.isSuccess = true;
        state.fetchGroupList.groupListData = action.payload.result;
      })
      .addCase(fetchGroupList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchGroupList.isLoading = false;
        state.fetchGroupList.isError = true;
        state.fetchGroupList.message = action.payload.result;
      })
      .addCase(fetchGroupUserList.pending, (state, action: PayloadAction<any>) => {
        state.fetchGroupUserList.isLoading = true;
      })
      .addCase(fetchGroupUserList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchGroupUserList.isLoading = false;
        state.fetchGroupUserList.isSuccess = true;
        state.fetchGroupUserList.groupUserListData = action.payload.result;
      })
      .addCase(fetchGroupUserList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchGroupUserList.isLoading = false;
        state.fetchGroupUserList.isError = true;
        state.fetchGroupUserList.message = action.payload.result;
      })
      .addCase(fetchUsersBySpeciality.pending, (state, action: PayloadAction<any>) => {
        state.fetchUsersBySpeciality.isLoading = true;
      })
      .addCase(fetchUsersBySpeciality.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUsersBySpeciality.isLoading = false;
        state.fetchUsersBySpeciality.isSuccess = true;
        state.fetchUsersBySpeciality.usersBySpecialityData = action.payload.result;
      })
      .addCase(fetchUsersBySpeciality.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUsersBySpeciality.isLoading = false;
        state.fetchUsersBySpeciality.isError = true;
        state.fetchUsersBySpeciality.message = action.payload.result;
      })
      .addCase(fetchSchedulesByGroup.pending, (state, action: PayloadAction<any>) => {
        state.fetchSchedulesByGroup.isLoading = true;
      })
      .addCase(fetchSchedulesByGroup.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSchedulesByGroup.isLoading = false;
        state.fetchSchedulesByGroup.isSuccess = true;
        state.fetchSchedulesByGroup.scheduleListData = action.payload.result;
      })
      .addCase(fetchSchedulesByGroup.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSchedulesByGroup.isLoading = false;
        state.fetchSchedulesByGroup.isError = true;
        state.fetchSchedulesByGroup.message = action.payload.result;
      })
      .addCase(fetchUnAssignedShiftList.pending, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftList.isLoading = true;
      })
      .addCase(fetchUnAssignedShiftList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftList.isLoading = false;
        state.fetchUnAssignedShiftList.isSuccess = true;
        state.fetchUnAssignedShiftList.UnAssignedListData = action.payload.result;
      })
      .addCase(fetchUnAssignedShiftList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftList.isLoading = false;
        state.fetchUnAssignedShiftList.isError = true;
        state.fetchUnAssignedShiftList.message = action.payload.result;
      })
      .addCase(fetchScheduleDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleDetails.isLoading = true;
      })
      .addCase(fetchScheduleDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleDetails.isLoading = false;
        state.fetchScheduleDetails.isSuccess = true;
        state.fetchScheduleDetails.scheduleDetailsData = action.payload.result;
      })
      .addCase(fetchScheduleDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleDetails.isLoading = false;
        state.fetchScheduleDetails.isError = true;
        state.fetchScheduleDetails.message = action.payload.result;
      })
      .addCase(fetchGroupDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchGroupDetails.isLoading = true;
      })
      .addCase(fetchGroupDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchGroupDetails.isLoading = false;
        state.fetchGroupDetails.isSuccess = true;
        state.fetchGroupDetails.groupDetailsData = action.payload.result;
      })
      .addCase(fetchGroupDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchGroupDetails.isLoading = false;
        state.fetchGroupDetails.isError = true;
        state.fetchGroupDetails.message = action.payload.result;
      })
      .addCase(fetchUnAssignedShiftUserList.pending, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftUserList.isLoading = true;
      })
      .addCase(fetchUnAssignedShiftUserList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftUserList.isLoading = false;
        state.fetchUnAssignedShiftUserList.isSuccess = true;
        state.fetchUnAssignedShiftUserList.UnAssignedUserListData = action.payload.result;
      })
      .addCase(fetchUnAssignedShiftUserList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftUserList.isLoading = false;
        state.fetchUnAssignedShiftUserList.isError = true;
        state.fetchUnAssignedShiftUserList.message = action.payload.result;
      })
      .addCase(fetchUnAssignedShiftUser.pending, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftUser.isLoading = true;
      })
      .addCase(fetchUnAssignedShiftUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftUser.isLoading = false;
        state.fetchUnAssignedShiftUser.isSuccess = true;
        state.fetchUnAssignedShiftUser.UnAssignedUserListsData = action.payload.result;
      })
      .addCase(fetchUnAssignedShiftUser.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedShiftUser.isLoading = false;
        state.fetchUnAssignedShiftUser.isError = true;
        state.fetchUnAssignedShiftUser.message = action.payload.result;
      })
      .addCase(changeScheduleName.pending, (state, action: PayloadAction<any>) => {
        state.changeScheduleName.isLoading = true;
      })
      .addCase(changeScheduleName.fulfilled, (state, action: PayloadAction<any>) => {
        state.changeScheduleName.isLoading = false;
        state.changeScheduleName.isSuccess = true;
      })
      .addCase(changeScheduleName.rejected, (state, action: PayloadAction<any>) => {
        state.changeScheduleName.isLoading = false;
        state.changeScheduleName.isError = true;
      })
      .addCase(fetchUnAssignedRequestCover.pending, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedRequestCover.isLoading = true;
      })
      .addCase(fetchUnAssignedRequestCover.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedRequestCover.isLoading = false;
        state.fetchUnAssignedRequestCover.isSuccess = true;
        state.fetchUnAssignedRequestCover.UnAssignedReqCoverData = action.payload.result;
      })
      .addCase(fetchUnAssignedRequestCover.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUnAssignedRequestCover.isLoading = false;
        state.fetchUnAssignedRequestCover.isError = true;
        state.fetchUnAssignedRequestCover.message = action.payload.result;
      });
  },
});

export const {
  resetFetchGroupList,
  resetFetchGroupUserList,
  resetFetchUsersBySpeciality,
  resetfetchSchedulesByGroup,
  resetfetchUnAssignedShiftList,
  resetFetchScheduleDetails,
  resetFetchGroupDetails,
  resetfetchUnAssignedShiftUserList,
  resetfetchUnAssignedShiftUser,
  resetFetchUnAssignedReqCover,
} = groupsSlice.actions;
export default groupsSlice.reducer;
