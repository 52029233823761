import axios from "axios";
import { API_ENDPOINTS, API_URL } from "../../constants";
import { UserSpeciality } from "../../pages/speciality/speciality.model";
import axiosInstance from "../../core/apiService/webApiService";
type fetchSpecialityListType = {
  searchQuery: string;
  activeStatus: string;
  role: string;
};

//api call to fetch speciality list
const fetchSpecialityList = async (
  { searchQuery, activeStatus, role }: fetchSpecialityListType,
  token: UserSpeciality
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SPECIALITY.FETCH_LIST}?name=${searchQuery}&is_active=${activeStatus}&role=${role}`,
    config
  );
  return response.data;
};

//api call to add new speciality
const addSpeciality = async (payload: any, token: UserSpeciality): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  //added newName temporary due issue in api.need to remove it.

  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.SPECIALITY.ADD_NEW}`, payload, config);
  return response.data;
};

//api call to search speciality
const fetchSpecialityListBySearch = async (searchQuery: UserSpeciality, token: UserSpeciality): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      name: searchQuery,
    },
  };

  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SPECIALITY.SEARCH}`, config);
  return response.data;
};

//api call to trigger action
const specialityToogleAction = async (specialityId: any, token: UserSpeciality): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.SPECIALITY.TOOGLE_ACTION}${specialityId}/delete/`,
    config
  );
  return response.data;
};

//api call to fetch speciality by id
const fetchSpecialityById = async (specialityId: UserSpeciality, token: UserSpeciality): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SPECIALITY.FETCH_BY_ID}${specialityId}/`, config);
  return response.data;
};

//api call to update sepciality
const updateSpeciality = async (
  { specialityId, updateSpecialityPayload }: { specialityId: any; updateSpecialityPayload: any },
  token: UserSpeciality
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.SPECIALITY.UPDATE}${specialityId}/update/`,
    updateSpecialityPayload,
    config
  );
  return response.data;
};

//api call to delete speciality
const deleteSpeciality = async (payload: any, token: UserSpeciality): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.SPECIALITY.DELETE_SPECIALITY}`, {
    ...config,
    data: payload,
  });
  return response.data;
};

const specialityService = {
  fetchSpecialityList,
  addSpeciality,
  fetchSpecialityListBySearch,
  fetchSpecialityById,
  updateSpeciality,
  specialityToogleAction,
  deleteSpeciality,
};

export default specialityService;
