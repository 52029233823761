import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import "./emsReportMangementTable.scss"

interface columnDataType {
  title: string;
  dataIndex: string;
}

interface EmsRptMgmtTblPropsType{
  data:any;
  regCode:string;
}

export const EmsReportManagementTable = ({ data,regCode }: EmsRptMgmtTblPropsType ) => {
  const columnsFor5067: ColumnsType<columnDataType> = [
    {
      title: "PATIENT",
      dataIndex: "patient_name",
      width:180,
      align:"center"
    },
    {
      title: "REQUESTED TIME",
      dataIndex: "requested_time",
      width:250,
      align:"center"
    },
    {
      title: "ACCEPTED TIME",
      dataIndex: "accepted_time",
      width:250,
      align:"center"
    },
    {
      title: "FIRST RESPONDED TIME (MINUTES)",
      dataIndex: "first_responded_time",
      width:200,
      align:"center"
    },
    {
      title: "COMPLETED TIME",
      dataIndex: "completed_time",
      width:250,
      align:"center"
    },
    {
      title: "CANCELLED TIME",
      dataIndex: "cancelled_time",
      width:250,
      align:"center"
    },
    {
      title: "TIMER START TIME",
      dataIndex: "timer_start_time",
      width:250,
      align:"center"
    },
    {
      title: "TIMER END TIME",
      dataIndex: "timer_end_time",
      width:250,
      align:"center"
    },
    {
      title: "TIME LAPSE (MINUTES)",
      dataIndex: "time_lapse",
      width:150,
      align:"center"
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      align:"center"
    },
    {
      title: "ACCEPTED EMERGENCY USER",
      dataIndex: "accepted_emergency_user",
      width:250,
      align:"center"
    },
    {
      title: "REJECTED EMERGENCY USER",
      dataIndex: "rejected_emergency_user",
      width:250,
      align:"center"
    },
    {
      title: "SITE",
      dataIndex: "site",
      width:300,
      align:"center"
    },
    {
      title: "CASE TYPE",
      dataIndex: "case_type",
      width:250,
      align:"center"
    },
  ];

  const columnsForemo1: ColumnsType<columnDataType> =[
    {
      title: "PATIENT",
      dataIndex: "patient_name",
      width:180,
      align:"center"
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      width:50,
      align:"center"
    },
    {
      title: "CASE TYPE",
      dataIndex: "case_type",
      width:300,
      align:"center"
    },
  ]
    
  // 'auto' && 3000 for scroll x
  return (
    <div className="ems-rpt-mgmt-tbl-container">
      <Table
        columns={(regCode==="5067" || regCode === "0010") ? columnsFor5067 : columnsForemo1}
        scroll={{
          x:(regCode==="5067" || regCode === "0010") ? 3000 : 'auto',
        }}
        rowClassName={(_record, index) =>
          index % 2 === 0 ? "table-row-light " : "table-row-dark"
        }
        dataSource={data}
      />
    </div>
  );
  
};
