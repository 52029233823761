import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import sitesService from "./sitesService";
import { UserSites } from "../../pages/sites/sites.model";

interface SitesState {
  fetchSitesList: {
    fetchSitesListData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addSite: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchAddedSiteDetails: {
    AddedSiteDetailsData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateAddedSiteDetails: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  deleteSite: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchStateList: {
    stateListData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchCityList: {
    cityListData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchTimezone: {
    timezoneData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  auth: {
    user: {
      token: string;
    };
  };
}

const initialState: SitesState = {
  fetchSitesList: {
    fetchSitesListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addSite: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchAddedSiteDetails: {
    AddedSiteDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateAddedSiteDetails: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  deleteSite: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchStateList: {
    stateListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },

  fetchCityList: {
    cityListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchTimezone: {
    timezoneData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  auth: {
    user: {
      token: "",
    },
  },
};

//to fetch sites list
export const fetchSitesList = createAsyncThunk("sites/fetchSitesList", async (searchQuery: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = searchQuery.isSearchApi
      ? await sitesService.fetchSitesListBySearch(searchQuery.searchQuery, token)
      : await sitesService.fetchSitesList(searchQuery.searchQuery, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

////to add new site
export const addSite = createAsyncThunk("sites/addSite", async (newSiteData: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;
    const response = await sitesService.addSite(newSiteData, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//to  fetch user added sites
export const fetchAddedSiteDetails = createAsyncThunk("sites/fetchAddedSiteDetails", async (siteId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await sitesService.fetchAddedSiteDetails(siteId, token);
    if (response.isSuccess) {
      return response;
    } else new Error(response.message);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//to  update added site details updateAddedSiteDetails
export const updateAddedSiteDetails = createAsyncThunk(
  "sites/updateAddedSiteDetails",
  async ({ siteId, payload }: { siteId: any; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await sitesService.updateAddedSiteDetails({ siteId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else new Error(response.message);
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//to  delete added sites
export const deleteSite = createAsyncThunk("sites/deleteSite", async (siteId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await sitesService.deleteSite(siteId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//to fetch  state list
export const fetchStateList = createAsyncThunk("sites/fetchStateList", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await sitesService.fetchStateList(token);
    if (response.isSuccess) {
      return response;
    } else new Error(response.message);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//to fetch city list
export const fetchCityList = createAsyncThunk("sites/fetchCityList", async (stateId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await sitesService.fetchCityList(stateId, token);
    if (response.isSuccess) {
      return response;
    } else new Error(response.message);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// to fetch time zone
export const fetchTimezone = createAsyncThunk("sites/fetchTimezone", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await sitesService.fetchTimezone(token);
    if (response.isSuccess) {
      return response;
    } else new Error(response.message);
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Sites slice
const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    resetFetchSitesList: (state) => {
      state.fetchSitesList.isLoading = false;
      state.fetchSitesList.isError = false;
      state.fetchSitesList.isSuccess = false;
    },
    resetAddSite: (state) => {
      state.addSite.isLoading = false;
      state.addSite.isError = false;
      state.addSite.isSuccess = false;
    },
    resetFetchAddedSiteDetails: (state) => {
      state.fetchAddedSiteDetails = initialState.fetchAddedSiteDetails;
    },
    resetUpdateAddedSiteDetails: (state) => {
      state.updateAddedSiteDetails = initialState.updateAddedSiteDetails;
    },
    resetDeleteSite: (state) => {
      state.deleteSite = initialState.deleteSite;
    },
    resetFetchStateList: (state) => {
      state.fetchStateList = initialState.fetchStateList;
    },
    resetFetchCityList: (state) => {
      state.fetchCityList = initialState.fetchCityList;
    },
    resetFetchTimezone: (state) => {
      state.fetchTimezone = initialState.fetchTimezone;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSitesList.pending, (state, action: PayloadAction<any>) => {
        state.fetchSitesList.isLoading = true;
      })
      .addCase(fetchSitesList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSitesList.isLoading = false;
        state.fetchSitesList.isSuccess = true;
        state.fetchSitesList.fetchSitesListData = action.payload.result;
      })
      .addCase(fetchSitesList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSitesList.isLoading = false;
        state.fetchSitesList.isError = true;
        state.fetchSitesList.message = action.payload;
      })
      .addCase(addSite.pending, (state, action: PayloadAction<any>) => {
        state.addSite.isLoading = true;
      })
      .addCase(addSite.fulfilled, (state, action: PayloadAction<any>) => {
        state.addSite.isLoading = false;
        state.addSite.isSuccess = true;
      })
      .addCase(addSite.rejected, (state, action: PayloadAction<any>) => {
        state.addSite.isLoading = false;
        state.addSite.isError = true;
        state.addSite.message = action.payload;
      })
      .addCase(fetchAddedSiteDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchAddedSiteDetails.isLoading = true;
      })
      .addCase(fetchAddedSiteDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchAddedSiteDetails.isLoading = false;
        state.fetchAddedSiteDetails.isSuccess = true;
        state.fetchAddedSiteDetails.AddedSiteDetailsData = action.payload.result;
      })
      .addCase(fetchAddedSiteDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchAddedSiteDetails.isLoading = false;
        state.fetchAddedSiteDetails.isError = true;
        state.fetchAddedSiteDetails.message = action.payload;
      })
      .addCase(updateAddedSiteDetails.pending, (state, action: PayloadAction<any>) => {
        state.updateAddedSiteDetails.isLoading = true;
      })
      .addCase(updateAddedSiteDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateAddedSiteDetails.isLoading = false;
        state.updateAddedSiteDetails.isSuccess = true;
      })
      .addCase(updateAddedSiteDetails.rejected, (state, action: PayloadAction<any>) => {
        state.updateAddedSiteDetails.isLoading = false;
        state.updateAddedSiteDetails.isError = true;
        state.updateAddedSiteDetails.message = action.payload;
      })
      .addCase(deleteSite.pending, (state, action: PayloadAction<any>) => {
        state.deleteSite.isLoading = true;
      })
      .addCase(deleteSite.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteSite.isLoading = false;
        state.deleteSite.isSuccess = true;
        state.deleteSite.message = action?.payload?.message;
      })
      .addCase(deleteSite.rejected, (state, action: PayloadAction<any>) => {
        state.deleteSite.isLoading = false;
        state.deleteSite.isError = true;
        state.deleteSite.message = action.payload
      })

      .addCase(fetchStateList.pending, (state, action: PayloadAction<any>) => {
        state.fetchStateList.isLoading = true;
      })
      .addCase(fetchStateList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchStateList.isLoading = false;
        state.fetchStateList.isSuccess = true;
        state.fetchStateList.stateListData = action.payload.result;
      })
      .addCase(fetchStateList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchStateList.isLoading = false;
        state.fetchStateList.isError = true;
        state.fetchStateList.message = action.payload;
      })
      .addCase(fetchCityList.pending, (state, action: PayloadAction<any>) => {
        state.fetchCityList.isLoading = true;
      })
      .addCase(fetchCityList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchCityList.isLoading = false;
        state.fetchCityList.isSuccess = true;
        state.fetchCityList.cityListData = action.payload.result;
      })
      .addCase(fetchCityList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchCityList.isLoading = false;
        state.fetchCityList.isError = true;
        state.fetchCityList.message = action.payload;
      })
      .addCase(fetchTimezone.pending, (state, action: PayloadAction<any>) => {
        state.fetchTimezone.isLoading = true;
      })
      .addCase(fetchTimezone.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchTimezone.isLoading = false;
        state.fetchTimezone.isSuccess = true;
        state.fetchTimezone.timezoneData = action.payload.result;
      })
      .addCase(fetchTimezone.rejected, (state, action: PayloadAction<any>) => {
        state.fetchTimezone.isLoading = false;
        state.fetchTimezone.isError = true;
        state.fetchTimezone.message = action.payload;
      });
  },
});

export const {
  resetFetchSitesList,
  resetAddSite,
  resetDeleteSite,
  resetFetchStateList,
  resetFetchCityList,
  resetUpdateAddedSiteDetails,
  resetFetchAddedSiteDetails,
  resetFetchTimezone,
} = sitesSlice.actions;
export default sitesSlice.reducer;
