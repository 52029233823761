import { Spin } from "antd";

import "./spinner.scss";

const Spinner: React.FC = () => {
  return (
    <div className="spinner-container">
      <Spin />
    </div>
  );
};
export default Spinner;
