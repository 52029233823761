import { API_ENDPOINTS, API_URL } from "../../constants";
import { UserSites } from "../../pages/sites/sites.model";
import axiosInstance from "../../core/apiService/webApiService";

//api call to fetch sites list
const fetchSitesList = async (searchQuery: UserSites, token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: searchQuery,
  };

  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SITES.SITES_LIST_FETCH}`, config);
  return response.data;
};

//api call to fetch sites list by search
const fetchSitesListBySearch = async (searchQuery: UserSites, token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    params: {
      name: searchQuery,
    },
  };

  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SITES.SITES_LIST_FETCH_BY_SEARCH}`, config);
  return response.data;
};

//api call to add new site
const addSite = async (newSiteData: any, token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.SITES.SITES_ADD_NEW}`, newSiteData, config);
  return response.data;
};

//api call to fetch added sites details
const fetchAddedSiteDetails = async (siteId: number, token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SITES.SITES_FETCH_USER_ADDED}${siteId}`, config);
  return response.data;
};

//api call to update added site details
const updateAddedSiteDetails = async (
  { siteId, payload }: { siteId: any; payload: any },
  token: UserSites
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.SITES.SITES_EDIT}${siteId}/change/`,
    payload,
    config
  );
  return response.data;
};

//api call to delete added sites
const deleteSite = async (siteId: any, token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    data: { pk_list: siteId },
  };
  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.SITES.SITES_DELETE}`, config);
  return response.data;
};

//api call to fetch state list
const fetchStateList = async (token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SITES.SITES_FETCH_STATE_LIST}`);
  return response.data;
};

//api call to fetch city list based on state selected
const fetchCityList = async (stateId: number, token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  //need to send  stateId in api later once backend api is corrected
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SITES.SITES_FETCH_CITY_LIST}${stateId}`, config);
  return response.data;
};

//api call to fetch time zone
const fetchTimezone = async (token: UserSites): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.SITES.SITES_TIMEZONE}`, config);
  return response.data;
};

const sitesService = {
  fetchSitesList,
  fetchSitesListBySearch,
  addSite,
  fetchAddedSiteDetails,
  updateAddedSiteDetails,
  deleteSite,
  fetchStateList,
  fetchCityList,
  fetchTimezone,
};

export default sitesService;
