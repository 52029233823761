import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserSpeciality } from "../../pages/speciality/speciality.model";
import { RootState } from "../../app/store";
import specialityService from "./specialityService";

interface SpecialityState {
  fetchSpecialityList: {
    SpecialityListData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addSpeciality: {
    addedSpecialityData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchSpecialityListBySearch: {
    fetchSpecialityListBySearchData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchSpecialityById: {
    fetchSpecialityByIdData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateSpeciality: {
    updateSpecialityData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  deleteSpeciality: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: SpecialityState = {
  fetchSpecialityList: {
    SpecialityListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addSpeciality: {
    addedSpecialityData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchSpecialityListBySearch: {
    fetchSpecialityListBySearchData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchSpecialityById: {
    fetchSpecialityByIdData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateSpeciality: {
    updateSpecialityData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  deleteSpeciality: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

type fetchSpecialityListType = {
  searchQuery: string;
  activeStatus: string;
  role: string;
};

//to fetch speciality list
export const fetchSpecialityList = createAsyncThunk(
  "speciality/fetchSitesList",
  async ({ searchQuery, activeStatus, role }: fetchSpecialityListType, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;
      const response = await specialityService.fetchSpecialityList({ searchQuery, activeStatus, role }, token);

      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//to add new speciality
export const addSpeciality = createAsyncThunk("speciality/addSpeciality", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await specialityService.addSpeciality(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//to search speciality
export const fetchSpecialityListBySearch = createAsyncThunk(
  "speciality/fetchSpecialityListBySearch",
  async (searchQuery: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await specialityService.fetchSpecialityListBySearch(searchQuery, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//to fetch speciality by id
export const fetchSpecialityById = createAsyncThunk(
  "speciality/fetchSpecialityById",
  async (specialityId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await specialityService.fetchSpecialityById(specialityId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//to update sepciality
export const updateSpeciality = createAsyncThunk(
  "speciality/updateSpeciality",
  async ({ specialityId, updateSpecialityPayload }: { specialityId: any; updateSpecialityPayload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await specialityService.updateSpeciality({ specialityId, updateSpecialityPayload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to toogle speciality action
export const specialityToogleAction = createAsyncThunk(
  "speciality/specialityToogleAction",
  async (specialityId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await specialityService.specialityToogleAction(specialityId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//to delete sepciality
export const deleteSpeciality = createAsyncThunk("speciality/deleteSpeciality", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await specialityService.deleteSpeciality(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//speciality slice
const specialitySlice = createSlice({
  name: "speciality",
  initialState,
  reducers: {
    resetFetchSpecialityList: (state) => {
      state.fetchSpecialityList = initialState.fetchSpecialityList;
    },
    resetFetchSpecialityListBySearch: (state) => {
      state.fetchSpecialityListBySearch = initialState.fetchSpecialityListBySearch;
    },
    resetFetchSpecialityById: (state) => {
      state.fetchSpecialityById = initialState.fetchSpecialityById;
    },
    resetUpdateSpeciality: (state) => {
      state.updateSpeciality = initialState.updateSpeciality;
    },
    resetDeleteSpeciality: (state) => {
      state.deleteSpeciality = initialState.deleteSpeciality;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecialityList.pending, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityList.isLoading = true;
      })
      .addCase(fetchSpecialityList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityList.isLoading = false;
        state.fetchSpecialityList.isSuccess = true;
        state.fetchSpecialityList.SpecialityListData = action.payload.result;
      })
      .addCase(fetchSpecialityList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityList.isLoading = false;
        state.fetchSpecialityList.isError = true;
        state.fetchSpecialityList.message = action.payload;
      })
      .addCase(addSpeciality.pending, (state, action: PayloadAction<any>) => {
        state.addSpeciality.isLoading = true;
      })
      .addCase(addSpeciality.fulfilled, (state, action: PayloadAction<any>) => {
        state.addSpeciality.isLoading = false;
        state.addSpeciality.isSuccess = true;
        state.addSpeciality.addedSpecialityData = action.payload;
      })
      .addCase(addSpeciality.rejected, (state, action: PayloadAction<any>) => {
        state.addSpeciality.isLoading = false;
        state.addSpeciality.isError = true;
        state.addSpeciality.message = action.payload;
      })
      .addCase(fetchSpecialityListBySearch.pending, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityListBySearch.isLoading = true;
      })
      .addCase(fetchSpecialityListBySearch.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityListBySearch.isLoading = false;
        state.fetchSpecialityListBySearch.isSuccess = true;
        state.fetchSpecialityListBySearch.fetchSpecialityListBySearchData = action.payload;
      })
      .addCase(fetchSpecialityListBySearch.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityListBySearch.isLoading = false;
        state.fetchSpecialityListBySearch.isError = true;
        state.fetchSpecialityListBySearch.message = action.payload;
      })
      .addCase(fetchSpecialityById.pending, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityById.isLoading = true;
      })
      .addCase(fetchSpecialityById.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityById.isLoading = false;
        state.fetchSpecialityById.isSuccess = true;
        state.fetchSpecialityById.fetchSpecialityByIdData = action.payload;
      })
      .addCase(fetchSpecialityById.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSpecialityById.isLoading = false;
        state.fetchSpecialityById.isError = true;
        state.fetchSpecialityById.message = action.payload;
      })
      .addCase(updateSpeciality.pending, (state, action: PayloadAction<any>) => {
        state.updateSpeciality.isLoading = true;
      })
      .addCase(updateSpeciality.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateSpeciality.isLoading = false;
        state.updateSpeciality.isSuccess = true;
        state.updateSpeciality.updateSpecialityData = action.payload;
      })
      .addCase(updateSpeciality.rejected, (state, action: PayloadAction<any>) => {
        state.updateSpeciality.isLoading = false;
        state.updateSpeciality.isError = true;
        state.updateSpeciality.message = action.payload;
      })

      .addCase(deleteSpeciality.pending, (state, action: PayloadAction<any>) => {
        state.deleteSpeciality.isLoading = true;
      })
      .addCase(deleteSpeciality.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteSpeciality.isLoading = false;
        state.deleteSpeciality.isSuccess = true;
        state.deleteSpeciality.message = action.payload.message;
      })
      .addCase(deleteSpeciality.rejected, (state, action: PayloadAction<any>) => {
        state.deleteSpeciality.isLoading = false;
        state.deleteSpeciality.isError = true;
        state.deleteSpeciality.message = action.payload;
      });
  },
});

export const {
  resetFetchSpecialityList,
  resetFetchSpecialityListBySearch,
  resetFetchSpecialityById,
  resetUpdateSpeciality,
  resetDeleteSpeciality,
} = specialitySlice.actions;
export default specialitySlice.reducer;
