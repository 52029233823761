import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import supportStaffService from "./supportStaffService";

interface SupportStaffState {
  fetchSupportStaffList: {
    supportStaffListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  deleteSupportStaff: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  createSupportStaff: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchSupportStaffDetails: {
    supportStaffDetailsData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  updateSupportStaff: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
}

const initialState: SupportStaffState = {
  fetchSupportStaffList: {
    supportStaffListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  deleteSupportStaff: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  createSupportStaff: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchSupportStaffDetails: {
    supportStaffDetailsData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  updateSupportStaff: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
};

//action to fetch support staff list
export const fetchSupportStaffList = createAsyncThunk(
  "supportStaff/fetchSupportStaffList",
  async ({ searchQuery, siteId, specialityId }: { searchQuery: string; siteId: any; specialityId: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await supportStaffService.fetchSupportStaffList({ searchQuery, siteId, specialityId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to delete support staff
export const deleteSupportStaff = createAsyncThunk(
  "supportStaff/deleteSupportStaff",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await supportStaffService.deleteSupportStaff(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to create a new support staff
export const createSupportStaff = createAsyncThunk(
  "supportStaff/createSupportStaff",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await supportStaffService.createSupportStaff(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch support staff details for editing
export const fetchSupportStaffDetails = createAsyncThunk(
  "supportStaff/fetchSupportStaffDetails",
  async (supportStaffId: number, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await supportStaffService.fetchSupportStaffDetails(supportStaffId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to update added support staff details
export const updateSupportStaff = createAsyncThunk(
  "supportStaff/updateSupportStaff",
  async ({ payload, supportStaffId }: { payload: any; supportStaffId: number | any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await supportStaffService.updateSupportStaff({ payload, supportStaffId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const supportStaffSlice = createSlice({
  name: "supportStaff",
  initialState,
  reducers: {
    resetFetchSupportStaffList: (state) => {
      state.fetchSupportStaffList = initialState.fetchSupportStaffList;
    },
    resetDeleteSupportStaff: (state) => {
      state.deleteSupportStaff = initialState.deleteSupportStaff;
    },
    resetCreateSupportStaff: (state) => {
      state.createSupportStaff = initialState.createSupportStaff;
    },
    resetFetchSupportStaffDetails: (state) => {
      state.fetchSupportStaffDetails = initialState.fetchSupportStaffDetails;
    },
    resetUpdateSupportStaff: (state) => {
      state.updateSupportStaff = initialState.updateSupportStaff;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportStaffList.pending, (state, action: PayloadAction<any>) => {
        state.fetchSupportStaffList.isLoading = true;
      })
      .addCase(fetchSupportStaffList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSupportStaffList.isLoading = false;
        state.fetchSupportStaffList.isSuccess = true;
        state.fetchSupportStaffList.supportStaffListData = action.payload.result;
      })
      .addCase(fetchSupportStaffList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSupportStaffList.isLoading = false;
        state.fetchSupportStaffList.isError = true;
        state.fetchSupportStaffList.message = action.payload;
      })

      .addCase(deleteSupportStaff.pending, (state, action: PayloadAction<any>) => {
        state.deleteSupportStaff.isLoading = true;
      })
      .addCase(deleteSupportStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteSupportStaff.isLoading = false;
        state.deleteSupportStaff.isSuccess = true;
        state.deleteSupportStaff.message = action.payload.message;
      })
      .addCase(deleteSupportStaff.rejected, (state, action: PayloadAction<any>) => {
        state.deleteSupportStaff.isLoading = false;
        state.deleteSupportStaff.isError = true;
        state.deleteSupportStaff.message = action.payload;
      })

      .addCase(createSupportStaff.pending, (state, action: PayloadAction<any>) => {
        state.createSupportStaff.isLoading = true;
      })
      .addCase(createSupportStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.createSupportStaff.isLoading = false;
        state.createSupportStaff.isSuccess = true;
        state.createSupportStaff.message = action.payload.message;
      })
      .addCase(createSupportStaff.rejected, (state, action: PayloadAction<any>) => {
        state.createSupportStaff.isLoading = false;
        state.createSupportStaff.isError = true;
        state.createSupportStaff.message = action.payload;
      })

      .addCase(fetchSupportStaffDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchSupportStaffDetails.isLoading = true;
      })
      .addCase(fetchSupportStaffDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSupportStaffDetails.isLoading = false;
        state.fetchSupportStaffDetails.isSuccess = true;
        state.fetchSupportStaffDetails.message = action.payload.message;
        state.fetchSupportStaffDetails.supportStaffDetailsData = action.payload.result;
      })
      .addCase(fetchSupportStaffDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSupportStaffDetails.isLoading = false;
        state.fetchSupportStaffDetails.isError = true;
        state.fetchSupportStaffDetails.message = action.payload;
      })

      .addCase(updateSupportStaff.pending, (state, action: PayloadAction<any>) => {
        state.updateSupportStaff.isLoading = true;
      })
      .addCase(updateSupportStaff.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateSupportStaff.isLoading = false;
        state.updateSupportStaff.isSuccess = true;
        state.updateSupportStaff.message = action.payload.message;
      })
      .addCase(updateSupportStaff.rejected, (state, action: PayloadAction<any>) => {
        state.updateSupportStaff.isLoading = false;
        state.updateSupportStaff.isError = true;
        state.updateSupportStaff.message = action.payload;
      });
  },
});

export const {
  resetFetchSupportStaffList,
  resetDeleteSupportStaff,
  resetCreateSupportStaff,
  resetFetchSupportStaffDetails,
  resetUpdateSupportStaff,
} = supportStaffSlice.actions;

export default supportStaffSlice.reducer;
