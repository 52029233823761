import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {useGroup} from "../../../../../data/groupContextApi"

interface ColumnGraphPropsType {
  data: any;
  isClicked:boolean;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ColumnGraph = ({data, isClicked}:ColumnGraphPropsType) => {
  const {isDarkMode} = useGroup()
  const options = {
    maintainAspectRatio: false,
    height:500,
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: (isDarkMode && !isClicked) ?  "rgba(221, 209, 215, 0.3)": "#969595",
        },
        ticks: {
          color:(isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
      y: {
        stacked: true,
        grid: {
          color:(isDarkMode && !isClicked) ?  "rgba(221, 209, 215, 0.3)": "#969595",
        },
        ticks: {
          color:(isDarkMode && !isClicked) ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
    },
  };

  

  return <div style={{padding:"0rem 1rem 0 3rem ",height: '600px',margin:"3rem 0 0 0"}}>
    <Bar  options={options} data={data} />
  </div>;
}
