import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useGroup } from "../../../../data/groupContextApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface lineGraphPropsTypes {
  data: any;
  isClicked: boolean;
}

export const LineGraph = ({ data, isClicked }: lineGraphPropsTypes) => {
  const { isDarkMode } = useGroup();
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    height: 700,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
    scales: {
      x: {
        grid: {
          color:
            isDarkMode && !isClicked ? "rgba(221, 209, 215, 0.3)" : "#969595",
        },
        ticks: {
          color:
            isDarkMode && !isClicked ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
      y: {
        grid: {
          color:
            isDarkMode && !isClicked ? "rgba(221, 209, 215, 0.3)" : "#969595",
        },
        ticks: {
          color:
            isDarkMode && !isClicked ? "rgba(221, 209, 215, 0.8)" : "#1c1b1b",
        },
      },
    },
  };

  return (
    data && (
      <div style={{ height: "600px" }}>
        <Line data={data} options={options} />
      </div>
    )
  );
};
