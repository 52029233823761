import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import dashboardService from "./dashboardService";
import { CardListType, providerPayloadType, SendMessageProviderType, UserListType } from "../../pages/dashboard/types";

interface dashboardState {
  fetchSiteList: {
    siteListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchCardList: {
    cardListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchUserProfile: {
    userProfileData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };

  fetchUserList: {
    userListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchRoleList: {
    roleListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchProviderList: {
    providerListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  sendProviderMessage: {
    sendProviderMessageData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchTimezone: {
    timezoneData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchGroupList: {
    groupListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchSystemUsageStats: {
    systemUsageStatsData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchStatusBoardChart: {
    statusBoardChartData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchBedOccupancyRate: {
    bedOccupancyRateData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchPatientChart: {
    patientChartData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchEmergencyChart: {
    emergencyChartData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  fetchSitesByPatientChart: {
    sitesByPatientChartData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
}

const initialState: dashboardState = {
  fetchSiteList: {
    siteListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchCardList: {
    cardListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchUserProfile: {
    userProfileData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchUserList: {
    userListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchRoleList: {
    roleListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchProviderList: {
    providerListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  sendProviderMessage: {
    sendProviderMessageData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchTimezone: {
    timezoneData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchGroupList: {
    groupListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchSystemUsageStats: {
    systemUsageStatsData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchStatusBoardChart: {
    statusBoardChartData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchBedOccupancyRate: {
    bedOccupancyRateData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchPatientChart: {
    patientChartData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchEmergencyChart: {
    emergencyChartData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  fetchSitesByPatientChart: {
    sitesByPatientChartData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
};

export const fetchSiteList = createAsyncThunk("dashboard/fetchSiteList", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchSiteList(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchCardList = createAsyncThunk("dashboard/fetchCardList", async (payload: CardListType, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchCardList(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchUserProfile = createAsyncThunk("dashboard/fetchUserProfile", async (profileId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchUserProfile(profileId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchUserList = createAsyncThunk("dashboard/fetchUserList", async (payload: UserListType, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchUserList(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchRoleList = createAsyncThunk("dashboard/fetchRoleList", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchRoleList(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchProviderList = createAsyncThunk(
  "dashboard/fetchProviderList",
  async (payload: providerPayloadType, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await dashboardService.fetchProviderList(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendProviderMessage = createAsyncThunk(
  "dashboard/sendProviderMessage",
  async (payload: SendMessageProviderType, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await dashboardService.sendProviderMessage(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchTimezone = createAsyncThunk(
  "dashboard/fetchTimezone",
  async (userId: number | undefined, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await dashboardService.fetchTimezone(userId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchGroupList = createAsyncThunk("dashboard/fetchGroupList", async (payload, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchGroupList(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchSystemUsageStats = createAsyncThunk(
  "dashboard/fetchSystemUsageStats",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await dashboardService.fetchSystemUsageStats(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchStatusBoardChart = createAsyncThunk(
  "dashboard/fetchStatusBoardChart",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await dashboardService.fetchStatusBoardChart(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchBedOccupancyRate = createAsyncThunk(
  "dashboard/fetchBedOccupancyRate",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await dashboardService.fetchBedOccupancyRate(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchPatientChart = createAsyncThunk("dashboard/fetchPatientChart", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchPatientChart(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchSitesByPatientChart = createAsyncThunk(
  "dashboard/fetchSitesByPatientChart",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await dashboardService.fetchSitesByPatientChart(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchEmergencyChart = createAsyncThunk("dashboard/fetchEmergencyChart", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await dashboardService.fetchEmergencyChart(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetFetchSiteList: (state) => {
      state.fetchSiteList = initialState.fetchSiteList;
    },
    resetFetchCardList: (state) => {
      state.fetchCardList = initialState.fetchCardList;
    },
    resetFetchUserProfile: (state) => {
      state.fetchUserProfile = initialState.fetchUserProfile;
    },
    resetFetchUserList: (state) => {
      state.fetchUserList = initialState.fetchUserList;
    },
    resetFetchRoleList: (state) => {
      state.fetchRoleList = initialState.fetchRoleList;
    },
    resetFetchProviderList: (state) => {
      state.fetchProviderList = initialState.fetchProviderList;
    },
    resetSendProviderMessage: (state) => {
      state.sendProviderMessage = initialState.sendProviderMessage;
    },
    resetFetchTimezone: (state) => {
      state.fetchTimezone = initialState.fetchTimezone;
    },
    resetFetchGroupList: (state) => {
      state.fetchGroupList = initialState.fetchGroupList;
    },
    resetFetchSystemUsageStats: (state) => {
      state.fetchSystemUsageStats = initialState.fetchSystemUsageStats;
    },

    resetFetchStatusBoardChart: (state) => {
      state.fetchStatusBoardChart = initialState.fetchStatusBoardChart;
    },
    resetFetchBedOccupancyRate: (state) => {
      state.fetchBedOccupancyRate = initialState.fetchBedOccupancyRate;
    },
    resetFetchPatientChart: (state) => {
      state.fetchPatientChart = initialState.fetchPatientChart;
    },
    resetFetchEmergencyChart: (state) => {
      state.fetchEmergencyChart = initialState.fetchEmergencyChart;
    },
    resetFetchSitesByPatientChart: (state) => {
      state.fetchSitesByPatientChart = initialState.fetchSitesByPatientChart;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteList.pending, (state, action: PayloadAction<any>) => {
        state.fetchSiteList.isLoading = true;
      })
      .addCase(fetchSiteList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSiteList.isLoading = false;
        state.fetchSiteList.isSuccess = true;
        state.fetchSiteList.siteListData = action.payload.result;
      })
      .addCase(fetchSiteList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSiteList.isLoading = false;
        state.fetchSiteList.isError = true;
        state.fetchSiteList.message = action.payload;
      })

      .addCase(fetchCardList.pending, (state, action: PayloadAction<any>) => {
        state.fetchCardList.isLoading = true;
      })
      .addCase(fetchCardList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchCardList.isLoading = false;
        state.fetchCardList.isSuccess = true;
        state.fetchCardList.cardListData = action.payload.result;
      })
      .addCase(fetchCardList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchCardList.isLoading = false;
        state.fetchCardList.isError = true;
        state.fetchCardList.message = action.payload;
      })

      .addCase(fetchUserProfile.pending, (state, action: PayloadAction<any>) => {
        state.fetchUserProfile.isLoading = true;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUserProfile.isLoading = false;
        state.fetchUserProfile.isSuccess = true;
        state.fetchUserProfile.userProfileData = action.payload.result;
      })
      .addCase(fetchUserProfile.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUserProfile.isLoading = false;
        state.fetchUserProfile.isError = true;
        state.fetchUserProfile.message = action.payload;
      })

      .addCase(fetchUserList.pending, (state, action: PayloadAction<any>) => {
        state.fetchUserList.isLoading = true;
      })
      .addCase(fetchUserList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUserList.isLoading = false;
        state.fetchUserList.isSuccess = true;
        state.fetchUserList.userListData = action.payload.result;
      })
      .addCase(fetchUserList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUserList.isLoading = false;
        state.fetchUserList.isError = true;
        state.fetchUserList.message = action.payload;
      })

      .addCase(fetchRoleList.pending, (state, action: PayloadAction<any>) => {
        state.fetchRoleList.isLoading = true;
      })
      .addCase(fetchRoleList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchRoleList.isLoading = false;
        state.fetchRoleList.isSuccess = true;
        state.fetchRoleList.roleListData = action.payload.result;
      })
      .addCase(fetchRoleList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchRoleList.isLoading = false;
        state.fetchRoleList.isError = true;
        state.fetchRoleList.message = action.payload;
      })

      .addCase(fetchProviderList.pending, (state, action: PayloadAction<any>) => {
        state.fetchProviderList.isLoading = true;
      })
      .addCase(fetchProviderList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchProviderList.isLoading = false;
        state.fetchProviderList.isSuccess = true;
        state.fetchProviderList.providerListData = action.payload.result;
      })
      .addCase(fetchProviderList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchProviderList.isLoading = false;
        state.fetchProviderList.isError = true;
        state.fetchProviderList.message = action.payload;
      })

      .addCase(sendProviderMessage.pending, (state, action: PayloadAction<any>) => {
        state.sendProviderMessage.isLoading = true;
      })
      .addCase(sendProviderMessage.fulfilled, (state, action: PayloadAction<any>) => {
        state.sendProviderMessage.isLoading = false;
        state.sendProviderMessage.isSuccess = true;
        state.sendProviderMessage.sendProviderMessageData = action.payload.result;
      })
      .addCase(sendProviderMessage.rejected, (state, action: PayloadAction<any>) => {
        state.sendProviderMessage.isLoading = false;
        state.sendProviderMessage.isError = true;
        state.sendProviderMessage.message = action.payload;
      })

      .addCase(fetchTimezone.pending, (state, action: PayloadAction<any>) => {
        state.fetchTimezone.isLoading = true;
      })
      .addCase(fetchTimezone.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchTimezone.isLoading = false;
        state.fetchTimezone.isSuccess = true;
        state.fetchTimezone.timezoneData = action.payload.result;
      })
      .addCase(fetchTimezone.rejected, (state, action: PayloadAction<any>) => {
        state.fetchTimezone.isLoading = false;
        state.fetchTimezone.isError = true;
        state.fetchTimezone.message = action.payload;
      })

      .addCase(fetchGroupList.pending, (state, action: PayloadAction<any>) => {
        state.fetchGroupList.isLoading = true;
      })
      .addCase(fetchGroupList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchGroupList.isLoading = false;
        state.fetchGroupList.isSuccess = true;
        state.fetchGroupList.groupListData = action.payload.result;
      })
      .addCase(fetchGroupList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchGroupList.isLoading = false;
        state.fetchGroupList.isError = true;
        state.fetchGroupList.message = action.payload;
      })

      .addCase(fetchSystemUsageStats.pending, (state, action: PayloadAction<any>) => {
        state.fetchSystemUsageStats.isLoading = true;
      })
      .addCase(fetchSystemUsageStats.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSystemUsageStats.isLoading = false;
        state.fetchSystemUsageStats.isSuccess = true;
        state.fetchSystemUsageStats.systemUsageStatsData = action.payload.result;
      })
      .addCase(fetchSystemUsageStats.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSystemUsageStats.isLoading = false;
        state.fetchSystemUsageStats.isError = true;
        state.fetchSystemUsageStats.message = action.payload;
      })

      .addCase(fetchStatusBoardChart.pending, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardChart.isLoading = true;
      })
      .addCase(fetchStatusBoardChart.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardChart.isLoading = false;
        state.fetchStatusBoardChart.isSuccess = true;
        state.fetchStatusBoardChart.statusBoardChartData = action.payload.result;
      })
      .addCase(fetchStatusBoardChart.rejected, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardChart.isLoading = false;
        state.fetchStatusBoardChart.isError = true;
        state.fetchStatusBoardChart.message = action.payload;
      })

      .addCase(fetchBedOccupancyRate.pending, (state, action: PayloadAction<any>) => {
        state.fetchBedOccupancyRate.isLoading = true;
      })
      .addCase(fetchBedOccupancyRate.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchBedOccupancyRate.isLoading = false;
        state.fetchBedOccupancyRate.isSuccess = true;
        state.fetchBedOccupancyRate.bedOccupancyRateData = action.payload.result;
      })
      .addCase(fetchBedOccupancyRate.rejected, (state, action: PayloadAction<any>) => {
        state.fetchBedOccupancyRate.isLoading = false;
        state.fetchBedOccupancyRate.isError = true;
        state.fetchBedOccupancyRate.message = action.payload;
      })

      .addCase(fetchPatientChart.pending, (state, action: PayloadAction<any>) => {
        state.fetchPatientChart.isLoading = true;
      })
      .addCase(fetchPatientChart.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchPatientChart.isLoading = false;
        state.fetchPatientChart.isSuccess = true;
        state.fetchPatientChart.patientChartData = action.payload.result;
      })
      .addCase(fetchPatientChart.rejected, (state, action: PayloadAction<any>) => {
        state.fetchPatientChart.isLoading = false;
        state.fetchPatientChart.isError = true;
        state.fetchPatientChart.message = action.payload;
      })

      .addCase(fetchSitesByPatientChart.pending, (state, action: PayloadAction<any>) => {
        state.fetchSitesByPatientChart.isLoading = true;
      })
      .addCase(fetchSitesByPatientChart.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSitesByPatientChart.isLoading = false;
        state.fetchSitesByPatientChart.isSuccess = true;
        state.fetchSitesByPatientChart.sitesByPatientChartData = action.payload.result;
      })
      .addCase(fetchSitesByPatientChart.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSitesByPatientChart.isLoading = false;
        state.fetchSitesByPatientChart.isError = true;
        state.fetchSitesByPatientChart.message = action.payload;
      })

      .addCase(fetchEmergencyChart.pending, (state, action: PayloadAction<any>) => {
        state.fetchEmergencyChart.isLoading = true;
      })
      .addCase(fetchEmergencyChart.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchEmergencyChart.isLoading = false;
        state.fetchEmergencyChart.isSuccess = true;
        state.fetchEmergencyChart.emergencyChartData = action.payload.result;
      })
      .addCase(fetchEmergencyChart.rejected, (state, action: PayloadAction<any>) => {
        state.fetchEmergencyChart.isLoading = false;
        state.fetchEmergencyChart.isError = true;
        state.fetchEmergencyChart.message = action.payload;
      });
  },
});

export const {
  resetFetchSiteList,
  resetFetchCardList,
  resetFetchUserProfile,
  resetFetchUserList,
  resetFetchRoleList,
  resetFetchProviderList,
  resetSendProviderMessage,
  resetFetchTimezone,
  resetFetchGroupList,
  resetFetchSystemUsageStats,
  resetFetchStatusBoardChart,
  resetFetchBedOccupancyRate,
  resetFetchPatientChart,
  resetFetchEmergencyChart,
  resetFetchSitesByPatientChart,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
