import { toast } from "react-toastify";
import { NavigateFunction } from "react-router-dom";
import dayjs from "dayjs";

import { API_URL, DEFAULT_PROFILE_IMAGE } from "../constants";
import { StorageService } from "../core/storageService/storageService";

export const formatPhoneNumber = (value: any) => {
  if (!value) return value;

  const numericValue = value.replace(/[^\d]/g, "");
  const isBackspacePressed = value.slice(-1) === "-" && value.length > numericValue.length;

  if (!numericValue) return "";

  let formattedValue = "";
  let count = 0;

  for (let i = 0; i < numericValue.length; i++) {
    if (count === 0) {
      formattedValue += `(${numericValue[i]}`;
    } else if (count === 3) {
      formattedValue += `) ${numericValue[i]}`;
    } else if (count === 6) {
      formattedValue += `-${numericValue[i]}`;
    } else {
      formattedValue += numericValue[i];
    }

    if (!isNaN(numericValue[i])) {
      count++;
    }
  }

  if (isBackspacePressed) {
    // If deleting a formatting character, adjust the count
    count = Math.max(count - 1, 0);

    // If the last character is a formatting character, remove it
    if (!isNaN(value.slice(-1))) {
      formattedValue = formattedValue.slice(0, -1);
    }
  }

  if (count > 10) {
    formattedValue = formattedValue.slice(0, 14);
  }

  return formattedValue;
};

export const containsOnlyRepeatedValues = (array: any) => {
  if (array.length === 0) {
    return false; // Empty array is not considered to have only repeated values
  }

  const firstElement = array[0];

  // Use the every() method to check if all elements are equal to the first element
  return array.every((element: any) => element === firstElement);
};

export function transformAddUserStateForApi(addUserState: any) {
  return {
    first_name: addUserState.firstName ?? "",
    last_name: addUserState.lastName ?? "",
    email: addUserState.email ?? "",
    pager: addUserState.pager ?? "",
    profile_pic: addUserState.profilePic ?? "",
    display_name: addUserState.displayName ?? "",
    // timezone: addUserState.timezone ?? "",
    // country: addUserState.countryCode,
    color: addUserState.color ?? "",
    groups: addUserState.groups ?? [],
    hospital: addUserState.hospital ?? [],
    speciality: addUserState.speciality ?? [],
    primary_contact_details: {
      number: addUserState.primaryContactDetails.number ?? " ",
      country_code: addUserState.primaryContactDetails.countryCode ?? " ",
      country_name: addUserState.primaryContactDetails.countryName ?? " ",
      number_type: addUserState.primaryContactDetails.numberType ?? " ",
      number_status: addUserState.primaryContactDetails.numberStatus ?? " ",
      can_send_sms: addUserState.primaryContactDetails.canSendSMS,
      can_call: addUserState.primaryContactDetails.canCall,
      is_active: addUserState.primaryContactDetails.isActive,
      is_delete: addUserState.primaryContactDetails.isDelete,
      country_name_code:addUserState.primaryContactDetails.countryNameCode
    },
    secondary_contact_details: {
      number: addUserState.secondaryContactDetails.number ?? " ",
      country_code: addUserState.secondaryContactDetails.countryCode ?? " ",
      country_name: addUserState.secondaryContactDetails.countryName ?? " ",
      number_type: addUserState.secondaryContactDetails.numberType ?? " ",
      number_status: addUserState.secondaryContactDetails.numberStatus ?? " ",
      can_send_sms: addUserState.secondaryContactDetails.canSendSMS,
      can_call: addUserState.secondaryContactDetails.canCall,
      is_active: addUserState.secondaryContactDetails.isActive,
      is_delete: addUserState.secondaryContactDetails.isDelete,
      country_name_code:addUserState.secondaryContactDetails.countryNameCode
    },
    contact_preference: {
      user: addUserState.contactPreference.user ?? null,
      mobile_number: parseInt(addUserState.contactPreference.mobileNumber) ?? null,
      alternate_number: parseInt(addUserState.contactPreference.alternateNumber) ?? null,
      pager: parseInt(addUserState.contactPreference.pager) ?? null,
      email: parseInt(addUserState.contactPreference.email) ?? null,
    },
  };
}

//function handle api response after dispatching an action
export const handleApiResponse = (data: any, successPagePath?: string, navigate?: NavigateFunction) => {
  if (data.payload.isSuccess) {
    toast.success(data?.payload?.message);
    if (successPagePath && navigate) {
      navigate(successPagePath);
    }
  } else {
    toast.error(data?.payload);
  }
};

export const disabledDate = (current: any) => {
  return current && current.isAfter(dayjs().endOf("day"));
};

export const disabledPastDate = (current: any) => {
  return current && current.isBefore(dayjs(), "day");
};

// function to display image
export const displayImage = () => {
  const defaultImage = DEFAULT_PROFILE_IMAGE;
  let displayImage = "";

  const userObj = StorageService.getObjStorage("user");
  if (userObj) {
    const lclUser = JSON.parse(userObj);
    let profPic = lclUser?.result?.user_data?.profile_pic;
    if (profPic) {
      displayImage = `${API_URL}${profPic}`;
    } else {
      return defaultImage;
    }
  }

  return displayImage;
};

export const getUserDetailsFromLocalStorage = (userName: string) => {
  const userObj = StorageService.getObjStorage("user");
  if (userObj) {
    const lclUser = JSON.parse(userObj);
    if (userName) {
      console.log("lclUser", lclUser);
      const { first_name, last_name } = lclUser?.result?.user_data;
      return `${first_name} ${last_name}`;
    }
  }
  return "";
};
