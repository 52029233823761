import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, Form, Grid, Modal, Row, Select, Skeleton, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { RootState, appDispatch } from '../../../../../app/store';
import { fetchTimezone, resetFetchTimezone } from '../../../../../features/dashboard/dashboardSlice';
import { createLeave, fetchLeaveDetails, fetchScheduleList, resetFetchLeaveDetails, resetFetchScheduleList, updateLeaveDetails, validateLeave } from '../../../../../features/users/usersSlice';
import { disabledPastDate } from '../../../../../utils/helper';
import './addLeave.scss';



const { confirm } = Modal;
interface AddLeaveProps {
    open: boolean,
    toogleModal: any
    isDayOffModal: number
    userId: number
    handleEditModalToggle?: any
    leaveId: number | null
    handleFetchUserDetails: any
    clearLeaveId: any
    shouldFetchScheduleCalenderData: any
}
const AddLeave: React.FC<AddLeaveProps> = ({ open, toogleModal, isDayOffModal, userId, leaveId, handleFetchUserDetails, clearLeaveId, shouldFetchScheduleCalenderData }) => {
    const { RangePicker } = DatePicker;
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const dispatch = useDispatch<appDispatch>();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const { timezoneData, isSuccess: isSuccessFetchTimezone, isError: isErrorFetchTimezone, isLoading: isLoadingFetchTimezone, } = useSelector((state: RootState) => state.dashboard.fetchTimezone);
    const { leaveDetailsData, isSuccess: isSuccessFetchLeaveDetails, isLoading: isLoadingFetchLeaveDetails } = useSelector((state: RootState) => state.users.fetchLeaveDetails);
    const { scheduleListData, isSuccess: isSuccessFetchScheduleList, isLoading: isLoadingFetchScheduleList } = useSelector((state: RootState) => state.users.fetchScheduleList);
    const { isLoading: isLoadingValidateLeaves } = useSelector((state: RootState) => state.users.validateLeave);

    const dateFormat = "YYYY-MM-DD";
    const endDate = dayjs().format(dateFormat);
    const startDate = dayjs().format(dateFormat);
    // const startDate = dayjs().subtract(1, 'week').format(dateFormat);
    const [form] = Form.useForm();

    const [dateRange, setDateRange] = useState<any>([startDate, endDate]);
    const [selectedTimezone, setSelectedTimezone] = useState<string>('')
    const [selectedSchedules, setSelectedSchedules] = useState<number | null>(null)
    const [selectedSites, setSelectedSites] = useState<number[]>([])
    const [isAllChecked, setIsAllChecked] = useState(false)

    useEffect(() => {
        open && dispatch(fetchTimezone(userId));
        return () => {
            dispatch(resetFetchTimezone());
        };
    }, [userId, open]);

    useEffect(() => {
        if (leaveId) {

            dispatch(fetchLeaveDetails(leaveId))
        }
        return () => {
            dispatch(resetFetchLeaveDetails())
        }
    }, [leaveId])


    //for create leave
    useEffect(() => {
        if (isSuccessFetchTimezone) {
            let initialTimezone = getInitialTimezone(timezoneData)
            form.setFieldValue("timezone", initialTimezone)
            !leaveId && setSelectedTimezone(initialTimezone)
            !leaveId && dispatch(fetchScheduleList({ userId, selectedTimezone: initialTimezone }));
            !leaveId && form.setFieldsValue({ dateRange: [dayjs(startDate), dayjs(startDate)] });
            !leaveId && form.setFieldValue('schedule', null)
            !leaveId && form.setFieldValue('site', [])
        }
    }, [isSuccessFetchTimezone])


    useEffect(() => {
        if (isSuccessFetchLeaveDetails) {
            const timezoneFromLeaveDetails = leaveDetailsData?.timezone
            form.setFieldValue("timezone", timezoneFromLeaveDetails)
            setSelectedTimezone(timezoneFromLeaveDetails)
            dispatch(fetchScheduleList({ userId, selectedTimezone: timezoneFromLeaveDetails }));
        }
    }, [isSuccessFetchTimezone, isSuccessFetchLeaveDetails])


    //when editing the leave details
    useEffect(() => {
        if (leaveDetailsData && isSuccessFetchLeaveDetails && isSuccessFetchScheduleList) {
            // form.setFieldValue("dateRange", [dayjs(leaveDetailsData?.start_time)?.format('YYYY-MM-DD'), dayjs(leaveDetailsData?.end_time)?.format('YYYY-MM-DD')])
            setDateRange([dayjs(leaveDetailsData?.start_time)?.format('YYYY-MM-DD'), dayjs(leaveDetailsData?.end_time)?.format('YYYY-MM-DD')])
            form.setFieldsValue({ dateRange: [dayjs(leaveDetailsData?.start_time), dayjs(leaveDetailsData?.end_time)] });
            setSelectedSchedules(leaveDetailsData?.applies_to_all_schedules ? null : leaveDetailsData?.schedules[0])
            form.setFieldValue("schedule", leaveDetailsData?.applies_to_all_schedules ? null : leaveDetailsData?.schedules[0]);
            setSelectedSites(leaveDetailsData?.applies_to_all_schedules ? [] : [...leaveDetailsData?.hospitals])
            form.setFieldValue("site", leaveDetailsData?.applies_to_all_schedules ? [] : [...leaveDetailsData?.hospitals]);
            setIsAllChecked(leaveDetailsData?.applies_to_all_schedules)
        }
    }, [isSuccessFetchScheduleList, isSuccessFetchLeaveDetails])


    const getInitialTimezone = (timezoneData: any) => {
        return timezoneData?.find((data: any) => data?.timezone === userTimeZone)?.timezone ?? timezoneData[0]?.timezone
    }

    const handleChecked = () => {
        isAllChecked ? setIsAllChecked(false) : confirm({
            title: "On applying, all schedules and sites will  be affected.",
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: (<p>Are you sure you want to continue? </p>),
            onOk() {
                const allSchedules = scheduleListData?.map((schedule: any) => schedule?.id)
                setSelectedSchedules(null)
                const allSites = uniqueSites?.map((site: any) => site?.id)
                setSelectedSites([])
                form.setFieldsValue({ schedule: [], site: [] });
                setIsAllChecked(true)
            },

            onCancel() { },
        });

    }

    const isLargeScreen = screens.lg;

    const handleDateRangePickerChange = (data: any) => {
        const formattedDates = data?.map((date: any) => date.format("YYYY-MM-DD"));
        setDateRange(formattedDates);


    };

    const handleClear = () => {
        setSelectedTimezone(getInitialTimezone(timezoneData))
        setSelectedSchedules(null)
        setSelectedSites([])
        setDateRange([startDate, startDate])
        form.setFieldValue("schedule", null);
        form.setFieldValue("site", []);
        form.setFieldValue("timezone", getInitialTimezone(timezoneData));
        form.setFieldsValue({ dateRange: [dayjs(startDate), dayjs(startDate)] });
        setIsAllChecked(false)
        dispatch(resetFetchLeaveDetails())
        form.resetFields()
    }


    const resetDateRange = () => {
        setDateRange([startDate, endDate]);
    };

    const handleCancelModal = () => {
        handleClear()
        toogleModal()
        clearLeaveId()
        resetDateRange();
    }

    const handleApiResponse = () => {
        handleFetchUserDetails()
        handleCancelModal()
    }

    // Format the leave validation message
    const formatLeaveValidationMessage = (message: string) => {
        // Split the message into individual leaves based on newline character
        const leaves = message.split("\n");

        // Map over the leaves array to create separate <p> elements for each leave
        const formattedLeaves = leaves?.map((leave: any, index: any) => {
            return <p key={index}>{leave}</p>;
        });

        // Render formattedLeaves
        return (formattedLeaves);
    }


    // handle save button for creating or updating leave
    const handleSave = () => {
        form
            .validateFields()
            .then((data) => {

                const payload = {
                    user: userId,
                    timezone: selectedTimezone,
                    start_time: `${dateRange[0]} 00:00:00`,
                    end_time: `${dateRange[1]}  23:59:59`,
                    is_custom: false,
                    is_off: isDayOffModal === 1 ? true : false,
                    hospitals: isAllChecked ? [] : selectedSites?.map((site) => site),
                    applies_to_all_schedules: isAllChecked,
                    schedules: isAllChecked ? [] : [selectedSchedules],
                }
                // const action = leaveId ? updateLeaveDetails({ leaveId, payload }) : createLeave(payload)

                const handleLeaveApi = (leaveIdsFromApi?: number[]) => {
                    let updatedPayload, action

                    //leaveIdsFromApi indicate validation has failed,hence we need to pass the leaveIdsFromApi value to replace the leaves in server
                    updatedPayload = leaveIdsFromApi ? { ...payload, remove_leave: leaveIdsFromApi } : payload
                    action = leaveId ? updateLeaveDetails({ leaveId, payload: updatedPayload }) : createLeave(updatedPayload)

                    dispatch(action)
                        .then((data: any) => {
                            if (data?.payload?.isSuccess) {
                                toast.success(data?.payload?.message)
                                dispatch(resetFetchScheduleList())
                                handleApiResponse()
                                shouldFetchScheduleCalenderData()
                            } else {
                                toast.error(data?.payload)
                            }
                        })
                }

                //dispatching validate leave api
                dispatch(validateLeave(payload)).then((data) => {
                    if (data?.payload?.isSuccess) {
                        handleLeaveApi()
                    } else {
                        confirm({
                            title: "Leave Details Confirmation",
                            icon: <ExclamationCircleOutlined />,
                            centered: true,
                            okText: 'Yes',
                            cancelText: 'No',
                            content: (<p>{formatLeaveValidationMessage(data?.payload?.message)}</p>),
                            onOk() {
                                const { leave_ids: leaveIdsFromApi } = data?.payload?.result
                                handleLeaveApi(leaveIdsFromApi)
                            },
                            onCancel() { },
                        });


                    }
                })
            })
            .catch((errorInfo) => { });
    }

    //find header based on modal type
    const getHeader = (isDayOffModal: number) => {
        let header = isDayOffModal === 1 ? 'Day off' : 'Avoid'
        let mode = leaveId ? 'Edit' : 'Add'
        return ` ${mode} ${header} Details`
    }


    const TextWithCheckbox = (props: any) => {
        return (
            <div>
                <Checkbox
                    checked={props.checked}
                    style={{ margin: "0 .5rem 0 0rem" }}
                />
                {props.children}
            </div>
        );
    };

    const tagRender = (props: any) => {
        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label?.props?.children ? label?.props?.children : null}
            </Tag>
        );
    }


    const handleTimezoneChange = (value: string) => {
        setSelectedTimezone(value)
        setSelectedSchedules(null)
        setSelectedSites([])
        form.setFieldValue("schedule", []);
        form.setFieldValue("site", []);
        dispatch(fetchScheduleList({ userId, selectedTimezone: value }));
    }

    const handleSelectAllSites = (e: any, uniqueSites: any) => {
        if (e.target.checked) {
            const selectedSites = uniqueSites?.map((site: any) => site?.id)
            setSelectedSites(selectedSites)
            form.setFieldValue("site", selectedSites);
        } else {
            setSelectedSites([])
            form.setFieldValue("site", []);
        }
    }

    const uniqueSites = Array?.from(
        new Map(
            scheduleListData
                ?.filter((schedule: any) => schedule?.id === selectedSchedules)
                ?.flatMap((schedule: any) => schedule?.hospitals?.map((site: any) => [site?.id, site]))
        )?.values() || []
    );


    const handleScheduleChange = (schedule: number) => {
        console.log('schedule', schedule)
        setSelectedSchedules(schedule)
        form.setFieldValue("site", []);
        setSelectedSites([])
    }

    const getIsLoading = () => {
        return leaveId ? isLoadingFetchLeaveDetails : isLoadingFetchTimezone
    }

    return (
        <div className='add-leave-container'>
            <Modal open={open}
                width={isLargeScreen ? "60vw" : '90vw'}
                centered={true}
                destroyOnClose={true}
                closeIcon={false} title={<span style={{ display: 'flex', justifyContent: 'start', fontSize: '20px' }}>{getHeader(isDayOffModal)}</span>}
                footer={[
                    <Button key="cancel" onClick={handleCancelModal}>
                        Cancel
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSave} loading={isLoadingValidateLeaves} >
                        Save
                    </Button>,
                ]}>
                <Skeleton loading={getIsLoading()} active>
                    <Form
                        form={form}
                        layout="vertical"
                        style={{
                            padding: "30px",
                            overflowY: "scroll",
                            maxHeight: "60vh",
                            width: "100%",
                            overflowX: "hidden",
                        }}
                    >
                        <Row gutter={[16, 16]} >
                            <Col xs={24} md={24} lg={12} >
                                <Form.Item label="Timezone" name='timezone' rules={[{ required: true, message: 'Please select a timezone' }]}
                                >
                                    <Select virtual={false} placeholder="Timezone"

                                        onChange={handleTimezoneChange}
                                        value={selectedTimezone}
                                        options={[...timezoneData?.map((timezoneItem: any) => ({
                                            value: timezoneItem?.timezone,
                                            label: timezoneItem?.timezone

                                        }))]} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Date" name='dateRange' rules={[{ required: true, message: 'Please select date' }]}>
                                    <RangePicker
                                        value={[dayjs(`${dateRange[0]}`, dateFormat), dayjs(`${dateRange[1]}`, dateFormat)]}
                                        inputReadOnly={true}
                                        format={dateFormat}
                                        onChange={handleDateRangePickerChange}
                                        disabledDate={disabledPastDate}
                                        allowClear={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} >
                            <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Schedules" name='schedule' rules={[{ required: isAllChecked ? false : true, message: 'Please select schedule' }]}>
                                    <Select
                                        placeholder="Schedules"
                                        showSearch={false}
                                        value={selectedSchedules}
                                        disabled={isAllChecked}
                                        style={{ width: '100%' }}
                                        onChange={handleScheduleChange}
                                        loading={isLoadingFetchScheduleList}
                                        options={[...scheduleListData?.map((schedule: any) => ({ value: schedule?.id, label: schedule?.name }))]} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Sites" name='site' rules={[{ required: isAllChecked ? false : true, message: 'Please select site' }]}>
                                    <Select
                                        virtual={false}
                                        placeholder="Sites"
                                        mode='multiple'
                                        maxTagCount={"responsive"}
                                        disabled={isAllChecked}
                                        onChange={(site) => setSelectedSites(site)}
                                        value={selectedSites}
                                        tagRender={tagRender}
                                        dropdownRender={(menu) => (
                                            <>
                                                <Space style={{ padding: "5px 12px 4px" }}>
                                                    <Checkbox
                                                        indeterminate={selectedSites.length > 0 && selectedSites.length < uniqueSites.length}
                                                        checked={selectedSites?.length > 0 && selectedSites?.length === uniqueSites?.length}
                                                        onChange={(e) => handleSelectAllSites(e, uniqueSites)}
                                                    />
                                                    Select All
                                                </Space>
                                                <Divider style={{ margin: "8px 0" }} />
                                                {menu}
                                            </>
                                        )}
                                    >
                                        {uniqueSites?.map((site: any) =>
                                            <Select.Option
                                                key={site?.id}
                                                value={site?.id}
                                                label={site?.name}
                                            >
                                                <TextWithCheckbox checked={selectedSites?.includes(site?.id)}>
                                                    {site?.name}
                                                </TextWithCheckbox>
                                            </Select.Option>
                                        )
                                        }
                                    </Select>

                                </Form.Item>
                            </Col>
                        </Row>

                        <Row justify={'end'} >
                            <Col >
                                <Checkbox checked={isAllChecked} onChange={handleChecked} >
                                    Apply for all schedules and sites
                                </Checkbox>
                            </Col>
                        </Row>

                    </Form>
                </Skeleton>

            </Modal >
        </div >
    )
}

export default AddLeave