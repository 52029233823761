import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import statusBoardService from "./statusBoardService";

interface statusBoardState {
  fetchStatusBoardList: {
    statusBoardListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addStatusBoard: {
    addStatusBoardData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchStatusBoardDetails: {
    statusBoardDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateStatusBoard: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUnitList: {
    unitListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  deleteStatusBoard: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: statusBoardState = {
  fetchStatusBoardList: {
    statusBoardListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addStatusBoard: {
    addStatusBoardData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchStatusBoardDetails: {
    statusBoardDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateStatusBoard: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUnitList: {
    unitListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },

  deleteStatusBoard: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

export const fetchStatusBoardList = createAsyncThunk(
  "statusBoard/fetchStatusBoardList",
  async ({ searchQuery, siteId, unitId }: { searchQuery: string; siteId: any; unitId: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardService.fetchStatusBoardList({ searchQuery, siteId, unitId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to add status board
export const addStatusBoard = createAsyncThunk("statusBoard/addStatusBoard", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await statusBoardService.addStatusBoard(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch status board details
export const fetchStatusBoardDetails = createAsyncThunk(
  "statusBoard/fetchStatusBoardDetails",
  async (statusId: number, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardService.fetchStatusBoardDetails(statusId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateStatusBoard = createAsyncThunk(
  "statusBoard/updateStatusBoard",
  async ({ statusId, payload }: { statusId: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardService.updateStatusBoard({ statusId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUnitList = createAsyncThunk("statusBoard/fetchUnitList", async (siteId: string, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await statusBoardService.fetchUnitList(siteId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const deleteStatusBoard = createAsyncThunk("statusBoard/deleteStatusBoard", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await statusBoardService.deleteStatusBoard(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const statusBoardSlice = createSlice({
  name: "statusBoard",
  initialState,
  reducers: {
    resetStatusBoard: (state) => {
      state.fetchStatusBoardList = initialState.fetchStatusBoardList;
    },
    resetAddStatusBoard: (state) => {
      state.addStatusBoard = initialState.addStatusBoard;
    },
    resetStatusBoardDetails: (state) => {
      state.fetchStatusBoardDetails = initialState.fetchStatusBoardDetails;
    },
    resetStatusBoardUpdate: (state) => {
      state.updateStatusBoard = initialState.updateStatusBoard;
    },
    resetFetchUnitList: (state) => {
      state.fetchUnitList = initialState.fetchUnitList;
    },
    resetDeleteStatusBoard: (state) => {
      state.deleteStatusBoard = initialState.deleteStatusBoard;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchStatusBoardList.pending, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardList.isLoading = true;
      })
      .addCase(fetchStatusBoardList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardList.isLoading = false;
        state.fetchStatusBoardList.isSuccess = true;
        state.fetchStatusBoardList.statusBoardListData = action.payload.result;
      })
      .addCase(fetchStatusBoardList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardList.isLoading = false;
        state.fetchStatusBoardList.isError = true;
        state.fetchStatusBoardList.message = action.payload;
      })

      .addCase(addStatusBoard.pending, (state, action: PayloadAction<any>) => {
        state.addStatusBoard.isLoading = true;
      })
      .addCase(addStatusBoard.fulfilled, (state, action: PayloadAction<any>) => {
        state.addStatusBoard.isLoading = false;
        state.addStatusBoard.isSuccess = true;
        state.addStatusBoard.addStatusBoardData = action.payload.result;
      })
      .addCase(addStatusBoard.rejected, (state, action: PayloadAction<any>) => {
        state.addStatusBoard.isLoading = false;
        state.addStatusBoard.isError = true;
        state.addStatusBoard.message = action.payload;
      })

      .addCase(fetchStatusBoardDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardDetails.isLoading = true;
      })
      .addCase(fetchStatusBoardDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardDetails.isLoading = false;
        state.fetchStatusBoardDetails.isSuccess = true;
        state.fetchStatusBoardDetails.statusBoardDetailsData = action.payload.result;
      })
      .addCase(fetchStatusBoardDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardDetails.isLoading = false;
        state.fetchStatusBoardDetails.isError = true;
        state.fetchStatusBoardDetails.message = action.payload;
      })

      .addCase(updateStatusBoard.pending, (state, action: PayloadAction<any>) => {
        state.updateStatusBoard.isLoading = true;
      })
      .addCase(updateStatusBoard.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateStatusBoard.isLoading = false;
        state.updateStatusBoard.isSuccess = true;
      })
      .addCase(updateStatusBoard.rejected, (state, action: PayloadAction<any>) => {
        state.updateStatusBoard.isLoading = false;
        state.updateStatusBoard.isError = true;
        state.updateStatusBoard.message = action.payload;
      })

      .addCase(fetchUnitList.pending, (state, action: PayloadAction<any>) => {
        state.fetchUnitList.isLoading = true;
      })
      .addCase(fetchUnitList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUnitList.isLoading = false;
        state.fetchUnitList.isSuccess = true;
        state.fetchUnitList.unitListData = action.payload.result;
      })
      .addCase(fetchUnitList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUnitList.isLoading = false;
        state.fetchUnitList.isError = true;
        state.fetchUnitList.message = action.payload;
      })

      .addCase(deleteStatusBoard.pending, (state, action: PayloadAction<any>) => {
        state.deleteStatusBoard.isLoading = true;
      })
      .addCase(deleteStatusBoard.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteStatusBoard.isLoading = false;
        state.deleteStatusBoard.isSuccess = true;
        state.deleteStatusBoard.message = action.payload.message;
      })
      .addCase(deleteStatusBoard.rejected, (state, action: PayloadAction<any>) => {
        state.deleteStatusBoard.isLoading = false;
        state.deleteStatusBoard.isError = true;
        state.deleteStatusBoard.message = action.payload;
      });
  },
});

export const {
  resetStatusBoard,
  resetAddStatusBoard,
  resetStatusBoardDetails,
  resetStatusBoardUpdate,
  resetFetchUnitList,
  resetDeleteStatusBoard,
} = statusBoardSlice.actions;

export default statusBoardSlice.reducer;
