import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import "./statusBoardColumnTable.scss";
import AddStatusBoardColumn from "../addStatusBoardColumn/AddStatusBoardColumn";
import { useState } from "react";
import { fetchStatusBoardColumnDetails } from "../../../../features/statusBoardColumn/statusBoardColumnSlice";
import { useDispatch } from "react-redux";
import { appDispatch } from "../../../../app/store";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface StatusBoardColumnTableProps {
  statusBoardColumnTableDataApi: any;
  selectedTableRowKeys: any;
  setSelectedTableRowKeys: any;
  setSelectedTableRows: any;
  isLoading: boolean;
  handleClearFilters: () => void;
  handleClearLastFilterValues: () => void;
  filterSiteId: string;
  filterTypeId: string
  searchQuery: string
}

interface StatusBoardTableDataType {
  key: React.Key;
  id: number;
  site: any;
  type: any;
  name: string;
}

const StatusBoardColumnTable: React.FC<StatusBoardColumnTableProps> = ({
  statusBoardColumnTableDataApi,
  selectedTableRowKeys,
  setSelectedTableRowKeys,
  setSelectedTableRows,
  isLoading,
  handleClearFilters,
  handleClearLastFilterValues,
  filterSiteId,
  filterTypeId,
  searchQuery
}) => {

  const dispatch = useDispatch<appDispatch>();

  const [isAddStatusBoardColumnModalOpen, setIsAddStatusBoardColumnModalOpen] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingStatusId, setEditingStatusId] = useState<number>();

  //handle close of add status board column modal
  const handleAddStatusColumnBoardClose = () => {
    setIsAddStatusBoardColumnModalOpen(false);
  };

  //to handle modal opening for add/edit
  const openModal = (id: number) => {
    setEditingStatusId(id);
    dispatch(fetchStatusBoardColumnDetails(id));
    setIsAddStatusBoardColumnModalOpen(true);
    setIsEditMode(true);
  };

  const rowSelection = {
    selectedRowKeys: selectedTableRowKeys,
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: StatusBoardTableDataType[]
    ) => {
      setSelectedTableRowKeys(selectedRowKeys);
      setSelectedTableRows(selectedRows);
    },
    getCheckboxProps: (record: StatusBoardTableDataType) => ({
      // name: record.name,
    }),
  };

  let columns: ColumnsType<StatusBoardTableDataType> = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "id",
      // defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: ({ sortOrder }: any) => sortOrder === "ascend" ? <IoIosArrowUp /> : <IoIosArrowDown />,
      render: (text: any, record: StatusBoardTableDataType) => (
        <a onClick={() => openModal(record?.id)}>{text}</a>
      ),
    },
    {
      title: "SITE",
      dataIndex: "site",
    },
    {
      title: "TYPE",
      dataIndex: "type",
    },
  ];

  return (
    <div style={{ marginTop: "1rem" }}>
      {/* Status Board Column Table */}
      <Table
        loading={isLoading}
        rowSelection={{ ...rowSelection }}
        columns={columns}
        pagination={{ position: ["bottomLeft"], pageSize: 25 }}
        dataSource={statusBoardColumnTableDataApi}
        scroll={{ x: "auto" }}
        rowClassName={(_record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
      />
      <AddStatusBoardColumn
        open={isAddStatusBoardColumnModalOpen}
        onClose={handleAddStatusColumnBoardClose}
        isEditMode={isEditMode}
        editingStatusId={editingStatusId}
        handleClearFilters={handleClearFilters}
        handleClearLastFilterValues={handleClearLastFilterValues}
        filterSiteId={filterSiteId}
        filterTypeId={filterTypeId}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default StatusBoardColumnTable;
