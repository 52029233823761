import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import loginService from "./loginService";
import { User } from "../../pages/login/login.model";
import { StorageService } from "../../core/storageService/storageService";

interface AuthState {
  user: User | any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}


const userFromLocalStorage = StorageService.getObjStorage("user");
let user: User | null;
const isSavePassword = localStorage.getItem("save_password") === "true";
const isFirstLogin = sessionStorage.getItem("is_first_login");
user = userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;

if (user && (isSavePassword || isFirstLogin)) {
  user = user;
} else {
  user = null;
}

const initialState: AuthState = {
  user: user ? user : "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//Login User
export const login = createAsyncThunk(
  "auth/login",
  async (userData: User, thunkAPI) => {
    try {
      const response = await loginService.authService(userData);

      if (response.isSuccess) {
        localStorage.setItem("reg_code", userData.reg_code || "");
        StorageService.setObjStorage("user", JSON.stringify({...response,token:response?.result?.user_data?.token}));
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Logout User
// export const logout = createAsyncThunk("auth/logout", async () => {
//   await loginService.logout();
// });
export const clearUserDataFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.user = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      clearUserDataFromLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = {...action?.payload,token:action?.payload?.result?.user_data?.token};
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action?.payload
        state.user = "";
      });
  },
});

export const { resetAuth } = authSlice.actions;
// export default authSlice.reducer;
