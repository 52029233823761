import { Table, Space, Button, Popover, Row, Col, Divider } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./scheduleReportTable.scss";
import UserModal from "../../../user/components/UserModal";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";


interface ScheduleReportTableType {
  data: any;
  reportType: any;
  columnData?: any;
}

interface columnDataType {
  title: string;
  dataIndex: string;
  total?: number;
}

interface popoverTextContentType {
  title: string;
  content: string;
}

export const ScheduleReportTable = ({
  data,
  reportType,
  columnData
}: ScheduleReportTableType) => {
  const [tableColumn, setTableColumn] = useState<ColumnsType<columnDataType>>();
  const [tableWidth, setTableWidth] = useState<any>();


  const popoverTextContentForChange: popoverTextContentType[] = [
    {
      title: "NO CHANGE :",
      content: "No change happens",
    },
    {
      title: "REQUEST PENDING :",
      content: "Waiting for approval",
    },
    {
      title: "REQUEST ACCEPTED :",
      content: "Approved",
    },
    {
      title: "OWNER DECLINED :",
      content: "Canceled by owner",
    },
    {
      title: "ALL USERS DECLINED :",
      content: "Every one canceled",
    },
    {
      title: "AUTOMATIC DECLINED :",
      content: "Time out and got canceled",
    },
  ];

  const popoverTextContentForCareGiven: popoverTextContentType[] = [
    {
      title: "OFF:",
      content: "Added leave in the Day Off section",
    },
    {
      title: "ON CALL :",
      content: "Currently running a shift",
    },
    {
      title: "AVAILABLE ON DEMAND :",
      content: " Added leave in the Avoid section",
    },
    {
      title: "AVAILABLE ON DEMAND :",
      content: "Has future or past shifts",
    },
  ];

  const InfoButton = ({popoverTextContent,title}:any) => {

    const handlePopoverVisibleChange = (popoverVisible: any) => {

    };

    

    const popoverContent = (
      <div className="change-info-container">
        <Row justify={"center"}>
          <Col lg={12} xl={12} >
            <p className="title-style">{title}</p>
          </Col>
        </Row>
        {popoverTextContent?.map((data: popoverTextContentType) => {
          return (
            <>
              <Row
                gutter={[12, 12]}
                align={"middle"}
                style={{ margin: "0 0 .35rem 0" }}
              >
                <Col lg={12} xl={12} className="align-right" >
                  <p className="sub-title-style">{data.title}</p>
                </Col>
                <Col lg={12} xl={12} >
                  <p>{data.content}</p>
                </Col>
              </Row>
            </>
          );
        })}
      </div>
    );

    return (
      <Popover
        content={popoverContent}
        trigger="click"
        onOpenChange={handlePopoverVisibleChange}
      >
        <Button
          type="link"
          icon={<InfoCircleOutlined />}
        />
      </Popover>
    );
  };

  const columnForScheduleHistory: ColumnsType<columnDataType> = [
    {
      title: "USER",
      dataIndex: "user",
      align: "center",
      width: 200,
    },
    {
      title: "SITE",
      dataIndex: "hospital",
      align: "center",
      width: 250,
    },
    {
      title: "SHIFT",
      dataIndex: "shift",
      align: "center",
    },
    {
      title: "START TIME",
      dataIndex: "start_time",
      align: "center",
      width:200
    },
    {
      title: "END TIME",
      dataIndex: "end_time",
      align: "center",
      width:200
    },
    {
      title: "GROUP",
      dataIndex: "group",
      align: "center",
      width: 300,
    },
    {
      title:  (
        <Space>
          CARE GIVER STATUS
          <InfoButton popoverTextContent={popoverTextContentForCareGiven} title={"CAREGIVER'S STATUS"} />
        </Space>
      ),
      dataIndex: "care_giver_status",
      align: "center",
    },
    {
      title: (
        <Space>
          CHANGE
          <InfoButton popoverTextContent={popoverTextContentForChange} title={"CHANGE STATUS"} />
        </Space>
      ),
      dataIndex: "change",
      align: "center",
    },

    {
      title: "CREATED BY",
      dataIndex: "created_by",
      align: "center",
    },
    {
      title: "CREATED ON UTC",
      dataIndex: "created_on",
      align: "center",
    },
    {
      title: "IS CREATED BY RULE",
      dataIndex: "is_created_by_rule",
      align: "center",
      render: (available) => (available ? 'True' : 'False')
    },
  ];

  const columnForONCallLookupHistory: ColumnsType<columnDataType> = [
    {
      title: "USER",
      dataIndex: "user",
      align: "left",
      width: 600,
      sorter: (a:any, b:any) => `${a.user__first_name} ${a.user__last_name}`.localeCompare(`${b.user__first_name} ${b.user__last_name}`),
      sortIcon: ({sortOrder}:any)=>sortOrder==="ascend" ? <IoIosArrowUp /> :  <IoIosArrowDown />,
      render: (text, record: any) => `${record.user__first_name} ${record.user__last_name}`
    },
    {
      title: () => (
        <div  className="onCall-count-header-wrap">
          <h2>NO OF TIME ON CALL LOOKUP USED</h2>
          {data?.total_on_call_lookup_count&&<div className="onCall-count-header-box" >
            <p>Total Count</p>
            <p>{data?.total_on_call_lookup_count}</p>
          </div>}
        </div>
      ),
      dataIndex: "total",
      align: "center",
      sortIcon:({sortOrder}:any)=>sortOrder==="ascend" ? <IoIosArrowUp /> :  <IoIosArrowDown />,
      sorter: (a, b) => (a.total ?? 0) - (b.total ?? 0)
    },
  ];

  const dataIndexList = ["-1", "-2", "-3"];
  const addSorterOptionToTable = (columns: any, dataIndexList: any) => {
    return columns?.map((column: any) => {
      if (dataIndexList.includes(column.dataIndex)) {
        column.sortIcon = ({sortOrder}:any)=>sortOrder==="ascend" ? <IoIosArrowUp /> :  <IoIosArrowDown />
        column.sorter = (a: any, b: any) => a[column.dataIndex] - b[column.dataIndex];
      }
      return column;
    });
  };

  useEffect(() => {
    if (reportType === 2) {
      setTableColumn(columnForScheduleHistory);
      setTableWidth(2000);
    } else if (reportType === 3) {
      setTableWidth("auto");
      setTableColumn(columnForONCallLookupHistory);
    } else {
      setTableWidth("auto");
      const columnsWithSorters = addSorterOptionToTable(columnData, dataIndexList);
      setTableColumn(columnsWithSorters);
    }
  }, [reportType, columnData, data]);

  return (
    <div>
      <Table
        dataSource={reportType === 3 ? data?.data : data}
        scroll={{
          x: tableWidth,
        }}
        rowClassName={(_record, index) =>
          index % 2 === 0 ? "table-row-light " : "table-row-dark"
        }
        columns={tableColumn}
      />
    </div>
  );
};
