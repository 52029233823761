import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined, } from "@ant-design/icons";
import { Col, Divider, Input, Modal, Row, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { LiaSearchSolid } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RootState, appDispatch } from "../../app/store";
import SimpleButton from "../../components/buttons/simpleButton/SimpleButton";
import { AddPublicHolidayCard } from "./components/AddPublicHoliday/AddPublicHolidayCard";
import { PublicHolidaysTable } from "./components/PublicHolidaysTable/PublicHolidaysTable";
import { deletePublicHoliday, fetchPublicHolidayList, resetDeletePublicHoliday, resetFetchPublicHolidayList } from "../../features/publicHoliday/publicHolidaySlice";
import "./publicHoliday.scss";

const { confirm } = Modal
export const PublicHolidays: React.FC = () => {

    const dispatch = useDispatch<appDispatch>()

    const { publicHolidayListData, isLoading } = useSelector((state: RootState) => state.publicHoliday.fetchPublicHolidayList)
    const { isLoading: isLoadingDeletePublicHoliday, isSuccess: isSuccessDeletePublicHoliday, isError: isErrorDeletePublicHoliday, message: messageDeletePublicHoliday } = useSelector((state: RootState) => state.publicHoliday.deletePublicHoliday)

    const [searchQuery, setSearchQuery] = useState<string>("")
    const [publicHolidayTableDataApi, setPublicHolidayTableDataApi] = useState([])
    const [selectedTableRowKeys, setSelectedTableRowKeys] = useState<React.Key[]>([])
    const [selectedTableRows, setSelectedTableRows] = useState<any>([]);
    const [isAddPublicHolidayModalVisible, setIsAddPublicHolidayModalVisible] = useState<boolean>(false)
    const [isIpad, setIsIpad] = useState<boolean>(false)
    const [isMobile, setIsmobile] = useState<boolean>(window.innerWidth < 478)


    // checking device is mobile or not
    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.addEventListener("resize", handleResize)
        }
    }, [])


    //to fetch public holiday list from server when page initially loads
    useEffect(() => {
        dispatch(fetchPublicHolidayList({ searchQuery }))

        return () => {
            dispatch(resetFetchPublicHolidayList())
        }
    }, [])

    //handle api response for delete public holiday api
    useEffect(() => {
        if (isSuccessDeletePublicHoliday) {
            toast.success(messageDeletePublicHoliday)
            clearSelectedTableRows()
            dispatch(fetchPublicHolidayList({ searchQuery }))

        } else if (isErrorDeletePublicHoliday) {
            toast.error(messageDeletePublicHoliday)
        }
        return () => {
            dispatch(resetDeletePublicHoliday())
        }
    }, [isSuccessDeletePublicHoliday, isErrorDeletePublicHoliday, messageDeletePublicHoliday])

    //to set public holiday table by default
    useEffect(() => {
        setPublicHolidayTableDataApi(publicHolidayListData)
    }, [publicHolidayListData])


    const handleResize = () => {
        setIsmobile(window.innerWidth < 478)
        setIsIpad(window.innerWidth >= 768 && window.innerWidth <= 1024 && (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth))
    }

    //handle public holiday search
    const handlePublicHolidaySearch = () => {
        dispatch(fetchPublicHolidayList({ searchQuery }))
    }

    //handle add public holiday
    const handleAddPublicHoliday = () => {
        clearSelectedTableRows()
        setIsAddPublicHolidayModalVisible(true)
    }

    //handle modal close for add/edit public holiday
    const handleCloseAddPublicHolidayModal = () => {
        setIsAddPublicHolidayModalVisible(false)
    }

    //handle delete button is disabled or not
    const isDeleteButtonDisabled = () => {
        return (
            (selectedTableRowKeys && selectedTableRowKeys.length === 0) ||
            selectedTableRowKeys === undefined
        );
    };

    //handle delete selected public holiday
    const handleDeleteSelectedPublicHoliday = () => {

        // Extract names from selected public holiday Rows
        const selectedPublicHolidayNames = selectedTableRows.map((row: any) => row.name).join(', ');

        // Display confirmation modal
        confirm({
            title: "Delete  Public holiday confirmation",
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: (<p>Are you sure you want to delete  <b>{selectedPublicHolidayNames}</b>  Public Holiday ? </p>),
            onOk() {
                const payload = {
                    public_holiday_ids: selectedTableRowKeys
                }
                dispatch(deletePublicHoliday(payload))
            },
            // Do nothing if user cancels
            onCancel() { },
        });
    };

    //handle to clear selected table rows
    const clearSelectedTableRows = () => {
        if (selectedTableRowKeys) {
            setSelectedTableRowKeys([]);
            setSelectedTableRows([]);
        }
    };

    return (
        <div className="public-holiday-container">
            {/* Search header */}
            <Row>
                <Col xs={24} md={24}>
                    <div className="public-holiday-header">Search by name</div>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                {/* Search bar & button*/}
                <Col xs={24} sm={15} md={12} lg={13} xl={15}>
                    <Row>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                            }}
                        >
                            <Col xs={16} md={18} lg={24} span={10}>
                                <span className="search-bar">
                                    <Input
                                        placeholder="Search"
                                        prefix={<LiaSearchSolid className="search-bar-icon" />}
                                        onChange={(e) => {
                                            setSearchQuery(e.target.value);
                                        }}
                                    />
                                </span>
                            </Col>
                            <Col xs={8} md={6} lg={4}>
                                <span>
                                    <SimpleButton
                                        text={isMobile ? "" : "Search"}
                                        color="primary"
                                        icon={<SearchOutlined />}
                                        onClick={handlePublicHolidaySearch}
                                    />
                                </span>
                            </Col>
                        </div>
                    </Row>
                </Col>

                {/* Add, Delete & Filter button*/}
                <Col xs={24} sm={9} md={12} lg={11} xl={9}>
                    <Row justify="end" gutter={10} className="add-public-holiday-sec ">
                        <Space direction="horizontal" align="center">
                            {/* Add new status board button */}
                            <Tooltip title={isMobile || isIpad ? "" : "Add New Public Holiday"} placement="bottomRight">
                                <span>
                                    <SimpleButton
                                        icon={<PlusOutlined className="add-public-holiday-icon" />}
                                        text={isMobile ? "" : "Add"}
                                        color="secondary"
                                        onClick={handleAddPublicHoliday}
                                    />
                                </span>
                            </Tooltip>
                            <AddPublicHolidayCard
                                open={isAddPublicHolidayModalVisible}
                                onClose={handleCloseAddPublicHolidayModal}
                                isEditMode={false}
                                searchQuery={searchQuery}
                            />

                            {/*Delete button */}
                            <SimpleButton
                                text={isMobile ? "" : "Delete"}
                                color={
                                    isDeleteButtonDisabled()
                                        ? "danger-disabled"
                                        : "danger-default"
                                }
                                disabled={isDeleteButtonDisabled()}
                                icon={
                                    <RiDeleteBin6Line
                                        color={
                                            isDeleteButtonDisabled()
                                                ? "danger-disabled"
                                                : "danger-default"
                                        }
                                    />
                                }
                                onClick={handleDeleteSelectedPublicHoliday}
                            />

                        </Space>
                    </Row>
                </Col>
            </Row>

            <Divider />


            {/* public holiday table */}
            < PublicHolidaysTable
                publicHolidayTableDataApi={publicHolidayTableDataApi}
                selectedTableRowKeys={selectedTableRowKeys}
                setSelectedTableRowKeys={setSelectedTableRowKeys}
                isMobile={isMobile}
                setSelectedTableRows={setSelectedTableRows}
                searchQuery={searchQuery}
            />
        </div >
    )
}
