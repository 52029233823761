import { Col, List, Row, Tag } from "antd";
import UserModalRangePicker from "../UserModalRangePicker";

interface GeneralInfoTabContentProps {
    fetchUserDetailsData: any;
    handleRangePickerChange: any
    dateRange: any
}

const GeneralInfoTabContent: React.FC<GeneralInfoTabContentProps> = ({
    fetchUserDetailsData,
    handleRangePickerChange,
    dateRange
}) => {
    const data = [
        {
            title: "Select Date Range:",
            content: <UserModalRangePicker userId={fetchUserDetailsData[0]?.id} handleRangePickerChange={handleRangePickerChange} dateRange={dateRange} />,
        },
        {
            title: "Specialty(s):",
            content: fetchUserDetailsData[0]?.speciality.map((item: any) => (
                <Tag key={item.id} className="generalinfo-tab-content">
                    {item?.name}
                </Tag>
            )),
        },
        {
            title: "Group(s):",
            content: (
                <div>
                    {fetchUserDetailsData[0]?.department.map((item: any) => (
                        <Tag key={item.id} className="generalinfo-tab-content">
                            {item?.name}
                        </Tag>
                    ))}
                </div>
            ),
        },
        {
            title: "Active site(s):",
            content: (
                <div>
                    {fetchUserDetailsData[0]?.hospital.map((item: any) => (
                        <Tag key={item.id}>{item?.name}</Tag>
                    ))}
                </div>
            ),
        },
        {
            title: "Number of changes:",
            content: fetchUserDetailsData[0]?.change_count,
        },
        {
            title: "Accepted requests:",
            content: fetchUserDetailsData[0]?.accept_count,
        },
        {
            title: "Number of shifts:",
            content: fetchUserDetailsData[0]?.shift_count,
        },
        { title: "Leaves:", content: fetchUserDetailsData[0]?.holiday_count },
        {
            title: "Number of hours:",
            content: fetchUserDetailsData[0]?.total_hours,
        },
    ];

    return (
        <List
            dataSource={data}
            className="user-card-section"
            renderItem={(item) => (
                <List.Item style={{ padding: "8px",overflowX:"hidden" }}>
                    <Row gutter={[16, 8]} align="middle">
                        <Col flex="none">
                            <h4 style={{ margin: 0 }} className="generalinfo-tab-content">
                                {item.title}
                            </h4>
                        </Col>
                        <Col flex="auto">
                            <p style={{ margin: 0 }} className="generalinfo-tab-content">
                                {item.content}
                            </p>
                        </Col>
                    </Row>
                </List.Item>
            )}
        />
    );
};


export default GeneralInfoTabContent