import {
  ExclamationCircleOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
} from "@ant-design/icons";
import type { TabsProps } from "antd";
import { Col, Grid, Modal, Row, Switch, Tabs, Button, Skeleton } from "antd";
import dayjs from "dayjs";
import React, { FC, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL, DEFAULT_PROFILE_IMAGE } from "../../../constants";

import { RootState, appDispatch } from "../../../app/store";
import {
  fetchUserDetails,
  multiFunctionDeleteUsers,
  resetFetchUserDetails,
} from "../../../features/users/usersSlice";
import GeneralInfoTabContent from "./userModalTabs/GeneralInfoTabContent";
import LeaveDaysTabContent from "./userModalTabs/LeaveDaysTabContent";
import PublicHolidayTabContent from "./userModalTabs/PublicHolidayTabContent";
import "./usermodal.scss";

const { confirm } = Modal;
const { useBreakpoint } = Grid;

type UserDetailsCardPropsType = {
  fetchUserDetailsData: [any];
  handleEditUser: any;
  isLoading: boolean;
  selectedShift?:any;
};

const UserCardDetails: React.FC<UserDetailsCardPropsType> = ({
  fetchUserDetailsData,
  handleEditUser,
  isLoading,
  selectedShift
}) => {
  const dispatch = useDispatch<appDispatch>();
  const screens = useBreakpoint();
  const [isActive, setIsActive] = useState(
    fetchUserDetailsData?.[0]?.is_active
  );

  const imageStyle = {
    maxWidth: screens.xs ? "75%" : "60%",
    marginTop: screens.xs ? "2rem" : "0",
    height: "auto",
    borderRadius: screens.xs ? "50%" : "0",
  };

  const center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  //to activate and deactivate user
  const handleToggle = () => {
    const userId = fetchUserDetailsData[0]?.id;
    const updatedDeleteMethod = {
      deactivate_ids: isActive ? [userId] : [],
      activate_ids: isActive ? [] : [userId],
    };
    const actionName = isActive ? "deactivate" : "activate";
    const userName = `${fetchUserDetailsData[0]?.first_name} ${fetchUserDetailsData[0]?.last_name}`;
    confirm({
      title: "Confirmation for user status change ?",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: (
        <p>
          Are you sure you want change status of <b>{userName}</b> to{" "}
          <b>{actionName}</b> ?
        </p>
      ),
      onOk() {
        dispatch(multiFunctionDeleteUsers(updatedDeleteMethod)).then((data) => {
          if (data.payload.isSuccess) {
            toast.success(data.payload.message);
            setIsActive(!isActive);
          } else {
            toast.error(data.payload);
          }
        });
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    setIsActive(fetchUserDetailsData?.[0]?.is_active);
  }, [fetchUserDetailsData]);

  const [profilePic, setProfilePic] = useState(DEFAULT_PROFILE_IMAGE);

  useEffect(() => {
    const img = new Image();
    let profileImage =
      fetchUserDetailsData && fetchUserDetailsData[0]?.profile_pic
        ? `${API_URL}${fetchUserDetailsData[0]?.profile_pic}`
        : DEFAULT_PROFILE_IMAGE;
    img.src = profileImage;

    img.onload = () => {
      // The image was successfully loaded
      setProfilePic(profileImage);
    };

    img.onerror = () => {
      // The image failed to load
      setProfilePic(DEFAULT_PROFILE_IMAGE);
    };
  }, [fetchUserDetailsData]);

  return (
    <div style={{ padding: "10px" }}>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={8} style={screens.xs ? center : {}}>
          <img src={profilePic} alt="User" style={imageStyle} />
        </Col>

        <Col xs={24} sm={16}>
          <div style={{ marginRight: "20px" }} className="user-profile-detail">
            <Skeleton active loading={isLoading}>
              <Row gutter={16}>
                <Col xs={24} md={12} lg={12} style={screens.xs ? center : {}}>
                  <h3 className="profile-name">
                    {`${fetchUserDetailsData[0]?.first_name} ${fetchUserDetailsData[0]?.last_name}`}{" "}
                  </h3>
                </Col>
                <Col xs={12} sm={8} lg={8}>
                  <Switch
                    defaultChecked={fetchUserDetailsData[0]?.is_active}
                    checked={isActive}
                    onChange={handleToggle}
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    style={{ marginRight: "10px" }}
                  />
                </Col>
                <Col xs={12} sm={4} lg={4}>
                  {fetchUserDetailsData[0]?.edit === 0 ? (
                    <span style={{ color: "#1677ff80", cursor: "not-allowed" }}>
                      Edit
                    </span>
                  ) : (
                    <a onClick={handleEditUser}>
                      <FaEdit />
                      Edit
                    </a>
                  )}
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12} style={screens.xs ? { marginTop: ".5rem" } : {}}>
                  <h4>
                    {" "}
                    <b>Primary Phone Number </b>
                  </h4>
                </Col>
                <Col span={12} style={screens.xs ? { marginTop: ".5rem" } : {}}>
                  <h4>
                    <b> Secondary Phone Number</b>
                  </h4>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <h4>{fetchUserDetailsData[0]?.mobile_number}</h4>
                </Col>
                <Col span={12}>
                  <h4>{fetchUserDetailsData[0]?.alternate_number}</h4>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <h4>
                    <b>Pager</b>
                  </h4>
                </Col>
                <Col span={12}>
                  <h4>
                    <b>Email Address</b>
                  </h4>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <h4>
                    {fetchUserDetailsData[0]?.pager !== "null"
                      ? fetchUserDetailsData[0]?.pager
                      : "No Pager"}{" "}
                  </h4>
                </Col>
                <Col span={12}>
                  <h4>{fetchUserDetailsData[0]?.email}</h4>
                </Col>
              </Row>
            </Skeleton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

type UserCardTabsPropsType = {
  defaultTabKey: string;
  onChange: any;
  fetchUserDetailsData: [any];
  handleRangePickerChange: any;
  dateRange: any;
  selectedShift?:any;
};

const UserCardtabs: React.FC<UserCardTabsPropsType> = ({
  dateRange,
  handleRangePickerChange,
  onChange,
  defaultTabKey,
  fetchUserDetailsData,
  selectedShift
}) => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General info",
      children: (
        <GeneralInfoTabContent
          fetchUserDetailsData={fetchUserDetailsData}
          handleRangePickerChange={handleRangePickerChange}
          dateRange={dateRange}
        />
      ),
    },
    {
      key: "2",
      label: "Leaves days",
      children: (
        <LeaveDaysTabContent
          fetchUserDetailsData={fetchUserDetailsData}
          handleRangePickerChange={handleRangePickerChange}
          dateRange={dateRange}
          selectedShift={selectedShift}
        />
      ),
    },
    {
      key: "3",
      label: "Public holidays",
      children: (
        <PublicHolidayTabContent
          fetchUserDetailsData={fetchUserDetailsData}
          handleRangePickerChange={handleRangePickerChange}
          dateRange={dateRange}
        />
      ),
    },
  ];
  const screens = useBreakpoint();

  return (
    <Tabs
      style={screens.xs ? { padding: "1rem" } : {}}
      defaultActiveKey={defaultTabKey}
      onChange={onChange}
      items={items}
      activeKey={defaultTabKey}
    />
  );
};

interface UserModalProps {
  visible: boolean;
  onClose: () => void;
  selectedUser?: any;
  selectedShift?:any;
}

const UserModal: React.FC<UserModalProps> = ({
  visible,
  onClose,
  selectedUser,
  selectedShift
}) => {
  const [defaultTabKey, setDefaultTabKey] = useState("1");
  const [isNextButtonClicked, setIsNextButtonClicked] =
    useState<boolean>(false);

  const dispatch = useDispatch<appDispatch>();

  const currentYear = dayjs().year();
  const startDate = dayjs()
    .year(currentYear)
    .startOf("year")
    .format("YYYY-MM-DD");
  const endDate = dayjs().year(currentYear).endOf("year").format("YYYY-MM-DD");
  const [dateRange, setDateRange] = useState([startDate, endDate]);

  const { fetchUserDetailsData, isLoading } = useSelector(
    (state: RootState) => state.users.fetchUserDetails
  );

  const navigate = useNavigate();

  const onChange = (key: string) => {
    setDefaultTabKey(key);
  };

  // const userId = fetchUserDetailsData[0]?.id;
  const userId = selectedUser?.id;

  const handleEditUser = () => {
    navigate(`/users/edit-user/${userId}`, { state: { isEditMode: true } });
  };

  const handleRangePickerChange = (dateString: any) => {
    // setDateRange(dateString.format('YYYY-MM-DD'));
    const formattedDates = dateString?.map((date: any) =>
      date.format("YYYY-MM-DD")
    );

    const dateObjects = formattedDates?.map((dateString: any) =>
      dayjs(dateString).format("YYYY-MM-DD")
    );
    setDateRange(dateObjects);
    // setDateRange(formattedDates);
  };

  const handleUserModelClose = () => {
    setDefaultTabKey("1");
    onClose();
  };

  useEffect(() => {
    if (selectedUser) {
      const [startDate, endDate] = dateRange;
      dispatch(fetchUserDetails({ userId, startDate, endDate })).then(
        (data) => {
          if (data.payload.isSuccess) {
            // toast.success('data fetched')
            setDefaultTabKey("1");
          } else {
            // toast.error('not fetched')
          }
        }
      );
    }
    return () => {
      dispatch(resetFetchUserDetails());
    };
  }, [userId, dateRange]);
  const screens = useBreakpoint();
  return (
    <>
      {fetchUserDetailsData && (
        <Modal
          width={screens.xs ? 300 : 800}
          centered
          open={visible}
          onCancel={() => {
            handleUserModelClose();
            setIsNextButtonClicked(false);
          }}
          footer={
            screens.xs
              ? [
                  <Button
                    className="usercard-next-button"
                    icon={
                      isNextButtonClicked ? (
                        <LeftCircleOutlined color={"green"} />
                      ) : (
                        <RightCircleOutlined />
                      )
                    }
                    style={
                      isNextButtonClicked
                        ? { margin: "0 90% 1rem .75rem" }
                        : { margin: "0 1rem 1rem 0" }
                    }
                    onClick={() => {
                      setIsNextButtonClicked(!isNextButtonClicked);
                    }}
                  ></Button>,
                ]
              : [null]
          }
        >
          {screens.xs &&
            (!isNextButtonClicked ? (
              <UserCardDetails
                handleEditUser={handleEditUser}
                fetchUserDetailsData={fetchUserDetailsData}
                isLoading={isLoading}
              />
            ) : (
              <UserCardtabs
                defaultTabKey={defaultTabKey}
                handleRangePickerChange={handleRangePickerChange}
                onChange={onChange}
                dateRange={dateRange}
                fetchUserDetailsData={fetchUserDetailsData}
                selectedShift={selectedShift}
              />
            ))}

          {!screens.xs && (
            <>
              <UserCardDetails
                handleEditUser={handleEditUser}
                fetchUserDetailsData={fetchUserDetailsData}
                isLoading={isLoading}
              />
              <UserCardtabs
                defaultTabKey={defaultTabKey}
                handleRangePickerChange={handleRangePickerChange}
                onChange={onChange}
                dateRange={dateRange}
                fetchUserDetailsData={fetchUserDetailsData}
                selectedShift={selectedShift}
              />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

export default UserModal;
