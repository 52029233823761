import { Col, List, Row } from "antd";

import UserModalRangePicker from "../UserModalRangePicker";
import UserModalTable from "../UserModalTable";


interface PublicHolidayTabContentProps {
    fetchUserDetailsData: any;
    handleRangePickerChange: any
    dateRange: any
}
const PublicHolidayTabContent: React.FC<PublicHolidayTabContentProps> = ({
    fetchUserDetailsData,
    handleRangePickerChange,
    dateRange
}) => {

    const publicholidaydata = [
        {
            title: "Select Date Range:",
            content: <UserModalRangePicker userId={fetchUserDetailsData[0]?.id} handleRangePickerChange={handleRangePickerChange} dateRange={dateRange} />,
        },
    ];

    return (
        <List
            dataSource={publicholidaydata}
            className="user-card-section"
            renderItem={(item, index) => (
                <>
                    <List.Item key={index}
                        style={{ padding: "8px", borderBottom: "1px solid #e8e8e8" }}
                    >
                        <Row gutter={[16, 8]} align="middle">
                            <Col flex="none">
                                <h4 style={{ margin: 0 }} className="publicholiday-content">
                                    {item.title}
                                </h4>
                            </Col>
                            <Col flex="auto">
                                <p style={{ margin: 0 }} className="publicholiday-content">
                                    {item.content}
                                </p>
                            </Col>
                        </Row>
                    </List.Item>
                    <UserModalTable fetchUserDetailsData={fetchUserDetailsData} dateRange={dateRange} userId={fetchUserDetailsData[0]?.id} />
                </>
            )}
        />
    );
};

export default PublicHolidayTabContent