import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import assignNurseService from "./assignNurseService";

interface assignNurseState {
  fetchAssignNurseList: {
    assignNurseListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
}

const initialState: assignNurseState = {
  fetchAssignNurseList: {
    assignNurseListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
};

export const fetchAssignNurseList = createAsyncThunk(
  "careTeam/fetchAssignNurseList",
  async (
    {
      searchQuery,
      unitID,
      siteID,
    }: { searchQuery: string; unitID: any; siteID: any },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await assignNurseService.fetchAssignNurseList(
        { searchQuery, unitID, siteID },
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const assignNurseSlice = createSlice({
  name: "assignNurse",
  initialState,
  reducers: {
    resetfetchAssignNurseList: (state) => {
      state.fetchAssignNurseList = initialState.fetchAssignNurseList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchAssignNurseList.pending,
        (state, action: PayloadAction<any>) => {
          state.fetchAssignNurseList.isLoading = true;
        }
      )
      .addCase(
        fetchAssignNurseList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.fetchAssignNurseList.isLoading = false;
          state.fetchAssignNurseList.isSuccess = true;
          state.fetchAssignNurseList.assignNurseListData =
            action.payload.result;
        }
      )
      .addCase(
        fetchAssignNurseList.rejected,
        (state, action: PayloadAction<any>) => {
          state.fetchAssignNurseList.isLoading = false;
          state.fetchAssignNurseList.isError = true;
          state.fetchAssignNurseList.message = action.payload;
        }
      );
  },
});

export const { resetfetchAssignNurseList } = assignNurseSlice.actions;

export default assignNurseSlice.reducer;
