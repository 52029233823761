import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import bedReportService from "./bedReportService";

interface bedReportState {
    fetchDoctorList: {
      doctorListData: any;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      message: string;
    };
    fetchCareTeamList: {
      careTeamListData: any;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      message: string;
    };
    fetchAssignedNurseList: {
      assignedNurseListData: any;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      message: string;
    };
    fetchCalledNurseList: {
      calledNurseListData:any;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      message: string;
    };
    generatePreview: {
      generatePreviewData:any;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      message: string;
    };
    downloadCSV: {
      generatePreviewData?:any;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      message: string;
    };
}

const initialState: bedReportState = {
    fetchDoctorList: {
      doctorListData: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      message: "",
    },
    fetchCareTeamList: {
      careTeamListData: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      message: "",
    },
    fetchAssignedNurseList: {
      assignedNurseListData: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      message: "",
    },
    fetchCalledNurseList: {
      calledNurseListData: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      message: "",
    },
    generatePreview: {
      generatePreviewData: [],
      isLoading: false,
      isSuccess: false,
      isError: false,
      message: "",
    },
    downloadCSV: {
      isLoading: false,
      isSuccess: false,
      isError: false,
      message: "",
    },
}

export const fetchDoctorList = createAsyncThunk(
    "bedReport/fetchDoctorList",
    async (siteID:any, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        const token = state.auth.user.token;
  
        const response = await bedReportService.fetchDoctorList(siteID, token);
        if (response) {
          return response;
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const fetchCareTeamList = createAsyncThunk(
    "bedReport/fetchCareTeamList",
    async (siteID:any, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        const token = state.auth.user.token;
  
        const response = await bedReportService.fetchCareTeamList(siteID, token);
        if (response) {
          return response;
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const fetchAssignedNurseList = createAsyncThunk(
    "bedReport/fetchAssignedNurseList",
    async (payload:any, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        const token = state.auth.user.token;
  
        const response = await bedReportService.fetchAssignedNurseList(payload, token);
        if (response) {
          return response;
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const fetchCalledNurseList = createAsyncThunk(
    "bedReport/fetchCalledNurseList",
    async (siteID:any, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        const token = state.auth.user.token;
  
        const response = await bedReportService.fetchCalledNurseList(siteID, token);
        if (response) {
          return response;
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const generatePreview = createAsyncThunk(
    "bedReport/generatePreview",
    async (payload:any, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        const token = state.auth.user.token;
  
        const response = await bedReportService.generatePreview(payload, token);
        if (response) {
          return response;
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

export const downloadCSV = createAsyncThunk(
    "bedReport/downloadCSV",
    async (payload:any, thunkAPI) => {
      try {
        const state = thunkAPI.getState() as RootState;
        const token = state.auth.user.token;
  
        const response = await bedReportService.downloadCSV(payload, token);
        if (response) {
          return response;
        } else {
          throw new Error(response.message);
        }
      } catch (error: any) {
        const message =
          (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  const bedReportSlice = createSlice({
    name: "bedReport",
    initialState,
    reducers: {
      resetFetchDoctorList: (state) => {
        state.fetchDoctorList = initialState.fetchDoctorList;
      },
      resetFetchCareTeamList: (state) => {
        state.fetchCareTeamList = initialState.fetchCareTeamList;
      },
      resetFetchAssignedNurseList: (state) => {
        state.fetchAssignedNurseList = initialState.fetchAssignedNurseList;
      },
      resetFetchCalledNurseList: (state) => {
        state.fetchCalledNurseList = initialState.fetchCalledNurseList;
      },
      resetGeneratePreview: (state) => {
        state.generatePreview = initialState.generatePreview;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchDoctorList.pending, (state, action: PayloadAction<any>) => {
          state.fetchDoctorList.isLoading = true;
        })
        .addCase(fetchDoctorList.fulfilled, (state, action: PayloadAction<any>) => {
          state.fetchDoctorList.isLoading = false;
          state.fetchDoctorList.isSuccess = true;
          state.fetchDoctorList.doctorListData = action.payload.results;
        })
        .addCase(fetchDoctorList.rejected, (state, action: PayloadAction<any>) => {
          state.fetchDoctorList.isLoading = false;
          state.fetchDoctorList.isError = true;
          state.fetchDoctorList.message = action.payload;
        })
        .addCase(fetchCareTeamList.pending, (state, action: PayloadAction<any>) => {
          state.fetchCareTeamList.isLoading = true;
        })
        .addCase(fetchCareTeamList.fulfilled, (state, action: PayloadAction<any>) => {
          state.fetchCareTeamList.isLoading = false;
          state.fetchCareTeamList.isSuccess = true;
          state.fetchCareTeamList.careTeamListData = action.payload.results;
        })
        .addCase(fetchCareTeamList.rejected, (state, action: PayloadAction<any>) => {
          state.fetchCareTeamList.isLoading = false;
          state.fetchCareTeamList.isError = true;
          state.fetchCareTeamList.message = action.payload;
        })
        .addCase(fetchAssignedNurseList.pending, (state, action: PayloadAction<any>) => {
          state.fetchAssignedNurseList.isLoading = true;
        })
        .addCase(fetchAssignedNurseList.fulfilled, (state, action: PayloadAction<any>) => {
          state.fetchAssignedNurseList.isLoading = false;
          state.fetchAssignedNurseList.isSuccess = true;
          state.fetchAssignedNurseList.assignedNurseListData = action.payload.results;
        })
        .addCase(fetchAssignedNurseList.rejected, (state, action: PayloadAction<any>) => {
          state.fetchAssignedNurseList.isLoading = false;
          state.fetchAssignedNurseList.isError = true;
          state.fetchAssignedNurseList.message = action.payload;
        })
        .addCase(fetchCalledNurseList.pending, (state, action: PayloadAction<any>) => {
          state.fetchCalledNurseList.isLoading = true;
        })
        .addCase(fetchCalledNurseList.fulfilled, (state, action: PayloadAction<any>) => {
          state.fetchCalledNurseList.isLoading = false;
          state.fetchCalledNurseList.isSuccess = true;
          state.fetchCalledNurseList.calledNurseListData = action.payload.results;
        })
        .addCase(fetchCalledNurseList.rejected, (state, action: PayloadAction<any>) => {
          state.fetchCalledNurseList.isLoading = false;
          state.fetchCalledNurseList.isError = true;
          state.fetchCalledNurseList.message = action.payload;
        })
        .addCase(generatePreview.pending, (state, action: PayloadAction<any>) => {
          state.generatePreview.isLoading = true;
        })
        .addCase(generatePreview.fulfilled, (state, action: PayloadAction<any>) => {
          state.generatePreview.isLoading = false;
          state.generatePreview.isSuccess = true;
          state.generatePreview.generatePreviewData = action.payload.result;
        })
        .addCase(generatePreview.rejected, (state, action: PayloadAction<any>) => {
          state.generatePreview.isLoading = false;
          state.generatePreview.isError = true;
          state.generatePreview.message = action.payload;
        })
        .addCase(downloadCSV.pending, (state, action: PayloadAction<any>) => {
          state.downloadCSV.isLoading = true;
        })
        .addCase(downloadCSV.fulfilled, (state, action: PayloadAction<any>) => {
          state.downloadCSV.isLoading = false;
          state.downloadCSV.isSuccess = true;
          // state.downloadCSV.downloadCSVData = action.payload.result;
        })
        .addCase(downloadCSV.rejected, (state, action: PayloadAction<any>) => {
          state.downloadCSV.isLoading = false;
          state.downloadCSV.isError = true;
          state.downloadCSV.message = action.payload;
        })
    },
  });

  export const {
   resetFetchDoctorList,
   resetFetchCareTeamList,
   resetFetchAssignedNurseList,
   resetFetchCalledNurseList,
   resetGeneratePreview
  } = bedReportSlice.actions;
  
  export default bedReportSlice.reducer;