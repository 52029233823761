import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import shiftRuleService from "./shiftRuleService";

interface shiftRuleSlice {
  fetchShiftList: {
    shiftListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchShiftDetails: {
    shiftDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleUsers: {
    scheduleUserData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchGroupSchedule: {
    scheduleGroupData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUserRule: {
    userRuleData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleRuleDetails: {
    scheduleRuleDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleRuleList: {
    scheduleRuleListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateScheduleShiftCalender: {
    ScheduleShiftCalenderData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleShiftList: {
    scheduleShiftListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  shiftRuleCheck: {
    shiftRuleCheckData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchAvailableUserList: {
    availableUserListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: shiftRuleSlice = {
  fetchShiftList: {
    shiftListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchShiftDetails: {
    shiftDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleUsers: {
    scheduleUserData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchGroupSchedule: {
    scheduleGroupData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUserRule: {
    userRuleData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleRuleDetails: {
    scheduleRuleDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleRuleList: {
    scheduleRuleListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateScheduleShiftCalender: {
    ScheduleShiftCalenderData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleShiftList: {
    scheduleShiftListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  shiftRuleCheck: {
    shiftRuleCheckData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchAvailableUserList: {
    availableUserListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

//action to add shift
export const addShift = createAsyncThunk("shift/addShift", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.addShift(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch shift list
export const fetchShiftList = createAsyncThunk("shift/fetchShiftList", async (scheduleId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.fetchShiftList(scheduleId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to  fetch shift details
export const fetchShiftDetails = createAsyncThunk("shift/fetchShiftDetails", async (shiftId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.fetchShiftDetails(shiftId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to update shift details
export const updateShiftDetails = createAsyncThunk(
  "shift/updateShiftDetails",
  async ({ shiftId, payload }: { shiftId: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.updateShiftDetails({ shiftId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to  delete shift
export const deleteShift = createAsyncThunk("shift/deleteShift", async (shiftId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.deleteShift(shiftId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch list of users in schedule
export const fetchScheduleUsers = createAsyncThunk(
  "groups/fetchScheduleUsers",
  async ({ scheduleId, groupId }: { scheduleId: any; groupId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.fetchScheduleUsers(scheduleId, groupId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchGroupSchedule = createAsyncThunk(
  "groups/fetchGroupSchedule",
  async (
    {
      guid,
      start_time,
      end_time,
      shift,
      shedule_name_id,
      view_type,
    }: { guid: any; start_time: any; end_time: any; shift: any; shedule_name_id: any; view_type: any },
    thunkAPI
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.fetchGroupSchedule(
        guid,
        start_time,
        end_time,
        shift,
        shedule_name_id,
        view_type,
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to create user rule
export const createUserRule = createAsyncThunk("shift/createUserRule", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.createUserRule(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch user rule
export const fetchUserRule = createAsyncThunk("shift/fetchUserRule", async (userId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.fetchUserRule(userId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch user list to  display in table if first time rule is creating
export const fetchAvailableUserList = createAsyncThunk(
  "shift/fetchAvailableUserList",
  async (userId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.fetchAvailableUserList(userId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to create schedule rule
export const createScheduleRule = createAsyncThunk("shift/createScheduleRule", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.createScheduleRule(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to update schedule rule
export const updateScheduleRule = createAsyncThunk(
  "shift/updateScheduleRule",
  async ({ shiftId, payload }: { shiftId: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.updateScheduleRule({ shiftId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch schedule details
export const fetchScheduleRuleDetails = createAsyncThunk(
  "shift/fetchScheduleRuleDetails",
  async (shiftId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.fetchScheduleRuleDetails(shiftId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch schedule list
export const fetchScheduleRuleList = createAsyncThunk(
  "shift/fetchScheduleRuleList",
  async (scheduleId: number, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.fetchScheduleRuleList(scheduleId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to apply  shift assignment
export const applyShiftAssignment = createAsyncThunk("shift/applyShiftAssignment", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.applyShiftAssignment(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const shiftRuleCheck = createAsyncThunk("shift/shiftRuleCheck", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.shiftRuleCheck(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const assignShift = createAsyncThunk("shift/assignShift", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.assignShift(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const clearRule = createAsyncThunk("shift/clearRule", async (userId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await shiftRuleService.clearRule(userId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateScheduleShiftCalender = createAsyncThunk(
  "shift/updateScheduleShiftCalender",
  async ({ scheduleId, shiftId, date }: { scheduleId: number; shiftId: number; date: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.updateScheduleShiftCalender({ scheduleId, shiftId, date }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchScheduleShiftList = createAsyncThunk(
  "shift/fetchScheduleShiftList",
  async (scheduleId: number, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await shiftRuleService.fetchScheduleShiftList(scheduleId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const shiftRuleSlice = createSlice({
  name: "shiftRule",
  initialState,
  reducers: {
    resetFetchShiftList: (state) => {
      state.fetchShiftList = initialState.fetchShiftList;
    },
    resetFetchShiftDetails: (state) => {
      state.fetchShiftDetails = initialState.fetchShiftDetails;
    },
    resetScheduleUsers: (state) => {
      state.fetchScheduleUsers = initialState.fetchScheduleUsers;
    },
    resetGroupSchedule: (state) => {
      state.fetchGroupSchedule = initialState.fetchGroupSchedule;
    },
    resetFetchUserRule: (state) => {
      state.fetchUserRule = initialState.fetchUserRule;
    },
    resetFetchScheduleRuleDetails: (state) => {
      state.fetchScheduleRuleDetails = initialState.fetchScheduleRuleDetails;
    },
    resetFetchScheduleRuleList: (state) => {
      state.fetchScheduleRuleList = initialState.fetchScheduleRuleList;
    },
    resetUpdateScheduleShiftCalender: (state) => {
      state.updateScheduleShiftCalender = initialState.updateScheduleShiftCalender;
    },
    resetFetchScheduleShiftList: (state) => {
      state.fetchScheduleShiftList = initialState.fetchScheduleShiftList;
    },
    resetshiftRuleCheck: (state) => {
      state.fetchScheduleShiftList = initialState.fetchScheduleShiftList;
    },
    resetFetchAvailableUserList: (state) => {
      state.fetchAvailableUserList = initialState.fetchAvailableUserList;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchShiftList.pending, (state, action: PayloadAction<any>) => {
        state.fetchShiftList.isLoading = true;
      })
      .addCase(fetchShiftList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchShiftList.isLoading = false;
        state.fetchShiftList.isSuccess = true;
        state.fetchShiftList.shiftListData = action.payload.result;
      })
      .addCase(fetchShiftList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchShiftList.isLoading = false;
        state.fetchShiftList.isError = true;
        state.fetchShiftList.message = action.payload;
      })

      .addCase(fetchShiftDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchShiftDetails.isLoading = true;
      })
      .addCase(fetchShiftDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchShiftDetails.isLoading = false;
        state.fetchShiftDetails.isSuccess = true;
        state.fetchShiftDetails.shiftDetailsData = action.payload.result;
      })
      .addCase(fetchShiftDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchShiftDetails.isLoading = false;
        state.fetchShiftDetails.isError = true;
        state.fetchShiftDetails.message = action.payload;
      })
      .addCase(fetchScheduleUsers.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleUsers.isLoading = true;
      })
      .addCase(fetchScheduleUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleUsers.isLoading = false;
        state.fetchScheduleUsers.isSuccess = true;
        state.fetchScheduleUsers.scheduleUserData = action.payload.result;
      })
      .addCase(fetchScheduleUsers.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleUsers.isLoading = false;
        state.fetchScheduleUsers.isError = true;
        state.fetchScheduleUsers.message = action.payload.result;
      })
      .addCase(fetchGroupSchedule.pending, (state, action: PayloadAction<any>) => {
        state.fetchGroupSchedule.isLoading = true;
      })
      .addCase(fetchGroupSchedule.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchGroupSchedule.isLoading = false;
        state.fetchGroupSchedule.isSuccess = true;
        state.fetchGroupSchedule.scheduleGroupData = action.payload.result;
      })
      .addCase(fetchGroupSchedule.rejected, (state, action: PayloadAction<any>) => {
        state.fetchGroupSchedule.isLoading = false;
        state.fetchGroupSchedule.isError = true;
        state.fetchGroupSchedule.message = action.payload.result;
      })
      .addCase(fetchUserRule.pending, (state, action: PayloadAction<any>) => {
        state.fetchUserRule.isLoading = true;
      })
      .addCase(fetchUserRule.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUserRule.isLoading = false;
        state.fetchUserRule.isSuccess = true;
        state.fetchUserRule.userRuleData = action.payload.result;
      })
      .addCase(fetchUserRule.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUserRule.isLoading = false;
        state.fetchUserRule.isError = true;
        state.fetchUserRule.message = action.payload.result;
      })
      .addCase(fetchAvailableUserList.pending, (state, action: PayloadAction<any>) => {
        state.fetchAvailableUserList.isLoading = true;
      })
      .addCase(fetchAvailableUserList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchAvailableUserList.isLoading = false;
        state.fetchAvailableUserList.isSuccess = true;
        state.fetchAvailableUserList.availableUserListData = action.payload.result;
      })
      .addCase(fetchAvailableUserList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchAvailableUserList.isLoading = false;
        state.fetchAvailableUserList.isError = true;
        state.fetchAvailableUserList.message = action.payload.result;
      })
      .addCase(fetchScheduleRuleDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleRuleDetails.isLoading = true;
      })
      .addCase(fetchScheduleRuleDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleRuleDetails.isLoading = false;
        state.fetchScheduleRuleDetails.isSuccess = true;
        state.fetchScheduleRuleDetails.scheduleRuleDetailsData = action.payload.result;
      })
      .addCase(fetchScheduleRuleDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleRuleDetails.isLoading = false;
        state.fetchScheduleRuleDetails.isError = true;
        state.fetchScheduleRuleDetails.message = action.payload.result;
      })
      .addCase(fetchScheduleRuleList.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleRuleList.isLoading = true;
      })
      .addCase(fetchScheduleRuleList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleRuleList.isLoading = false;
        state.fetchScheduleRuleList.isSuccess = true;
        state.fetchScheduleRuleList.scheduleRuleListData = action.payload.result;
      })
      .addCase(fetchScheduleRuleList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleRuleList.isLoading = false;
        state.fetchScheduleRuleList.isError = true;
        state.fetchScheduleRuleList.message = action.payload.result;
      })
      .addCase(updateScheduleShiftCalender.pending, (state, action: PayloadAction<any>) => {
        state.updateScheduleShiftCalender.isLoading = true;
      })
      .addCase(updateScheduleShiftCalender.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateScheduleShiftCalender.isLoading = false;
        state.updateScheduleShiftCalender.isSuccess = true;
        state.updateScheduleShiftCalender.ScheduleShiftCalenderData = action.payload.result;
      })
      .addCase(updateScheduleShiftCalender.rejected, (state, action: PayloadAction<any>) => {
        state.updateScheduleShiftCalender.isLoading = false;
        state.updateScheduleShiftCalender.isError = true;
        state.updateScheduleShiftCalender.message = action.payload.result;
      })
      .addCase(fetchScheduleShiftList.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleShiftList.isLoading = true;
      })
      .addCase(fetchScheduleShiftList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleShiftList.isLoading = false;
        state.fetchScheduleShiftList.isSuccess = true;
        state.fetchScheduleShiftList.scheduleShiftListData = action.payload.result;
      })
      .addCase(fetchScheduleShiftList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleShiftList.isLoading = false;
        state.fetchScheduleShiftList.isError = true;
        state.fetchScheduleShiftList.message = action.payload.result;
      })
      .addCase(shiftRuleCheck.pending, (state, action: PayloadAction<any>) => {
        state.shiftRuleCheck.isLoading = true;
      })
      .addCase(shiftRuleCheck.fulfilled, (state, action: PayloadAction<any>) => {
        state.shiftRuleCheck.isLoading = false;
        state.shiftRuleCheck.isSuccess = true;
        state.shiftRuleCheck.shiftRuleCheckData = action.payload.result;
      })
      .addCase(shiftRuleCheck.rejected, (state, action: PayloadAction<any>) => {
        state.shiftRuleCheck.isLoading = false;
        state.shiftRuleCheck.isError = true;
        state.shiftRuleCheck.message = action.payload.result;
      });
  },
});

export const {
  resetFetchShiftList,
  resetFetchShiftDetails,
  resetScheduleUsers,
  resetGroupSchedule,
  resetFetchUserRule,
  resetFetchScheduleRuleDetails,
  resetFetchScheduleRuleList,
  resetUpdateScheduleShiftCalender,
  resetFetchScheduleShiftList,
  resetshiftRuleCheck,
  resetFetchAvailableUserList,
} = shiftRuleSlice.actions;
export default shiftRuleSlice.reducer;
