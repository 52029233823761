import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Table, Row, Col, Input, Button, Divider, Tooltip, Select } from "antd";
import { FaCircleCheck } from "react-icons/fa6";
import { VscErrorSmall } from "react-icons/vsc";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./dashBoardTable.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootState, appDispatch } from "../../../../app/store";
import {
  providerPayloadFetchType,
  providerPayloadType,
  SendMessageProviderType,
} from "../../types";
import {
  fetchGroupList,
  fetchProviderList,
  sendProviderMessage,
} from "../../../../features/dashboard/dashboardSlice";
import SimpleButton from "../../../../components/buttons/simpleButton/SimpleButton";
import { SearchOutlined } from "@ant-design/icons";
import { LiaSearchSolid } from "react-icons/lia";
import { toast } from "react-toastify";

export const DashBoardTable = () => {
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedTableRowKeys, setSelectedTableRowKeys] = useState<any>([]);
  const [selectedTableRows, setSelectedTableRows] = useState<any>();
  const [message, setMessage] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>();

  const location = useLocation();
  const dispatch = useDispatch<appDispatch>();

  const savedSelectedSite: any = sessionStorage.getItem("selectedSite");

  // Access the passed data
  const selectedData: providerPayloadFetchType =
    location.state?.data;

  const { groupListData } = useSelector(
    (state: RootState) => state.dashboard.fetchGroupList
  );

  const { providerListData, isLoading: providerListLoading } = useSelector(
    (state: RootState) => state.dashboard.fetchProviderList
  );

  const columns: any = [
    {
      title: "NAME",
      dataIndex: "name",
      align: "left",
    },
    {
      title: "LAST SHIFT",
      dataIndex: "last_shift",
      align: "left",
    },
    {
      title: "NEXT SHIFT",
      dataIndex: "next_shift",
      align: "left",
    },
    {
      title: "ON CALL",
      render: (record: any) => (
        <Tooltip
          title={
            record.availability_status === "OFF" ? "Not on call" : "On call"
          }
        >
          <div
            className="oncall-status-symbol"
            style={{
              backgroundColor:
                record.availability_status === "OFF" ? "#e71d1d" : "green",
            }}
          ></div>
        </Tooltip>
      ),
      align: "center",
    },
  ];

  useEffect(() => {
    const clearSessionStorageOnRefresh = () => {
      sessionStorage.removeItem("selectedProviders");
    };

    window.addEventListener("beforeunload", clearSessionStorageOnRefresh);

    return () => {
      window.removeEventListener("beforeunload", clearSessionStorageOnRefresh);
    };
  }, []);

  const handlefetchProviderList = (
    group: string | null,
    search: string,
    site: number[],
    pageNumber: number
  ) => {
    setCurrentPage(pageNumber);
    const payload: providerPayloadType = {
      payload: {
        group,
        search,
        site: site ?? JSON.parse(savedSelectedSite),
      },
      pageNumber,
    };
    dispatch(fetchProviderList(payload));
  };

  const handleGroupChange = (id: string) => {
    setSelectedGroup(id);
    handlefetchProviderList(id, "", JSON.parse(savedSelectedSite), 1);
    setCurrentPage(1);
  };

  const handleUserSearch = () => {
    handlefetchProviderList(selectedGroup, searchQuery, JSON.parse(savedSelectedSite), 1);
    setCurrentPage(1);
  };

  const sendMessage = () => {
    const savedSelectedProvidersString: any =
      sessionStorage.getItem("selectedProviders");
    const savedSelectedProviders: any = JSON.parse(
      savedSelectedProvidersString
    );
    const payload: SendMessageProviderType = {
      message: message,
      users: savedSelectedProviders,
    };
    dispatch(sendProviderMessage(payload)).then((data: any) => {
      if (data.payload.isSuccess) {
        toast.success(data?.payload?.message);
        setMessage("");
        sessionStorage.removeItem("selectedProviders");
        setSelectedTableRowKeys([]);
      } else {
        toast.error(data?.payload?.message);
      }
    });
  };

  const savedSelectedProvidersString: any =
    sessionStorage.getItem("selectedProviders");
  const savedSelectedProviders: any = JSON.parse(savedSelectedProvidersString);

  const rowSelection = {
    selectedRowKeys: savedSelectedProviders
      ? savedSelectedProviders
      : selectedTableRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedTableRowKeys(selectedRowKeys);
      setSelectedTableRows(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
    onSelect: (record: any, selected: any, selectedRows: any) => {
      if (selected) {
        if (savedSelectedProviders) {
          const selectedProviders = [...savedSelectedProviders, record.key];
          sessionStorage.setItem(
            "selectedProviders",
            JSON.stringify(selectedProviders)
          );
          setSelectedTableRowKeys(selectedProviders);
        } else {
          sessionStorage.setItem(
            "selectedProviders",
            JSON.stringify([record.key])
          );
          setSelectedTableRowKeys([record.key]);
        }
      } else {
        const filteredData = savedSelectedProviders.filter(
          (id: any) => id !== record.key
        );
        sessionStorage.setItem(
          "selectedProviders",
          JSON.stringify(filteredData)
        );
        setSelectedTableRowKeys(filteredData);
      }
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      const selectedArrayIDs = changeRows.map((data: any) => data.key);
      if (selected) {
        if (savedSelectedProviders) {
          const selectedProviders = [
            ...savedSelectedProviders,
            ...selectedArrayIDs,
          ];
          const uniqueArray = selectedProviders.filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
          sessionStorage.setItem(
            "selectedProviders",
            JSON.stringify(uniqueArray)
          );
          setSelectedTableRowKeys(uniqueArray);
        } else {
          sessionStorage.setItem(
            "selectedProviders",
            JSON.stringify(selectedArrayIDs)
          );
          setSelectedTableRowKeys(selectedArrayIDs);
        }
      } else {
        sessionStorage.setItem("selectedProviders", JSON.stringify([]));
        setSelectedTableRowKeys([]);
      }
    },
  };

  useEffect(() => {
    if (savedSelectedProviders) {
      setSelectedTableRowKeys(savedSelectedProviders);
    }
  }, []);

  // To fetch providers list
  useEffect(() => {
    handlefetchProviderList(selectedData?.group, selectedData?.search, selectedData?.site, 1);
    dispatch(fetchGroupList());
  }, []);

  const [providerList, setProviderList] = useState<any>([]);

  // manipulating the table data depend on selecting
  useEffect(() => {
    const savedSelectedProvidersString: any =
      sessionStorage.getItem("selectedProviders");
    const savedSelectedProviders: any = JSON.parse(
      savedSelectedProvidersString
    );

    if (
      savedSelectedProviders?.length !== 0 &&
      savedSelectedProviders &&
      providerListData?.data
    ) {
      // Custom sorting function
      const customSort = (a: any, b: any) => {
        // Get the index of each item in the itemIdsToMoveToTop array
        const indexA = savedSelectedProviders.indexOf(a.key);
        const indexB = savedSelectedProviders.indexOf(b.key);
        // Check if the item is in the itemIdsToMoveToTop array
        const isInTopA = indexA !== -1;
        const isInTopB = indexB !== -1;
        // If both items are in the top or bottom, maintain their relative order

        if ((isInTopA && isInTopB) || (!isInTopA && !isInTopB)) {
          return 0;
        }

        // If item A is in the top, move it up
        if (isInTopA) {
          return -1;
        }

        // If item B is in the top, move it up
        return 1;
      };
      const sortedData = [...providerListData?.data]?.sort(customSort);
      setProviderList(sortedData);
    } else {
      setProviderList(providerListData?.data);
    }
  }, [selectedTableRowKeys, providerListData]);

  const paginationConfig = {
    pageSize: 20,
    total: providerListData?.count,
    current: currentPage,
    // showSizeChanger: true,
    // showQuickJumper: true,
    onChange: (page: any, pageSize: any) =>
      handlefetchProviderList(selectedGroup, searchQuery, JSON.parse(savedSelectedSite), page),
    showSizeChanger: false,
  };

  return (
    <div className="dashboard-table-container">
      {/* Title and back button section */}
      <Row gutter={[18, 18]} align={"middle"}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <span style={{ alignItems: "center", display: "flex" }}>
            <Tooltip title="Dashboard" placement="bottom">
              <Button
                size="small"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  sessionStorage.removeItem("selectedProviders");
                  navigate("/dashboard");
                }}
              />
            </Tooltip>
            <span
              className="text-label-style"
              style={{
                fontSize: "20px",
                fontWeight: 600,
                marginLeft: "10px",
              }}
            >
              Providers
            </span>
          </span>
        </Col>
      </Row>
      {/* Filter select section */}
      <Row justify={"start"}>
        <Col xs={24} md={24} xl={24} xxl={24}>
          <div className="statusBoard-header"> Search by name </div>
        </Col>
      </Row>
      <Row
        gutter={[0, 16]}
        style={{ margin: "0.5rem 0 0 0" }}
        justify={"space-between"}
      >
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={12}
          xl={12}
          className="group-filter-container"
        >
          {/* Search bar & button*/}
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
              }}
            >
              <Col xs={16} md={18} lg={20} span={10}>
                <span className="search-bar">
                  <Input
                    placeholder="Search"
                    prefix={<LiaSearchSolid className="search-bar-icon" />}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </span>
              </Col>
              <Col xs={8} md={6} lg={3}>
                <span>
                  <SimpleButton
                    text={"Search"}
                    color="primary"
                    icon={<SearchOutlined />}
                    onClick={handleUserSearch}
                  />
                </span>
              </Col>
            </div>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={10}
          lg={6}
          xl={6}
          className="group-filter-container"
        >
          <label>Group</label>
          <Select
            value={selectedGroup}
            style={{ width: "70%", textAlign: "left" }}
            onChange={handleGroupChange}
            defaultValue={"All"}
            options={[
              {
                value: null,
                label: "All",
              },
              ...groupListData?.map((data: any) => ({
                value: data.id,
                label: data.name,
              })),
            ]}
          ></Select>
        </Col>
      </Row>

      <Divider></Divider>
      {/* Table section */}
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div>
            <Table
              columns={columns}
              dataSource={providerList}
              rowSelection={{ ...rowSelection }}
              rowClassName={(_record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              loading={providerListLoading}
              pagination={paginationConfig}
            />
          </div>
        </Col>
      </Row>
      {/* Send message section */}
      <Row justify={"start"}>
        <Col className="dashboard-send-message-text">
          <p>Send Message:</p>
        </Col>
      </Row>
      <Row justify={"start"} align={"bottom"}>
        <Col xs={16} sm={16} md={12} lg={12} xl={12}>
          <Input.TextArea
            placeholder={""}
            bordered={true}
            autoSize={{ minRows: 3, maxRows: 5 }}
            onChange={(e: any) => setMessage(e.target.value)}
            value={message}
          />
        </Col>
        <Col className="dashboard-send-btn-wrapper">
          <Button
            onClick={sendMessage}
            disabled={selectedTableRowKeys?.length === 0}
            style={{ opacity: selectedTableRowKeys?.length === 0 ? ".2" : "1" }}
          >
            Send
          </Button>
        </Col>
      </Row>
    </div>
  );
};
