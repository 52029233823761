import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";
import { UserHome } from "../../pages/home/home.model";

//api call to change password
const ChangePassword = async (userHome: UserHome, token: UserHome): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.HOME.CHANGE_PASSWORD}`, userHome, config);
  return response.data;
};

//api call to logout
const logout = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.AUTH.LOGOUT}`, {}, config);
  return response.data;
};

//api call to fetch sidebar and cards items
const fetchSidebarAndCardItems = async (token: UserHome): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.HOME.HOME_FETCH_SIDEBAR_AND_CARD_ITEMS}`, config);
  return response.data;
};

//api call to fetch about us
const fetchAboutUs = async (token: UserHome): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.HOME.ABOUT_US}`, config);
  return response.data;
};

const homeService = {
  ChangePassword,
  logout,
  fetchSidebarAndCardItems,
  fetchAboutUs,
};

export default homeService;
