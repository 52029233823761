import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  Col,
  Row,
  Select,
  Form,
  Radio,
  DatePicker,
  TimePicker,
  Button,
  Divider,
  Tooltip,
  Modal,
  Checkbox,
  Tag,
  Space,
} from "antd";
import jsPDF from "jspdf";
import dayjs from "dayjs";
import Spinner from "../../components/spinner/Spinner";
import { EmsReportManagementTable } from "./components/emsReportManagementTable/EmsReportManagementTable";
import { CriteriaCardAndGraph } from "./components/criteriaCardAndGraph/CriteriaCardAndGraph";
import {
  fetchEMSList,
  resetFetchEMSList,
  fetchCaseTypeList,
  resetCaseTypeList,
  fetchEmergencyUserList,
  resetEmergencyUserList,
  fetchEMTUsersList,
  resetEMTUsersList,
  generateEMSReport,
  resetGeneratedReport,
  generateCSV,
  downloadCSV,
} from "../../features/emsReportManagemnet/emsReportManagementSlice";
import { fetchSiteList } from "../../features/unitDepartment/unitDepartmentSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState, appDispatch } from "../../app/store";
import "./emsReportManagement.scss";
import { LineGraph } from "./components/lineGraph/LineGraph";
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import html2canvas from "html2canvas";
import { CriteriaCard } from "./components/criteriaCard/CriteriaCard";
import ReactDOM from "react-dom";

interface radioItemsType {
  id: number | null;
  name: string;
}

interface itemsType {
  id: number | null;
  name: string;
  reportID: String;
  selecionElementData?: any[];
  criteriaData?: any[];
}

export interface payloadType {
  patientType: string;
  site: string;
  emtUsers: string;
  ems: string;
  caseType: string;
  emergencyUser: string;
  startTime: string;
  endTime: string;
  startDate: string;
  endDate: string;
  reportID: String;
  timeZone: string;
  reportName?: string;
}

export const EmsReportManagement = () => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const reg_code = localStorage.getItem("reg_code") ?? "";
  const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const divRef: any = useRef();
  const criteriaCardRef: any = useRef();
  const ref: any = useRef();

  const dispatch = useDispatch<appDispatch>();
  const navigate = useNavigate();

  const { emsListData } = useSelector(
    (state: RootState) => state.emsReportManagement.fetchEMSList
  );

  const { caseTypeList } = useSelector(
    (state: RootState) => state.emsReportManagement.fetchCaseTypeList
  );

  const { emergencyUserList } = useSelector(
    (state: RootState) => state.emsReportManagement.fetchEmergencyUserList
  );

  const { emtUsersList } = useSelector(
    (state: RootState) => state.emsReportManagement.fetchEMTUsersList
  );

  const { generatedReport, isLoading } = useSelector(
    (state: RootState) => state.emsReportManagement.generateEMSReport
  );

  const { siteListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchSiteList
  );

  const startDate = dayjs().format("YYYY-MM-DD");
  const endDate = dayjs().format("YYYY-MM-DD");
  const dateFormat = "YYYY-MM-DD";

  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const [selectedTimeRange, setSelectedTimeRange] = useState<any>([]);
  const [timeRange, setTimeRange] = useState<any>(["", ""]);
  const [typeOfReportID, setTypeOFReportID] = useState<number>();
  const [selectedReportID, setSelectedReportID] = useState<any>();
  const [generateSectionTitle, setGenerateSectionTitle] = useState<string>();
  const [sampleReportDropdownItems, setSampleReportDropdownItems] =
    useState<any>();
  const [patientType, setPatientType] = useState<any>("");
  const [site, setSite] = useState<any>("");
  const [siteName, setSiteName] = useState<string>("");
  const [emtUsers, setEmtUsers] = useState<any>("");
  const [emtUsersNames, setEmtUsersNames] = useState<any>();
  const [ems, setEms] = useState<any>("");
  const [caseType, setCaseType] = useState<any>("");
  const [caseTypeName, setCaseTypeName] = useState<any>();
  const [emergencyUser, setEmergencyUser] = useState<any>("");
  const [emergencyUserName, setEmergencyUserName] = useState<any>();
  const [reportID, setReportID] = useState<any>("");
  const [isGenerateClicked, setIsGenerateClicked] = useState<boolean>(false);
  const [isPrintClicked, setIsPrintClicked] = useState<boolean>(false);
  const [isSameDay, setIsSameDay] = useState<any>();
  const [isResultEmpty, setIsResultEmpty] = useState<any>();

  const radioItems: radioItemsType[] = [
    {
      id: 1,
      name: "Comprehensive  Data",
    },
    {
      id: 2,
      name: "Sample Report",
    },
  ];

  const patientTypeDropDownItems = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "InPatient",
    },
    {
      id: 3,
      name: "OutPatient",
    },
  ];

  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label?.props?.children ? label?.props?.children : null}
      </Tag>
    );
  };

  const TextWithCheckbox = (props: any) => {
    return (
      <div>
        <Checkbox
          checked={props.checked}
          style={{ margin: "0 .5rem 0 0rem" }}
        />
        {props.children}
      </div>
    );
  };

  const disabledDate = (current: any) => {
    // Disable dates after today
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const handleSelectAll = (
    e: any,
    handleChange: any,
    options: any,
    name?: string
  ) => {
    // if select all clicked then it will call the appropriate handle function
    // then update selected value
    if (e.target.checked) {
      handleChange(
        options.map((data: any) => data.id),
        ""
      );
      if (name) {
        form.setFieldValue(
          name,
          options.map((data: any) => data.id)
        );
      }
    } else {
      if (name) {
        form.setFieldValue(name, []);
      }
      handleChange([], "");
    }
  };

  const handleSiteChange = (ID: any, option: any) => {
    setSite(ID);
    form.setFieldValue("site", ID);
    setSiteName(option.children);
    dispatch(fetchEmergencyUserList(ID));
    setEmergencyUser("");
    form.setFieldValue("emergency_user", []);
  };

  const handlepatientTypeChange = (ID: any, option: any) => {
    setPatientType(patientTypeDropDownItems[ID - 1].name.toLowerCase());
    form.setFieldValue("patient_type", ID);
  };

  const handleEMSChange = (ID: any, option: any) => {
    setEms(ID);
    form.setFieldValue("ems", ID);
  };

  const handleCaseChange = (IDs: any, option: any) => {
    if (Array.isArray(IDs)) {
      setCaseTypeName(
        caseTypeList.map((data: any, index: any) => {
          if (IDs.includes(data.id)) {
            return data.name;
          }
          return null;
        })
      );
    }
    setCaseType(IDs);
    form.setFieldValue("case_type", IDs);
  };

  const handleEMTUserChange = (IDs: any, option: any) => {
    setEmtUsers(IDs);
    form.setFieldValue("emt_users", IDs);
    setEmtUsersNames(
      emtUsersList.map((data: any, index: any) => {
        if (IDs.includes(data.id)) {
          return data.name;
        }
        return null;
      })
    );
  };

  const handleEmergencyUserChange = (IDs: any, option: any) => {
    setEmergencyUser(IDs);
    form.setFieldValue("emergency_user", IDs);
    setEmergencyUserName(
      emergencyUserList.map((data: any, index: any) => {
        if (IDs.includes(data.id)) {
          return data.name;
        }
        return null;
      })
    );
  };

  const criteriaCardFor5067 = [
    {
      title: "TIMEZONE",
      content: TIME_ZONE,
    },
    {
      title: "SITE NAME",
      content: siteName,
    },
    {
      title: "START DATE",
      content: dateRange[0],
    },
    {
      title: "START TIME",
      content: timeRange[0] ? timeRange[0].toUpperCase() : "",
    },
    {
      title: "END TIME",
      content: timeRange[1] ? timeRange[1].toUpperCase() : "",
    },
    {
      title: "END DATE",
      content: dateRange[1],
    },
    {
      title: "CASE TYPES",
      content: caseTypeName
        ?.filter((item: any) => item !== null && item !== undefined)
        .join(", "),
    },
    {
      title:
        selectedReportID === 1 ? "ACCEPTED USER NAMES" : "REJECTED USER NAMES",
      content: emergencyUserName
        ?.filter((item: any) => item !== null && item !== undefined)
        .join(", "),
    },
  ];

  const criteriaCardForemo1 = [
    {
      title: "TIMEZONE",
      content: TIME_ZONE,
    },
    {
      title: "START DATE",
      content: dateRange[0],
    },
    {
      title: "START TIME",
      content: timeRange[0] ? timeRange[0].toUpperCase() : "",
    },
    {
      title: "END TIME",
      content: timeRange[1] ? timeRange[1].toUpperCase() : "",
    },
    {
      title: "END DATE",
      content: dateRange[1],
    },
    {
      title: "EMT USER IDS",
      content: emtUsersNames
        ?.filter((item: any) => item !== null && item !== undefined)
        .join(", "),
    },
  ];

  // Sample report dropdown items for network 5067
  const sampleReportItemsFor5067: itemsType[] = [
    {
      id: 1,
      name: "Total Cases Accepted by Emergency Department",
      reportID: "rep_2",
      criteriaData: criteriaCardFor5067,
    },
    {
      id: 2,
      name: " Total Cases Rejected by Emergency Department",
      reportID: "rep_3",
      criteriaData: criteriaCardFor5067,
    },
    {
      id: 3,
      name: "Cases in the Accept-to-Transfer Time Range",
      reportID: "rep_6",
    },
    {
      id: 4,
      name: "Cases in the Timer Duration Range",
      reportID: "rep_7",
    },
    {
      id: 5,
      name: "Total Completed Cases in the Emergency Department",
      reportID: "rep_8",
      criteriaData: criteriaCardFor5067.slice(0, -1),
    },
    {
      id: 6,
      name: "Total Cancelled Cases in the Emergency Department",
      reportID: "rep_9",
      criteriaData: criteriaCardFor5067.slice(0, -1),
    },
  ];

  // Sample report dropdown items for network em01
  const sampleReportItemsForem01: itemsType[] = [
    {
      id: 1,
      name: "Total Cases submitted by EMT users",
      reportID: "rep_1",
      criteriaData: criteriaCardForemo1,
    },
    {
      id: 2,
      name: "Total Completed Cases in the EMS",
      reportID: "rep_5",
      criteriaData: criteriaCardForemo1,
    },
    {
      id: 3,
      reportID: "rep_10",
      name: "Total Cases Send to Hospitals",
    },
  ];

  const handleClearAll = () => {
    setEms("");
    setSite("");
    setPatientType("");
    setEmergencyUser("");
    setCaseType("");
    setEmtUsers("");
    setTimeRange(["", ""]);
  };

  const handleRadioButtonChange = (e: any) => {
    setTypeOFReportID(e.target.value);
    dispatch(resetEmergencyUserList());
    if (e.target.value === 1) {
      setGenerateSectionTitle("Case Patient Table");
      setSelectedReportID("Case Patient Table");
      if (reg_code === "5067" || reg_code === "0010") {
        setReportID("rep_4");
      } else {
        setReportID("rep_11");
      }
    } else {
      if (reg_code === "5067" || reg_code === "0010") {
        setSampleReportDropdownItems(sampleReportItemsFor5067);
      } else {
        setSampleReportDropdownItems(sampleReportItemsForem01);
      }
      setGenerateSectionTitle("");
      handleReportChange(1);
    }
    dispatch(resetGeneratedReport());
    form.resetFields();
    handleClearAll();
  };

  const handleReportChange = (ID: any) => {
    setSelectedReportID(ID);
    dispatch(resetEmergencyUserList());
    dispatch(resetGeneratedReport());
    if (reg_code === "5067" || reg_code === "0010") {
      setReportID(sampleReportItemsFor5067[ID - 1]?.reportID);
      setGenerateSectionTitle(sampleReportItemsFor5067[ID - 1]?.name);
    } else {
      setReportID(sampleReportItemsForem01[ID - 1]?.reportID);
      setGenerateSectionTitle(sampleReportItemsForem01[ID - 1]?.name);
    }
    form.resetFields();
    handleClearAll();
  };

  const handleDateRangePickerChange = (data: any) => {
    const formattedDates = data?.map((date: any) => date.format("YYYY-MM-DD"));
    setDateRange(formattedDates);
    setIsSameDay(dayjs(data[0]).isSame(dayjs(data[1])));
    if (dayjs(data[0]).isSame(dayjs(data[1]))) {
      setTimeRange((prev: any) => {
        return [prev[1], prev[0]];
      });
    } else {
      setTimeRange((prev: any) => {
        return [prev[1], prev[0]];
      });
    }
  };

  const handleTimeRangePickerChange = (data: any) => {
    // setSelectedTimeRange(data)
    if (data) {
      const formattedTimeRange = data.map((time: any) =>
        dayjs(time).format("h:mm a").replace(/\s/g, "")
      );
      setTimeRange(formattedTimeRange);
    } else {
      setTimeRange(["", ""]);
    }
  };

  const generatePayload = () => {
    return {
      patientType,
      site,
      emtUsers: emtUsers ? emtUsers.join(",") : "",
      ems,
      caseType: Array.isArray(caseType) ? caseType.join(",") : caseType,
      emergencyUser: emergencyUser ? emergencyUser.join(",") : "",
      startTime: timeRange[0],
      endTime: timeRange[1],
      startDate: dateRange[0],
      endDate: dateRange[1],
      reportID,
      timeZone: TIME_ZONE,
      reportName:
        typeOfReportID === 1
          ? selectedReportID
          : reg_code === "5067" || reg_code === "0010"
          ? sampleReportItemsFor5067[selectedReportID - 1].name
          : sampleReportItemsForem01[selectedReportID - 1].name,
    };
  };

  const handleSubmit = () => {
    const payload: payloadType = generatePayload();
    form
      .validateFields()
      .then((data) => {
        dispatch(generateEMSReport(payload)).then((data) => {
          // checking if the response value is empty or not
          let isEmpty: boolean = false;
          if (
            typeOfReportID === 1 ||
            ([3, 4].includes(selectedReportID) &&
              (reg_code === "5067" || reg_code === "0010"))
          ) {
            if (data.payload.result.data.length !== 0) {
              isEmpty = true;
            } else {
              isEmpty = false;
            }
          } else if (
            selectedReportID === 3 &&
            reg_code === "ems01" &&
            !timeRange[0]
          ) {
            if (Object.keys(data.payload.result).length !== 0) {
              isEmpty = true;
            } else {
              isEmpty = false;
            }
          } else {
            Object.keys(data.payload.result).forEach((key) => {
              if (!isEmpty) {
                if (
                  data.payload.result[key].every(
                    (data: any, index: any) => data.value === 0
                  )
                ) {
                  isEmpty = false;
                } else {
                  isEmpty = true;
                }
              }
            });
          }
          if (isEmpty) {
            setIsResultEmpty(false);
            isEmpty = false;
          } else {
            setIsResultEmpty(true);
            confirm({
              title: "",
              icon: null,
              centered: true,
              width: 350,
              content: (
                <div className="empty-message-popup-container">
                  <ExclamationCircleOutlined width={"2em"} height={"2em"} />
                  <p>No records to show for the selected inputs</p>
                </div>
              ),
              onOk() {},
              footer: [
                <div
                  key="custom-footer"
                  style={{ textAlign: "right", margin: ".8rem 0 0 0" }}
                >
                  <Button
                    key="ok"
                    type="primary"
                    onClick={() => Modal.destroyAll()}
                  >
                    OK
                  </Button>
                </div>,
              ],
            });
          }
        });
        setIsGenerateClicked(true);
      })
      .catch((errorInfo) => {});
  };

  const handleDownloadCSV = () => {
    const payload: payloadType = generatePayload();
    dispatch(generateCSV(payload))
      .then((data) => {
        if (data.payload.isSuccess) {
          dispatch(downloadCSV(payload));
        }
      })
      .catch((err) => "");
  };

  useEffect(() => {
    dispatch(fetchEMSList());
    dispatch(fetchCaseTypeList());
    dispatch(fetchSiteList());
    dispatch(fetchEMTUsersList());
    return () => {
      dispatch(resetFetchEMSList());
      dispatch(resetCaseTypeList());
      dispatch(resetEmergencyUserList());
      dispatch(resetGeneratedReport());
    };
  }, []);

  useEffect(() => {
    setTypeOFReportID(1);
    setSelectedReportID("Case Patient Table");
    setGenerateSectionTitle("Case Patient Table");
    if (reg_code === "5067" || reg_code === "0010") {
      setReportID("rep_4");
      setSampleReportDropdownItems(sampleReportItemsFor5067);
    } else {
      setReportID("rep_11");
      setSampleReportDropdownItems(sampleReportItemsForem01);
    }
  }, []);

  const handleGeneratSectionComponent = () => {
    if (generatedReport?.data && typeOfReportID === 1) {
      return (
        <EmsReportManagementTable
          data={generatedReport?.data}
          regCode={reg_code}
        />
      );
    }
    if (generatedReport.length !== 0) {
      return (
        <LineGraph
          selectedReportID={selectedReportID}
          typeOfReportID={typeOfReportID}
          isClicked={isPrintClicked}
          chartRef={ref}
          data={generatedReport}
        />
      );
    }
  };

  const downloadPDF: any = () => {
    setIsPrintClicked(true);
    const element1: any = ref.current.canvas;

    setTimeout(async () => {
      if (
        element1 &&
        generatedReport.length !== 0 &&
        [1, 2, 5, 6].includes(selectedReportID)
      ) {
        const containerForCriteria = document.createElement("div");
        containerForCriteria.style.position = "absolute";
        containerForCriteria.style.left = "-9999px";
        containerForCriteria.style.width = "1000px";
        document.body.appendChild(containerForCriteria);

        const criteriaCardData =
          reg_code === "5067" || reg_code === "0010"
            ? sampleReportItemsFor5067[selectedReportID - 1]?.criteriaData
            : sampleReportItemsForem01[selectedReportID - 1]?.criteriaData;

        ReactDOM.render(
          <Col
            md={24}
            lg={24}
            xl={24}
            className="criteria-card-container"
            ref={criteriaCardRef}
          >
            <Row>
              <h4 className={"criteria-section-title dark-color-text"}>
                {"CRITERIA"}
              </h4>
            </Row>
            <Row gutter={[18, 18]}>
              {criteriaCardData?.map((data: any, index: any) => {
                return (
                  data?.content && (
                    <Col
                      md={7}
                      lg={
                        [6, 7].includes(index) ||
                        (reg_code !== "5067" &&
                          reg_code !== "0010" &&
                          index === 5)
                          ? 20
                          : 5
                      }
                      xl={
                        [6, 7].includes(index) ||
                        (reg_code !== "5067" &&
                          reg_code !== "0010" &&
                          index === 5)
                          ? 20
                          : 5
                      }
                    >
                      <CriteriaCard
                        title={data?.title}
                        content={data?.content}
                      />
                    </Col>
                  )
                );
              })}
            </Row>
          </Col>,
          containerForCriteria
        );

        const criteriaCanvas = await html2canvas(containerForCriteria, {
          useCORS: true,
        });

        const pdf = new jsPDF("landscape", "px", "a4");

        pdf.addImage(
          element1.toDataURL("image/png"),
          "JPEG",
          25,
          10,
          pdf.internal.pageSize.width - 50,
          pdf.internal.pageSize.height - 100
        );

        pdf.addPage();
        pdf.addImage(
          criteriaCanvas.toDataURL("image/png"),
          "JPEG",
          65,
          20,
          pdf.internal.pageSize.width - 50,
          pdf.internal.pageSize.height - 200
        );
        if (reg_code === "5067" || reg_code === "0010") {
          pdf.save(
            `${sampleReportItemsFor5067[selectedReportID - 1]?.name}.pdf`
          );
        } else {
          pdf.save(
            `${sampleReportItemsForem01[selectedReportID - 1]?.name}.pdf`
          );
        }
        setIsPrintClicked(false);
      } else if (
        element1 &&
        generatedReport.length !== 0 &&
        ![1, 2, 5, 6].includes(selectedReportID)
      ) {
        const pdf = new jsPDF("landscape", "px", "a4");
        pdf.addImage(
          element1.toDataURL("image/png"),
          "JPEG",
          25,
          10,
          pdf.internal.pageSize.width - 50,
          pdf.internal.pageSize.height - 100
        );
        if (reg_code === "5067" || reg_code === "0010") {
          pdf.save(
            `${sampleReportItemsFor5067[selectedReportID - 1]?.name}.pdf`
          );
        } else {
          pdf.save(
            `${sampleReportItemsForem01[selectedReportID - 1]?.name}.pdf`
          );
        }
        setIsPrintClicked(false);
      }
    }, 400);
  };

  return (
    <div className="ems-rpt-management-container">
      <Form form={form} className="form-container">
        {/* ems report management header section */}
        <Row gutter={[18, 18]} align={"middle"}>
          <Col xs={24} sm={24} md={24} lg={12} xl={8}>
            <span style={{ alignItems: "center", display: "flex" }}>
              <Tooltip title="Reports management page" placement="bottom">
                <Button
                  size="small"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate("/reports-management")}
                />
              </Tooltip>
              <span
                className="text-label-style"
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  marginLeft: "10px",
                }}
              >
                EMS Management Reports
              </span>
            </span>
          </Col>
        </Row>
        {/* First row part */}
        <Row gutter={[18, 18]} style={{ marginTop: "20px" }}>
          {/* typer of report selection section*/}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={5}
            xl={5}
            className="ems-rpt-mngt-radio-button-container align-column"
          >
            <Radio.Group onChange={handleRadioButtonChange} defaultValue={1}>
              {radioItems.map((item) => {
                return (
                  <Radio value={item?.id} key={item?.id} className="mb-2">
                    <span className="text-label-style">{item?.name}</span>
                  </Radio>
                );
              })}
            </Radio.Group>
          </Col>
          {/* Type of report selection section */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={7}
            xl={8}
            className="ems-rpt-mngt-select-report-container align-column "
          >
            <p className="text-label-style mb-1">Report</p>
            <Select
              disabled={typeOfReportID === 1}
              placeholder="Select Report"
              virtual={false}
              onChange={handleReportChange}
              value={selectedReportID}
            >
              {sampleReportDropdownItems?.map((item: any) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          {/* Date range picker */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-date-range-picker-container align-column"
          >
            <p className="text-label-style mb-1 required">Date Range</p>
            <Form.Item
              name={"date-range-picker"}
              rules={[
                {
                  required: true,
                  message: "Please select a date range",
                },
              ]}
            >
              <RangePicker
                disabledDate={disabledDate}
                inputReadOnly={true}
                allowClear={false}
                onChange={handleDateRangePickerChange}
                value={[
                  dayjs(`${dateRange[0]}`, "YYYY-MM-DD"),
                  dayjs(`${dateRange[1]}`, "YYYY-MM-DD"),
                ]}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          {/* Time Range Picker */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-time-range-picker-container align-column"
          >
            <p className="text-label-style mb-1">Time Range</p>
            <Form.Item name={"time-range"}>
              <TimePicker.RangePicker
                order={isSameDay ? true : false}
                // value={selectedTimeRange}
                allowClear={true}
                format="h:mm a"
                use12Hours={true}
                onChange={handleTimeRangePickerChange}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* Second row part */}
        <Row gutter={[18, 18]} style={{ textAlign: "right" }} align={"middle"}>
          {/* EMS select section */}
          {(reg_code === "5067" || reg_code === "0010") && (
            <Col md={12} lg={5} xl={5} className="align-column">
              <p className="text-label-style mb-1 required">EMS</p>
              <Form.Item
                name={"ems"}
                rules={[
                  {
                    required: true,
                    message: "Please choose EMS",
                  },
                ]}
              >
                <Select
                  placeholder="Select EMS"
                  virtual={false}
                  onChange={handleEMSChange}
                  value={ems}
                >
                  {emsListData?.map((item: any) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* Patient Type select section */}
          {(reg_code === "5067" || reg_code === "0010") &&
            typeOfReportID === 1 && (
              <Col md={12} lg={5} xl={5} className="align-column">
                <p className="text-label-style mb-1 required">Patient Type</p>
                <Form.Item
                  name={"patient_type"}
                  rules={[
                    {
                      required: true,
                      message: "Please choose patient type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Patient Type"
                    virtual={false}
                    onChange={handlepatientTypeChange}
                    value={patientType}
                  >
                    {patientTypeDropDownItems?.map((item: any) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        label={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          {/* Site select section */}
          {(reg_code === "5067" || reg_code === "0010") &&
            typeOfReportID === 2 && (
              <Col md={12} lg={5} xl={5} className="align-column">
                <p className="text-label-style mb-1 required">Site</p>
                <Form.Item
                  name={"site"}
                  rules={[
                    {
                      required: true,
                      message: "Please choose site",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Site"
                    virtual={false}
                    onChange={handleSiteChange}
                    value={site}
                  >
                    {siteListData?.map((item: any) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        label={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          {/* Emergency user select section */}
          {(reg_code === "5067" || reg_code === "0010") &&
            typeOfReportID === 2 &&
            [1, 2].includes(selectedReportID) && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={5}
                xl={5}
                className="align-column"
              >
                <p className="text-label-style mb-1 required">Emergency User</p>
                <Form.Item
                  name={"emergency_user"}
                  rules={[
                    {
                      required: true,
                      message: "Please choose emergency user",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    onChange={handleEmergencyUserChange}
                    placeholder={"Select Emergency User"}
                    maxTagCount={"responsive"}
                    value={emergencyUser}
                    virtual={false}
                    tagRender={tagRender}
                    dropdownRender={(menu) => (
                      <>
                        {emergencyUserList?.length !== 0 && (
                          <>
                            <Space style={{ padding: "5px 12px 4px" }}>
                              <Checkbox
                                checked={
                                  emergencyUser?.length ===
                                  emergencyUserList?.length
                                }
                                onChange={(e) =>
                                  handleSelectAll(
                                    e,
                                    handleEmergencyUserChange,
                                    emergencyUserList,
                                    "emergency_user"
                                  )
                                }
                              >
                                Select All
                              </Checkbox>
                            </Space>
                            <Divider style={{ margin: "8px 0" }} />
                          </>
                        )}
                        {menu}
                      </>
                    )}
                  >
                    {emergencyUserList?.map(
                      (item: any) =>
                        item.type !== 1 && (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                            label={item.name}
                          >
                            <TextWithCheckbox
                              checked={emergencyUser?.includes(item?.id)}
                            >
                              {item.name}
                            </TextWithCheckbox>
                          </Select.Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
          {/* Case Type select section */}
          {(reg_code === "5067" || reg_code === "0010") &&
            typeOfReportID === 2 && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={5}
                xl={5}
                className="align-column"
              >
                <p className="text-label-style mb-1 required">Case Type</p>
                <Form.Item
                  name={"case_type"}
                  rules={[
                    {
                      required: true,
                      message: "Please choose case type",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    onChange={handleCaseChange}
                    placeholder={"Select Case Type"}
                    maxTagCount={"responsive"}
                    value={caseType}
                    virtual={false}
                    tagRender={tagRender}
                    dropdownRender={(menu) => (
                      <>
                        {caseTypeList?.length !== 0 && (
                          <>
                            <Space style={{ padding: "5px 12px 4px" }}>
                              <Checkbox
                                checked={
                                  caseType?.length === caseTypeList?.length
                                }
                                onChange={(e) =>
                                  handleSelectAll(
                                    e,
                                    handleCaseChange,
                                    caseTypeList,
                                    "case_type"
                                  )
                                }
                              >
                                Select All
                              </Checkbox>
                            </Space>
                            <Divider style={{ margin: "8px 0" }} />
                          </>
                        )}
                        {menu}
                      </>
                    )}
                  >
                    {caseTypeList?.map(
                      (item: any) =>
                        item.type !== 1 && (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                            label={item.name}
                          >
                            <TextWithCheckbox
                              checked={
                                Array.isArray(caseType)
                                  ? caseType?.includes(item?.id)
                                  : false
                              }
                            >
                              {item.name}
                            </TextWithCheckbox>
                          </Select.Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
          {/* EMT user select section */}
          {reg_code === "ems01" &&
            typeOfReportID === 2 &&
            [1, 2].includes(selectedReportID) && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={5}
                xl={5}
                className="align-column"
              >
                <p className="text-label-style mb-1 required">EMT Users</p>
                <Form.Item
                  name={"emt_users"}
                  rules={[
                    {
                      required: true,
                      message: "Please choose emt user",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    onChange={handleEMTUserChange}
                    placeholder={"Select EMT Users"}
                    maxTagCount={"responsive"}
                    value={emtUsers}
                    virtual={false}
                    tagRender={tagRender}
                    dropdownRender={(menu) => (
                      <>
                        {emtUsersList?.length !== 0 && (
                          <>
                            <Space style={{ padding: "5px 12px 4px" }}>
                              <Checkbox
                                checked={
                                  emtUsers?.length === emtUsersList?.length
                                }
                                onChange={(e) =>
                                  handleSelectAll(
                                    e,
                                    handleEMTUserChange,
                                    emtUsersList,
                                    "emt_users"
                                  )
                                }
                              >
                                Select All
                              </Checkbox>
                            </Space>
                            <Divider style={{ margin: "8px 0" }} />
                          </>
                        )}
                        {menu}
                      </>
                    )}
                  >
                    {emtUsersList?.map(
                      (item: any) =>
                        item.type !== 1 && (
                          <Select.Option
                            key={item.id}
                            value={item.id}
                            label={item.name}
                          >
                            <TextWithCheckbox
                              checked={emtUsers?.includes(item?.id)}
                            >
                              {item.name}
                            </TextWithCheckbox>
                          </Select.Option>
                        )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}
          <Col style={{ display: "flex" }}>
            <Button className="generate-report-button" onClick={handleSubmit}>
              Generate Report
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider></Divider>
      <Row
        gutter={16}
        justify={"space-between"}
        style={{ margin: "0 1rem 0 0" }}
      >
        {generatedReport.length !== 0 && !isResultEmpty && (
          <h4 className="generate-section-title">{generateSectionTitle}</h4>
        )}
        {generatedReport.length !== 0 && !isResultEmpty && (
          <Col>
            <Button
              className="generate-report-button"
              style={{ margin: "0 1rem 0 0" }}
              onClick={handleDownloadCSV}
            >
              Download CSV
            </Button>
            {typeOfReportID !== 1 && (
              <Button className="generate-report-button" onClick={downloadPDF}>
                Download PDF
              </Button>
            )}
          </Col>
        )}
      </Row>
      <Row ref={divRef}>
        <Col sm={24} md={24} lg={24} xl={24}>
          {isGenerateClicked &&
            (!isLoading ? (
              !isResultEmpty && handleGeneratSectionComponent()
            ) : (
              <div style={{ height: "50vh" }}>
                <Spinner />
              </div>
            ))}
        </Col>
      </Row>
    </div>
  );
};
