import { Button } from "antd";
import { FC, ReactNode } from "react";
import "./simpleButton.scss";

interface SimpleButtonProps {
  color: string;
  text?: string;
  icon?: ReactNode;
  onClick?: any;
  size?: any;
  block?: boolean;
  htmlType?: string;
  customHtmlType?: any;
  disabled?: boolean
  opacity?: number
}

const SimpleButton: FC<SimpleButtonProps> = ({ color, text, icon, onClick, size, block, customHtmlType, disabled, opacity }) => {
  return (
    <Button
      className={`simple-button ${color} `}
      icon={icon}
      onClick={onClick}
      size={size || "medium"}
      block={block ? true : false}
      htmlType={customHtmlType || "submit"}
      disabled={disabled}
      style={{ opacity: opacity }}
    >
      {text}
    </Button>
  );
};

export default SimpleButton;
