import { Col, Form, Input, Layout, Row, Select, Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RootState, appDispatch } from "../../../../app/store";
import SimpleButton from "../../../../components/buttons/simpleButton/SimpleButton";
import CustomModal from "../../../../components/modal/CustomModal";
import {
  createStatusBoardColumn, fetchStatusBoardColumnList, fetchValue, resetFetchStatusBoardColumnDetails, resetFetchValue,
  updateStatusBoardColumn,
} from "../../../../features/statusBoardColumn/statusBoardColumnSlice";
import "./addStatusBoardColumn.scss";

interface AddStatusBoardColumnProps {
  open: boolean;
  onClose: () => void;
  isEditMode: boolean;
  editingStatusId?: number | string;
  handleClearFilters: () => void;
  handleClearLastFilterValues: () => void;
  filterSiteId?: string;
  filterTypeId?: string;
  searchQuery: string
}

interface typeOptionsType {
  value: string;
  label: string;
}

const AddStatusBoardColumn: React.FC<AddStatusBoardColumnProps> = ({
  open,
  onClose,
  isEditMode,
  editingStatusId,
  handleClearFilters,
  handleClearLastFilterValues,
  filterSiteId,
  filterTypeId,
  searchQuery
}) => {
  const dispatch = useDispatch<appDispatch>();

  //retrieving states of status board column api response (units and departments sites api)
  const { siteListData } = useSelector((state: RootState) => state.unitDepartment.fetchSiteList);

  //retrieving states of status board column details api response
  const { statusBoardColumnDetailsData, isLoading: isLoadingFetchStatusBoardColumnDetails, isSuccess: isSuccessFetchStatusBoardColumnDetails,
    isError: isErrorFetchStatusBoardColumnDetails, message: messageFetchStatusBoardColumnDetails, } = useSelector((state: RootState) => state.statusBoardColumn.fetchStatusBoardColumnDetails);

  //retrieving states of value api response
  const { valueData, isSuccess: isSuccessFetchValue, isError: isErrorFetchValue, isLoading: isLoadingFetchValue,
    message: messageFetchValue, } = useSelector((state: RootState) => state.statusBoardColumn.fetchValue);

  const [form] = Form.useForm();

  const [valuePalceHolder, setValuePalceHolder] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [editValue, setEditValue] = useState<any>({
    type: "",
    value: "",
  });

  const typeOptions: typeOptionsType[] = [
    { value: "1", label: "Text Field" },
    { value: "3", label: "Drop down text" },
  ];

  const valueManipulator = (type: any) => {
    if (type === "1") {
      return `{ "value": "${inputValue}" }`;
    } else if (type === "3") {
      const manipulatedValue = inputValue.replace(/\b(\w+)\b/g, '"$1"');
      return `{"type":"text","value_list":[${manipulatedValue}],"value":" "}`;
    }
  };

  const valuePalceHolders: string[] = [
    "Enter default value for this text column",
    "boolen",
    "Enter option values seperated by comma. Eg:option1,option2,option3",
  ];

  //handle type change
  const handleTypeChange = (value: string) => {
    dispatch(fetchValue(value));
    setValuePalceHolder(valuePalceHolders[parseInt(value) - 1]);
    if (editValue.type === value) {
      form.setFieldsValue({
        value: editValue.value,
      });
    } else {
      form.setFieldsValue({
        value: "",
      });
    }
  };

  //handle cancel button
  const handleCancel = () => {
    setValuePalceHolder("");
    form.resetFields();
    onClose();
  };

  //handle fetch value api response
  useEffect(() => {
    if (isErrorFetchValue) {
      toast.error(messageFetchValue);
    }
    return () => {
      dispatch(resetFetchValue());
    };
  }, [isSuccessFetchValue, isErrorFetchValue]);

  //handle fetch status board column details api response
  useEffect(() => {
    if (isErrorFetchStatusBoardColumnDetails) {
      toast.error(messageFetchStatusBoardColumnDetails);
    } else if (isSuccessFetchStatusBoardColumnDetails && isEditMode) {
      let manipulatedValue = JSON.parse(
        statusBoardColumnDetailsData?.[0]?.value
      ).value;
      if (statusBoardColumnDetailsData?.[0]?.type === 3) {
        manipulatedValue = JSON.parse(
          statusBoardColumnDetailsData?.[0]?.value
        ).value_list.join(", ");
      }
      if (!manipulatedValue) {
        setValuePalceHolder(
          valuePalceHolders[
          parseInt(statusBoardColumnDetailsData?.[0]?.type) - 1
          ]
        );
      }
      setEditValue({
        type: statusBoardColumnDetailsData?.[0]?.type.toString(),
        value: manipulatedValue,
      });
      form.setFieldsValue({
        name: statusBoardColumnDetailsData?.[0]?.name,
        value: manipulatedValue,
        type: statusBoardColumnDetailsData?.[0]?.type?.toString(),
        site: statusBoardColumnDetailsData?.[0]?.site,
      });
    }

    return () => {
      dispatch(resetFetchStatusBoardColumnDetails());
    };
  }, [isSuccessFetchStatusBoardColumnDetails, messageFetchStatusBoardColumnDetails, isErrorFetchStatusBoardColumnDetails,]);

  //handle api call
  const handleApiCall = (
    values: any,
    resetFields: boolean = false,
    closeModal: boolean = false
  ) => {
    //to display to a loading toast
    const loadingToast = toast.loading("Loading...", {
      autoClose: false, // Keep the loading toast until manually closed
    });
    let isEditing = false;
    if (isEditMode) {
      // If editing, dispatch update (put call)
      const payload = { ...values };
      dispatch(
        updateStatusBoardColumn({
          statusBoardColumnId: editingStatusId,
          payload,
        })
      ).then((data) => {
        isEditing = true;
        handleApiCallCallback(
          data,
          resetFields,
          closeModal,
          loadingToast,
          isEditing
        );
      });
    } else {
      // If not editing, dispatch create (post call)
      dispatch(createStatusBoardColumn(values)).then((data) => {
        handleApiCallCallback(
          data,
          resetFields,
          closeModal,
          loadingToast,
          isEditing
        );
      });
    }
  };

  const handleApiCallCallback = (
    data: any,
    resetFields: boolean,
    closeModal: boolean,
    loadingToast: any,
    isEditing: boolean
  ) => {
    // Determine the appropriate toast message based on the API response
    const toastMessage = data?.payload?.isSuccess
      ? data?.payload?.message
      : data?.payload;

    // Determine the toast type based on the API response
    const toastType = data?.payload?.isSuccess ? "success" : "error";

    // Update the existing toast
    toast.update(loadingToast, {
      render: toastMessage,
      type: toastType,
      isLoading: false,
      autoClose: 3000,
    });

    if (data?.payload?.isSuccess) {
      // toast.success(data?.payload?.message);
      dispatch(
        fetchStatusBoardColumnList({
          searchQuery: searchQuery,
          siteId: isEditing ? filterSiteId ?? "" : "",
          typeId: isEditing ? filterTypeId ?? "" : "",
        })
      );
      if (!isEditing) {
        handleClearFilters();
        handleClearLastFilterValues();
      }

      if (resetFields) {
        form.resetFields();
      }

      if (closeModal) {
        onClose();
      }
    } else {
      // toast.error(data?.payload);
    }
  };

  //handle "Save" button
  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        handleApiCall(
          { ...values, value: valueManipulator(values.type) },
          true,
          true
        );
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
    setValuePalceHolder("");
    setInputValue("");
  };

  return (
    <>
      <CustomModal visible={open} onClose={onClose}>
        <Layout>
          <Row>
            <Col>
              <h2>
                {" "}
                {isEditMode
                  ? "Change Status Board Column"
                  : "Add Status Board Column"}{" "}
              </h2>
            </Col>
          </Row>
          <Skeleton active loading={isLoadingFetchStatusBoardColumnDetails}>
            <Form
              name="addStatusBoardColumn"
              form={form}
              className="add-status-board-column-form"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            // onFinish={handleFinish}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="site"
                    label="Site"
                    rules={[
                      {
                        required: true,
                        message: "Please choose Site",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Site"
                      virtual={false}
                    // onChange={handleSiteChange}
                    // value={siteId}
                    >
                      {siteListData?.map((site: any) => (
                        <Select.Option key={site.id} value={site.id}>
                          {site.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="type"
                    label="Type"
                    rules={[
                      {
                        required: true,
                        message: "Please choose Type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Type"
                      virtual={false}
                      onChange={handleTypeChange}
                      // value={typeId}
                      options={typeOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    className="value-input-container"
                    name="value"
                    label="Value"
                  >
                    <Input.TextArea
                      autoSize={{ minRows: 3, maxRows: 6 }}
                      onChange={(e) => setInputValue(e.target.value)}
                      placeholder={valuePalceHolder}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end" gutter={[8, 8]} className="sbc-btn-section" >
                <Col >
                  <SimpleButton
                    block
                    text="Cancel"
                    color="tertiary"
                    customHtmlType="button"
                    onClick={handleCancel}
                  />
                </Col>
                <Col >
                  <SimpleButton
                    block
                    text="Save"
                    color="primary"
                    onClick={handleSave}
                    customHtmlType="submit"
                  />
                </Col>
              </Row>
            </Form>
          </Skeleton>
        </Layout>
      </CustomModal>
    </>
  );
};

export default AddStatusBoardColumn;
