import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

//api call to add shift
const addShift = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.SHIFT.ADD_SHIFT}`, payload, config);
  return response.data;
};

//api call to fetch shift list
const fetchShiftList = async (scheduleId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SHIFT.FETCH_SHIFT_LIST}?schedule_name=${scheduleId}`,
    config
  );
  return response.data;
};

//api call to fetch shift details
const fetchShiftDetails = async (shiftId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.SHIFT.FETCH_SHIFT_DETAILS}${shiftId}/`, config);
  return response.data;
};

//api call to update shift details
const updateShiftDetails = async (
  { shiftId, payload }: { shiftId: number; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.SHIFT.UPDATE_SHIFT_DETAILS}${shiftId}/`,
    payload,
    config
  );
  return response.data;
};

//api call to delete shift
const deleteShift = async (shiftId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.SHIFT.UPDATE_SHIFT_DETAILS}${shiftId}/`,
    config
  );
  return response.data;
};

//api to fetch users in scheduler

const fetchScheduleUsers = async (scheduleId: any, groupId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SHIFT.FETCH_SCHEDULE_USERS}${scheduleId}/?group=${groupId}`,
    "",
    config
  );
  return response.data;
};

//api for the rule check in assign shift

const shiftRuleCheck = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SHIFT.SCHEDULE_SHIFT_RULE_CHECK}`,
    payload,
    config
  );
  const apiUrl = `${API_URL}${API_ENDPOINTS.SHIFT.SCHEDULE_SHIFT_RULE_CHECK}`;

  return response.data;
};

//api to assign shift

const assignShift = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.SHIFT.SCHEDULE_ASSIGN_SHIFT}`, payload, config);
  return response.data;
};

const fetchGroupSchedule = async (
  guid: any,
  start_time: any,
  end_time: any,
  shift: any,
  shedule_name_id: any,
  view_type: any,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SHIFT.FETCH_GROUP_SCHEDULER}?guid=${guid}&start_time=${start_time}&end_time=${end_time}&shift=${shift}&shedule_name_id=${shedule_name_id}&view_type=${view_type}`,
    config
  );
  return response.data;
};

//api call to create user rule
const createUserRule = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.USER_ROLE.CREATE_USER_ROLE}`, payload, config);
  return response.data;
};

//api call to fetch user rule
const fetchUserRule = async (userId: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.USER_ROLE.FETCH_USER_ROLE}?user_id=${userId}`,
    config
  );
  return response.data;
};

//api call  fetch user list to  display in table if first time rule is creating
const fetchAvailableUserList = async (userId: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.USER_ROLE.FETCH_AVAILABLE_USER_LIST}?user_id=${userId}`,
    config
  );
  return response.data;
};

//api call to create schedule rule
const createScheduleRule = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_RULE.CREATE_SCHEDULE_RULE}`,
    payload,
    config
  );
  return response.data;
};

//api call to update schedule rule
const updateScheduleRule = async (
  { shiftId, payload }: { shiftId: number; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_RULE.UPDATE_SCHEDULE_RULE}${shiftId}/`,
    payload,
    config
  );
  return response.data;
};

//api call to fetch schedule details
const fetchScheduleRuleDetails = async (shiftId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_RULE.FETCH_SCHEDULE_RULE_DETAILS}${shiftId}/`,

    config
  );
  return response.data;
};

//api call to fetch schedule list
const fetchScheduleRuleList = async (scheduleId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_RULE.FETCH_SCHEDULE_RULE_LIST}?schedule_id=${scheduleId}&latest=1`,

    config
  );
  return response.data;
};

//api call to apply  shift assignment
const applyShiftAssignment = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_RULE.APPLY_SCHEDULE_RULE}`,
    payload,
    config
  );
  return response.data;
};

//api call to clear rule
const clearRule = async (userId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.USER_ROLE.CLEAR_RULE}?user_id=${userId}`, config);
  return response.data;
};

//api call to update Schedule calendar shift
const updateScheduleShiftCalender = async (
  { scheduleId, shiftId, date }: { scheduleId: number; shiftId: number; date: string },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_RULE.UPDATE_SCHEDULE_CALENDER_SHIFT}${scheduleId}/fetch/?shift_id=${shiftId}&date=${date}`,

    config
  );
  return response.data;
};

const fetchScheduleShiftList = async (scheduleId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SHIFT.SCHEDULE_SHIFT_LIST}?schedule_name=${scheduleId}`,
    config
  );

  return response.data;
};

const shiftRuleService = {
  addShift,
  fetchShiftList,
  fetchShiftDetails,
  updateShiftDetails,
  deleteShift,
  fetchScheduleUsers,
  fetchGroupSchedule,
  createUserRule,
  fetchUserRule,
  createScheduleRule,
  updateScheduleRule,
  fetchScheduleRuleDetails,
  fetchScheduleRuleList,
  applyShiftAssignment,
  assignShift,
  shiftRuleCheck,
  clearRule,
  updateScheduleShiftCalender,
  fetchScheduleShiftList,
  fetchAvailableUserList,
};
export default shiftRuleService;
