import axios from "axios";
import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

const fetchSiteList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_SITE_LIST}`, config);
  return response.data;
};

const fetchUnitDepartment = async (
  { searchquery, sid }: { searchquery: string; sid: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_UNIT_DEPARTMENT_LIST}?search=${searchquery}&site=${sid}`,
    config
  );
  return response.data;
};

const fetchUnitDepartmentUser = async (sid: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_UNIT_DEPARTMENT_USERS}?sid=${sid}`,
    config
  );
  return response.data;
};

//api to fetch bed list
const fetchBedList = async (
  { searchquery, unitId, page }: { searchquery: string; unitId: any; page: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_BED_LIST}?unit_id=${unitId}&search=${searchquery}&page=${page}`,
    config
  );
  return response.data;
};

//api to delete a bed
const deleteBed = async (bedId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.DELETE_BED}${bedId}/`, config);
  return response.data;
};

//api to add a new bed
const addBed = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.ADD_BED}`, payload, config);
  return response.data;
};

//api to fetch bed details
const fetchBedDetails = async (bedId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_BED_DETAILS}${bedId}/`,
    config
  );
  return response.data;
};

//api to update bed details
const updateBedDetails = async ({ bedId, payload }: { bedId: number; payload: any }, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_BED_DETAILS}${bedId}/`,
    payload,
    config
  );
  return response.data;
};

//api to add unit/department
const addUnitDepartment = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.ADD_UNIT_DEPARTMENT}`,
    payload,
    config
  );
  return response.data;
};

//api to update unit/department
const updateUnitDepartment = async (
  { unitDepartmentId, payload }: { unitDepartmentId: number; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.UPDATE_UNIT_DEPARTMENT}${unitDepartmentId}/`,
    payload,
    config
  );
  return response.data;
};

//api call to delete unit/department
const deleteUnitDepartment = async (unitId: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.DELETE_UNIT_DEPARTMENT}${unitId}/`,
    config
  );
  return response.data;
};

//api call to fetch nurse site list

const fetchNurseSiteList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_SITE_LIST}`, config);
  return response.data;
};

//api to fetch unit in nurse modal

const fetchNurseUnit = async ({ searchquery, sid }: { searchquery: string; sid: any }, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_UNIT_DEPARTMENT_LIST}?search=${searchquery}&site=${sid}`,
    config
  );
  return response.data;
};

//api call to fetch nurse list
const fetchNurseList = async (siteId: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_NURSE_LIST}?site_id=${siteId}`,
    config
  );
  return response.data;
};

//api call to assign nurse
const assignNurse = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.ASSIGN_NURSE}`, payload, config);
  return response.data;
};

//api call to fetch assigned nurse list and search
const fetchAssignedNurseList = async (
  { unitId, searchQuery }: { unitId: number; searchQuery: string },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.FETCH_ASSIGNED_NURSE}${unitId}/?name=${searchQuery}`,
    config
  );
  return response.data;
};

//api call to Unassign Nurse from unit
const unAssignNurse = async ({ unitId, nurseId }: { unitId: number; nurseId: number }, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.UNIT_DEPARTMENT.UNASSIGN_NURSE}${unitId}/?nurse=${nurseId}`,
    config
  );
  return response.data;
};

const unitDepartmentService = {
  fetchSiteList,
  fetchUnitDepartment,
  addUnitDepartment,
  updateUnitDepartment,
  deleteUnitDepartment,
  fetchUnitDepartmentUser,

  fetchBedList,
  deleteBed,
  addBed,
  fetchBedDetails,
  updateBedDetails,
  fetchNurseSiteList,
  fetchNurseUnit,
  fetchNurseList,
  assignNurse,
  fetchAssignedNurseList,
  unAssignNurse,
};

export default unitDepartmentService;
