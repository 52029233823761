import React from "react";

//This is an temporrary component used to render for pages to be created when user clicks on tabs on sidebar (routing)

const ComingSoon = () => {
  return (
    <div
      style={{
        fontSize: "30px",
        color: "#02b863",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        fontWeight: 600,
      }}
    >
      Coming Soon!!!
    </div>
  );
};

export default ComingSoon;
