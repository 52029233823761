import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";
import { generatePreviewPayloadProps } from "../../pages/bedReport/BedReport";


const fetchDoctorList = async (siteID: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.BED_REPORT.FETCH_DOCTOR_LIST}?site_name_id=${siteID}`,
    config
  );
  return response.data;
};

const fetchCareTeamList = async (siteID: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.BED_REPORT.FETCH_CARE_TEAM_LIST}?site_name_id=${siteID}`,
    config
  );
  return response.data;
};

const fetchAssignedNurseList = async (
  { siteID, unitID }: { siteID: any; unitID: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.BED_REPORT.FETCH_ASSIGNED_NURSE_LIST}?unit_name_id=${unitID}&site=${siteID}`,
    config
  );
  return response.data;
};

const fetchCalledNurseList = async (
  siteID: any,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.BED_REPORT.FETCH_CALLED_NURSE_LIST}?site_name_id=${siteID}`,
    config
  );
  return response.data;
};

const generatePreview = async (
  {
    siteID,
    days,
    doctorID,
    endDate,
    endTime,
    nurseID,
    repordID,
    startDate,
    startTime,
    unitID,
    careTeamID
  }: generatePreviewPayloadProps,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.BED_REPORT.GENERATE_PREVIEW}?unit_name_id=${unitID}&site_name_id=${siteID}&selection_id=${repordID}&start_date=${startDate}&end_date=${endDate}&nurse_selected=${nurseID}&days=${days}&start_time=${startTime}&end_time=${endTime}&doctor_selected=${doctorID}&careteam_selected=${careTeamID}`,
    config
  );
  return response.data;
};

const downloadCSV = async (
  {
    siteID,
    days,
    doctorID,
    endDate,
    endTime,
    nurseID,
    repordID,
    startDate,
    startTime,
    unitID,
    careTeamID,
    reportName
  }: generatePreviewPayloadProps,
  token: string
): Promise<any> => {
  const config:any = {
    responseType: 'blob',
    headers: {
      Authorization: `Token ${token}`, 
    },
  };
  const response:any = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.BED_REPORT.DOWNLOAD_CSV}?unit_name_id=${unitID}&site_name_id=${siteID}&selection_id=${repordID}&start_date=${startDate}&end_date=${endDate}&nurse_selected=${nurseID}&days=${days}&start_time=${startTime}&end_time=${endTime}&doctor_selected=${doctorID}&careteam_selected=${careTeamID}`,
    config,
  );

  const blob = new Blob([response.data], { type: 'text/csv' });

  // Create a download link
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${reportName}.csv`;

  // Append the link to the document and trigger the download
  document.body.appendChild(a);
  a.click();

  // Clean up: remove the link and revoke the object URL
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
  return response.data;
};

const bedReportService = {
  fetchDoctorList,
  fetchCareTeamList,
  fetchAssignedNurseList,
  fetchCalledNurseList,
  generatePreview,
  downloadCSV
};

export default bedReportService;
