import { Checkbox, Col, DatePicker, Form, Input, Layout, Row, Skeleton, Switch } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RootState, appDispatch } from "../../../../app/store";
import SimpleButton from "../../../../components/buttons/simpleButton/SimpleButton";
import CustomModal from "../../../../components/modal/CustomModal";
import ButtonSpinner from "../../../../components/buttonSpinner/ButtonSpinner";
import { createPublicHoliday, fetchPublicHolidayList, resetFetchPublicHolidayDetails, updatePublicHoliday } from "../../../../features/publicHoliday/publicHolidaySlice";
import "./addPublicHolidayCard.scss";
import dayjs from "dayjs";
// var weekday = require('dayjs/plugin/weekday')

interface AddPublicHolidayCardPropsType {
    open: boolean;
    onClose: () => void;
    isEditMode: boolean;
    editingPublicHolidayId?: any;
    setEditingPublicHolidayId?: any;
    searchQuery: string
}

export const AddPublicHolidayCard = ({
    open,
    isEditMode,
    onClose,
    editingPublicHolidayId,
    searchQuery
}: AddPublicHolidayCardPropsType) => {


    const { publicHolidayDetailsData, isLoading: isLoadingFetchPublicHolidayDetails, isError: isErrorFetchPublicHolidayDetails, isSuccess: isSuccessFetchPublicHolidayDetails, } = useSelector((state: RootState) => state.publicHoliday.fetchPublicHolidayDetails);
    const { isLoading: isLoadingCreatePublicHoliday, isError: isErrorCreatePublicHoliday, isSuccess: isSuccessCreatePublicHoliday, message: messageCreatePublicHoliday, } = useSelector((state: RootState) => state.publicHoliday.createPublicHoliday);
    const { isLoading: isLoadingUpdatePublicHoliday } = useSelector((state: RootState) => state.publicHoliday.updatePublicHoliday)

    const dateFormat = "YYYY-MM-DD"
    const [form] = Form.useForm();
    const dispatch = useDispatch<appDispatch>();
    const [name, setName] = useState<string>("");
    const [isFixedDate, setIsFixedDate] = useState(false)
    const [isApplyForEveryYear, setIsApplyForEveryYear] = useState(false)


    useEffect(() => {
        if (isEditMode && publicHolidayDetailsData?.length > 0) {
            const { name, day, month, year, fixed_date, apply_for_all_year } = publicHolidayDetailsData?.[0]
            const dateString = `${year}-${month}-${day}`;


            form.setFieldsValue({
                name: name,
                date: dayjs(dateString),
            });
            setIsFixedDate(fixed_date)
            setIsApplyForEveryYear(apply_for_all_year)
        }
    }, [publicHolidayDetailsData, isEditMode]);


    const handleFixedDate = (e: any) => {
        setIsFixedDate(e.target.checked)
    }

    const handleApplyForEveryYear = (e: any) => {
        setIsApplyForEveryYear(e.target.checked)
    }


    const getCurrentOccurrence = (date: Date,) => {
        const selectedDate = dayjs(date).format('YYYY-MM-DD');
        const currentDate = new Date(selectedDate);

        const dayOfWeek = currentDate.getDay();
        const currentDayOfMonth = currentDate.getDate();
        const firstDayOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );
        const firstDayOfWeek = firstDayOfMonth.getDay();
        let diff = dayOfWeek - firstDayOfWeek;
        if (diff < 0) diff += 7;
        const firstOccurrenceOffset = diff + 1;
        // Calculate the occurrence of the target day of the week
        const occurrence = Math.ceil((currentDayOfMonth - firstOccurrenceOffset) / 7) + 1;
        return occurrence;
    };


    //to handle save in add/edit modal
    const handleSave = () => {

        const { name, date } = form.getFieldsValue();
        const occurrence = getCurrentOccurrence(date);

        const payload = {
            name: name,
            month: `${dayjs(date).month() + 1}`,
            day: `${dayjs(date).date()}`,
            year: `${dayjs(date).year()}`,
            week_day: `${(dayjs(date).day() + 6) % 7}`,//to change week day from Monday as 0 and Sunday as 6 instead of default   0 (Sunday) to 6 (Saturday).
            week: `${occurrence}`,
            fixed_date: isFixedDate,
            apply_for_all_year: isApplyForEveryYear,

        }

        console.log('occurrence', occurrence)
        form
            .validateFields()
            .then((values) => {


                if (isEditMode) {
                    //edit mode - updating public holiday
                    dispatch(updatePublicHoliday({ publicHolidayId: editingPublicHolidayId, payload }))
                        .then((data: any) => {
                            if (data?.payload?.isSuccess) {
                                toast.success(data?.payload?.message)
                                dispatch(fetchPublicHolidayList({ searchQuery }));
                                handleCancel()

                            } else if (!data?.payload?.isSuccess) {
                                toast.error(data?.payload)
                            }
                        });
                } else {
                    //add mode - creating new public holiday
                    dispatch(createPublicHoliday(payload)).then((data) => {
                        if (data?.payload?.isSuccess) {
                            toast.success(data?.payload?.message)
                            dispatch(fetchPublicHolidayList({ searchQuery }));
                            handleCancel()

                        } else if (!data?.payload?.isSuccess) {
                            toast.error(data?.payload)
                        }
                    });
                }
            })
            .catch((errorInfo) => {
                console.log("Validation failed:", errorInfo);
            });
    };

    const handleClearValues = () => {
        setIsFixedDate(false);
        setIsApplyForEveryYear(false);
        form.resetFields();
        if (isEditMode) {
            dispatch(resetFetchPublicHolidayDetails());
        }
    }

    //to handle cancel in add/edit modal
    const handleCancel = () => {
        handleClearValues()
        onClose();

    };


    return (
        <CustomModal visible={open} onClose={onClose}>
            <Layout>
                <Row>
                    <Col>
                        <h2> {isEditMode ? "Change Public Holiday" : "Add Public Holiday"} </h2>
                    </Col>
                </Row>
                <Skeleton active loading={isLoadingFetchPublicHolidayDetails}>
                    <Form
                        name="AddPublicHolidayCard"
                        form={form}
                        className="add-public-holiday-form"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                    >
                        <Row gutter={12}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input name",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Holiday Name"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Form.Item
                                    name="date"
                                    label="Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Date",
                                        },
                                    ]}
                                >
                                    <DatePicker format={dateFormat} placeholder="Select Date" />
                                </Form.Item>
                            </Col>
                        </Row>


                        <Row gutter={12} style={{ marginTop: '.3rem' }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Checkbox checked={isFixedDate} onChange={handleFixedDate}>Fixed Date Holiday</Checkbox>
                            </Col>
                        </Row>

                        <Row gutter={12} style={{ marginTop: '.3rem' }} >
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Checkbox checked={isApplyForEveryYear} onChange={handleApplyForEveryYear}>Apply for all year</Checkbox>
                            </Col>
                        </Row>


                        <Row gutter={[8, 8]} className="ct-btn-section" style={{ marginTop: "15px" }} justify={"end"}>
                            <Col >
                                <SimpleButton
                                    block
                                    text="Cancel"
                                    color="tertiary"
                                    customHtmlType="button"
                                    onClick={handleCancel}
                                />
                            </Col>
                            <Col >
                                <SimpleButton
                                    block
                                    text="Save"
                                    color="primary"
                                    onClick={handleSave}
                                    customHtmlType="button"
                                    icon={(isLoadingUpdatePublicHoliday || isLoadingCreatePublicHoliday) && <ButtonSpinner />}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Skeleton>
            </Layout>
        </CustomModal>
    );
};
