import { useNavigate } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";

import AddStatusBoardCard from "../addStatusBoard/components/AddStatusBoardCard";
import { useDispatch } from "react-redux";
import { appDispatch } from "../../../../app/store";
import { fetchStatusBoardDetails } from "../../../../features/statusBoard/statusBoardSlice";

import "./statusBoardTable.scss";
interface BedDataType {
    id: number,
    name: string
}

interface DataType {
    id: number,
    name: string
}

// Define the data type for each row in the table
interface StatusBoardTableDataType {
    key: React.Key;
    id: number;
    bed: BedDataType[];
    name: string;
    all_beds: boolean;
    is_active: boolean
    site: DataType;
    unit: DataType;
    care_team: number[]
};

// Define the props type for the component
interface StatusBoardTableProps {
    isLoading: boolean;
    statusBoardTableDataApi: any;
    selectedStatusBoardKeys: React.Key[];
    setSelectedStatusBoardKeys: (keys: React.Key[]) => void;
    setSelectedStatusBoardRows: (rows: StatusBoardTableDataType[]) => void;
    isMobile: boolean,
    handleClearFilters: () => void;
    handleClearLastFilterValues: () => void;
    filterSite: string | any
    filterUnit: string | any
    searchQuery: string
};


/**
 * Renders a status board table component.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isLoading - Flag indicating if the data is loading.
 * @param {Array} props.statusBoardTableDataApi - Array of data for the table.
 */
const StatusBoardTable: React.FC<StatusBoardTableProps> = ({

    isLoading,
    statusBoardTableDataApi,
    selectedStatusBoardKeys,
    setSelectedStatusBoardKeys,
    isMobile,
    setSelectedStatusBoardRows,
    handleClearFilters,
    handleClearLastFilterValues,
    filterUnit,
    filterSite,
    searchQuery
}) => {

    //React hooks
    const navigate = useNavigate();
    const dispatch = useDispatch<appDispatch>();
    const [selectedKeysAvailable, setSelectedKeysAvailable] = useState<React.Key[]>([]);
    const [selectedStatusBoard, setSelectedSelectedStatus] = useState<StatusBoardTableDataType | null>(null);
    const [isAddStatusModalVisible, setIsAddStatusModalVisible] =
        useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editingStatusId, setEditingStatusId] = useState<number>();

    const openModal = async (statusId: any) => {
        setEditingStatusId(statusId)

        dispatch(fetchStatusBoardDetails(statusId))
        setIsEditMode(true);


        // setSelectedSelectedStatus(statusboard);
        setIsAddStatusModalVisible(true);
    };

    const handleAddStatusBoardClose = () => {
        setIsAddStatusModalVisible(false);
        setIsEditMode(false);

    };
    // const [selectedStatusBoardKeys, setSelectedStatusBoardKeys] = useState<React.Key[]>([]);

    /**
     * Table columns configuration.
     */
    let columns: ColumnsType<StatusBoardTableDataType> = [
        {
            title: "NAME",
            dataIndex: "name",
            render: (text: any, record: StatusBoardTableDataType) => (
                <a onClick={() => openModal(record?.id)}>{text}</a>
            ),
        },
        {
            title: "SITE",
            dataIndex: ["site", "name"],
        },
        {
            title: "UNIT",
            dataIndex: ["unit", "name"],
        },
        {
            title: "BEDS",
            dataIndex: "bed",
            render: (record: BedDataType[]) => (
                <span>
                    {record.map((bed: any, index: number) => (
                        <span key={bed?.id}>{index > 0 && " , "}{bed?.name}</span>
                    ))}
                </span>
            )
        },


    ];

    //if mobile view ,then display all columns except bed, support staff and care teams columns which will be displayed in expandable row
    if (isMobile) {
        columns = [
            ...columns.slice(0, columns.length - 1), // Exclude the BEDS column           
        ];
    }

    //if mobile view , then display bed, support staff and care teams columns in expandable row
    const expandableConfig = {
        expandedRowRender: (record: any) => (
            <div>
                {isMobile &&
                    <p>
                        <strong>BEDS : </strong>
                        <span>
                            {record?.bed?.map((bed: any, index: number) => (
                                <span key={bed?.id}>{index > 0 && " , "}{bed?.name}</span>
                            ))}
                        </span>
                    </p>
                }
                <p>
                    <strong>SUPPORT STAFF : </strong>
                    <span>
                        {record?.support_staff?.map((supportStaff: any, index: number) => (
                            <span key={supportStaff?.id}>{index > 0 && " , "}{supportStaff?.name}</span>
                        ))}
                    </span>
                </p>
                <p>
                    <strong>CARE TEAMS : </strong>
                    <span>
                        {record?.care_team?.map((careTeam: any, index: number) => (
                            <span key={careTeam?.id}>{index > 0 && " , "}{careTeam?.name}</span>
                        ))}
                    </span>
                </p>


            </div>
        ),
    }



    /**
     * Row selection configuration.
     */
    const rowSelection = {
        selectedRowKeys: selectedStatusBoardKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: StatusBoardTableDataType[]) => {
            setSelectedStatusBoardKeys(selectedRowKeys);
            setSelectedStatusBoardRows(selectedRows)
        },
        getCheckboxProps: (record: StatusBoardTableDataType) => ({
            name: record.name,
        }),
    };


    return (
        <>
            <div>
                {/* Status Board Table */}
                <Table
                    loading={isLoading}
                    rowSelection={{ ...rowSelection }}
                    columns={columns}
                    pagination={{ position: ["bottomLeft"], pageSize: 25 }}
                    dataSource={statusBoardTableDataApi}
                    scroll={{ x: "auto" }}
                    rowClassName={(_record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    expandable={expandableConfig}
                />
            </div>
            <AddStatusBoardCard open={isAddStatusModalVisible}
                onClose={handleAddStatusBoardClose} isEditMode={isEditMode} editingStatusId={editingStatusId} setIsEditMode={setIsEditMode}
                handleClearFilters={handleClearFilters}
                handleClearLastFilterValues={handleClearLastFilterValues}
                filterSite={filterSite} filterUnit={filterUnit}
                searchQuery={searchQuery} />
        </>

    );
};

export default StatusBoardTable;