import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { User } from "../../pages/login/login.model";
import loginService from "./loginService";

interface LoginRecoverState {
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
  loginRecoverData: any;
}

const initialState: LoginRecoverState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  loginRecoverData: "",
};

//login recover slice - forgot username & forgot password
export const loginRecover = createAsyncThunk("auth/recover-login", async (userData: User, thunkAPI) => {
  try {
    const response = await loginService.loginRecoverService(userData);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const LoginRecoverSlice = createSlice({
  name: "loginRecover",
  initialState,
  reducers: {
    resetLoginRecover: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginRecover.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(loginRecover.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.loginRecoverData = action.payload;
      })
      .addCase(loginRecover.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetLoginRecover } = LoginRecoverSlice.actions;
