import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

//api call to fetch public holiday list from server
const fetchPublicHolidayList = async ({ searchQuery }: { searchQuery: string }, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.PUBLIC_HOLIDAYS.FETCH_PUBLIC_HOLIDAYS_LIST}?name=${searchQuery}`,
    config
  );
  return response.data;
};

//api call to delete public holiday
const deletePublicHoliday = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.PUBLIC_HOLIDAYS.DELETE_PUBLIC_HOLIDAY}`, {
    ...config,
    data: payload,
  });
  return response.data;
};

//api call to  create public holiday
const createPublicHoliday = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.PUBLIC_HOLIDAYS.ADD_PUBLIC_HOLIDAY}`,
    payload,
    config
  );
  return response.data;
};

//api call to  update public holiday
const updatePublicHoliday = async (
  { publicHolidayId, payload }: { publicHolidayId: string; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.PUBLIC_HOLIDAYS.UPDATE_PUBLIC_HOLIDAY}${publicHolidayId}/`,
    payload,
    config
  );
  return response.data;
};

//
//api call to fetch public holiday details
const fetchPublicHolidayDetails = async (publicHolidayId: string, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.PUBLIC_HOLIDAYS.FETCH_PUBLIC_HOLIDAY_DETAILS}?id=${publicHolidayId}`,
    config
  );
  return response.data;
};

const publicHolidayService = {
  fetchPublicHolidayList,
  deletePublicHoliday,
  createPublicHoliday,
  updatePublicHoliday,
  fetchPublicHolidayDetails,
};

export default publicHolidayService;
