import {
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Skeleton,
  Space,
  Divider,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RootState, appDispatch } from "../../../app/store";
import { CareTeamOptions } from "../../../constants";
import {
  createCareTeam,
  fetchCareTeamList,
  fetchScheduleList,
  resetCreateCareTeam,
  resetFetchCareTeamDetails,
  resetFetchScheduleList,
  resetUpdateCareTeam,
  updateCareTeam,
} from "../../../features/careTeam/careTeamSlice";
import SimpleButton from "../../../components/buttons/simpleButton/SimpleButton";
import CustomModal from "../../../components/modal/CustomModal";
import "./addCareTeamCard.scss";
import { resetFetchAddedSiteDetails } from "../../../features/sites/sitesSlice";

interface AddCareTeamCardPropsType {
  open: boolean;
  onClose: () => void;
  isEditMode: boolean;
  editingCareTeamId?: any;
  setEditingCareTeamId?: any;
  handleClearFilters: () => void;
  handleClearLastFilterValues: () => void;
  selectedSiteOption?: string;
  selectedCategoryOption?: string;
  searchQuery: string
}

export const AddCareTeamCard = ({
  open,
  isEditMode,
  onClose,
  editingCareTeamId,
  handleClearFilters,
  handleClearLastFilterValues,
  selectedSiteOption,
  selectedCategoryOption,
  searchQuery
}: AddCareTeamCardPropsType) => {
  const { siteListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchSiteList
  );

  const { scheduleListData, isLoading: isLoadingFetchScheduleList } =
    useSelector((state: RootState) => state.careTeam.fetchScheduleList);

  const {
    careTeamDetailsData,
    isLoading: isLoadingFetchCareTeamDetails,
    isError: isErrorFetchCareTeamDetails,
    isSuccess: isSuccessFetchCareTeamDetails,
  } = useSelector((state: RootState) => state.careTeam.fetchCareTeamDetails);

  const {
    isLoading: isLoadingCreateCareTeam,
    isError: isErrorCreateCareTeam,
    isSuccess: isSuccessCreateCareTeam,
    message: messageCreateCareTeam,
  } = useSelector((state: RootState) => state.careTeam.createCareTeam);
  const {
    isLoading: isLoadingUpdateCareTeam,
    isError: isErrorUpdateCareTeam,
    isSuccess: isSuccessUpdateCareTeam,
    message: messageUpdateCareTeam,
  } = useSelector((state: RootState) => state.careTeam.updateCareTeam);

  const [form] = Form.useForm();
  const dispatch = useDispatch<appDispatch>();
  const [name, setName] = useState<string>("");

  const [selectedSite, setSelectedSite] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectAllSchedules, setSelectAllSchedules] = useState<boolean>(false);
  const [scheduleId, setScheduleId] = useState<string[]>([]);
  const [flag, setFlag] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([""]);

  //to set selected options in checkbox
  useEffect(() => {
    const initialScheduleIds =
      careTeamDetailsData?.schedule?.map((schedule: any) => schedule) || [];
    setSelectedOptions(initialScheduleIds);
  }, [careTeamDetailsData]);

  useEffect(() => {
    if (isEditMode && careTeamDetailsData) {
      if (careTeamDetailsData?.site) {
        dispatch(fetchScheduleList(careTeamDetailsData?.site));
      }
      setSelectedOptions(
        careTeamDetailsData?.schedule?.map((item: any) => item)
      );
      form.setFieldsValue({
        name: careTeamDetailsData?.name,
        site: careTeamDetailsData?.site,
        category: careTeamDetailsData?.category?.toString(),
        schedule: careTeamDetailsData?.schedule?.map((item: any) => item),
      });
    }
  }, [careTeamDetailsData, isEditMode]);

  //to handle checkbox value
  useEffect(() => {
    if (form.getFieldValue("schedule")?.length === scheduleListData?.length) {
      setSelectAllSchedules(true);
    }

    return () => {
      setSelectAllSchedules(false);
    };
  }, [scheduleListData]);

  //to reset fetch schedule list data when component unmounts
  useEffect(() => {
    return () => {
      dispatch(resetFetchScheduleList());
    };
  }, []);

  //handle site change in add/edit modal
  const handleSiteChange = (value: string) => {
    setSelectedSite(value);
    form.resetFields(["schedule"]);
    setSelectedOptions([]);
    setSelectAllSchedules(false);
    dispatch(fetchScheduleList(value));
  };

  //handle category change in add/edit modal
  const handleCategoryChange = (value: any) => {
    setSelectedCategory(value);
  };

  //handle select all schedules in add/edit modal
  const handleSelectAllSchedulesChange = (e: any) => {
    const isAllSchedulesSelected = e.target.checked;
    setSelectAllSchedules(isAllSchedulesSelected);

    const allScheduleId = scheduleListData?.map(
      (schedule: any) => schedule?.id
    );
    form.setFieldValue("schedule", isAllSchedulesSelected ? allScheduleId : []);
  };

  //handle schedule change in add/edit modal
  // const handleScheduleChange = (scheduleId: string) => {

  //   // setScheduleId(scheduleId)
  //   scheduleListData?.length === scheduleId?.length ? setSelectAllSchedules(true) : setSelectAllSchedules(false)

  // }

  //to handle save in add/edit modal
  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        //to display to a loading toast
        const loadingToast = toast.loading("Loading...", {
          autoClose: false, // Keep the loading toast until manually closed
        });

        if (isEditMode) {
          //edit mode - updating care team
          dispatch(
            updateCareTeam({ careTeamId: editingCareTeamId, payload: values })
          ).then((data: any) => {
            if (data?.payload?.isSuccess) {
              toast.update(loadingToast, {
                type: "success",
                render: data?.payload?.message,
                isLoading: false,
                autoClose: 2000,
              });
              setSelectAllSchedules(false);
              dispatch(resetFetchCareTeamDetails());
              dispatch(resetFetchScheduleList());
              dispatch(
                fetchCareTeamList({
                  searchQuery: searchQuery,
                  categoryId: selectedCategoryOption ?? "",
                  siteId: selectedSiteOption ?? "",
                })
              );
              form.resetFields();
              onClose();
            } else if (!data?.payload?.isSuccess) {
              toast.update(loadingToast, {
                type: "error",
                render: data?.payload,
                isLoading: false,
                autoClose: 2000,
              });
            }
          });
        } else {
          //add mode - creating new care team
          dispatch(createCareTeam(values)).then((data) => {
            if (data?.payload?.isSuccess) {
              toast.update(loadingToast, {
                type: "success",
                render: data?.payload?.message,
                isLoading: false,
                autoClose: 2000,
              });
              setSelectAllSchedules(false);
              dispatch(
                fetchCareTeamList({
                  searchQuery: searchQuery,
                  categoryId: "",
                  siteId: "",
                })
              );
              dispatch(resetFetchScheduleList());
              form.resetFields();
              onClose();
              handleClearFilters();
              handleClearLastFilterValues();
            } else if (!data?.payload?.isSuccess) {
              toast.update(loadingToast, {
                type: "error",
                render: data?.payload,
                isLoading: false,
                autoClose: 2000,
              });
            }
          });
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };

  //to handle cancel in add/edit modal
  const handleCancel = () => {
    setName("");
    setSelectedCategory("");
    setSelectedSite("");
    onClose();
    dispatch(resetFetchScheduleList());
    dispatch(resetFetchCareTeamDetails());
    form.resetFields();
  };

  //handle schedule change
  const handleScheduleChange = (value: string[]) => {
    setSelectedOptions(value);
    form.setFieldValue("schedule", value);
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      setSelectedOptions(scheduleListData.map((data: any) => data.id));
      form.setFieldValue(
        "schedule",
        scheduleListData.map((data: any) => data.id)
      );
    } else {
      form.setFieldValue("schedule", []);
      setSelectedOptions([]);
    }
  };

  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label?.props?.children ? label?.props?.children : null}
      </Tag>
    );
  };

  const dropdownRender = (menu: any) => (
    <>
      <Space style={{ padding: "5px 12px 4px" }}>
        <Checkbox
          checked={
            selectedOptions?.length === scheduleListData?.length &&
            scheduleListData.length > 0
          }
          onChange={handleSelectAll}
        >
          Select All
        </Checkbox>
      </Space>
      <Divider style={{ margin: "8px 0" }} />
      {menu}
    </>
  );

  //select all checkbox
  const handleCheckboxChange = (value: any) => {
    setSelectedOptions(value);
  };

  const TextWithCheckbox = (props: any) => {
    return (
      <div>
        <Checkbox
          checked={props.checked}
          style={{ margin: "0 .5rem 0 0rem" }}
        />
        {props.children}
      </div>
    );
  };

  return (
    <CustomModal visible={open} onClose={onClose}>
      <Layout>
        <Row>
          <Col>
            <h2> {isEditMode ? "Change Care Team" : "Add Care Team"} </h2>
          </Col>
        </Row>
        <Skeleton active loading={isLoadingFetchCareTeamDetails}>
          <Form
            name="addCareTeam"
            form={form}
            className="add-care-team-form"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <Form.Item
                  name="site"
                  label="Site"
                  rules={[
                    {
                      required: true,
                      message: "Please choose Site",
                    },
                  ]}
                >
                  <Select
                    virtual={false}
                    placeholder="Select Site"
                    onChange={handleSiteChange}
                    options={[
                      ...siteListData?.map((option: any) => ({
                        value: option?.id,
                        label: option?.name,
                      })),
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <Form.Item
                  name="category"
                  label="Category"
                  rules={[
                    {
                      required: true,
                      message: "Please choose category",
                    },
                  ]}
                >
                  <Select
                    virtual={false}
                    placeholder="Select Category"
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                    options={CareTeamOptions}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <Form.Item
                  name="schedule"
                  label="Schedules"
                  rules={[
                    {
                      required: true,
                      message: "Please choose schedule",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    onChange={handleScheduleChange}
                    placeholder={"Select Schedule"}
                    maxTagCount={"responsive"}
                    value={selectedOptions}
                    virtual={false}
                    tagRender={tagRender}
                    dropdownRender={dropdownRender}
                  >
                    {scheduleListData?.map((item: any) => (
                      <Select.Option key={item.id} value={item.id} label={item.name}>
                        <TextWithCheckbox checked={selectedOptions?.includes(item?.id)}>
                          {item.name}
                        </TextWithCheckbox>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 8]} className="ct-btn-section" style={{ marginTop: "15px" }} justify={"end"}>
              <Col >
                <SimpleButton
                  block
                  text="Cancel"
                  color="tertiary"
                  customHtmlType="button"
                  onClick={handleCancel}
                />
              </Col>
              <Col >
                <SimpleButton
                  block
                  text="Save"
                  color="primary"
                  onClick={handleSave}
                  customHtmlType="button"
                />
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Layout>
    </CustomModal>
  );
};
