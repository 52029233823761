import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

const fetchStatusBoardColumnList = async (
  { searchQuery, siteId, typeId }: { searchQuery: string; siteId: string; typeId: string },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD_COLUMN.FETCH_STATUS_BOARD_COLUMN_LIST}?name=${searchQuery}&site=${siteId}&type=${typeId}`,
    config
  );
  return response.data;
};

const deleteStatusBoardColumn = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD_COLUMN.DELETE_STATUS_BOARD_COLUMN}`,
    {
      ...config,
      data: payload,
    }
  );
  return response.data;
};

const createStatusBoardColumn = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD_COLUMN.CREATE_STATUS_BOARD_COLUMN}`,
    payload,
    config
  );
  return response.data;
};

const fetchStatusBoardColumnDetails = async (statusBoardColumnId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD_COLUMN.FETCH_STATUS_BOARD_COLUMN_DETAILS}?name=&site=&type=&id=${statusBoardColumnId}`,
    config
  );
  return response.data;
};

const updateStatusBoardColumn = async (
  { statusBoardColumnId, payload }: { statusBoardColumnId: number; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD_COLUMN.FETCH_STATUS_BOARD_COLUMN_DETAILS}${statusBoardColumnId}/`,
    payload,
    config
  );
  return response.data;
};

const fetchValue = async (typeId: string, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD_COLUMN.FETCH_VALUE}${typeId}/`,
    "",
    config
  );
  return response.data;
};

export const statusBoardColumnService = {
  fetchStatusBoardColumnList,
  deleteStatusBoardColumn,
  createStatusBoardColumn,
  fetchStatusBoardColumnDetails,
  updateStatusBoardColumn,
  fetchValue,
};
