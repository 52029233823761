import "react-toastify/dist/ReactToastify.css";
import { useRoutes } from "react-router-dom";
import { routes } from "./router/routes";
import { ToastContainer } from "react-toastify";

function App() {
  const content = useRoutes(routes);

  return (
    <>
      {content}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
