import { LiaSearchSolid } from "react-icons/lia";
import { MdOutlineSubject } from "react-icons/md";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined, } from "@ant-design/icons";
import { Button, Col, Divider, Input, Modal, Row, Select, Space, Tooltip, } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RiDeleteBin6Line } from "react-icons/ri";

import { RootState, appDispatch } from "../../app/store";
import SimpleButton from "../../components/buttons/simpleButton/SimpleButton";
import { fetchSiteList, resetFetchSiteList, } from "../../features/unitDepartment/unitDepartmentSlice";
import AddStatusBoardColumn from "./components/addStatusBoardColumn/AddStatusBoardColumn";
import StatusBoardColumnTable from "./components/statusBoardColumnTable/StatusBoardColumnTable";
import { deleteStatusBoardColumn, fetchStatusBoardColumnList, resetDeleteStatusBoardColumn, resetFetchStatusBoardColumnList } from "../../features/statusBoardColumn/statusBoardColumnSlice";
import "./statusBoardColumn.scss";

const { confirm } = Modal;

const StatusBoardColumn = () => {

  const dispatch = useDispatch<appDispatch>();

  //retrieving states of status board column api response
  const { statusBoardColumnListData, isLoading, isError, message } = useSelector((state: RootState) => state.statusBoardColumn.fetchStatusBoardColumnList);

  //retrieving states of delete status board column api response
  const { isSuccess: isSuccessDelete, isError: isErrorDelete, message: messageDelete, } = useSelector((state: RootState) => state.statusBoardColumn.deleteStatusBoardColumn);

  //retrieving states of sites api response (units and departments sites api)
  const { siteListData } = useSelector((state: RootState) => state.unitDepartment.fetchSiteList);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isAddStatusBoardColumnModalOpen, setIsAddStatusBoardColumnModalOpen] = useState<boolean>(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<string>("");
  const [typeId, setTypeId] = useState<string>("");
  const [isIpad, setIsIpad] = useState(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isFirstTimeFiltering, setIsFirstTimeFiltering] = useState(true);
  const [statusBoardColumnTableDataApi, setStatusBoardColumnTableDataApi] = useState([]);
  const [selectedTableRowKeys, setSelectedTableRowKeys] = useState<React.Key[]>([]);
  const [selectedTableRows, setSelectedTableRows] = useState<any>([]);
  const [site, setSite] = useState("All");
  const [type, setType] = useState("All");
  const [lastFilterValues, setLastFilterValues] = useState({
    siteId: "",
    typeId: "",
    site: "All",
    type: "All",
  });

  const handleResize = () => {
    setIsMobile(window.innerWidth < 478);
    setIsIpad(window.innerWidth >= 768 && window.innerWidth <= 1024 && (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth))
  };

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //to set status board column table data to a state by default
  useEffect(() => {
    if (statusBoardColumnListData) {
      setStatusBoardColumnTableDataApi(statusBoardColumnListData);
    }
  }, [statusBoardColumnListData]);

  //to fetch status board column list and site list date from server
  useEffect(() => {
    dispatch(fetchStatusBoardColumnList({ searchQuery, siteId, typeId }));
    dispatch(fetchSiteList());
    return () => {
      dispatch(resetFetchStatusBoardColumnList());
      dispatch(resetFetchSiteList());
    };
  }, []);

  //to handle error condition for status board column list api
  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    return () => {
      dispatch(resetFetchStatusBoardColumnList());
    };
  }, [isError]);

  //to handle error and success condition for delete status board column api
  useEffect(() => {
    if (isErrorDelete) {
      toast.error(messageDelete);
    } else if (isSuccessDelete) {
      toast.success(messageDelete);
      clearSelectedTableRows();
      dispatch(fetchStatusBoardColumnList({ searchQuery, siteId, typeId }));
    }
    return () => {
      dispatch(resetDeleteStatusBoardColumn());
    };
  }, [isErrorDelete, isSuccessDelete, messageDelete]);

  //to handle search query
  const handleStatusboardColumnSearch = () => {
    dispatch(fetchStatusBoardColumnList({ searchQuery, siteId, typeId }));
  };

  // to handle click event of add status board column button
  const handleAddStatusColumnBoard = () => {
    clearSelectedTableRows();
    setIsAddStatusBoardColumnModalOpen(true);
  };

  // to handle  deleting status board column button
  const handleDeleteSelectedStatusBoardColumn = () => {
    // Extract names from selected table row
    const selectedStatusBoardColumnNames = selectedTableRows
      .map((row: any) => row.name)
      .join(", ");

    // Display confirmation modal
    confirm({
      title: "Delete status boards columns confirmation",
      icon: <ExclamationCircleOutlined />,
      centered: true,
      content: (
        <p>
          Are you sure you want to delete{" "}
          <b>{selectedStatusBoardColumnNames}</b> status board column ?
        </p>
      ),

      onOk() {
        const payload = {
          ids: selectedTableRowKeys,
        };
        dispatch(deleteStatusBoardColumn(payload));
      },
      // Do nothing if user cancels
      onCancel() { },
    });
  };

  //handle filter modal submit
  const handleFilterModalSubmit = () => {
    setIsFirstTimeFiltering(false);
    dispatch(fetchStatusBoardColumnList({ searchQuery, siteId, typeId }));
    setIsFilterModalOpen(false);
    setLastFilterValues({ siteId, typeId, site, type });
  };

  //handle clearing selected filters in filter modal
  const handleClearFilters = () => {
    setSiteId("");
    setTypeId("");
    setSite("All");
    setType("All");
  };

  //hanlde to clear last filter values
  const handleClearLastFilterValues = () => {
    setLastFilterValues({ siteId: "", typeId: "", site: "All", type: "All" });
  }

  //handle site change in filter modal
  const handleSiteChange = (value: string) => {
    setSiteId(value);
    setSite(value);
  };

  //handle type change in filter modal
  const handleTypeChange = (value: string) => {
    setTypeId(value);
    setType(value);
  };

  //handle close of add status board column modal
  const handleAddStatusColumnBoardClose = () => {
    setIsAddStatusBoardColumnModalOpen(false);
  };

  //handle to clear selected table rows
  const clearSelectedTableRows = () => {
    if (selectedTableRowKeys) {
      setSelectedTableRowKeys([]);
      setSelectedTableRows([]);
    }
  };

  //handle cancel button of filter modal
  const handleCancelFilterModal = () => {
    if (isFirstTimeFiltering) {
      handleClearFilters();
    } else {
      // Reset filter values to the last applied values
      setSiteId(lastFilterValues.siteId);
      setTypeId(lastFilterValues.typeId);
      setSite(lastFilterValues.site);
      setType(lastFilterValues.type);
    }
    setIsFilterModalOpen(false);
  };

  const isDeleteButtonDisabled = () => {
    return (
      (selectedTableRowKeys && selectedTableRowKeys.length === 0) ||
      selectedTableRowKeys === undefined
    );
  };

  return (
    <div className="status-board-column-container">
      {/* Search header */}
      <Row justify={"start"}>
        <Col sm={24} md={24} className="status-board-column-header-container">
          <div>Search by name</div>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {/* Search Bar section*/}
        <Col xs={24} sm={15} md={12} lg={13} xl={15}>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
              }}
            >
              <Col xs={16} md={18} lg={24} span={10}>
                <span className="search-bar">
                  <Input
                    placeholder="Search"
                    prefix={<LiaSearchSolid className="search-bar-icon" />}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </span>
              </Col>
              <Col xs={8} md={6} lg={4}>
                <span>
                  <SimpleButton
                    text={isMobile ? "" : "Search"}
                    color="primary"
                    icon={<SearchOutlined />}
                    onClick={handleStatusboardColumnSearch}
                  />
                </span>
              </Col>
            </div>
          </Row>
        </Col>

        <Col xs={24} sm={9} md={12} lg={11} xl={9}>
          <Row
            justify="end"
            gutter={10}
            className="add-statusBoard-column-button"
          >
            {/* Add button section*/}
            <Space direction="horizontal" align="center">
              <Tooltip
                title={isMobile || isIpad ? "" : "Add New Status Board Column"}
                placement="bottomRight"
              >
                <span>
                  <SimpleButton
                    icon={
                      <PlusOutlined className="add-statusBoardColumn-icon" />
                    }
                    text={isMobile ? "" : "Add"}
                    color="secondary"
                    onClick={handleAddStatusColumnBoard}
                  />
                </span>
              </Tooltip>

              <AddStatusBoardColumn
                open={isAddStatusBoardColumnModalOpen}
                isEditMode={false}
                onClose={handleAddStatusColumnBoardClose}
                handleClearFilters={handleClearFilters}
                handleClearLastFilterValues={handleClearLastFilterValues}
                searchQuery={searchQuery}
              />

              {/* delete button section*/}
              <div className="sb-column-dlt-btn-contianer">
                <SimpleButton
                  text={isMobile ? "" : "Delete"}
                  color={
                    isDeleteButtonDisabled()
                      ? "danger-disabled"
                      : "danger-default"
                  }
                  icon={
                    <RiDeleteBin6Line
                      color={
                        isDeleteButtonDisabled()
                          ? "danger-disabled"
                          : "danger-default"
                      }
                    />
                  }
                  disabled={isDeleteButtonDisabled()}
                  onClick={handleDeleteSelectedStatusBoardColumn}
                />
              </div>

              {/* Filter button */}
              <Button
                icon={<MdOutlineSubject />}
                className="filter-button"
                onClick={() => {
                  setIsFilterModalOpen(true);
                  clearSelectedTableRows();
                }}
              >
                {isMobile ? "" : "Filter"}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>

      <Divider />

      {/* Filter modal */}
      <div style={{ paddingBottom: "10px" }}>
        <Modal
          width={300}
          centered={true}
          closeIcon={false}
          open={isFilterModalOpen}
          className="sbc-filter-modal"
          footer={[
            <Button key="cancel" onClick={handleCancelFilterModal}>
              Cancel
            </Button>,
            <Button
              key="apply"
              type="primary"
              onClick={handleFilterModalSubmit}
            >
              OK
            </Button>,
          ]}
        >
          <div className="popover-content" style={{ padding: "5px" }}>
            <div className="popover-header">
              <h4>Filter</h4>
              <Button onClick={handleClearFilters} className="clear-all-button">
                Clear All
              </Button>
            </div>
            <div className="popover-item">
              <label className="label-text">By Site</label>
              <Select
                virtual={false}
                value={site}
                onChange={handleSiteChange}
                style={{ width: "100%" }}
              >
                <Select.Option value="">All</Select.Option>
                {siteListData?.map((option: any) => (
                  <Select.Option key={option?.id} value={option?.id}>
                    {option?.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="popover-item">
              <label className="label-text">By Type</label>
              <Select
                virtual={false}
                value={type}
                onChange={handleTypeChange}
                style={{ width: "100%" }}
                options={[
                  { value: "", label: "All" },
                  { value: "1", label: "Text Field" },
                  { value: "3", label: "Drop down text" },
                ]}
              />
            </div>
          </div>
        </Modal>
      </div>

      {/* Status Board Column Table */}
      <StatusBoardColumnTable
        statusBoardColumnTableDataApi={statusBoardColumnTableDataApi}
        selectedTableRowKeys={selectedTableRowKeys}
        setSelectedTableRowKeys={setSelectedTableRowKeys}
        setSelectedTableRows={setSelectedTableRows}
        isLoading={isLoading}
        handleClearFilters={handleClearFilters}
        handleClearLastFilterValues={handleClearLastFilterValues}
        filterSiteId={siteId}
        filterTypeId={typeId}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default StatusBoardColumn;
