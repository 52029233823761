import { Table } from "antd";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, appDispatch } from "../../../../app/store";

import { PublicHolidaysTableDataType, sampleData } from "../../Type";
import { fetchPublicHolidayDetails } from "../../../../features/publicHoliday/publicHolidaySlice";
import { AddPublicHolidayCard } from "../AddPublicHoliday/AddPublicHolidayCard";

interface PublicHolidaysTablePropsType {
    publicHolidayTableDataApi: any;
    selectedTableRowKeys: any;
    setSelectedTableRowKeys: any;
    isMobile: boolean;
    setSelectedTableRows: any;
    searchQuery: string
}


export const PublicHolidaysTable = ({
    publicHolidayTableDataApi,
    selectedTableRowKeys,
    setSelectedTableRowKeys,
    isMobile,
    setSelectedTableRows,
    searchQuery
}: PublicHolidaysTablePropsType) => {

    const dispatch = useDispatch<appDispatch>()

    const [editingPublicHolidayId, setEditingPublicHolidayId] = useState();
    const [isEditPublicHolidayModalOpen, setIsEditPublicHolidayModalOpen] = useState<boolean>(false);


    const { publicHolidayListData, isLoading } = useSelector((state: RootState) => state.publicHoliday.fetchPublicHolidayList)

    const columns: ColumnsType<PublicHolidaysTableDataType> = [
        {
            title: "NAME",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            // defaultSortOrder: 'ascend',
            sortIcon: ({ sortOrder }: any) => sortOrder === "ascend" ? <IoIosArrowUp /> : <IoIosArrowDown />,
            render: (text: any, record: PublicHolidaysTableDataType) => (
                <a onClick={() => openModal(record?.id)}>{text}</a>
            ),
        },
        {
            title: "DATE",
            dataIndex: "date",
        },
    ];

    //handle modal open
    const openModal = (publicHolidayId: any) => {
        setEditingPublicHolidayId(publicHolidayId)
        dispatch(fetchPublicHolidayDetails(publicHolidayId))
        setIsEditPublicHolidayModalOpen(true);
    };

    //handle modal close
    const handleEditPublicHolidayModalClose = () => {
        setIsEditPublicHolidayModalOpen(false);
    };

    const rowSelection = {
        selectedRowKeys: selectedTableRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: PublicHolidaysTableDataType[]) => {
            setSelectedTableRowKeys(selectedRowKeys);
            setSelectedTableRows(selectedRows);
        },
        getCheckboxProps: (record: PublicHolidaysTableDataType) => ({
            name: record.name,
        }),
    };

    return (
        <>
            <div>
                <Table
                    loading={isLoading}
                    rowSelection={{ ...rowSelection }}
                    columns={columns}
                    pagination={{ position: ["bottomLeft"], pageSize: 25 }}
                    dataSource={publicHolidayTableDataApi}
                    scroll={{ x: "auto" }}
                    rowClassName={(_record, index) => index % 2 === 0 ? "table-row-light" : "table-row-dark"}

                />
            </div>
            <AddPublicHolidayCard
                open={isEditPublicHolidayModalOpen}
                isEditMode={true}
                editingPublicHolidayId={editingPublicHolidayId}
                onClose={handleEditPublicHolidayModalClose}
                setEditingPublicHolidayId={setEditingPublicHolidayId}
                searchQuery={searchQuery}
            />
        </>
    );
};
