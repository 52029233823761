import axios from "axios";
import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

//api call to fetch roles list
const fetchRolesList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(`${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.FETCH_LIST}`, config);
  return response.data;
};

//api call to fetch role's permissions
const fetchRolePermissions = async (roleId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.FETCH_ROLE_PERMISSIONS}${roleId}/`,
    config
  );
  return response.data;
};

// api for adding role
const addRole = async (roleName: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.ADD_ROLE}`, roleName, config);
  return response.data;
};

const editRole = async (token: string, { roleID, payload }: { roleID: number; payload: any }): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.EDIT_ROLE}${roleID}/`,
    payload,
    config
  );
  return response.data;
};

//api to add role permisssion
const addRolePermission = async (permissionPayload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.ADD_ROLES_PERMISSION}`,
    permissionPayload,
    config
  );
  return response.data;
};

//api call to fetch client role's permissions by role id
const fetchClientRolePermissions = async (roleId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.CLIENT_ROLE_PERMISSION}${roleId}/`,
    config
  );
  return response.data;
};

//api call to update client role's permissions
const updateClientRolePermissions = async (
  { roleId, payload }: { roleId: any; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.CLIENT_ROLE_PERMISSION}${roleId}/`,
    payload,
    config
  );
  return response.data;
};

//api to delete role
const deleteRole = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.ROLES_PERMISSIONS.DELETE_ROLE}`, {
    ...config,
    data: payload,
  });
  return response.data;
};

const userRolesService = {
  addRole,
  editRole,
  fetchRolesList,
  fetchRolePermissions,
  addRolePermission,
  fetchClientRolePermissions,
  updateClientRolePermissions,
  deleteRole,
};

export default userRolesService;
