import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  TimePicker,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appDispatch, RootState } from "../../app/store";
import {
  fetchSiteList,
  resetFetchSiteList,
} from "../../features/unitDepartment/unitDepartmentSlice";
import dayjs from "dayjs";
import Spinner from "../../components/spinner/Spinner";
import { generatePreview, resetGeneratePreview } from "../../features/bedReport/bedReportSlice";
import { downloadCSV } from "../../features/bedReport/bedReportSlice";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { LogoSvg } from "../../components/logoSvg/LogoSvg";
import { LineGraph } from "./components/lineGraph/LineGraph";

export interface generatePreviewPayloadProps {
  siteID: string;
  unitID: string;
  doctorID: string;
  nurseID: string;
  careTeamID: string;
  repordID: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  days: string;
  reportName?: string;
}

const SystemReports = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { Option } = Select;
  const { confirm } = Modal;
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch<appDispatch>();

  const { siteListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchSiteList
  );
  const { generatePreviewData, isLoading } = useSelector(
    (state: RootState) => state.bedReport.generatePreview
  );
  const { isLoading: csvLoading } = useSelector(
    (state: RootState) => state.bedReport.downloadCSV
  );

  const contentRef = useRef(null);

  const startDate = dayjs().format("YYYY-MM-DD");
  const endDate = dayjs().format("YYYY-MM-DD");
  const dateFormat = "YYYY-MM-DD";

  const [site, setSite] = useState<any>("");
  const [dateRange, setDateRange] = useState<any>([startDate, endDate]);
  const [days, setDays] = useState<any>([]);
  const [timeRange, setTimeRange] = useState<any>(["", ""]);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isResultEmpty, setIsResultEmpty] = useState<any>(false);
  const [graphDataSet, setGraphDataSet] = useState<any>();
  const [isPrintClicked, setIsPrintClicked] = useState<boolean>(false);

  const dayOptions = [
    { id: 1, label: "Sunday" },
    { id: 2, label: "Monday" },
    { id: 3, label: "Tuesday" },
    { id: 4, label: "Wednesday" },
    { id: 5, label: "Thursday" },
    { id: 6, label: "Friday" },
    { id: 7, label: "Saturday" },
  ];

  useEffect(() => {
    dispatch(fetchSiteList());
    return () => {
      dispatch(resetFetchSiteList());
    };
  }, []);

  useEffect(() => {
    if (generatePreviewData.length !== 0) {
      setIsResultEmpty(
        generatePreviewData?.msg_count_list?.every(
          (data: any, index: any) => data === 0
        ) &&
          generatePreviewData?.call_count_list?.every(
            (data: any, index: any) => data === 0
          )
      );
      setGraphDataSet({
        labels: generatePreviewData.date_list,
        datasets: [
          {
            label: "Messages Recieved",
            data: generatePreviewData?.msg_count_list,
            borderColor: "#3e95cd",
            backgroundColor: "#3e95cd",
            tension: 0.4,
          },
          {
            label: "Calls used",
            data: generatePreviewData?.call_count_list,
            tension: 0.4,
            borderColor: "#805491",
            backgroundColor: "#805491",
          },
        ],
      });
    }
  }, [generatePreviewData,isSubmitted]);

  // To render custom tage on multi select
  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label?.props?.children ? label?.props?.children : null}
      </Tag>
    );
  };

  // the component for check box options in multi select
  const TextWithCheckbox = (props: any) => {
    return (
      <div>
        <Checkbox
          checked={props.checked}
          style={{ margin: "0 .5rem 0 0rem" }}
        />
        {props.children}
      </div>
    );
  };

  const confirmPopUp = () => {
    confirm({
      title: "",
      icon: null,
      centered: true,
      width: 350,
      content: (
        <div className="empty-message-popup-container">
          <ExclamationCircleOutlined width={"2em"} height={"2em"} />
          <p>No records to show for the selected inputs</p>
        </div>
      ),
      onOk() {},
      footer: [
        <div
          key="custom-footer"
          style={{ textAlign: "right", margin: ".8rem 0 0 0" }}
        >
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              Modal.destroyAll();
              setIsSubmitted(false);
              setIsResultEmpty(false);
              dispatch(resetGeneratePreview());
            }}
          >
            OK
          </Button>
        </div>,
      ],
    });

    return <></>;
  };

  const handleSelectAll = (
    e: any,
    handleChange: any,
    options: any,
    name?: string
  ) => {
    // if select all clicked then it will call the appropriate handle function
    // then update selected value
    if (e.target.checked) {
      handleChange(
        options.map((data: any) => data.id),
        ""
      );
      if (name) {
        form.setFieldValue(
          name,
          options.map((data: any) => data.id)
        );
      }
    } else {
      if (name) {
        form.setFieldValue(name, []);
      }
      handleChange([], "");
    }
  };

  const handleSiteChange = (value: number, option: any) => {
    setSite(value);
  };

  const disabledDate = (current: any) => {
    // Disable dates after today
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const handleDateRangePickerChange = (data: any) => {
    const formattedDates = data?.map((date: any) => date.format("YYYY-MM-DD"));
    setDateRange(formattedDates);
  };

  const handleDayChange = (value: any, option: any) => {
    setDays(value);
  };

  const handleTimeRangePickerChange = (data: any) => {
    // converting the time value from the picker to api payload format
    if (data) {
      const formattedTimeRange = data.map((time: any) =>
        dayjs(time).format("h:mm a").replace(/\s/g, "")
      );
      setTimeRange(formattedTimeRange);
    } else {
      setTimeRange(["", ""]);
    }
  };

  const generatePayload = () => {
    const daysNameNew = days.map((day: any, index: any) => {
      return dayOptions[day - 1].label;
    });
    return {
      siteID: site,
      unitID: "",
      doctorID: "",
      careTeamID: "",
      nurseID: "",
      days: daysNameNew ? daysNameNew.join(",") : "",
      repordID: "NOTSUChart",
      startDate: dateRange ? dateRange[0] : "",
      endDate: dateRange ? dateRange[1] : "",
      startTime: timeRange ? timeRange[0] : "",
      endTime: timeRange ? timeRange[1] : "",
      reportName: "Number of Times System Used for Messages/Calls",
    };
  };

  const handleSubmit = () => {
    const payload: generatePreviewPayloadProps = generatePayload();
    form
      .validateFields()
      .then((data) => {
        setIsSubmitted(true);
        dispatch(generatePreview(payload));
      })
      .catch((errorInfo) => {});
  };

  const handleDownloadCSV = () => {
    const payload: generatePreviewPayloadProps = generatePayload();
    dispatch(downloadCSV(payload));
  };

  const downloadPDF = async () => {
    const element: any = contentRef.current;
    setIsPrintClicked(true);
    setTimeout(async () => {
      if (element) {
        const canvas = await html2canvas(element, {
          useCORS: true,
        });
        // Use html2canvas to capture the content of the div as a canvas

        // Convert canvas to data URL
        const img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.onload = () => {
          const pdf = new jsPDF("landscape", "px", "a4");
          pdf.addImage(
            img,
            "JPEG",
            15,
            10,
            pdf.internal.pageSize.width - 50,
            pdf.internal.pageSize.height
          );
          pdf.save(`NUMBER_OF_TIMES_SYSTEM_USED_FOR_MESSAGE/CALLS.pdf`);
          setIsPrintClicked(false);
        };
      }
    }, 400);
  };

  return (
    <div className="ems-rpt-management-container">
      <Form form={form} className="form-container">
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <span style={{ alignItems: "center", display: "flex" }}>
              <Tooltip title="Reports management page" placement="bottom">
                {" "}
                <Button
                  size="small"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate("/reports-management")}
                />
              </Tooltip>
              <span
                className="text-label-style"
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  marginLeft: "10px",
                }}
              >
                System Reports
              </span>
            </span>
          </Col>
        </Row>
        {/* <Divider /> */}
        <Row gutter={[18, 18]} style={{ marginTop: "20px" }}>
          {/*site */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-date-range-picker-container align-column"
          >
            <p className="text-label-style mb-1 required">Site</p>
            <Form.Item
              name={"date-range-picker-2"}
              rules={[
                {
                  required: true,
                  message: "Please select site",
                },
              ]}
            >
              <Select
                placeholder="Select Site"
                virtual={false}
                onChange={handleSiteChange}
                value={site}
              >
                {siteListData?.map((item: any) => (
                  <Option key={item.id} value={item.id} label={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* Date range picker */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-date-range-picker-container align-column"
          >
            <p className="text-label-style mb-1 required">Date Range</p>
            <Form.Item
              name={"date-range-picker"}
              rules={[
                {
                  required: true,
                  message: "Please select a date range",
                },
              ]}
            >
              <RangePicker
                disabledDate={disabledDate}
                inputReadOnly={true}
                allowClear={false}
                onChange={handleDateRangePickerChange}
                value={[
                  dayjs(`${dateRange[0]}`, "YYYY-MM-DD"),
                  dayjs(`${dateRange[1]}`, "YYYY-MM-DD"),
                ]}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          {/* Days select section */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-time-range-picker-container align-column"
          >
            <p className="text-label-style mb-1">Day</p>
            <Select
              mode="multiple"
              style={{
                width: "100%",
              }}
              onChange={handleDayChange}
              placeholder={"Select Days"}
              maxTagCount={"responsive"}
              value={days}
              virtual={false}
              tagRender={tagRender}
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "5px 12px 4px" }}>
                    <Checkbox
                      checked={days?.length === dayOptions?.length}
                      onChange={(e) =>
                        handleSelectAll(e, handleDayChange, dayOptions, "")
                      }
                    >
                      Select All
                    </Checkbox>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
            >
              {dayOptions?.map((item: any) => (
                <Option key={item.id} value={item.id} label={item.label}>
                  <TextWithCheckbox checked={days?.includes(item?.id)}>
                    {item.label}
                  </TextWithCheckbox>
                </Option>
              ))}
            </Select>
          </Col>
          {/* Time Range Picker */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={5}
            xl={5}
            className="ems-rpt-mngt-time-range-picker-container align-column"
          >
            <p className="text-label-style mb-1">Time Range</p>
            <Form.Item name={"time-range-picker"}>
              <TimePicker.RangePicker
                allowClear={true}
                format="h:mm a"
                use12Hours={true}
                onChange={handleTimeRangePickerChange}
              />
            </Form.Item>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Button className="generate-report-button" onClick={handleSubmit}>
              Generate Report
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider></Divider>
      {/* buttons section */}
      {isSubmitted &&
        (!isLoading && generatePreviewData.length !== 0 ? (
          !isResultEmpty ? (
            <div>
              <Row justify={"end"}>
                <Col style={{ margin: "0 .75rem 0 0" }}>
                  <Button
                    loading={csvLoading}
                    className="generate-report-button"
                    style={{ margin: "0 1rem 0 0" }}
                    onClick={handleDownloadCSV}
                  >
                    Download CSV
                  </Button>

                  <Button
                    loading={isPrintClicked}
                    className="generate-report-button"
                    onClick={downloadPDF}
                  >
                    Download PDF
                  </Button>
                </Col>
              </Row>
              <div
                className={
                  isPrintClicked
                    ? "bed-report-gnt-container pdf-generated"
                    : "bed-report-gnt-container"
                }
                ref={contentRef}
              >
                <Row justify={"space-between"} style={{ margin: "2rem 0 0 0" }}>
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    className="gnt-section-title-container"
                  >
                    <h2>{generatePreviewData.site}</h2>
                    <p>{`Report Generated on: ${dayjs(startDate).format(
                      "DD MMM YYYY"
                    )}`}</p>
                  </Col>
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    className="align-right gnt-section-icon-container"
                  >
                    <div className="icon">
                      <LogoSvg />
                    </div>

                    <p>Call Central</p>
                  </Col>
                </Row>
                <Row align={"middle"} justify={"center"}>
                  <Col>
                    <h2 className="report-title">
                      NUMBER OF TIMES SYSTEM USED FOR MESSAGE/CALLS
                    </h2>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  style={{ margin: "2rem 0 0 0" }}
                  className={"duration-and-unit-container"}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={10}
                    xl={10}
                    lg={10}
                    className="align-right"
                  >
                    <p>{`DURATION: ${generatePreviewData.duration}`}</p>
                  </Col>
                </Row>
                {generatePreviewData.length !== 0 && (
                  <Row
                    justify={"center"}
                    align={"middle"}
                    style={{ margin: "2rem 0 0 0" }}
                  >
                    <Col xs={23} sm={23} md={23} lg={22} xl={22}>
                      <LineGraph
                        isClicked={isPrintClicked}
                        data={graphDataSet && graphDataSet}
                      />
                    </Col>
                  </Row>
                )}
                <Row
                  style={{ margin: "4rem 0 0 0rem" }}
                  className="align-center-c"
                  justify={"center"}
                >
                  <div className="graph-details-container">
                    <Row>
                      <Col>
                        <p className="data-title-style">
                          Number of Times System Used For Messages:
                        </p>
                      </Col>
                      <Col className="ml-1">
                        <p className="data-text-style">
                          {generatePreviewData?.msg_count}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="data-title-style">
                          Number of Times System Used For Calls :
                        </p>
                      </Col>
                      <Col className="ml-1">
                        <p className="data-text-style">
                          {generatePreviewData?.call_count}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Row>
                <Row justify={"center"} className="" align={"middle"}>
                  <Col lg={18} xl={18} className="report-preview-footer">
                    <p>
                      This Report is generated in the call central applicaton
                    </p>
                    <p>
                      The confidentiality of the data is to be preserved. Any
                      Unauthorized usage of this report data is prohibited under
                      organizational policy
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            confirmPopUp()
          )
        ) : (
          <div style={{ height: "60vh" }}>
            <Spinner />
          </div>
        ))}
    </div>
  );
};

export default SystemReports;
