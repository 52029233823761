import axios from "axios";

// Create an instance of Axios
const axiosInstance = axios.create();

// Add an interceptor to modify request data for every request
axiosInstance.interceptors.request.use((config: any) => {
    // Add your extra parameter to the request data
    const reg_code = localStorage.getItem("reg_code") ?? "";
    config.url += config.url.includes("?") ? "&" : "?";
    config.url += `reg_code=${reg_code.trim()}`;
    return config;
});

// clearing local storage and redirecting to login page
const handleRedirect = () => {
    localStorage.clear();
    window.location.href = "/login";
};

// Intreceptor for checking every response
axiosInstance.interceptors.response.use(
    (response: any) => {
        //#76904 : To handle 401 error :this should be uncommented once dev server is deployed dashboard api's
        // if (response?.data?.status === 401) {
        //     handleRedirect();
        // } else {
        return response;
        // }
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            handleRedirect();
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
