import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";
import {
  userShiftSchedulePayloadType,
  onCallLookupHistoryPayloadType,
} from "../../pages/scheduleReport/ScheduleReport";

const fetchScheduleList = async (groupID: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_REPORT.FETCH_SCHEULE_LIST}?group_id=${groupID}`,
    config
  );
  return response.data;
};

const fetchUserShiftScheduleReport = async (
  { endTime, groupID, scheduleId, startTime, searchQuery }: userShiftSchedulePayloadType,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_REPORT.FETCH_USER_SHIFT_SCHEDULE_REPORT}?schedule_name_id=${scheduleId}&start_time=${startTime}&end_time=${endTime}&group_id=${groupID}&search=${searchQuery}`,
    config
  );
  return response.data;
};

const fetchScheduleHistoryReport = async (
  { endTime, groupID, scheduleId, startTime, searchQuery }: userShiftSchedulePayloadType,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_REPORT.FETCH_SCHEDULE_HISTORY_REPORT}?schedule_name_id=${scheduleId}&start_time=${startTime}&end_time=${endTime}&group_id=${groupID}&search=${searchQuery}`,
    config
  );
  return response.data;
};

const fetchOnCallLookupHistoryReport = async (
  { groupID, siteId, searchQuery }: onCallLookupHistoryPayloadType,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_REPORT.FETCH_ON_CALL_LOOKUP_HISTORY_REPORT}?site_id=${siteId}&group_id=${groupID}&search=${searchQuery}`,
    config
  );
  return response.data;
};

const fetchOnCallLookupHistoryGroupList = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SCHEDULE_REPORT.FETCH_ON_CALL_LOOKUP_HISTORY_GROUP_LIST}`,
    payload,
    config
  );
  return response.data;
};

const scheduleReportService = {
  fetchScheduleList,
  fetchUserShiftScheduleReport,
  fetchScheduleHistoryReport,
  fetchOnCallLookupHistoryReport,
  fetchOnCallLookupHistoryGroupList,
};

export default scheduleReportService;
