import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

const fetchAssignNurseList = async (
  {
    searchQuery,
    unitID,
    siteID,
  }: { searchQuery: string; unitID: any; siteID: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.ASSIGN_NURSE.FETCH_ASSIGN_NURSE_TABLE_DATA}?search=${searchQuery}&unit=${unitID}&site=${siteID}`,
    config
  );
  return response.data;
};

const assignNurseService = {
  fetchAssignNurseList,
};

export default assignNurseService;
