import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import publicHolidayService from "./publicHolidayService";

interface publicHolidayState {
  fetchPublicHolidayList: {
    publicHolidayListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  deletePublicHoliday: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  createPublicHoliday: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updatePublicHoliday: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchPublicHolidayDetails: {
    publicHolidayDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: publicHolidayState = {
  fetchPublicHolidayList: {
    publicHolidayListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  deletePublicHoliday: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },

  createPublicHoliday: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updatePublicHoliday: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchPublicHolidayDetails: {
    publicHolidayDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

//action to fetch public holiday list
export const fetchPublicHolidayList = createAsyncThunk(
  "publicHoliday/fetchPublicHolidayList",
  async ({ searchQuery }: { searchQuery: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await publicHolidayService.fetchPublicHolidayList({ searchQuery }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to delete public holiday
export const deletePublicHoliday = createAsyncThunk(
  "publicHoliday/deletePublicHoliday",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await publicHolidayService.deletePublicHoliday(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to create public holiday
export const createPublicHoliday = createAsyncThunk(
  "publicHoliday/createPublicHoliday",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await publicHolidayService.createPublicHoliday(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to update public holiday
export const updatePublicHoliday = createAsyncThunk(
  "publicHoliday/updatePublicHoliday",
  async ({ publicHolidayId, payload }: { publicHolidayId: string; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await publicHolidayService.updatePublicHoliday({ publicHolidayId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch public holiday details
export const fetchPublicHolidayDetails = createAsyncThunk(
  "publicHoliday/fetchPublicHolidayDetails",
  async (publicHolidayId: string, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await publicHolidayService.fetchPublicHolidayDetails(publicHolidayId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const publicHolidaySlice = createSlice({
  name: "publicHoliday",
  initialState,
  reducers: {
    resetFetchPublicHolidayList: (state) => {
      state.fetchPublicHolidayList = initialState.fetchPublicHolidayList;
    },
    resetDeletePublicHoliday: (state) => {
      state.deletePublicHoliday = initialState.deletePublicHoliday;
    },

    resetCreatePublicHoliday: (state) => {
      state.createPublicHoliday = initialState.createPublicHoliday;
    },
    resetUpdatePublicHoliday: (state) => {
      state.updatePublicHoliday = initialState.updatePublicHoliday;
    },
    resetFetchPublicHolidayDetails: (state) => {
      state.fetchPublicHolidayDetails = initialState.fetchPublicHolidayDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicHolidayList.pending, (state, action: PayloadAction<any>) => {
        state.fetchPublicHolidayList.isLoading = true;
      })
      .addCase(fetchPublicHolidayList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchPublicHolidayList.isLoading = false;
        state.fetchPublicHolidayList.isSuccess = true;
        state.fetchPublicHolidayList.publicHolidayListData = action.payload.result;
      })
      .addCase(fetchPublicHolidayList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchPublicHolidayList.isLoading = false;
        state.fetchPublicHolidayList.isError = true;
        state.fetchPublicHolidayList.message = action.payload;
      })

      .addCase(deletePublicHoliday.pending, (state, action: PayloadAction<any>) => {
        state.deletePublicHoliday.isLoading = true;
      })
      .addCase(deletePublicHoliday.fulfilled, (state, action: PayloadAction<any>) => {
        state.deletePublicHoliday.isLoading = false;
        state.deletePublicHoliday.isSuccess = true;
        state.deletePublicHoliday.message = action.payload.message;
      })
      .addCase(deletePublicHoliday.rejected, (state, action: PayloadAction<any>) => {
        state.deletePublicHoliday.isLoading = false;
        state.deletePublicHoliday.isError = true;
        state.deletePublicHoliday.message = action.payload;
      })

      .addCase(createPublicHoliday.pending, (state, action: PayloadAction<any>) => {
        state.createPublicHoliday.isLoading = true;
      })
      .addCase(createPublicHoliday.fulfilled, (state, action: PayloadAction<any>) => {
        state.createPublicHoliday.isLoading = false;
        state.createPublicHoliday.isSuccess = true;
        state.createPublicHoliday.message = action.payload.message;
      })
      .addCase(createPublicHoliday.rejected, (state, action: PayloadAction<any>) => {
        state.createPublicHoliday.isLoading = false;
        state.createPublicHoliday.isError = true;
        state.createPublicHoliday.message = action.payload;
      })

      .addCase(fetchPublicHolidayDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchPublicHolidayDetails.isLoading = true;
      })
      .addCase(fetchPublicHolidayDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchPublicHolidayDetails.isLoading = false;
        state.fetchPublicHolidayDetails.isSuccess = true;
        state.fetchPublicHolidayDetails.message = action.payload.message;
        state.fetchPublicHolidayDetails.publicHolidayDetailsData = action.payload.result;
      })
      .addCase(fetchPublicHolidayDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchPublicHolidayDetails.isLoading = false;
        state.fetchPublicHolidayDetails.isError = true;
        state.fetchPublicHolidayDetails.message = action.payload;
      })

      .addCase(updatePublicHoliday.pending, (state, action: PayloadAction<any>) => {
        state.updatePublicHoliday.isLoading = true;
      })
      .addCase(updatePublicHoliday.fulfilled, (state, action: PayloadAction<any>) => {
        state.updatePublicHoliday.isLoading = false;
        state.updatePublicHoliday.isSuccess = true;
        state.updatePublicHoliday.message = action.payload.message;
      })
      .addCase(updatePublicHoliday.rejected, (state, action: PayloadAction<any>) => {
        state.updatePublicHoliday.isLoading = false;
        state.updatePublicHoliday.isError = true;
        state.updatePublicHoliday.message = action.payload;
      });
  },
});

export const {
  resetFetchPublicHolidayList,
  resetDeletePublicHoliday,
  resetCreatePublicHoliday,
  resetUpdatePublicHoliday,
  resetFetchPublicHolidayDetails,
} = publicHolidaySlice.actions;

export default publicHolidaySlice.reducer;
