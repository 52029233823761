// ObjectContext.tsx
import { createContext, ReactNode, useContext, useState } from "react";

// Define the type for your object
interface GroupType {
  id: number;
  name: string;
  // Add more properties as needed
}
interface UserType {
  id: any;
  name: string;
}

interface SiteType {
  id: any;
  name: string;
}

interface TabType {
  id: any;
  // name?: string;
}

interface ScheduleType {
  id: number;
}

interface MultipleUser {
  id: number[];
}

interface shiftType {
  id?: any;
}

interface SelectedScheduleType {
  id?: any;
}

// Create a context with default values
const GroupScheduleContext = createContext<
  | {
    selectedGroup: GroupType | null;
    setSelectedGroup: (obj: GroupType | null) => void;
    selectedUserFromSchedule: MultipleUser | null;
    setSelectedUserFromSchedule: (obj: MultipleUser | null) => void;
    selectedSiteFromSchedule: SiteType | null;
    setSelectedSiteFromSchedule: (obj: SiteType | null) => void;
    selectedTab: TabType | null;
    setSelectedTab: (obj: TabType | null) => void;
    editingSchedule: ScheduleType | null;
    setEditingSchedule: (obj: TabType | null) => void;
    selectedShift: shiftType | null;
    setSelectedShift: (obj: shiftType | null) => void;
    selectedSchedule: SelectedScheduleType | null;
    setSelectedSchedule: (obj: SelectedScheduleType | null) => void;
    selectedDateRange: any;
    setSelectedDateRange: any;
    selectedUnassignedshiftSchedule: number | null;
    setSelectedUnassignedshiftSchedule: (value: number | null) => void;
    isDarkMode:boolean;
    setIsDarkMode:any;
  }
  | undefined
>(undefined);

// Define a custom hook to access the context values
export const useGroup = () => {
  const context = useContext(GroupScheduleContext);
  if (!context) {
    throw new Error("useGroup must be used within an provider");
  }
  return context;
};

interface GroupProviderProps {
  children: ReactNode;
}

const GroupProvider: React.FC<GroupProviderProps> = ({ children }) => {
  const [selectedGroup, setSelectedGroup] = useState<GroupType | null>(null);
  const [selectedUserFromSchedule, setSelectedUserFromSchedule] =
    useState<MultipleUser | null>({ id: [] });
  const [selectedSiteFromSchedule, setSelectedSiteFromSchedule] =
    useState<UserType | null>(null);
  const [selectedTab, setSelectedTab] = useState<TabType | null>(null);
  const [editingSchedule, setEditingSchedule] = useState<ScheduleType | null>(
    null
  );
  const [selectedShift, setSelectedShift] = useState<shiftType | null>(null);
  const [selectedSchedule, setSelectedSchedule] =
    useState<SelectedScheduleType | null>(null);

  //selected date range in unassigned shift
  const [selectedDateRange, setSelectedDateRange]: any = useState(['', '']);
  const [selectedUnassignedshiftSchedule, setSelectedUnassignedshiftSchedule] = useState<number | null>(null);

  const theme = localStorage.getItem("theme")
  const [isDarkMode,setIsDarkMode]:any = useState(theme==="true")

  return (
    <GroupScheduleContext.Provider
      value={{
        selectedGroup,
        setSelectedGroup,
        selectedUserFromSchedule,
        setSelectedUserFromSchedule,
        selectedSiteFromSchedule,
        setSelectedSiteFromSchedule,
        selectedTab,
        setSelectedTab,
        editingSchedule,
        setEditingSchedule,
        selectedShift,
        setSelectedShift,
        selectedSchedule,
        setSelectedSchedule,
        selectedDateRange,
        setSelectedDateRange,
        selectedUnassignedshiftSchedule,
        setSelectedUnassignedshiftSchedule,
        isDarkMode,
        setIsDarkMode
      }}
    >
      {children}
    </GroupScheduleContext.Provider>
  );
};

export { GroupProvider };
