import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState, appDispatch } from "../../../app/store";
import { AddCareTeamCard } from "../addCareTeamCard/AddCareTeamCard";
import { fetchCareTeamDetails } from "../../../features/careTeam/careTeamSlice";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

interface CareTeamTablePropsType {
  careTeamTableDataApi: any;
  selectedTableRowKeys: any;
  setSelectedTableRowKeys: any;
  isMobile: boolean;
  setSelectedTableRows: any;
  handleClearFilters: () => void
  handleClearLastFilterValues: () => void
  selectedSiteOption: string
  selectedCategoryOption: string
  searchQuery: string
}

interface CareTeamTableDataType {
  key: React.Key;
  id: number;
  name: string;
  category: number;
  is_active: boolean;
  site: number;
  schedule: number[];
  category_name: string;
  site_name: string;
  schedule_names: string
  // site_name: string; 
  // category_name: string;
  // guid: string;
}

export const CareTeamTable = ({
  careTeamTableDataApi,
  selectedTableRowKeys,
  setSelectedTableRowKeys,
  isMobile,
  setSelectedTableRows,
  handleClearFilters,
  handleClearLastFilterValues,
  selectedSiteOption,
  selectedCategoryOption,
  searchQuery
}: CareTeamTablePropsType) => {

  const dispatch = useDispatch<appDispatch>()

  const [editingCareTeamId, setEditingCareTeamId] = useState();
  const [isEditCareTeamModalOpen, setIsEditCareTeamModalOpen] = useState<boolean>(false);

  const { careTeamListData, isLoading } = useSelector((state: RootState) => state.careTeam.fetchCareTeamList)

  const columns: ColumnsType<CareTeamTableDataType> = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      // defaultSortOrder: 'ascend',
      sortIcon: ({ sortOrder }: any) => sortOrder === "ascend" ? <IoIosArrowUp /> : <IoIosArrowDown />,
      render: (text: any, record: CareTeamTableDataType) => (
        <a onClick={() => openModal(record?.id)}>{text}</a>
      ),
    },
    {
      title: "SITE",
      dataIndex: "site_name",
    },
    {
      title: "CATEGORY",
      dataIndex: "category_name",
    },
  ];

  //handle modal open
  const openModal = (careTeamId: any) => {
    setEditingCareTeamId(careTeamId)
    dispatch(fetchCareTeamDetails(careTeamId))
    setIsEditCareTeamModalOpen(true);
  };

  //handle modal close
  const handleEditCareTeamModalClose = () => {
    setIsEditCareTeamModalOpen(false);
  };

  const rowSelection = {
    selectedRowKeys: selectedTableRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: CareTeamTableDataType[]) => {
      setSelectedTableRowKeys(selectedRowKeys);
      setSelectedTableRows(selectedRows);
    },
    getCheckboxProps: (record: CareTeamTableDataType) => ({
      name: record.name,
    }),
  };

  return (
    <>
      <div>
        <Table
          loading={isLoading}
          rowSelection={{ ...rowSelection }}
          columns={columns}
          pagination={{ position: ["bottomLeft"], pageSize: 25 }}
          dataSource={careTeamTableDataApi}
          scroll={{ x: "auto" }}
          rowClassName={(_record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          expandable={{
            expandedRowRender: (record: any) => (
              <div>
                <p>
                  <strong>SCHEDULES: </strong>
                  {record.schedule_names || "No Schedules"}
                </p>
              </div>
            ),
          }}
        />
      </div>
      <AddCareTeamCard
        open={isEditCareTeamModalOpen}
        isEditMode={true}
        editingCareTeamId={editingCareTeamId}
        onClose={handleEditCareTeamModalClose}
        setEditingCareTeamId={setEditingCareTeamId}
        handleClearFilters={handleClearFilters}
        handleClearLastFilterValues={handleClearLastFilterValues}
        selectedSiteOption={selectedSiteOption}
        selectedCategoryOption={selectedCategoryOption}
        searchQuery={searchQuery}
      />
    </>
  );
};
