import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import React, { useEffect } from 'react';

const { RangePicker } = DatePicker
interface UserModalRangePickerProps {
    userId: any
    handleRangePickerChange: any
    dateRange: any
}

const UserModalRangePicker: React.FC<UserModalRangePickerProps> = ({ userId, handleRangePickerChange, dateRange }) => {
    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {

    }, [dateRange])
    return <RangePicker inputReadOnly allowClear={false} onChange={handleRangePickerChange} value={[dayjs(`${dateRange[0]}`, 'YYYY-MM-DD'), dayjs(`${dateRange[1]}`, 'YYYY-MM-DD')]} format={dateFormat} />
}

export default UserModalRangePicker



