import {
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  TimePicker,
  Checkbox,
  Space,
  Tag,
  Button,
  Tooltip,
  Modal,
} from "antd";
import { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { LOGO_ICON } from "../../constants";
import {
  fetchSiteList,
  resetFetchSiteList,
  fetchUnitDepartment,
  resetFetchUnitDepartment,
} from "../../features/unitDepartment/unitDepartmentSlice";
import {
  fetchDoctorList,
  resetFetchDoctorList,
  fetchCareTeamList,
  resetFetchCareTeamList,
  fetchAssignedNurseList,
  resetFetchAssignedNurseList,
  fetchCalledNurseList,
  resetFetchCalledNurseList,
  generatePreview,
  resetGeneratePreview,
  downloadCSV,
} from "../../features/bedReport/bedReportSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState, appDispatch } from "../../app/store";
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";
import "./bedReport.scss";
import { LineGraph } from "./components/graphs/lineGraph/LineGraph";
import { ColumnGraph } from "./components/graphs/columnGraph/ColumnGraph";
import Spinner from "../../components/spinner/Spinner";
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { LogoSvg } from "../../components/logoSvg/LogoSvg";
import html2canvas from "html2canvas";

export interface generatePreviewPayloadProps {
  siteID: string;
  unitID: string;
  doctorID: string;
  nurseID: string;
  careTeamID: string;
  repordID: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  days: string;
  reportName?: string;
}

const BedReport = () => {
  const [form] = Form.useForm();
  const { confirm } = Modal;

  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const dispatch = useDispatch<appDispatch>();
  const navigate = useNavigate();

  const { siteListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchSiteList
  );

  const { unitDepartmentData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchUnitDepartment
  );

  const { doctorListData } = useSelector(
    (state: RootState) => state.bedReport.fetchDoctorList
  );

  const { careTeamListData } = useSelector(
    (state: RootState) => state.bedReport.fetchCareTeamList
  );

  const { assignedNurseListData } = useSelector(
    (state: RootState) => state.bedReport.fetchAssignedNurseList
  );

  const { calledNurseListData } = useSelector(
    (state: RootState) => state.bedReport.fetchCalledNurseList
  );

  const { generatePreviewData, isLoading } = useSelector(
    (state: RootState) => state.bedReport.generatePreview
  );
  const { isLoading: csvLoading } = useSelector(
    (state: RootState) => state.bedReport.downloadCSV
  );

  const startDate = dayjs().format("YYYY-MM-DD");
  const endDate = dayjs().format("YYYY-MM-DD");
  const dateFormat = "YYYY-MM-DD";

  const [unitList, setUnitlist] = useState<any>();
  const [dateRange, setDateRange] = useState<any>([startDate, endDate]);
  const [timeRange, setTimeRange] = useState<any>(["", ""]);
  const [selectedReportID, setSelectedReportID] = useState<any>();
  const [site, setSite] = useState<any>("");
  const [unit, setUnit] = useState<any>("");
  const [nurse, setNurse] = useState<any>("");
  const [nurseList, setNurseList] = useState<any>([]);
  const [doctor, setDoctor] = useState<any>("");
  const [careTeam, setCareTeam] = useState<any>("");
  const [days, setDays] = useState<any>([]);

  const bedReportType = [
    { value: 1, label: "Number of Beds Occupied", id: "NOBOChart" },
    {
      value: 2,
      label: "Number of Assigned Beds Per Nurse ",
      id: "NOABPNChart",
    },
    { value: 3, label: "Number of Patients Discharged", id: "NOPDChart" },
    { value: 4, label: "Number of Nurses on Duty", id: "NONODChart" },
    {
      value: 5,
      label: "Number of Times Care Team was Called",
      id: "NOTCWCChart",
    },
    {
      value: 6,
      label: "Number of Times Nurse Called the Code",
      id: "NOTNCTCChart",
    },
  ];

  const dayOptions = [
    { id: 1, label: "Sunday" },
    { id: 2, label: "Monday" },
    { id: 3, label: "Tuesday" },
    { id: 4, label: "Wednesday" },
    { id: 5, label: "Thursday" },
    { id: 6, label: "Friday" },
    { id: 7, label: "Saturday" },
  ];

  const graphTitle = [
    "NUMBER OF BEDS OCCUPIED",
    "MIN,MAX & AVG NUMBER OF ASSIGNED BEDS",
    "NUMBER OF PATIENTS DISCHARGED",
    "MIN,MAX & AVG NUMBER OF NURSES ON DUTY",
    "NUMBER OF TIMES CARE TEAM WAS CALLED",
    "NUMBER OF TIMES NURSE CALLED THE CODE",
    "NUMBER OF TIMES SYSTEM USED FOR MESSAGE/CALLS",
  ];

  // To render custom tage on multi select
  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label?.props?.children ? label?.props?.children : null}
      </Tag>
    );
  };

  const disabledDate = (current: any) => {
    // Disable dates after today
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const handleReportChange = (value: number, option: any) => {
    setSelectedReportID(value);
    setSite("");
    setUnit("");
    setTimeRange(["", ""]);
    setCareTeam("");
    setDays([]);
    setDoctor("");
    setNurse("");
    dispatch(resetGeneratePreview());
    dispatch(resetFetchUnitDepartment());
    dispatch(resetFetchCalledNurseList());
    dispatch(resetFetchDoctorList());
    dispatch(resetFetchAssignedNurseList());
    dispatch(resetFetchCareTeamList());
    setIsSubmitted(false);
    form.resetFields();
  };

  const handleSiteChange = (value: number, option: any) => {
    setSite(value);
    dispatch(fetchUnitDepartment({ searchquery: "", sid: value }));
    dispatch(fetchDoctorList(value));
    dispatch(fetchCareTeamList(value));
    dispatch(fetchCalledNurseList(value));
    setUnit("");
    setDoctor("");
    setNurse("");
    setCareTeam("");
    form.setFieldValue("unit", []);
    form.setFieldValue("nurse", []);
    form.setFieldValue("doctor", []);
    form.setFieldValue("care-team", []);
  };

  const handleDateRangePickerChange = (data: any) => {
    const formattedDates = data?.map((date: any) => date.format("YYYY-MM-DD"));
    setDateRange(formattedDates);
  };

  const handleUnitChange = (value: any, option: any) => {
    setUnit(value);
    if (selectedReportID === 2) {
      const payload = {
        siteID: site,
        unitID: value,
      };
      dispatch(fetchAssignedNurseList(payload)).then((data: any) => {
        if (nurse) {
          const currentNurses = nurse?.filter((id: any) =>
            data?.payload?.results?.map((data: any) => data?.id).includes(id)
          );
          form.setFieldValue("nurse", currentNurses);
          setNurse(currentNurses);
        }
      });
    } else {
      setNurse("");
      form.setFieldValue("nurse", []);
    }
    setDoctor("");
    setCareTeam("");
    form.setFieldValue("doctor", []);
    form.setFieldValue("care-team", []);
  };

  const handleNurseChange = (value: any, option: any) => {
    setNurse(value);
  };

  const handleDoctorChange = (value: any, option: any) => {
    setDoctor(value);
  };

  const handleCareTeamChange = (value: any, option: any) => {
    setCareTeam(value);
  };

  const handleDayChange = (value: any, option: any) => {
    setDays(value);
  };

  // to handle select all action from multi select
  const handleSelectAll = (
    e: any,
    handleChange: any,
    options: any,
    name?: string
  ) => {
    // if select all clicked then it will call the appropriate handle function
    // then update selected value
    if (e.target.checked) {
      handleChange(
        options.map((data: any) => data.id),
        ""
      );
      if (name) {
        form.setFieldValue(
          name,
          options.map((data: any) => data.id)
        );
      }
    } else {
      if (name) {
        form.setFieldValue(name, []);
      }
      handleChange([], "");
    }
  };

  const handleTimeRangePickerChange = (data: any) => {
    // converting the time value from the picker to api payload format
    if (data) {
      const formattedTimeRange = data.map((time: any) =>
        dayjs(time).format("h:mm a").replace(/\s/g, "")
      );
      setTimeRange(formattedTimeRange);
    } else {
      setTimeRange(["", ""]);
    }
  };

  // the component for check box options in multi select
  const TextWithCheckbox = (props: any) => {
    return (
      <div>
        <Checkbox
          checked={props.checked}
          style={{ margin: "0 .5rem 0 0rem" }}
        />
        {props.children}
      </div>
    );
  };

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  // to generate payload for report generation and download csv
  const generatePayload = () => {
    const daysNameNew = days.map((day: any, index: any) => {
      return dayOptions[day - 1].label;
    });
    return {
      siteID: site,
      unitID: unit ? unit.join(",") : "",
      doctorID: doctor ? doctor.join(",") : "",
      careTeamID: careTeam ? careTeam.join(",") : "",
      nurseID: nurse ? nurse.join(",") : "",
      days: daysNameNew ? daysNameNew.join(",") : "",
      repordID: bedReportType[selectedReportID - 1].id,
      startDate: dateRange ? dateRange[0] : "",
      endDate: dateRange ? dateRange[1] : "",
      startTime: timeRange ? timeRange[0] : "",
      endTime: timeRange ? timeRange[1] : "",
      reportName: bedReportType[selectedReportID - 1].label,
    };
  };

  const handleSubmit = () => {
    const payload: generatePreviewPayloadProps = generatePayload();
    form
      .validateFields()
      .then((data) => {
        setIsSubmitted(true);
        dispatch(generatePreview(payload));
      })
      .catch((errorInfo) => {});
  };

  const contentRef = useRef(null);
  const [isPrintClicked, setIsPrintClicked] = useState<boolean>(false);

  const downloadPDF = async () => {
    const element: any = contentRef.current;
    setIsPrintClicked(true);
    setTimeout(async () => {
      if (element) {
        const canvas = await html2canvas(element, {
          useCORS: true,
        });
        // Use html2canvas to capture the content of the div as a canvas

        // Convert canvas to data URL
        const img = new Image();
        img.src = canvas.toDataURL("image/png");
        img.onload = () => {
          const pdf = new jsPDF("landscape", "px", "a4");
          pdf.addImage(
            img,
            "JPEG",
            15,
            10,
            pdf.internal.pageSize.width - 50,
            pdf.internal.pageSize.height
          );
          pdf.save(`${graphTitle[selectedReportID - 1]}.pdf`);
          setIsPrintClicked(false);
        };
      }
    }, 400);
  };

  const handleDownloadCSV = () => {
    const payload: generatePreviewPayloadProps = generatePayload();
    dispatch(downloadCSV(payload));
  };

  // fetching site list on the first render
  // then resetting the list when closing the component
  useEffect(() => {
    dispatch(fetchSiteList());
    return () => {
      dispatch(resetFetchDoctorList());
      dispatch(resetFetchAssignedNurseList());
      dispatch(resetFetchCalledNurseList());
      dispatch(resetFetchCareTeamList());
      dispatch(resetFetchUnitDepartment());
      dispatch(resetFetchSiteList());
    };
  }, []);

  // Setting the nurse list depends on report selected
  useEffect(() => {
    if (selectedReportID === 2) {
      setNurseList(assignedNurseListData);
    } else {
      setNurseList(calledNurseListData);
    }
  }, [selectedReportID, assignedNurseListData, calledNurseListData]);

  const [graphDataSet, setGraphDataSet] = useState<any>();

  function getRandomColor() {
    // Generate random values for red, green, and blue
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);

    // Combine the values to form a CSS-friendly color string
    const color = `rgb(${red}, ${green}, ${blue})`;

    return color;
  }

  const [isResultEmpty, setIsResultEmpty] = useState<any>(true);

  // Setting graph values depends on the type of report
  useEffect(() => {
    if (selectedReportID === 1) {
      setIsResultEmpty(
        generatePreviewData?.count?.every((data: any, index: any) => data === 0)
      );
      setGraphDataSet({
        labels: generatePreviewData?.date_time,
        datasets: [
          {
            label: "count",
            data: generatePreviewData?.count,
            borderColor: "rgb(33, 194, 120)",
            backgroundColor: "rgb(33, 194, 120)",
          },
        ],
      });
    } else if (selectedReportID === 2 && generatePreviewData.length !== 0) {
      const arrayFromEntries = Object.entries(
        generatePreviewData?.data_per_nurse
      );
      const minAssignedData = arrayFromEntries.map(
        ([key, value]: any, index: any) => {
          return value.min_assigned;
        }
      );
      const maxAssignedData = arrayFromEntries.map(
        ([key, value]: any, index: any) => {
          return value.max_assigned;
        }
      );
      const avgAssignedData = arrayFromEntries.map(
        ([key, value]: any, index: any) => {
          return value.average_assigned;
        }
      );
      setIsResultEmpty(
        minAssignedData.every((data) => data === 0) &&
          maxAssignedData.every((data) => data === 0) &&
          avgAssignedData.every((data) => data === 0)
      );
      const labels = arrayFromEntries.map(([key, value]: any, index: any) => {
        return key;
      });

      setGraphDataSet({
        labels: labels,
        datasets: [
          {
            label: "Minimum assigned",
            data: minAssignedData,
            borderColor: "#3e95cd",
            backgroundColor: "#3e95cd",
            stack: "Stack 0",
          },
          {
            label: "Average assigned",
            data: avgAssignedData,
            borderColor: "#cb4154",
            backgroundColor: "#cb4154",
            stack: "Stack 1",
          },
          {
            label: "Maximum assigned",
            data: maxAssignedData,
            borderColor: "#8e5ea2",
            backgroundColor: "#8e5ea2",
            stack: "Stack 2",
          },
        ],
      });
    } else if (selectedReportID === 3 && generatePreviewData.length !== 0) {
      setIsResultEmpty(
        generatePreviewData?.doctors?.every((data: any) =>
          data.dataset.every((data: any, index: any) => data === 0)
        )
      );
      const dataSets = generatePreviewData?.doctors?.map(
        (data: any, index: any) => {
          const color = getRandomColor();
          return {
            label: data.name,
            data: data.dataset,
            borderColor: color,
            backgroundColor: color,
            stack: `Stack ${index}`,
          };
        }
      );
      setGraphDataSet({
        labels: generatePreviewData?.units,
        datasets: dataSets,
      });
    } else if (selectedReportID === 4 && generatePreviewData.length !== 0) {
      setIsResultEmpty(
        !generatePreviewData.min_on_duty &&
          !generatePreviewData.average_on_duty &&
          !generatePreviewData.max_on_duty
      );
      setGraphDataSet({
        labels: generatePreviewData.duration,
        datasets: [
          {
            label: "minimum nurses on duty",
            data: [generatePreviewData.min_on_duty],
            borderColor: "#3e95cd",
            backgroundColor: "#3e95cd",
            stack: "Stack 0",
          },
          {
            label: "average nurses on duty",
            data: [generatePreviewData.average_on_duty],
            borderColor: "#8e5ea2",
            backgroundColor: "#8e5ea2",
            stack: "Stack 1",
          },
          {
            label: "maximum nurses on duty",
            data: [generatePreviewData.max_on_duty],
            borderColor: "#cb4154",
            backgroundColor: "#cb4154",
            stack: "Stack 2",
          },
        ],
      });
    } else if (
      (selectedReportID === 5 && generatePreviewData.length !== 0) ||
      (selectedReportID === 6 && generatePreviewData.length !== 0)
    ) {
      setIsResultEmpty(
        generatePreviewData?.nurses?.every((data: any) =>
          data.dataset.every((data: any, index: any) => data === "0")
        )
      );
      const dataSets = generatePreviewData?.nurses?.map(
        (data: any, index: any) => {
          const color = getRandomColor();
          return {
            label: data.name,
            data: data.dataset,
            borderColor: color,
            backgroundColor: color,
            stack: `Stack ${index}`,
          };
        }
      );
      setGraphDataSet({
        labels: generatePreviewData?.careteams,
        datasets: dataSets,
      });
    } else {
      setIsResultEmpty(
        generatePreviewData?.msg_count_list?.every(
          (data: any, index: any) => data === 0
        ) &&
          generatePreviewData?.call_count_list?.every(
            (data: any, index: any) => data === 0
          )
      );
      setGraphDataSet({
        labels: generatePreviewData.date_list,
        datasets: [
          {
            label: "Messages Recieved",
            data: generatePreviewData?.msg_count_list,
            borderColor: "#3e95cd",
            backgroundColor: "#3e95cd",
            tension: 0.4,
          },
          {
            label: "Calls used",
            data: generatePreviewData?.call_count_list,
            tension: 0.4,
            borderColor: "#805491",
            backgroundColor: "#805491",
          },
        ],
      });
    }
  }, [generatePreviewData, isSubmitted]);

  const confirmPopUp = () => {
    confirm({
      title: "",
      icon: null,
      centered: true,
      width: 350,
      content: (
        <div className="empty-message-popup-container">
          <ExclamationCircleOutlined width={"2em"} height={"2em"} />
          <p>No records to show for the selected inputs</p>
        </div>
      ),
      onOk() {},
      footer: [
        <div
          key="custom-footer"
          style={{ textAlign: "right", margin: ".8rem 0 0 0" }}
        >
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              Modal.destroyAll();
              setIsSubmitted(false);
              setIsResultEmpty(false);
              dispatch(resetGeneratePreview());
            }}
          >
            OK
          </Button>
        </div>,
      ],
    });

    return <></>;
  };

  useEffect(() => {
    setUnitlist(unitDepartmentData.filter((data: any) => data.type === 2));
  }, [unitDepartmentData]);

  useEffect(() => {
    setSelectedReportID(1);
  }, []);

  return (
    <div className="ems-rpt-management-container">
      <Form form={form} className="form-container">
        <Row gutter={[18, 18]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <span style={{ alignItems: "center", display: "flex" }}>
              <Tooltip title="Reports management page" placement="bottom">
                {" "}
                <Button
                  size="small"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate("/reports-management")}
                />
              </Tooltip>
              <span
                className="text-label-style"
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  marginLeft: "10px",
                }}
              >
                Bed Reports
              </span>
            </span>
          </Col>
        </Row>
        {/* <Divider /> */}
        <Row gutter={[18, 18]} style={{ marginTop: "20px" }}>
          {/* Type of report selection section */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={7}
            xl={8}
            className="ems-rpt-mngt-select-report-container align-column "
          >
            <p className="text-label-style mb-1">Select Report</p>
            <Select
              placeholder="Select Report"
              virtual={false}
              onChange={handleReportChange}
              value={selectedReportID}
              options={bedReportType}
            />
          </Col>
          {/*site */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-date-range-picker-container align-column"
          >
            <p className="text-label-style mb-1 required">Site</p>
            <Form.Item
              name={"date-range-picker-2"}
              rules={[
                {
                  required: true,
                  message: "Please select site",
                },
              ]}
            >
              <Select
                placeholder="Select Site"
                virtual={false}
                onChange={handleSiteChange}
                value={site}
              >
                {siteListData?.map((item: any) => (
                  <Option key={item.id} value={item.id} label={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* Select Unit */}
          {selectedReportID !== 7 && (
            <Col xs={24} sm={24} md={12} lg={6} xl={5} className="align-column">
              <p className="text-label-style mb-1 required">Unit</p>
              <Form.Item
                name={"unit"}
                rules={[
                  {
                    required: true,
                    message: "Please choose unit",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleUnitChange}
                  placeholder={"Select Unit"}
                  maxTagCount={"responsive"}
                  value={unit}
                  virtual={false}
                  tagRender={tagRender}
                  dropdownRender={(menu) => (
                    <>
                      {unitList?.length !== 0 && (
                        <>
                          <Space style={{ padding: "5px 12px 4px" }}>
                            <Checkbox
                              checked={unit?.length === unitList?.length}
                              onChange={(e) =>
                                handleSelectAll(
                                  e,
                                  handleUnitChange,
                                  unitList,
                                  "unit"
                                )
                              }
                            >
                              Select All
                            </Checkbox>
                          </Space>
                          <Divider style={{ margin: "8px 0" }} />
                        </>
                      )}
                      {menu}
                    </>
                  )}
                >
                  {unitList?.map((item: any) => (
                    <Option key={item.id} value={item.id} label={item.name}>
                      <TextWithCheckbox checked={unit?.includes(item?.id)}>
                        {item.name}
                      </TextWithCheckbox>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* Select Nurse */}
          {[2, 5, 6].includes(selectedReportID) && (
            <Col xs={24} sm={24} md={12} lg={6} xl={5} className="align-column">
              <p className="text-label-style mb-1 required">Nurse</p>
              <Form.Item
                name={"nurse"}
                rules={[
                  {
                    required: true,
                    message: "Please choose nurse",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleNurseChange}
                  placeholder={"Select Nurse"}
                  maxTagCount={"responsive"}
                  value={nurse}
                  virtual={false}
                  tagRender={tagRender}
                  dropdownRender={(menu) => (
                    <>
                      {nurseList?.length !== 0 && (
                        <>
                          <Space style={{ padding: "5px 12px 4px" }}>
                            <Checkbox
                              checked={nurse?.length === nurseList?.length}
                              onChange={(e) =>
                                handleSelectAll(
                                  e,
                                  handleNurseChange,
                                  nurseList,
                                  "nurse"
                                )
                              }
                            >
                              Select All
                            </Checkbox>
                          </Space>
                          <Divider style={{ margin: "8px 0" }} />
                        </>
                      )}
                      {menu}
                    </>
                  )}
                >
                  {nurseList?.map((item: any) => (
                    <Option key={item.id} value={item.id} label={item.name}>
                      <TextWithCheckbox checked={nurse?.includes(item?.id)}>
                        {item.name}
                      </TextWithCheckbox>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* Select Doctor */}
          {selectedReportID === 3 && (
            <Col xs={24} sm={24} md={12} lg={6} xl={5} className="align-column">
              <p className="text-label-style mb-1 required">Doctor</p>
              <Form.Item
                name={"doctor"}
                rules={[
                  {
                    required: true,
                    message: "Please choose doctor",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleDoctorChange}
                  placeholder={"Select Doctor"}
                  maxTagCount={"responsive"}
                  value={doctor}
                  virtual={false}
                  tagRender={tagRender}
                  dropdownRender={(menu) => (
                    <>
                      {doctorListData?.length !== 0 && (
                        <>
                          <Space style={{ padding: "5px 12px 4px" }}>
                            <Checkbox
                              checked={
                                doctor?.length === doctorListData?.length
                              }
                              onChange={(e) =>
                                handleSelectAll(
                                  e,
                                  handleDoctorChange,
                                  doctorListData,
                                  "doctor"
                                )
                              }
                            >
                              Select All
                            </Checkbox>
                          </Space>
                          <Divider style={{ margin: "8px 0" }} />
                        </>
                      )}
                      {menu}
                    </>
                  )}
                >
                  {doctorListData?.map((item: any) => (
                    <Option key={item.id} value={item.id} label={item.name}>
                      <TextWithCheckbox checked={doctor?.includes(item?.id)}>
                        {item.name}
                      </TextWithCheckbox>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* Select CareTeam */}
          {[5, 6].includes(selectedReportID) && (
            <Col xs={24} sm={24} md={12} lg={6} xl={5} className="align-column">
              <p className="text-label-style mb-1 required">Care Team</p>
              <Form.Item
                name={"care-team"}
                rules={[
                  {
                    required: true,
                    message: "Please choose care team",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                  }}
                  onChange={handleCareTeamChange}
                  placeholder={"Select Care Team"}
                  maxTagCount={"responsive"}
                  value={careTeam}
                  virtual={false}
                  tagRender={tagRender}
                  dropdownRender={(menu) => (
                    <>
                      {careTeamListData?.length !== 0 && (
                        <>
                          <Space style={{ padding: "5px 12px 4px" }}>
                            <Checkbox
                              checked={
                                careTeam?.length === careTeamListData?.length
                              }
                              onChange={(e) =>
                                handleSelectAll(
                                  e,
                                  handleCareTeamChange,
                                  careTeamListData,
                                  "care-team"
                                )
                              }
                            >
                              Select All
                            </Checkbox>
                          </Space>
                          <Divider style={{ margin: "8px 0" }} />
                        </>
                      )}
                      {menu}
                    </>
                  )}
                >
                  {careTeamListData?.map((item: any) => (
                    <Option key={item.id} value={item.id} label={item.name}>
                      <TextWithCheckbox checked={careTeam?.includes(item?.id)}>
                        {item.name}
                      </TextWithCheckbox>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          {/* Date range picker */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-date-range-picker-container align-column"
          >
            <p className="text-label-style mb-1 required">Date Range</p>
            <Form.Item
              name={"date-range-picker"}
              rules={[
                {
                  required: true,
                  message: "Please select a date range",
                },
              ]}
            >
              <RangePicker
                disabledDate={disabledDate}
                inputReadOnly={true}
                allowClear={false}
                onChange={handleDateRangePickerChange}
                value={[
                  dayjs(`${dateRange[0]}`, "YYYY-MM-DD"),
                  dayjs(`${dateRange[1]}`, "YYYY-MM-DD"),
                ]}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          {/* Days select section */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={5}
            className="ems-rpt-mngt-time-range-picker-container align-column"
          >
            <p className="text-label-style mb-1">Day</p>
            <Select
              mode="multiple"
              style={{
                width: "100%",
              }}
              onChange={handleDayChange}
              placeholder={"Select Days"}
              maxTagCount={"responsive"}
              value={days}
              virtual={false}
              tagRender={tagRender}
              dropdownRender={(menu) => (
                <>
                  <Space style={{ padding: "5px 12px 4px" }}>
                    <Checkbox
                      checked={days?.length === dayOptions?.length}
                      onChange={(e) =>
                        handleSelectAll(e, handleDayChange, dayOptions, "")
                      }
                    >
                      Select All
                    </Checkbox>
                  </Space>
                  <Divider style={{ margin: "8px 0" }} />
                  {menu}
                </>
              )}
            >
              {dayOptions?.map((item: any) => (
                <Option key={item.id} value={item.id} label={item.label}>
                  <TextWithCheckbox checked={days?.includes(item?.id)}>
                    {item.label}
                  </TextWithCheckbox>
                </Option>
              ))}
            </Select>
          </Col>
          {/* second row part */}
          {/* Time Range Picker */}
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={5}
            xl={5}
            className="ems-rpt-mngt-time-range-picker-container align-column"
          >
            <p className="text-label-style mb-1">Time Range</p>
            <Form.Item name={"time-range-picker"}>
              <TimePicker.RangePicker
                allowClear={true}
                format="h:mm a"
                use12Hours={true}
                onChange={handleTimeRangePickerChange}
              />
            </Form.Item>
          </Col>

          <Col style={{ display: "flex", alignItems: "center" }}>
            <Button className="generate-report-button" onClick={handleSubmit}>
              Generate Report
            </Button>
          </Col>
        </Row>
      </Form>
      <Divider></Divider>
      {/* buttons section */}
      {isSubmitted &&
        (!isLoading && generatePreviewData.length !== 0 ? (
          !isResultEmpty ? (
            <div>
              <Row justify={"end"}>
                <Col style={{ margin: "0 .75rem 0 0" }}>
                  <Button
                    loading={csvLoading}
                    className="generate-report-button"
                    style={{ margin: "0 1rem 0 0" }}
                    onClick={handleDownloadCSV}
                  >
                    Download CSV
                  </Button>

                  <Button
                    loading={isPrintClicked}
                    className="generate-report-button"
                    onClick={downloadPDF}
                  >
                    Download PDF
                  </Button>
                </Col>
              </Row>
              <div
                className={
                  isPrintClicked
                    ? "bed-report-gnt-container pdf-generated"
                    : "bed-report-gnt-container"
                }
                ref={contentRef}
              >
                <Row justify={"space-between"} style={{ margin: "2rem 0 0 0" }}>
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    className="gnt-section-title-container"
                  >
                    <h2>{generatePreviewData.site}</h2>
                    <p>{`Report Generated on: ${dayjs(startDate).format(
                      "DD MMM YYYY"
                    )}`}</p>
                  </Col>
                  <Col
                    md={12}
                    lg={12}
                    xl={12}
                    className="align-right gnt-section-icon-container"
                  >
                    <div className="icon">
                      <LogoSvg />
                    </div>

                    <p>Call Central</p>
                  </Col>
                </Row>
                <Row align={"middle"} justify={"center"}>
                  <Col>
                    <h2 className="report-title">
                      {graphTitle[selectedReportID - 1]}
                    </h2>
                  </Col>
                </Row>
                <Row
                  justify={"space-between"}
                  style={{ margin: "2rem 0 0 0" }}
                  className={"duration-and-unit-container"}
                >
                  {selectedReportID !== 7 && (
                    <Col
                      xs={23}
                      sm={23}
                      md={10}
                      xl={10}
                      lg={10}
                      className="align-left"
                    >
                      <p>
                        {unit?.length === unitList?.length
                          ? "UNIT: All"
                          : `UNIT: ${
                              selectedReportID === 3
                                ? generatePreviewData.unit_name
                                : generatePreviewData.unit
                            }`}
                      </p>
                    </Col>
                  )}
                  <Col
                    xs={24}
                    sm={24}
                    md={10}
                    xl={10}
                    lg={10}
                    className="align-right"
                  >
                    <p>{`DURATION: ${generatePreviewData.duration}`}</p>
                  </Col>
                </Row>
                {generatePreviewData.length !== 0 && (
                  <Row
                    justify={"center"}
                    align={"middle"}
                    style={{ margin: "2rem 0 0 0" }}
                  >
                    <Col xs={23} sm={23} md={23} lg={22} xl={22}>
                      {[1, 7].includes(selectedReportID) && (
                        <LineGraph
                          isClicked={isPrintClicked}
                          data={graphDataSet && graphDataSet}
                        />
                      )}
                      {[2, 3, 4, 5, 6].includes(selectedReportID) && (
                        <ColumnGraph
                          isClicked={isPrintClicked}
                          data={graphDataSet && graphDataSet}
                        />
                      )}
                    </Col>
                  </Row>
                )}
                <Row
                  style={{ margin: "4rem 0 0 0rem" }}
                  className="align-center-c"
                  justify={"center"}
                >
                  {selectedReportID === 3 && (
                    <div className="graph-details-container">
                      <Row>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24}>
                          <p
                            className="data-title-style"
                            style={{ fontSize: "1.15rem" }}
                          >
                            Number of Patients Discharged per Unit:
                          </p>
                        </Col>
                        <Col
                          className="ml-1 align-center"
                          style={{ margin: "1rem 0 0 0" }}
                          xs={20}
                          sm={20}
                          md={20}
                          xl={20}
                          lg={20}
                        >
                          {Object.entries(
                            generatePreviewData?.per_unit_discharge
                          ).map(([key, value]: any, index) => {
                            return (
                              <>
                                <Col style={{ margin: "0 0 0 1rem" }}>
                                  <p
                                    className="data-title-style"
                                    style={{ fontSize: ".8rem" }}
                                  >
                                    {key}
                                  </p>
                                  {Object.entries(value).map(
                                    ([key, value], index) => {
                                      return (
                                        <p
                                          className="data-text-style"
                                          style={{
                                            fontSize: ".7rem",
                                            margin: ".5rem 0 0 0",
                                          }}
                                        >{`${key}: ${value}`}</p>
                                      );
                                    }
                                  )}
                                </Col>
                              </>
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  )}
                  {selectedReportID === 4 && (
                    <div
                      className="graph-details-container"
                      style={{ width: "70%", margin: "0 0 0 30%" }}
                    >
                      <div></div>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          <p className="data-title-style">
                            Maximum Nurses On-Duty Day(s):
                          </p>
                        </Col>
                        <Col
                          className="ml-1"
                          style={{ textAlign: "left" }}
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                          lg={12}
                        >
                          <p className="data-text-style">
                            {generatePreviewData?.max_on_duty_on?.join(", ")}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          <p className="data-title-style">
                            Minimum Nurses On-Duty Day(s):
                          </p>
                        </Col>
                        <Col
                          xs={12}
                          sm={12}
                          md={12}
                          xl={12}
                          lg={12}
                          className="ml-1"
                          style={{ textAlign: "left" }}
                        >
                          <p className="data-text-style">
                            {generatePreviewData?.min_on_duty_on?.join(", ")}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          <p className="data-title-style">
                            Maximum Nurses On-Duty:
                          </p>
                        </Col>
                        <Col style={{ textAlign: "left" }}>
                          <p className="ml-1  data-text-style">
                            {generatePreviewData?.max_on_duty}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          <p className="data-title-style">
                            Minimum Nurses On-Duty:
                          </p>
                        </Col>
                        <Col style={{ textAlign: "left" }}>
                          <p className="ml-1  data-text-style">
                            {generatePreviewData?.min_on_duty}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ textAlign: "left" }}>
                          <p className="data-title-style">
                            Average Nurses On-Duty Assigned:
                          </p>
                        </Col>
                        <Col style={{ textAlign: "left" }}>
                          <p className="ml-1 data-text-style">
                            {generatePreviewData?.average_on_duty}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {(selectedReportID === 5 || selectedReportID === 6) && (
                    <div className="graph-details-container">
                      <Row justify={"center"}>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24}>
                          <p
                            className="data-title-style"
                            style={{ fontSize: "1.15rem" }}
                          >
                            {selectedReportID === 5
                              ? "Number of Times Care Team was Called :"
                              : "Number of Times Nurse Called the Code :"}
                          </p>
                        </Col>
                        <Col
                          className="ml-1 align-center"
                          style={{ margin: "1rem 0 0 0" }}
                          xs={20}
                          sm={20}
                          md={20}
                          xl={20}
                          lg={20}
                        >
                          {Object.entries(
                            generatePreviewData?.careteam_call_count_dict
                          ).map(([key, value]: any, index) => {
                            return (
                              <>
                                <Col
                                  style={{ margin: "0 0 0 1rem" }}
                                  className="align-center-c"
                                >
                                  <p
                                    className="data-title-style"
                                    style={{ fontSize: ".8rem" }}
                                  >
                                    {key}
                                  </p>
                                  <p
                                    className="data-text-style"
                                    style={{
                                      fontSize: ".7rem",
                                      margin: ".5rem 0 0 0",
                                    }}
                                  >
                                    {value}
                                  </p>
                                </Col>
                              </>
                            );
                          })}
                        </Col>
                      </Row>
                    </div>
                  )}
                  {selectedReportID === 7 && (
                    <div className="graph-details-container">
                      <Row>
                        <Col>
                          <p className="data-title-style">
                            Number of Times System Used For Messages:
                          </p>
                        </Col>
                        <Col className="ml-1">
                          <p className="data-text-style">
                            {generatePreviewData?.msg_count}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="data-title-style">
                            Number of Times System Used For Calls :
                          </p>
                        </Col>
                        <Col className="ml-1">
                          <p className="data-text-style">
                            {generatePreviewData?.call_count}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Row>
                <Row justify={"center"} className="" align={"middle"}>
                  <Col lg={18} xl={18} className="report-preview-footer">
                    <p>
                      This Report is generated in the call central applicaton
                    </p>
                    <p>
                      The confidentiality of the data is to be preserved. Any
                      Unauthorized usage of this report data is prohibited under
                      organizational policy
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            confirmPopUp()
          )
        ) : (
          <div style={{ height: "60vh" }}>
            <Spinner />
          </div>
        ))}
    </div>
  );
};

export default BedReport;
