import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import { Col, Modal, Row, Table, Tag } from "antd";
import { MdCancelPresentation } from "react-icons/md";
import { toast } from "react-toastify";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { RootState, appDispatch } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { unAssignNurse,resetUnAssignNurse } from "../../../../features/unitDepartment/unitDepartmentSlice";
import { fetchAssignNurseList } from "../../../../features/assignNurse/assignNurseSlice";
import "./assignNurseTable.scss";
import assignNurseService from "../../../../features/assignNurse/assignNurseService";

interface AssignNurseTableProps {
  isLoading?: boolean;
  assignNurseTableDataApi: any;
}

export const AssignNurseTable = ({
  assignNurseTableDataApi,
  isLoading,
}: AssignNurseTableProps) => {
  const { confirm } = Modal;
  const dispatch = useDispatch<appDispatch>();

  const { nurseSiteListData, isLoading: isLoadingFetchSiteList } = useSelector(
    (state: RootState) => state.unitDepartment.fetchNurseSiteList
  );

  const { token } = useSelector((state: RootState) => state.auth.user);

  const {
    isError: isErrorUnassignNurse,
    isSuccess: isSuccessUnassignNurse,
    message: messageUnassignNurse,
  } = useSelector((state: RootState) => state.unitDepartment.unAssignNurse);

  const [assignedNurseList, setAssignedNurseList] = useState<any>();
  const [selectedUnit, setSelectedUnit] = useState<any>("");
  const [selectedSite, setSelectedSite] = useState<any>("");
  const [isAssignNurseListModalVisible, setIsAssignNurseListModalVisible] =
    useState<boolean>(false);

  const MAX_TAG_COUNT = 1;

  const TableTagList = ({ assignedNurseList, unitID, site }: any) => {
    // render tag with close button if assignedNurseList length === MAX_TAG_COUNT
    // Other wise render tag with first nurse name and count
    const nurseName = `${assignedNurseList[0]?.first_name} ${assignedNurseList[0]?.last_name}`;
    return (
      <div className="assigned-nurse-tag-container">
        {assignedNurseList?.length === MAX_TAG_COUNT ? (
          // Tag with close btn
          <Tag
            className="assigned-nurse-tag"
            closable={false}
            icon={
              <MdCancelPresentation
                onClick={() =>
                  handleUnassignNurse(assignedNurseList[0], unitID, site)
                }
              />
            }
          >
            {nurseName}
          </Tag>
        ) : (
          // Tag with count
          <Tag
            onClick={() => {
              setAssignedNurseList(assignedNurseList);
              setSelectedUnit(unitID);
              setSelectedSite(site);
              setIsAssignNurseListModalVisible(true);
            }}
            className="assigned-nurse-tag"
            style={{ cursor: "pointer" }}
          >
            {`${nurseName} +${assignedNurseList.length - MAX_TAG_COUNT}...`}
          </Tag>
        )}
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "SITE",
      dataIndex: "site",
      key: "site",
      width: 350,
      sorter: (a, b) => a.site.localeCompare(b.site),
      sortIcon: ({ sortOrder }: any) =>
        sortOrder === "ascend" ? <IoIosArrowUp /> : <IoIosArrowDown />,
    },
    {
      title: "UNIT",
      dataIndex: "unit",
      width: 350,
    },
    {
      title: "ASSIGNED NURSES",
      dataIndex: "nurses",
      render: (_, { nurses, id, site }) => (
        <TableTagList assignedNurseList={nurses} unitID={id} site={site} />
      ),
    },
  ];

  const handleUnassignNurse = async (
    selectedNurse: any,
    unitID: any,
    siteName: string
  ) => {
    const site = nurseSiteListData.find((site: any) => site.name === siteName);
    assignNurseService
      .fetchAssignNurseList(
        {
          searchQuery: "",
          siteID: site?.id,
          unitID: unitID,
        },
        token
      )
      .then(async (data) => {
        // finding the selected nurse from the response to check if the nurse is in oncall or not
        const nurse = data?.result[0]?.nurses.find(
          (nurse: any) => nurse.id === selectedNurse?.id
        );

        const nurseName = <b>{`${nurse?.first_name} ${nurse?.last_name}`}</b>;
        const confirmationMessage = (
          <p>
            {nurse?.oncall ? (
              <>{nurseName} has an ongoing shift, do you want to Unassign ?</>
            ) : (
              <>Are you sure you want to Unassign {nurseName} ?</>
            )}
          </p>
        );
        const refreshFetchAssignNurseListApi = () =>
          dispatch(
            fetchAssignNurseList({ searchQuery: "", siteID: "", unitID: "" })
          );

        confirm({
          title: "Unassign nurse confirmation",
          icon: <ExclamationCircleOutlined />,
          centered: true,
          content: confirmationMessage,
          onOk() {
            dispatch(
              unAssignNurse({ unitId: unitID, nurseId: nurse?.id })
            ).then(() => {
              // fetching table data after unassigning a nurse
              refreshFetchAssignNurseListApi();
            });
          },
          // To refresh the table data
          onCancel() {
            refreshFetchAssignNurseListApi();
          },
        });
      });
  };

  // Updating the modal nurse list after unassigning nurse
  useEffect(() => {
    const items = assignNurseTableDataApi?.find(
      (items: any) => items.id === selectedUnit
    );
    setAssignedNurseList(items?.nurses);
    // if the modal nurse list is empty after unassigning nure
    //  the modal wil be closed
    if (!items) {
      setIsAssignNurseListModalVisible(false);
    }
  }, [assignNurseTableDataApi]);

  // Showing toast message for unassigning nurse
  useEffect(() => {
    if (isSuccessUnassignNurse) {
      toast.success(messageUnassignNurse);
    } else if (isErrorUnassignNurse) {
      toast.error(messageUnassignNurse);
    }
    return ()=>{
      dispatch(resetUnAssignNurse())
    }
  }, [isSuccessUnassignNurse, isErrorUnassignNurse]);

  return (
    <div>
      <Table
        loading={isLoading}
        columns={columns}
        pagination={{ position: ["bottomLeft"], pageSize: 10 }}
        dataSource={assignNurseTableDataApi}
        scroll={{ x: "auto" }}
        rowClassName={(_record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
      />
      {/* Assigned nurse list modal */}
      <Modal
        open={isAssignNurseListModalVisible}
        onCancel={() => setIsAssignNurseListModalVisible(false)}
        footer={[null, null]}
        className="assigned-nurse-list-modal"
      >
        <Row>
          <h2>Assigned Nurses</h2>
        </Row>
        {/* List of assigned nurses */}
        <Row className="assigned-nurse-list-container" gutter={[0, 8]}>
          {assignedNurseList?.map((nurse: any) => (
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tag
                className="assigned-nurse-list-tag"
                closeIcon={<MdCancelPresentation />}
                onClose={(e) => {
                  handleUnassignNurse(nurse, selectedUnit, selectedSite);
                }}
              >
                {`${nurse?.first_name} ${nurse?.last_name}`}
              </Tag>
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};
