import { ReactNode, useEffect, FC } from "react"
import { useNavigate } from "react-router-dom"
import useAuth from "../hooks/useAuth"

interface AuthGuardProps {
    children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {

    const navigate = useNavigate()
    const { isLoggedIn, user } = useAuth()

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/login')
        }
    }, [isLoggedIn])

    return <>{isLoggedIn ? children : null}</>
}

export default AuthGuard;