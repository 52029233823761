

import { LiaSearchSolid } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useEffect, useState } from "react";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Input, Modal, Row, Select, Space, Tooltip } from "antd";
import { MdOutlineSubject } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


import { RootState, appDispatch } from "../../app/store";
import SimpleButton from "../../components/buttons/simpleButton/SimpleButton";
import { deleteStatusBoard, fetchStatusBoardList, fetchUnitList, resetDeleteStatusBoard, resetFetchUnitList, resetStatusBoard } from "../../features/statusBoard/statusBoardSlice";
import StatusBoardTable from "./components/statusBoardTable/StatusBoardTable";
import "./statusBoard.scss";


import { useNavigate } from "react-router-dom";
import AddStatusBoardCard from "./components/addStatusBoard/components/AddStatusBoardCard";
import { fetchSiteList, resetFetchSiteList } from "../../features/unitDepartment/unitDepartmentSlice";

/**
 * Render the status board component.
 * 
 * @returns {JSX.Element} The rendered status board component.
 */
const { confirm } = Modal
const StatusBoard = () => {
    const navigate = useNavigate()

    const dispatch = useDispatch<appDispatch>()
    const { statusBoardListData, isLoading, isError, message } = useSelector((state: RootState) => state.statusBoard.fetchStatusBoardList)
    //retrieving states of sites api response (units and departments sites api) 
    const { siteListData } = useSelector((state: RootState) => state.unitDepartment.fetchSiteList);
    const { unitListData, isLoading: isLoadingFetchUnitList } = useSelector((state: RootState) => state.statusBoard.fetchUnitList)
    const { isSuccess: isSuccessDeletStatusBoard, isError: isErrorDeleteStatusBoard, message: messageDeleteStatusBoard } = useSelector((state: RootState) => state.statusBoard.deleteStatusBoard)

    const [isAddStatusModalVisible, setIsAddStatusModalVisible] =
        useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [statusBoardTableDataApi, setStatusBoardTableDataApi] = useState([])
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isIpad, setIsIpad] = useState<boolean>(false)
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    const [siteId, setSiteId] = useState<string>("");
    const [unitId, setUnitId] = useState<string>("");
    const [site, setSite] = useState("All");
    const [unit, setUnit] = useState("All");
    const [selectedStatusBoardKeys, setSelectedStatusBoardKeys] = useState<React.Key[]>([]);
    const [selectedStatusBoardRows, setSelectedStatusBoardRows] = useState<any>([]);
    const [isFirstTimeFiltering, setIsFirstTimeFiltering] = useState(true)
    const [lastFilterValues, setLastFilterValues] = useState({
        siteId: "",
        unitId: "",
        site: "All",
        unit: "All",
    });

    //handles searching for status board 
    const handleStatusboardSearch = () => {
        dispatch(fetchStatusBoardList({ searchQuery, siteId, unitId }))
    }
    //handle site change in status board filter
    const handleSiteChange = (value: string) => {
        dispatch(fetchUnitList(value))
        setSiteId(value)
        setSite(value);
        setUnitId("")
        setUnit("All")
    };

    //handle unit change in status board filter
    const handleUnitChange = (value: string) => {
        setUnitId(value)
        setUnit(value)
    }


    //handles clear filters  
    const handleClearFilters = () => {
        setSiteId("")
        setUnitId("")
        setSite('All')
        setUnit('All')
    }

    //handle to clear last filter values
    const handleClearLastFilterValues = () => {
        setLastFilterValues({
            siteId: "",
            unitId: "",
            site: "All",
            unit: "All",
        })
    }

    //handle filter modal submit
    const handleFilterModalSubmit = () => {
        setIsFirstTimeFiltering(false)
        dispatch(fetchStatusBoardList({ searchQuery, siteId, unitId }))
        setPopoverVisible(false)
        setLastFilterValues({ siteId, unitId, site, unit });
    }

    //handle cancel filer modal
    const handleCancelFilterModal = () => {
        if (isFirstTimeFiltering) {
            handleClearFilters()
        } else {
            // Reset filter values to the last applied values
            setSiteId(lastFilterValues.siteId);
            setUnitId(lastFilterValues.unitId);
            setSite(lastFilterValues.site);
            setUnit(lastFilterValues.unit);
        }
        //to get the last selected filter unit value from server
        dispatch(fetchUnitList(lastFilterValues.siteId))
        setPopoverVisible(false)
    }

    //handle delete selected status board
    const handleDeleteSelectedStatusBoard = () => {

        // Extract names from selectedStatusBoardRows
        const selectedStatusBoardNames = selectedStatusBoardRows.map((row: any) => row.name).join(', ');

        // Display confirmation modal
        confirm({
            title: "Delete  Status Board confirmation",
            icon: <ExclamationCircleOutlined />,
            centered: true,
            content: (
                <p>
                    Are you sure you want to delete  <b>{selectedStatusBoardNames}</b>  Status Board ?
                </p>
            ),
            onOk() {
                const payload = {
                    status_board_id: selectedStatusBoardKeys
                }
                dispatch(deleteStatusBoard(payload))
            },
            // Do nothing if user cancels
            onCancel() { },
        });

    }

    const handleResize = () => {
        setIsMobile(window.innerWidth < 478);
        setIsIpad(window.innerWidth >= 768 && window.innerWidth <= 1024 && (window.innerHeight < window.innerWidth || window.innerHeight > window.innerWidth))
    };

    useEffect(() => {


        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    // Update the statusBoardTableDataApi state with the new statusBoardListData
    useEffect(() => {
        setStatusBoardTableDataApi(statusBoardListData)
    }, [statusBoardListData])

    const handleAddStatusBoard = () => {
        setIsAddStatusModalVisible(true);

        //to clear row selection and set selected row key as emtpy
        setSelectedStatusBoardKeys([])
        setSelectedStatusBoardRows([])
    }

    const handleAddStatusBoardClose = () => {
        setIsAddStatusModalVisible(false);
    };


    // display toast message if api fetching status board fails 
    useEffect(() => {
        if (isError) {
            toast.error(message)
        }
        return () => {
            dispatch(resetStatusBoard())
        }
    }, [isError])


    //fire api for fetching status board list , site list and unit list
    useEffect(() => {
        dispatch(fetchStatusBoardList({ searchQuery, siteId, unitId }))
        dispatch(fetchSiteList());
        dispatch(fetchUnitList(siteId))

        return () => {
            dispatch(resetStatusBoard())
            dispatch(resetFetchSiteList());
            dispatch(resetFetchUnitList())
        }
    }, [])


    //handles delete selected status board api response 
    useEffect(() => {
        if (isSuccessDeletStatusBoard) {
            toast.success(messageDeleteStatusBoard)
            setSelectedStatusBoardKeys([])
            dispatch(fetchStatusBoardList({ searchQuery, siteId, unitId }))
        } else if (isErrorDeleteStatusBoard) {
            toast.error(messageDeleteStatusBoard)
        }
        return () => {
            dispatch(resetDeleteStatusBoard())
        }
    }, [isSuccessDeletStatusBoard, isErrorDeleteStatusBoard])

    const isDeleteButtonDisabled = () => {
        return (selectedStatusBoardKeys && selectedStatusBoardKeys.length === 0) || selectedStatusBoardKeys === undefined
    };

    return (
        <div className="statusBoard-container">
            {/* Search header */}
            <Row>
                <Col xs={24} md={24}>
                    <div className="statusBoard-header">Search by name</div>
                </Col>
            </Row>


            <Row gutter={[16, 16]}>

                {/* Search bar & button*/}
                <Col xs={24} sm={15} md={12} lg={13} xl={15}>
                    <Row>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                            }}
                        >
                            <Col xs={16} md={18} lg={24} span={10}>
                                <span className="search-bar">
                                    <Input
                                        placeholder="Search"
                                        prefix={<LiaSearchSolid className="search-bar-icon" />}
                                        onChange={(e) => { setSearchQuery(e.target.value) }}
                                    />
                                </span>
                            </Col>
                            <Col xs={8} md={6} lg={4}>
                                <span>
                                    <SimpleButton
                                        text={isMobile ? "" : "Search"}
                                        color="primary"
                                        icon={<SearchOutlined />}
                                        onClick={handleStatusboardSearch}
                                    />
                                </span>
                            </Col>
                        </div>

                    </Row>
                </Col>

                {/* Add, Delete & Filter button*/}
                <Col xs={24} sm={9} md={12} lg={11} xl={9}>
                    <Row justify="end" gutter={10} className="add-statusBoard-sec ">
                        <Space direction="horizontal" align="center">

                            {/* Add new status board button */}
                            <Tooltip title={isMobile || isIpad ? "" : 'Add New Status Board'} placement="bottomRight" >
                                <span >
                                    <SimpleButton
                                        icon={<PlusOutlined className="add-statusBoard-icon" />}
                                        text={isMobile ? "" : "Add"}
                                        color="secondary"
                                        onClick={handleAddStatusBoard}
                                    />
                                </span>
                            </Tooltip>
                            <AddStatusBoardCard open={isAddStatusModalVisible}
                                onClose={handleAddStatusBoardClose}
                                handleClearFilters={handleClearFilters}
                                handleClearLastFilterValues={handleClearLastFilterValues}
                                searchQuery={searchQuery} />

                            {/*Delete button */}
                            <SimpleButton
                                text={isMobile ? "" : "Delete"}
                                color={isDeleteButtonDisabled() ? "danger-disabled" : "danger-default"}
                                disabled={isDeleteButtonDisabled()}
                                icon={<RiDeleteBin6Line color={isDeleteButtonDisabled() ? "danger-disabled" : "danger-default"} />}
                                onClick={handleDeleteSelectedStatusBoard}
                            />

                            {/*filter button */}
                            <Button
                                icon={<MdOutlineSubject />}
                                className="filter-button"
                                onClick={() => {
                                    setPopoverVisible(true);
                                    if (selectedStatusBoardKeys) {
                                        setSelectedStatusBoardKeys([]);
                                    }
                                }}
                            >
                                {isMobile ? "" : "Filter"}
                            </Button>

                        </Space>

                    </Row>
                </Col>

            </Row>

            <Divider />

            {/* Filter modal */}
            <div style={{ paddingBottom: "10px", }}>
                <Modal
                    width={300}
                    centered={true}
                    closeIcon={false}
                    open={isPopoverVisible}
                    className="sb-filter-modal"
                    footer={[
                        <Button key="cancel" onClick={handleCancelFilterModal}>
                            Cancel
                        </Button>,
                        <Button
                            key="apply"
                            type="primary"
                            // style={{ margin: "0 1rem 1rem 0" }}
                            onClick={handleFilterModalSubmit}
                        >
                            OK
                        </Button>,
                    ]}
                >
                    <div className="popover-content" style={{ padding: "5px" }}>
                        <div className="popover-header">
                            <h4>Filter</h4>
                            <Button onClick={handleClearFilters} className="clear-all-button">
                                Clear All
                            </Button>
                        </div>
                        <div className="popover-item">
                            <label className="label-text">By Site</label>
                            <Select
                                virtual={false}
                                value={site}
                                onChange={handleSiteChange}
                                style={{ width: "100%" }}
                            >
                                <Select.Option value="">All</Select.Option>
                                {siteListData?.map((option: any) =>
                                    <Select.Option key={option?.id} value={option?.id}>
                                        {option?.name}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>
                        <div className="popover-item">
                            <label className="label-text">By Unit</label>
                            <Select
                                virtual={false}
                                value={unit}
                                onChange={handleUnitChange}
                                style={{ width: "100%" }}
                                loading={isLoadingFetchUnitList}
                            >
                                <Select.Option value="">All</Select.Option>
                                {unitListData?.map((option: any) =>
                                    <Select.Option key={option?.id} value={option?.id}>
                                        {option?.name}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>
                    </div>
                </Modal>
            </div>

            {/* Status board table */}
            <StatusBoardTable isLoading={isLoading} statusBoardTableDataApi={statusBoardTableDataApi} selectedStatusBoardKeys={selectedStatusBoardKeys} setSelectedStatusBoardKeys={setSelectedStatusBoardKeys} isMobile={isMobile} setSelectedStatusBoardRows={setSelectedStatusBoardRows}
                handleClearFilters={handleClearFilters}
                handleClearLastFilterValues={handleClearLastFilterValues}
                filterSite={siteId}
                filterUnit={unitId}
                searchQuery={searchQuery} />

        </div>
    )
};

export default StatusBoard;