import { configureStore } from "@reduxjs/toolkit";
import {
  authSlice,
  LoginRecoverSlice,
  forgotUsernameSlice,
  resetPasswordSlice,
  privacyPolicySlice,
  termsConditionSlice,
} from "../features/login/index";
import homeReducer from "../features/home/homeSlice";
import sitesReducer from "../features/sites/sitesSlice";
import specialityReducer from "../features/speciality/specialitySlice";
import rolesReducer from "../features/userRoles/userRolesSlice";
import usersReducer from "../features/users/usersSlice";
import groupsReducer from "../features/groups/groupsSlice";
import shiftRuleReducer from "../features/shiftRule/shiftRuleSlice";
import unitDepartmentReducer from "../features/unitDepartment/unitDepartmentSlice";
import statusBoardReducer from "../features/statusBoard/statusBoardSlice";
import statusBoardColumnReducer from "../features/statusBoardColumn/statusBoardColumnSlice";
import supportStaffReducer from "../features/supportStaff/supportStaffSlice";
import careTeamReducer from "../features/careTeam/careTeamSlice";
import emsReportManagementReducer from "../features/emsReportManagemnet/emsReportManagementSlice";
import scheduleReportReducer from "../features/scheduleReport/scheduleReportSlice";
import bedReportReducer from "../features/bedReport/bedReportSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import assignNurseReducer from "../features/assignNurse/assignNurseSlice";
import publicHolidayReducer from "../features/publicHoliday/publicHolidaySlice";
import helpReducer from "../features/help/helpSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    loginRecover: LoginRecoverSlice.reducer,
    forgotUsername: forgotUsernameSlice.reducer,
    resetPassword: resetPasswordSlice.reducer,
    privacyPolicy: privacyPolicySlice.reducer,
    termsCondition: termsConditionSlice.reducer,
    home: homeReducer,
    sites: sitesReducer,
    speciality: specialityReducer,
    roles: rolesReducer,
    users: usersReducer,
    groups: groupsReducer,
    shiftRule: shiftRuleReducer,
    unitDepartment: unitDepartmentReducer,
    statusBoard: statusBoardReducer,
    statusBoardColumn: statusBoardColumnReducer,
    supportStaff: supportStaffReducer,
    careTeam: careTeamReducer,
    emsReportManagement: emsReportManagementReducer,
    scheduleReport: scheduleReportReducer,
    bedReport: bedReportReducer,
    dashboard: dashboardReducer,
    assignNurse: assignNurseReducer,
    publicHoliday: publicHolidayReducer,
    help: helpReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type appDispatch = typeof store.dispatch;
export default store;
