import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Space,
} from "antd";
import { LiaSearchSolid } from "react-icons/lia";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { MdOutlineSubject } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import SimpleButton from "../../components/buttons/simpleButton/SimpleButton";
import { AssignNurseTable } from "./components/assignNurseTable/AssignNurseTable";
import AssignNurseModal from "./components/assignNurseModal/AssignNurseModal";
import {
  fetchNurseSiteList,
  fetchNurseUnit,
  resetFetchNurseUnit,
} from "../../features/unitDepartment/unitDepartmentSlice";
import { RootState, appDispatch } from "../../app/store";
import { fetchAssignNurseList } from "../../features/assignNurse/assignNurseSlice";
import "./assignNurse.scss";

export const AssignNurse = () => {
  const dispatch = useDispatch<appDispatch>();

  const { nurseSiteListData, isLoading: isLoadingFetchSiteList } = useSelector(
    (state: RootState) => state.unitDepartment.fetchNurseSiteList
  );

  const { nurseUnitListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchNurseUnit
  );

  const { assignNurseListData, isLoading: isTableLoading } = useSelector(
    (state: RootState) => state.assignNurse.fetchAssignNurseList
  );

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedSite, setSelectedSite] = useState<any>("");
  const [selectedUnit, setsSelectedUnit] = useState<any>("");
  const [isDataFiltered, setIsDataFiltered] = useState<boolean>(false);
  const [previousSelectedData, setPreviousSelectedData] = useState({
    unit: "",
    site: "",
  });

  const [isAssignNurseModalVisible, setIsAssignNurseModalVisible] =
    useState<boolean>(false);
  const [isFilterModalVisible, setIsFilterModalVisible] =
    useState<boolean>(false);

  const [isIpad, setIsIpad] = useState<boolean>(false);
  const [isMobile, setIsmobile] = useState<boolean>(window.innerWidth < 478);

  const handleAssignNurseSearch = () => {
    dispatch(fetchAssignNurseList({ searchQuery, siteID: "", unitID: "" }));
  };

  const handleAddAssignedNurse = () => {
    setIsAssignNurseModalVisible(true);
  };

  const handleCloseAssignedNurseModal = () => {
    setIsAssignNurseModalVisible(false);
  };

  const handleClearFilters = () => {
    setSelectedSite("");
    setsSelectedUnit("");
    dispatch(resetFetchNurseUnit());
  };

  const handleSiteChange = (siteID: any) => {
    setSelectedSite(siteID);
    setsSelectedUnit("")
    if (siteID) {
      dispatch(fetchNurseUnit({ searchquery: "", sid: siteID }));
    }
  };

  const handleUnitChange = (unitID: any) => {
    setsSelectedUnit(unitID);
  };

  const handleFilterModalSubmit = () => {
    // setting the current selected unit and site into PreviousSelectedData state
    setPreviousSelectedData({
      unit: selectedUnit,
      site: selectedSite,
    });
    setIsFilterModalVisible(false);
    dispatch(
      fetchAssignNurseList({
        searchQuery: "",
        siteID: selectedSite,
        unitID: selectedUnit,
      })
    );
    //if selected unit and selected site is all then setting DataFiltered into false
    if (!selectedUnit && !selectedSite) {
      setIsDataFiltered(false);
    } 
    else {
      setIsDataFiltered(true);
    }

  };

  const handleCancelFilterModal = () => {
    // If data is filtered setting the filter modal selection values into
    // previous selected values at the time of filtered
    if (isDataFiltered) {
      setSelectedSite(previousSelectedData.site);
      setsSelectedUnit(previousSelectedData.unit);
      dispatch(fetchNurseUnit({ searchquery: "", sid: previousSelectedData.site}));
    } else {
      setSelectedSite("");
      setsSelectedUnit("");
      dispatch(resetFetchNurseUnit());
    }
    setIsFilterModalVisible(false);
  };

  const handleResize = () => {
    setIsmobile(window.innerWidth < 478);
    setIsIpad(
      window.innerWidth >= 768 &&
        window.innerWidth <= 1024 &&
        (window.innerHeight < window.innerWidth ||
          window.innerHeight > window.innerWidth)
    );
  };

  useEffect(() => {
    dispatch(fetchNurseSiteList());
    dispatch(fetchAssignNurseList({ searchQuery: "", siteID: "", unitID: "" }));
  }, []);

  // checking device is mobile or not
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="assign-nurse-container">
      {/* Search header */}
      <Row>
        <Col xs={24} md={24}>
          <div className="assign-nurse-header">Search by nurse name</div>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        {/* Search bar & button*/}
        <Col xs={24} sm={15} md={12} lg={13} xl={15}>
          <Row>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
              }}
            >
              <Col xs={16} md={18} lg={24} span={10}>
                <span className="search-bar">
                  <Input
                    placeholder="Search"
                    prefix={<LiaSearchSolid className="search-bar-icon" />}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                  />
                </span>
              </Col>
              <Col xs={8} md={6} lg={4}>
                <span>
                  <SimpleButton
                    text={isMobile ? "" : "Search"}
                    color="primary"
                    icon={<SearchOutlined />}
                    onClick={handleAssignNurseSearch}
                  />
                </span>
              </Col>
            </div>
          </Row>
        </Col>

        {/* Add, Delete & Filter button*/}
        <Col xs={24} sm={9} md={12} lg={11} xl={9}>
          <Row justify="end" gutter={10} className="add-assign-nurse-sec">
            <Space direction="horizontal" align="center">
              <span>
                <SimpleButton
                  icon={<PlusOutlined className="add-assign-nurse-icon" />}
                  text={isMobile ? "" : "Assign Nurse"}
                  color="secondary"
                  onClick={handleAddAssignedNurse}
                />
              </span>

              <AssignNurseModal
                onClose={handleCloseAssignedNurseModal}
                open={isAssignNurseModalVisible}
              />

              {/*filter button */}
              <Button
                icon={<MdOutlineSubject />}
                className="filter-button"
                onClick={() => {
                  setIsFilterModalVisible(true);
                }}
              >
                {isMobile ? "" : "Filter"}
              </Button>
            </Space>
          </Row>
        </Col>
      </Row>

      <Divider />

      {/* Filter modal */}
      <div style={{ paddingBottom: "10px" }}>
        <Modal
          width={300}
          centered={true}
          closeIcon={false}
          open={isFilterModalVisible}
          className="an-filter-modal"
          footer={[
            <Button key="cancel" onClick={handleCancelFilterModal}>
              Cancel
            </Button>,
            <Button
              key="apply"
              type="primary"
              onClick={handleFilterModalSubmit}
            >
              OK
            </Button>,
          ]}
        >
          <div className="popover-content" style={{ padding: "5px" }}>
            <div className="popover-header">
              <h4>Filter</h4>
              <Button onClick={handleClearFilters} className="clear-all-button">
                Clear All
              </Button>
            </div>
            <div className="popover-item">
              <label className="label-text">By Site</label>
              <Select
                value={selectedSite}
                onChange={handleSiteChange}
                style={{ width: "100%" }}
                virtual={false}
                loading={isLoadingFetchSiteList}
                defaultValue={""}
                options={[
                  { value: "", label: "All" },
                  ...nurseSiteListData?.map((option: any) => ({
                    value: option?.id,
                    label: option?.name,
                  })),
                ]}
              />
            </div>
            <div className="popover-item">
              <label className="label-text">By Unit</label>
              <Select
                value={selectedUnit}
                onChange={handleUnitChange}
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                virtual={false}
                defaultValue={""}
              >
                <Select.Option key={""} value={""}>
                  All
                </Select.Option>
                {nurseUnitListData
                  ?.filter((unit: any) => unit.type === 2)
                  ?.map((unit: any) => (
                    <Select.Option key={unit.id} value={unit.id}>
                      {unit.name}
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </div>
        </Modal>
      </div>

      {/* Assigned nurse table */}
      <AssignNurseTable
        assignNurseTableDataApi={assignNurseListData}
        isLoading={isTableLoading}
      />
    </div>
  );
};
