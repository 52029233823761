import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import homeService from "./homeService";
import { UserHome } from "../../pages/home/home.model";
import { RootState } from "../../app/store";

// interface User {
//   accessToken: string;
// }
interface HomeState {
  changePassword: {
    changePasswordData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  logout: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  fetchSidebarAndCardItems: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
    fetchSidebarAndCardItemsData: any | null;
  };
  fetchAboutUs: {
    aboutUsData: any | null;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  auth: {
    user: {
      token: string;
    };
  };
}

const initialState: HomeState = {
  changePassword: {
    changePasswordData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  logout: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchSidebarAndCardItems: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
    fetchSidebarAndCardItemsData: [],
  },
  fetchAboutUs: {
    aboutUsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  auth: {
    user: {
      token: "",
    },
  },
};

//to change password
export const changePassword = createAsyncThunk("home/changePassword", async (userHome: UserHome, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user && state.auth.user.token;

    const response = await homeService.ChangePassword(userHome, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const logout = createAsyncThunk("home/logout", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user && state.auth.user.token;

    const response = await homeService.logout(token);
    if (response.isSuccess) {
      localStorage.removeItem("user");
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Logout User
// export const logout = createAsyncThunk("home/logout", async () => {
//   await homeService.logout();
// });

// to fetch sidebar and cards items
export const fetchSidebarAndCardItems = createAsyncThunk("home/fetchSidebarAndCardItems", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user && state.auth.user.token;

    const response = await homeService.fetchSidebarAndCardItems(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch about us
export const fetchAboutUs = createAsyncThunk("home/fetchAboutUs", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user && state.auth.user.token;

    const response = await homeService.fetchAboutUs(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    resetChangePassword: (state) => {
      state.changePassword.isLoading = false;
      state.changePassword.isError = false;
      state.changePassword.isSuccess = false;
      state.changePassword.changePasswordData = [];
    },
    resetLogout: (state) => {
      state.logout.isLoading = false;
      state.logout.isError = false;
      state.logout.isSuccess = false;
    },
    resetFetchSidebarAndCardItems: (state) => {
      state.fetchSidebarAndCardItems = initialState.fetchSidebarAndCardItems;
    },
    resetAboutUs: (state) => {
      state.fetchAboutUs = initialState.fetchAboutUs;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state, action: PayloadAction<any>) => {
        state.changePassword.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action: PayloadAction<any>) => {
        state.changePassword.isLoading = false;
        state.changePassword.isSuccess = true;
        state.changePassword.changePasswordData = action.payload;
      })
      .addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
        state.changePassword.isLoading = false;
        state.changePassword.isError = true;
        state.changePassword.message = action.payload;
        state.changePassword.changePasswordData = [];
      })
      .addCase(logout.pending, (state, action: PayloadAction<any>) => {
        state.logout.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action: PayloadAction<any>) => {
        state.changePassword.isLoading = false;
        state.changePassword.isSuccess = true;
        // state.logout = action.payload;
      })
      .addCase(logout.rejected, (state, action: PayloadAction<any>) => {
        state.changePassword.isLoading = false;
        state.changePassword.isError = true;
        state.changePassword.message = action.payload;
      })
      .addCase(fetchSidebarAndCardItems.pending, (state, action: PayloadAction<any>) => {
        state.fetchSidebarAndCardItems.isLoading = true;
      })
      .addCase(fetchSidebarAndCardItems.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSidebarAndCardItems.isLoading = false;
        state.fetchSidebarAndCardItems.isSuccess = true;
        state.fetchSidebarAndCardItems.fetchSidebarAndCardItemsData = action.payload.result;
      })
      .addCase(fetchSidebarAndCardItems.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSidebarAndCardItems.isLoading = false;
        state.fetchSidebarAndCardItems.isError = true;
        state.fetchSidebarAndCardItems.message = action.payload;
        state.fetchSidebarAndCardItems.fetchSidebarAndCardItemsData = [];
      })
      .addCase(fetchAboutUs.pending, (state, action: PayloadAction<any>) => {
        state.fetchAboutUs.isLoading = true;
      })
      .addCase(fetchAboutUs.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchAboutUs.isLoading = false;
        state.fetchAboutUs.isSuccess = true;
        state.fetchAboutUs.aboutUsData = action.payload.result;
      })
      .addCase(fetchAboutUs.rejected, (state, action: PayloadAction<any>) => {
        state.fetchAboutUs.isLoading = false;
        state.fetchAboutUs.isError = true;
        state.fetchAboutUs.message = action.payload;
        state.fetchAboutUs.aboutUsData = [];
      });
  },
});

export const { resetChangePassword, resetLogout, resetFetchSidebarAndCardItems, resetAboutUs } = homeSlice.actions;
export default homeSlice.reducer;
