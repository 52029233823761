import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import { statusBoardColumnService } from "./statusBoardColumnService";

interface statusBoardColumnState {
  fetchStatusBoardColumnList: {
    statusBoardColumnListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  deleteStatusBoardColumn: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  createStatusBoardColumn: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateStatusBoardColumn: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchStatusBoardColumnDetails: {
    statusBoardColumnDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchValue: {
    valueData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: statusBoardColumnState = {
  fetchStatusBoardColumnList: {
    statusBoardColumnListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  deleteStatusBoardColumn: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  createStatusBoardColumn: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateStatusBoardColumn: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchStatusBoardColumnDetails: {
    statusBoardColumnDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchValue: {
    valueData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

export const fetchStatusBoardColumnList = createAsyncThunk(
  "statusBoardColumn/fetchStatusBoardColumnList",
  async ({ searchQuery, siteId, typeId }: { searchQuery: string; siteId: string; typeId: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardColumnService.fetchStatusBoardColumnList(
        { searchQuery, siteId, typeId },
        token
      );
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteStatusBoardColumn = createAsyncThunk(
  "statusBoardColumn/deleteStatusBoardColumn",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardColumnService.deleteStatusBoardColumn(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createStatusBoardColumn = createAsyncThunk(
  "statusBoardColumn/createStatusBoardColumn",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardColumnService.createStatusBoardColumn(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateStatusBoardColumn = createAsyncThunk(
  "statusBoardColumn/updateStatusBoardColumn",
  async ({ statusBoardColumnId, payload }: { statusBoardColumnId: any; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardColumnService.updateStatusBoardColumn({ statusBoardColumnId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchStatusBoardColumnDetails = createAsyncThunk(
  "statusBoardColumn/fetchStatusBoardColumnDetails",
  async (statusBoardColumnId: number, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await statusBoardColumnService.fetchStatusBoardColumnDetails(statusBoardColumnId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchValue = createAsyncThunk("statusBoardColumn/fetchValue", async (typeId: string, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await statusBoardColumnService.fetchValue(typeId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const statusBoardColumnSlice = createSlice({
  name: "statusBoardColumn",
  initialState,
  reducers: {
    resetFetchStatusBoardColumnList: (state) => {
      state.fetchStatusBoardColumnList = initialState.fetchStatusBoardColumnList;
    },
    resetDeleteStatusBoardColumn: (state) => {
      state.deleteStatusBoardColumn = initialState.deleteStatusBoardColumn;
    },
    resetCreateStatusBoardColumn: (state) => {
      state.createStatusBoardColumn = initialState.createStatusBoardColumn;
    },
    resetUpdateStatusBoardColumn: (state) => {
      state.updateStatusBoardColumn = initialState.updateStatusBoardColumn;
    },
    resetFetchStatusBoardColumnDetails: (state) => {
      state.fetchStatusBoardColumnDetails = initialState.fetchStatusBoardColumnDetails;
    },
    resetFetchValue: (state) => {
      state.fetchValue = initialState.fetchValue;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchStatusBoardColumnList.pending, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardColumnList.isLoading = true;
      })
      .addCase(fetchStatusBoardColumnList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardColumnList.isLoading = false;
        state.fetchStatusBoardColumnList.isSuccess = true;
        state.fetchStatusBoardColumnList.message = action.payload.message;
        state.fetchStatusBoardColumnList.statusBoardColumnListData = action.payload.result;
      })
      .addCase(fetchStatusBoardColumnList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardColumnList.isLoading = false;
        state.fetchStatusBoardColumnList.isError = true;
        state.fetchStatusBoardColumnList.message = action.payload;
      })

      .addCase(deleteStatusBoardColumn.pending, (state, action: PayloadAction<any>) => {
        state.deleteStatusBoardColumn.isLoading = true;
      })
      .addCase(deleteStatusBoardColumn.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteStatusBoardColumn.isLoading = false;
        state.deleteStatusBoardColumn.isSuccess = true;
        state.deleteStatusBoardColumn.message = action.payload.message;
      })
      .addCase(deleteStatusBoardColumn.rejected, (state, action: PayloadAction<any>) => {
        state.deleteStatusBoardColumn.isLoading = false;
        state.deleteStatusBoardColumn.isError = true;
        state.deleteStatusBoardColumn.message = action.payload;
      })

      .addCase(createStatusBoardColumn.pending, (state, action: PayloadAction<any>) => {
        state.createStatusBoardColumn.isLoading = true;
      })
      .addCase(createStatusBoardColumn.fulfilled, (state, action: PayloadAction<any>) => {
        state.createStatusBoardColumn.isLoading = false;
        state.createStatusBoardColumn.isSuccess = true;
        state.createStatusBoardColumn.message = action.payload.message;
      })
      .addCase(createStatusBoardColumn.rejected, (state, action: PayloadAction<any>) => {
        state.createStatusBoardColumn.isLoading = false;
        state.createStatusBoardColumn.isError = true;
        state.createStatusBoardColumn.message = action.payload;
      })

      .addCase(updateStatusBoardColumn.pending, (state, action: PayloadAction<any>) => {
        state.updateStatusBoardColumn.isLoading = true;
      })
      .addCase(updateStatusBoardColumn.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateStatusBoardColumn.isLoading = false;
        state.updateStatusBoardColumn.isSuccess = true;
        state.updateStatusBoardColumn.message = action.payload.message;
      })
      .addCase(updateStatusBoardColumn.rejected, (state, action: PayloadAction<any>) => {
        state.updateStatusBoardColumn.isLoading = false;
        state.updateStatusBoardColumn.isError = true;
        state.updateStatusBoardColumn.message = action.payload;
      })

      .addCase(fetchStatusBoardColumnDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardColumnDetails.isLoading = true;
      })
      .addCase(fetchStatusBoardColumnDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardColumnDetails.isLoading = false;
        state.fetchStatusBoardColumnDetails.isSuccess = true;
        state.fetchStatusBoardColumnDetails.message = action.payload.message;
        state.fetchStatusBoardColumnDetails.statusBoardColumnDetailsData = action.payload.result;
      })
      .addCase(fetchStatusBoardColumnDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchStatusBoardColumnDetails.isLoading = false;
        state.fetchStatusBoardColumnDetails.isError = true;
        state.fetchStatusBoardColumnDetails.message = action.payload;
      })

      .addCase(fetchValue.pending, (state, action: PayloadAction<any>) => {
        state.fetchValue.isLoading = true;
      })
      .addCase(fetchValue.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchValue.isLoading = false;
        state.fetchValue.isSuccess = true;
        state.fetchValue.message = action.payload.message;
        state.fetchValue.valueData = action.payload.result;
      })
      .addCase(fetchValue.rejected, (state, action: PayloadAction<any>) => {
        state.fetchValue.isLoading = false;
        state.fetchValue.isError = true;
        state.fetchValue.message = action.payload;
      });
  },
});

export const {
  resetFetchStatusBoardColumnList,
  resetDeleteStatusBoardColumn,
  resetCreateStatusBoardColumn,
  resetUpdateStatusBoardColumn,
  resetFetchStatusBoardColumnDetails,
  resetFetchValue,
} = statusBoardColumnSlice.actions;

export default statusBoardColumnSlice.reducer;
