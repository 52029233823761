import { Empty } from 'antd';

const EmptyPage = () =>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{ height: 100 }}
            description={
                <span style={{ fontSize: '1.2rem' }}>
                    You have no Permissions enabled.<br /> Please Contact Admin!
                </span>
            }
        >

        </Empty>
    </div>

export default EmptyPage