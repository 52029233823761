import { API_ENDPOINTS, API_URL, DASHBOARD_APP_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";
import {
  CardListType,
  providerPayloadType,
  SendMessageProviderType,
  SystemUsageStatsType,
  UserListType,
} from "../../pages/dashboard/types";

const fetchSiteList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_SITE_LIST}`, config);
  return response.data;
};

const fetchCardList = async (payload: CardListType, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_CARD_GROUP_LIST}`,
    payload,
    config
  );
  return response.data;
};

const fetchUserProfile = async (profileId: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_USER_PROFILE}?id=${profileId}`,

    config
  );
  return response.data;
};

const fetchUserList = async (payload: UserListType, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.DASHBOARD.FETCH_USER_LIST}`, payload, config);
  return response.data;
};

const fetchRoleList = async (token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(`${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_ROLE_LIST}`, config);
  return response.data;
};

const fetchProviderList = async (payload: providerPayloadType, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.DASHBOARD.FETCH_PROVIDER_LIST}?page=${payload.pageNumber}`,
    payload?.payload,
    config
  );
  return response.data;
};

const sendProviderMessage = async (payload: SendMessageProviderType, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.DASHBOARD.SEND_PROVIDER_MESSAGE}`,
    payload,
    config
  );
  return response.data;
};

const fetchTimezone = async (userId: number | undefined, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_TIMEZONE}?user_id=${userId ?? ""}`,
    config
  );
  return response.data;
};

const fetchGroupList = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.DASHBOARD.FETCH_GROUP_LIST}`, payload, config);
  return response.data;
};

const fetchSystemUsageStats = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.DASHBOARD.FETCH_SYSTEM_USAGE_CHART}`,
    payload,
    config
  );
  return response.data;
};

const fetchStatusBoardChart = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_STATUS_BOARD_CHART}`,
    payload,
    config
  );
  return response.data;
};

const fetchBedOccupancyRate = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_BOR}`,
    payload,
    config
  );
  return response.data;
};

const fetchPatientChart = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_PATIENT_CHART}`,
    payload,
    config
  );
  return response.data;
};

const fetchEmergencyChart = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_EMERGENCY_CHART}`,
    payload,
    config
  );
  return response.data;
};

const fetchSitesByPatientChart = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${DASHBOARD_APP_URL}${API_ENDPOINTS.DASHBOARD.FETCH_SITES_BY_PATIENT_CHART}`,
    payload,
    config
  );
  return response.data;
};

const dashboardService = {
  fetchSiteList,
  fetchCardList,
  fetchUserProfile,
  fetchUserList,
  fetchRoleList,
  fetchProviderList,
  sendProviderMessage,
  fetchTimezone,
  fetchGroupList,
  fetchSystemUsageStats,
  fetchStatusBoardChart,
  fetchBedOccupancyRate,
  fetchPatientChart,
  fetchEmergencyChart,
  fetchSitesByPatientChart,
};

export default dashboardService;
