import { Modal } from "antd";
import { FC } from "react";
import "./customModal.scss";

interface CustomModalProps {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const CustomModal: FC<CustomModalProps> = ({ visible, onClose, children }) => {
  return (
    <Modal open={visible} onCancel={onClose} footer={null} maskClosable={false} closeIcon={null} keyboard={false}>
      {children}
    </Modal>
  );
};

export default CustomModal;
