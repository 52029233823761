import CryptoJS from 'crypto-js';
import { CRYPTO_KEY } from "../../constants";

//const CRYPTO_KEY = "7458dkfikfkfk";

const setObjStorage = async (key: string, value: string) => {
    const encryptedData = await CryptoJS.AES.encrypt(value, CRYPTO_KEY as string).toString();
    localStorage.setItem(key, encryptedData);
};

const getObjStorage = (key: string) => {
    const storedData = localStorage.getItem(key);
    let decryptedData = null;

    if (storedData) {
        decryptedData = CryptoJS.AES.decrypt(storedData as string, CRYPTO_KEY as string).toString(CryptoJS.enc.Utf8);
    }

    return decryptedData;
};

export const StorageService = {
    setObjStorage,
    getObjStorage,
};
