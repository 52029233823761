import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

const fetchStatusBoardList = async (
  { searchQuery, siteId, unitId }: { searchQuery: string; siteId: number; unitId: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD.FETCH_STATUS_BOARD_LIST}?q=${searchQuery}&site_id=${siteId}&unit_id=${unitId}`,
    config
  );
  return response.data;
};

//api to add a new status board
const addStatusBoard = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD.ADD_STATUS_BOARD}`,
    payload,
    config
  );
  return response.data;
};

//api to fetch bed details
const fetchStatusBoardDetails = async (statusId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD.FETCH_STATUS_BOARD_DETAILS}${statusId}/`,
    config
  );
  return response.data;
};

const updateStatusBoard = async (
  { statusId, payload }: { statusId: number; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD.UPDATE_STATUS_BOARD}${statusId}/`,
    payload,
    config
  );
  return response.data;
};

const fetchUnitList = async (siteId: string, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.STATUS_BOARD.FETCH_UNIT_LIST}?site=${siteId}`,
    config
  );
  return response.data;
};

const deleteStatusBoard = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.STATUS_BOARD.DELETE_STATUS_BOARD}`, {
    ...config,
    data: payload,
  });
  return response.data;
};

const statusBoardService = {
  fetchStatusBoardList,
  addStatusBoard,
  fetchStatusBoardDetails,
  updateStatusBoard,
  fetchUnitList,
  deleteStatusBoard,
};

export default statusBoardService;
