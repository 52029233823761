import {
  Col,
  Form,
  Layout,
  Row,
  Select,
  Space,
  Tag,
  Divider,
  Checkbox,
} from "antd";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RootState, appDispatch } from "../../../../app/store";
import SimpleButton from "../../../../components/buttons/simpleButton/SimpleButton";
import CustomModal from "../../../../components/modal/CustomModal";
import {
  assignNurse,
  fetchNurseList,
  fetchNurseUnit,
  resetFetchNurseUnit,
  resetFetchNurseList,
} from "../../../../features/unitDepartment/unitDepartmentSlice";
import "./assignNurseModal.scss";
import { fetchAssignNurseList } from "../../../../features/assignNurse/assignNurseSlice";

interface AssignNurseProps {
  open: boolean;
  onClose: () => void;
}

const AssignNurseModal: FC<AssignNurseProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch<appDispatch>();

  const { nurseSiteListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchNurseSiteList
  );

  const { nurseUnitListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchNurseUnit
  );
  const { nurseListData } = useSelector(
    (state: RootState) => state.unitDepartment.fetchNurseList
  );

  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [selectedNurse, setSelectedNurse] = useState<any>([]);

  const [form] = Form.useForm();

  const handleSiteChange = (siteId: any, option: any) => {
    setSelectedItem((prevdata) => {
      if (prevdata !== siteId) {
        setSelectedUnit(null);
      }
      return siteId;
    });

    if (siteId) {
      dispatch(fetchNurseUnit({ searchquery: "", sid: siteId }));
      dispatch(fetchNurseList(siteId));
    }
  };

  const handleUnitChange = (unitId: any, option: any) => {
    setSelectedUnit(unitId);
  };

  const handleNurseChange = (nurseId: any, option: any) => {
    setSelectedNurse(nurseId);
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(resetFetchNurseList());
    dispatch(resetFetchNurseUnit());
    setSelectedUnit(null);
    setSelectedItem(null);
    setSelectedNurse([]);
    onClose();
  };

  const handleSave = async () => {
    const payload = {
      site: selectedItem,
      unit: selectedUnit,
      nurse: selectedNurse,
    };
    setSelectedUnit(null);
    await dispatch(assignNurse(payload)).then((data) => {
      if (data?.payload?.isSuccess) {
        toast.success(data?.payload?.message);
        onClose();
        form.resetFields();
        setSelectedNurse([]);
        dispatch(resetFetchNurseList());
        dispatch(resetFetchNurseUnit());
        dispatch(
          fetchAssignNurseList({ searchQuery: "", siteID: "", unitID: "" })
        );
      } else {
        toast.error(data?.payload);
      }
    });

    setSelectedItem(null);
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      setSelectedNurse(nurseListData.map((data: any) => data.id));
      form.setFieldValue(
        "nurseID",
        nurseListData.map((data: any) => data.id)
      );
    } else {
      setSelectedNurse([]);
      form.setFieldValue("nurseID", []);
    }
  };

  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label?.props?.children ? label?.props?.children : null}
      </Tag>
    );
  };

  const dropdownRender = (menu: any) => (
    <>
      <Space style={{ padding: "5px 12px 4px" }}>
        <Checkbox
          checked={
            selectedNurse?.length === nurseListData?.length &&
            nurseListData.length > 0
          }
          onChange={handleSelectAll}
        >
          Select All
        </Checkbox>
      </Space>
      <Divider style={{ margin: "8px 0" }} />
      {menu}
    </>
  );

  const TextWithCheckbox = (props: any) => {
    return (
      <div>
        <Checkbox
          checked={props.checked}
          style={{ margin: "0 .5rem 0 0rem" }}
        />
        {props.children}
      </div>
    );
  };

  return (
    <>
      <CustomModal visible={open} onClose={onClose}>
        <Layout>
          <Row>
            <Col>
              <h2>Assign Nurse</h2>
            </Col>
          </Row>
          <Form
            name="frmAssignNurse"
            form={form}
            className="assign-nurse-form"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="siteID"
                  label="Site"
                  rules={[
                    {
                      required: true,
                      message: "Please choose site",
                    },
                  ]}
                >
                  <Select.Option value={null} disabled>
                    Select site
                  </Select.Option>
                  <Select
                    placeholder="Select site"
                    onChange={handleSiteChange}
                    value={selectedItem}
                    virtual={false}
                  >
                    {nurseSiteListData?.map((site: any) => (
                      <Select.Option key={site.id} value={site.id}>
                        {site.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="unitID"
                  label="Unit"
                  rules={[
                    {
                      required: true,
                      message: "Please choose unit",
                    },
                  ]}
                >
                  <Select.Option value={null} disabled>
                    Select Unit
                  </Select.Option>
                  <Select
                    placeholder="Select unit"
                    onChange={handleUnitChange}
                    value={selectedUnit}
                    virtual={false}
                  >
                    {nurseUnitListData
                      ?.filter((unit: any) => unit.type === 2)
                      ?.map((unit: any) => (
                        <Select.Option key={unit.id} value={unit.id}>
                          {unit.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="nurseID"
                  label="Nurse"
                  rules={[
                    {
                      required: true,
                      message: "Please choose nurse",
                    },
                  ]}
                >
                  <Select.Option value={null} disabled>
                    Select Nurse
                  </Select.Option>
                  <Select
                    mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    filterOption={(input, option: any) =>option?.label?.toLowerCase().indexOf(input.toLowerCase()) >=0}
                    optionFilterProp="children"
                    onChange={handleNurseChange}
                    placeholder={"Select Nurse"}
                    maxTagCount={"responsive"}
                    value={selectedNurse}
                    virtual={false}
                    tagRender={tagRender}
                    dropdownRender={dropdownRender}
                  >
                    {nurseListData?.map((item: any) => (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        label={item.name}
                      >
                        <TextWithCheckbox
                          checked={selectedNurse?.includes(item?.id)}
                        >
                          {item.name}
                        </TextWithCheckbox>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" gutter={[8, 8]} style={{ marginTop: "15px" }}>
              <Space direction="horizontal" align="center">
                <SimpleButton
                  block
                  text="Cancel"
                  color="tertiary"
                  customHtmlType="button"
                  onClick={handleCancel}
                />

                <SimpleButton
                  block
                  text="Save"
                  color="primary"
                  onClick={handleSave}
                  customHtmlType="button"
                  disabled={
                    selectedNurse.length !== 0 && selectedUnit && selectedItem
                      ? false
                      : true
                  }
                />
              </Space>
            </Row>
          </Form>
        </Layout>
      </CustomModal>
    </>
  );
};

export default AssignNurseModal;
