import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

//api call to fetch group list with search
const fetchGroupList = async (
  { searchquery, guId }: { searchquery: string; guId: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.GROUPS.FETCH_GROUP_LIST}?guid=${guId}&search=${searchquery}`,
    config
  );
  return response.data;
};

//api call to delete group
const deleteGroup = async (groupId: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.GROUPS.DELETE_GROUP}${groupId}/`, config);
  return response.data;
};

//api call to fetch users list in group
const fetchGroupUserList = async (
  { groupId, selectedSiteFromSchedule }: { groupId: any; selectedSiteFromSchedule?: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.GROUPS.GROUP_USER_LIST}?group_id=${groupId}${
      selectedSiteFromSchedule ? `&location_id=${selectedSiteFromSchedule}` : ""
    }`,
    config
  );
  return response.data;
};

///api call to to create a new group
const createGroup = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.GROUPS.CREATE_GROUP}`, payload, config);
  return response.data;
};

//api call to fetch a list of users belonging to a speciality group.
const fetchUsersBySpeciality = async (
  { groupId, searchQuery }: { groupId: number; searchQuery: string },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.GROUPS.FETCH_USERS_BY_GROUP}?group=${groupId}&q=${searchQuery}`,
    config
  );
  return response.data;
};

// api call to update users on the group
const updateGroupUsers = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.GROUPS.FETCH_USERS_BY_GROUP}`, payload, config);
  return response.data;
};

//api call to fetch schedule list in group
const fetchSchedulesByGroup = async (groupId: number, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.FETCH_SCHEDULES_BY_GROUP}?group=${groupId}`,
    config
  );
  return response.data;
};

//api call to fetch group details
const fetchGroupDetails = async ({ groupId, guId }: { groupId: number; guId: string }, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.GROUPS.FETCH_GROUP_DETAILS}${groupId}/?guid=${guId}&search=`,
    config
  );
  return response.data;
};

//api call to update schedule
const updateSchedule = async (
  { scheduleId, groupId }: { scheduleId: number; groupId: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.UPDATE_SCHEDULE}${scheduleId}/?group_id=${groupId}`,
    config
  );
  return response.data;
};

//api call to create schedule
const createSchedule = async (payload: object, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.SCHEDULE.CREATE_SCHEDULE}`, payload, config);
  return response.data;
};

//api call to add sites to newly created schedule
const addSitesToSchedule = async (
  { newScheduleId, payload }: { newScheduleId: any; payload: object },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.ADD_SITES_TO_SCHEDULE}${newScheduleId}/add`,
    payload,
    config
  );
  return response.data;
};

//api call to add users to newly created schedule
export const addUsersToSchedule = async (
  { newScheduleId, payload }: { newScheduleId: any; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.ADD_USER_TO_SCHEDULE}${newScheduleId}/add/`,
    payload,
    config
  );
  return response.data;
};

//api call to fetch unassigned list
const fetchUnAssignedShiftList = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.GROUPS.UNASSIGNED_SHIFT_LIST}`, payload, config);
  return response.data;
};

//api call  delete schedule
const deleteSchedule = async (
  { scheduleId, groupId }: { scheduleId: number; groupId: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.DELETE_SCHEDULE}${scheduleId}/?group=${groupId}`,
    config
  );
  return response.data;
};

//api call to fetch schedule details
const fetchScheduleDetails = async (
  { scheduleId, groupId }: { scheduleId: any; groupId: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.DELETE_SCHEDULE}${scheduleId}/?group=${groupId}`,
    "",
    config
  );
  return response.data;
};

//api call to remove site from schedule list
const removeSiteFromSchedule = async (
  { scheduleId, siteId }: { scheduleId: string; siteId: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.REMOVE_SITE_FROM_SCHEDULE}${scheduleId}/remove/?site_id=${siteId}`,
    config
  );
  return response.data;
};

//api call to remove user from schedule list
const removeUserFromSchedule = async (
  { scheduleId, userId }: { scheduleId: string; userId: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.REMOVE_USER_FROM_SCHEDULE}${scheduleId}/remove/?user_id=${userId}`,
    config
  );
  return response.data;
};

const fetchUnAssignedShiftUserList = async (
  shiftId: number,
  isRule: boolean,
  date: any,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.GROUPS.UNASSIGNED_SHIFT_USER_LIST}?shift_id=${shiftId}&is_rule=${isRule}&date=${date}`,
    config
  );
  return response.data;
};

const fetchUnAssignedShiftUser = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.GROUPS.UNASSIGNED_SHIFT_USER_LIST}`,
    payload,
    config
  );
  return response.data;
};

//api call to update group details
const updateGroupDetails = async (
  { groupId, payload }: { groupId: any; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.GROUPS.UPDATE_GROUP_DETAILS}${groupId}/`,
    payload,
    config
  );
  return response.data;
};

//api call to change schedule name
const changeScheduleName = async (
  { scheduleId, groupId, payload }: { scheduleId: number; groupId: number; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.SCHEDULE.SCHEDULE_NAME_CHANGE}${scheduleId}/?group=${groupId}`,
    payload,
    config
  );
  return response.data;
};

const fetchUnAssignedRequestCover = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.GROUPS.UNASSIGNED_SHIFT_RC}`, payload, config);
  return response.data;
};

const groupService = {
  fetchGroupList,
  deleteGroup,
  fetchGroupUserList,
  createGroup,
  fetchUsersBySpeciality,
  updateGroupUsers,
  fetchSchedulesByGroup,
  updateSchedule,
  createSchedule,
  addSitesToSchedule,
  addUsersToSchedule,
  fetchUnAssignedShiftList,
  deleteSchedule,
  fetchScheduleDetails,
  removeSiteFromSchedule,
  removeUserFromSchedule,
  fetchGroupDetails,
  fetchUnAssignedShiftUserList,
  fetchUnAssignedShiftUser,
  updateGroupDetails,
  changeScheduleName,
  fetchUnAssignedRequestCover,
};
export default groupService;
