import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Upload,
  Image,
} from "antd";
import { toast } from "react-toastify";
import { FaPhotoVideo } from "react-icons/fa";
import { IoMdImages } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { RootState, appDispatch } from "../../../../app/store";
import {
  addHelpVideo,
  editHelpVideo,
  fetchHelpVideoData,
} from "../../../../features/help/helpSlice";
import { retrieveVideo } from "../../../../features/help/helpSlice";
import "./addHelp.scss";

interface AddHelpPropsType {
  isOpen: boolean;
  isEditMode: boolean;
  onCancel: any;
  totalVideos: any;
  selectedVideo?: any;
}

export const AddHelp = ({
  isOpen,
  onCancel,
  isEditMode,
  totalVideos,
  selectedVideo,
}: AddHelpPropsType) => {

  const [form] = Form.useForm();

  const { Dragger } = Upload;

  const dispatch = useDispatch<appDispatch>();

  const { retrieveVideoData, isLoading } = useSelector(
    (state: RootState) => state.help.retrieveVideo
  );

  const {  isLoading:videoUploadLoading } = useSelector(
    (state: RootState) => state.help.addHelpVideo
  );

  const viewOptions = [
    {
      id: 1,
      value: 1,
      label: "Mobile",
    },
    {
      id: 2,
      value: 2,
      label: "Web",
    },
  ];

  const [formData, setFormData] = useState<any>({
    caption: "",
    order: totalVideos + 1,
    view: "",
    url: null,
    videoFormat: null,
    snapshot: null,
    snapshotFormat: null,
    hide: false,
    isSnapshotChanged: false,
    isVideoChanged: false,
  });

  const [previewVideoUrl, setPreviewVideoURl] = useState<any>("");
  const [isVideoSelected, setIsVideoSelected] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(false);
  const [selectedImgUrl, setSelectedImgUrl] = useState<any>("");
  const [videoFileList, setVideoFileList] = useState<any>([]);
  const [imageFileList, setImageFileList] = useState<any>([]);

  // fetching selected video details using id
  useEffect(() => {
    if (isOpen && isEditMode) {
      const payload = {
        id: selectedVideo?.id,
      };
      dispatch(retrieveVideo(payload));
    }
  }, [isOpen]);

  const handleViewChange = (value: string) => {
    setFormData({
      ...formData,
      view: value,
    });
  };

  const handleCancel = () => {
    onCancel();
    if (!isEditMode) {
      setFormData({
        caption: "",
        order: totalVideos + 1,
        view: "",
        url: "",
        snapshot: "",
        hide: false,
      });
      setVideoFileList([]);
      setPreviewVideoURl("");
      setIsVideoSelected(false);
      setImageFileList([]);
      setSelectedImgUrl("");
      setSelectedImage(false);
      form.resetFields();
      form.setFieldValue("order", totalVideos + 1);
    }
  };

  const showToastMessage = (response:any) =>{
    if (response?.payload?.isSuccess) {
      toast.success(response?.payload?.message);
      handleCancel();
    } else {
      toast.error(response?.payload?.message);
      handleCancel();
    }
    const fetchPayload = {
      searchquery: "",
      hide: true,
      pageNumber: 1,
    };
    dispatch(fetchHelpVideoData(fetchPayload));
  }

  const handleSave = () => {
    form
      .validateFields()
      .then(async (res) => {
        if (isEditMode) {
          const payload = {
            payload: {
              caption: formData?.caption?.toUpperCase(),
              order: formData?.order,
              visibility: formData?.view,
              snapshot_file: formData?.snapshot ? formData?.snapshot :null ,
              snapshot_format: formData?.snapshotFormat
                ? formData?.snapshotFormat
                : null,
              video_file: formData?.url,
              vedio_format: formData?.videoFormat,
              is_snapshot_changed: formData?.isSnapshotChanged,
              is_video_changed: formData?.isVideoChanged,
              is_hide:formData?.hide
            },
            id: selectedVideo?.id,
          };
          const response = await dispatch(editHelpVideo(payload));
          showToastMessage(response)
        } else {
          const payload = {
            caption: formData?.caption?.toUpperCase(),
            order: formData?.order,
            visibility:formData?.view,
            snapshot_file: formData?.snapshot ? formData?.snapshot : null,
            snapshot_format: formData?.snapshotFormat
              ? formData?.snapshotFormat
              : null,
            video_file: formData?.url,
            vedio_format: formData?.videoFormat,
            is_hide:formData?.hide
          };
          const response = await dispatch(addHelpVideo(payload));
          showToastMessage(response)
        }
      })
      .catch((error) => {
        // message.error('Validation failed:', error);
      });
  };

  useEffect(() => {
    if (retrieveVideoData?.data[0].length !== 0 && isEditMode) {
      setFormData({
        caption: retrieveVideoData?.data[0]?.caption,
        hide: !retrieveVideoData?.data[0]?.is_active,
        order: retrieveVideoData?.data[0]?.order,
        snapshot: retrieveVideoData?.data[0]?.snapshot_url,
        url: retrieveVideoData?.data[0]?.video_url,
        view: retrieveVideoData?.data[0]?.visibility,
        isSnapshotChanged: false,
        isVideoChanged: false,
      });

      setVideoFileList([
        {
          uid: "-1",
          name: `${retrieveVideoData?.data[0]?.caption}.mp4`,
          status: "done"
        },
      ]);

      setImageFileList(
        retrieveVideoData?.data[0]?.snapshot_url
          ? [
              {
                uid: "-1",
                name: `${retrieveVideoData?.data[0]?.caption}.jpeg`,
                status: "done",
              },
            ]
          : []
      );

      form.setFieldsValue(
        form.setFieldsValue({
          caption: retrieveVideoData?.data[0]?.caption,
          order: retrieveVideoData?.data[0]?.order,
          url: retrieveVideoData?.data[0]?.video_url,
          view: retrieveVideoData?.data[0]?.visibility,
        })
      );

      setPreviewVideoURl(retrieveVideoData?.data[0]?.video_url);

      setSelectedImgUrl(
        retrieveVideoData?.data[0]?.snapshot_url
          ? retrieveVideoData?.data[0]?.snapshot_url
          : ""
      );
    }

    if (!isEditMode) {
      form.setFieldValue("order", totalVideos + 1);
      setFormData({
        ...formData,
        order:totalVideos+1
      })
    }

  }, [retrieveVideoData,totalVideos]);

  // To set preview of video
  const beforeVideoUpload = (file: any) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPreviewVideoURl(reader.result);
    };
    return false; // Returning false to prevent automatic upload
  };

  const handleVideoChange = (event: any) => {
    setIsVideoSelected(event.fileList.length !== 0);
    if (event.fileList.length !== 0) {
      const reader: any = new FileReader();
      reader.readAsArrayBuffer(event?.file);// To get the file as binary
      reader.onload = () => {
        const binaryData = reader?.result;
        setFormData({
          ...formData,
          url: binaryData,
          videoFormat: event?.file.name.split(".").pop(),//Extracting file extension from file name
          isVideoChanged: true,
        });

        setVideoFileList([
          {
            uid: "-1",
            name: event?.file.name,
            status: "done",
          },
        ]);

        form.setFieldsValue({
          url: binaryData,
        });
      };
    }
  };

  // To set preview of image
  const beforeImgUpload = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedImgUrl(reader.result);
    };
    reader.onerror = (error) => {};
    return false;
  };

  // To handle image change
  const handleImageChange = async (event: any) => {
    if (event.fileList.length !== 0) {
      const reader: any = new FileReader();
      reader.readAsArrayBuffer(event?.file); // To get the file as binary
      reader.onload = () => {
        const binaryData = reader?.result;
        setFormData({
          ...formData,
          snapshot: binaryData,
          snapshotFormat: event?.file?.name?.split(".").pop(), //Extracting file extension from file name
          isSnapshotChanged: true,
        });
        setImageFileList([
          {
            uid: "-1",
            name: event?.file.name,
            status: "done",
          },
        ]);
      };
    }
    setSelectedImage(event.fileList.length !== 0);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      okText={"Save"}
      onOk={handleSave}
      confirmLoading={videoUploadLoading}
    >
      <div className="add-Help-container">
        <Row>
          <Col>
            <h2> {isEditMode ? "Edit Help" : "Add Help"} </h2>
          </Col>
        </Row>
        <Form name="addHelp" form={form} className="add-status-board-form">
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                name="caption"
                label="Caption"
                rules={[
                  {
                    required: true,
                    message: "Please input caption",
                  },
                ]}
              >
                <Input
                  value={formData?.caption ?? ""}
                  placeholder="Enter caption"
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      caption: event?.target?.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
              <Form.Item
                name="order"
                label="Order"
                rules={[
                  {
                    required: true,
                    message: "Please input order",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: " 70%", margin: "0 0 0 1rem" }}
                  max={totalVideos + 1}
                  min={1}
                  defaultValue={totalVideos + 1}
                  value={formData?.order}
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      order: value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={14} sm={14} md={14} lg={14} xl={14}>
              <Form.Item
                name="view"
                label="View"
                rules={[
                  {
                    required: true,
                    message: "Please select view",
                  },
                ]}
              >
                <Select
                  placeholder="Select View"
                  onChange={handleViewChange}
                  value={formData?.view ?? ""}
                  virtual={false}
                >
                  {viewOptions?.map((view: any) => (
                    <Select.Option key={view?.id} value={view?.value}>
                      {view?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={14}
              sm={14}
              md={14}
              lg={14}
              xl={14}
              className="url-text-area-container"
            >
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: "Please choose video",
                  },
                ]}
              >
                <p className="video-label"><span>*</span>Video</p>
                <Dragger
                  fileList={videoFileList}
                  className="upload-video-container"
                  name="video"
                  action="/upload"
                  beforeUpload={beforeVideoUpload}
                  onChange={handleVideoChange}
                  accept="video/*"
                  maxCount={1}
                  onRemove={() => {
                    form.setFieldsValue({
                      url: "",
                    });
                    setVideoFileList([]);
                  }}
                >
                  {!isVideoSelected && !(videoFileList?.length !== 0) ? (
                    <>
                      <p className="upload-drag-icon">
                        <FaPhotoVideo />
                      </p>
                      <p className="upload-text">
                        Click or drag video to this area to upload
                      </p>
                    </>
                  ) : (
                    <div className="preview-video-container">
                      <video
                        controls={true}
                        src={previewVideoUrl ? previewVideoUrl : ""}
                        height={150}
                      ></video>
                    </div>
                  )}
                </Dragger>
              </Form.Item>
            </Col>
            <Col
              xs={10}
              sm={10}
              md={10}
              lg={10}
              xl={10}
              className="snapshot-container"
            >
              <p className="video-label">Snapshot</p>
              <Dragger
                fileList={imageFileList}
                accept="image/*"
                maxCount={1}
                onChange={handleImageChange}
                beforeUpload={beforeImgUpload}
                onRemove={() => {
                  setFormData({
                    ...formData,
                    snapshot: null,
                    snapshotFormat: null,
                  });
                  setImageFileList([]);
                }}
                className="upload-image-container"
              >
                {!selectedImage && !(imageFileList.length !== 0) ? (
                  <>
                    <p className="upload-drag-icon">
                      <IoMdImages />
                    </p>
                    <p className="upload-text">
                      Click or drag image to this area to upload
                    </p>
                  </>
                ) : (
                  <div className="image-container">
                    <Image
                      width={"100%"}
                      height={"9.4rem"}
                      src={selectedImgUrl}
                      preview={false}
                    />
                  </div>
                )}
              </Dragger>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item name="hide" style={{ textAlign: "right" }}>
                <Checkbox
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      hide: event?.target?.checked,
                    });
                  }}
                  value={formData?.hide}
                  defaultChecked={false}
                >
                  Hide
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
