import { Card } from "antd";
import "./criteriaCard.scss";
import { useState } from "react";

interface CriteriaCardPropsType {
  title: string;
  content: any;
}

export const CriteriaCard: any = ({
  title,
  content,
}: CriteriaCardPropsType) => {

  return (
    <div className="criteria-card-container">
      <Card
        className="criteria-card expand-card"
      >
        <p className="criteria-card-title">{title}</p>
        <p className="criteria-card-content">{content}</p>
      </Card>
    </div>
  );
};
