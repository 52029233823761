import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import userRolesService from "./userRolesService";

interface RolesState {
  fetchRolesList: {
    fetchRolesListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchRolePermissions: {
    fetchRolePermissionsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addRole: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  editRole: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  auth: {
    user: {
      token: string;
    };
  };
  fetchClientRolePermissions: {
    clientRolePermissionsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  deleteRole: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: RolesState = {
  fetchRolesList: {
    fetchRolesListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchRolePermissions: {
    fetchRolePermissionsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addRole: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  editRole: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  auth: {
    user: {
      token: "",
    },
  },
  fetchClientRolePermissions: {
    clientRolePermissionsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  deleteRole: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

//Action to fetch roles list
export const fetchRolesList = createAsyncThunk("userRoles/fetchRolesList", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await userRolesService.fetchRolesList(token);

    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Action to fetch role's permissions
export const fetchRolePermissions = createAsyncThunk(
  "userRoles/fetchRolePermissions",
  async (roleId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await userRolesService.fetchRolePermissions(roleId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// action for adding role
export const addRole = createAsyncThunk("userRoles/addRole", async (roleName: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await userRolesService.addRole(roleName, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// action for edit role
export const editRole = createAsyncThunk(
  "userRoles/editRole",
  async ({ roleID, payload }: { roleID: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await userRolesService.editRole(token, { roleID, payload });
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// action for adding role permission
export const addRolePermission = createAsyncThunk("userRoles/addRole", async (permissionPayload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await userRolesService.addRolePermission(permissionPayload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch client role's permissions by role id
export const fetchClientRolePermissions = createAsyncThunk(
  "userRoles/fetchClientRolePermissions",
  async (roleId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await userRolesService.fetchClientRolePermissions(roleId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to update client role's permissions
export const updateClientRolePermissions = createAsyncThunk(
  "userRoles/updateClientRolePermissions",
  async ({ roleId, payload }: { roleId: any; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await userRolesService.updateClientRolePermissions({ roleId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Action to delete role
export const deleteRole = createAsyncThunk("userRoles/deleteRole", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await userRolesService.deleteRole(payload, token);

    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//user roles  slice
const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    resetFetchRolesList: (state) => {
      state.fetchRolesList = initialState.fetchRolesList;
    },
    resetFetchRolePermissions: (state) => {
      state.fetchRolePermissions = initialState.fetchRolePermissions;
    },
    resetAddRole: (state) => {
      state.addRole = initialState.addRole;
    },
    resetFetchClientRolePermissions: (state) => {
      state.fetchClientRolePermissions = initialState.fetchClientRolePermissions;
    },
    resetDeleteRole: (state) => {
      state.deleteRole = initialState.deleteRole;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRolesList.pending, (state, action: PayloadAction<any>) => {
        state.fetchRolesList.isLoading = true;
      })
      .addCase(fetchRolesList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchRolesList.isLoading = false;
        state.fetchRolesList.isSuccess = true;
        state.fetchRolesList.fetchRolesListData = action.payload.result;
      })
      .addCase(fetchRolesList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchRolesList.isLoading = false;
        state.fetchRolesList.isError = true;
        state.fetchRolesList.message = action.payload;
      })
      .addCase(fetchRolePermissions.pending, (state, action: PayloadAction<any>) => {
        state.fetchRolePermissions.isLoading = true;
      })
      .addCase(fetchRolePermissions.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchRolePermissions.isLoading = false;
        state.fetchRolePermissions.isSuccess = true;
        state.fetchRolePermissions.fetchRolePermissionsData = action.payload.result;
      })
      .addCase(fetchRolePermissions.rejected, (state, action: PayloadAction<any>) => {
        state.fetchRolePermissions.isLoading = false;
        state.fetchRolePermissions.isError = true;
        state.fetchRolePermissions.message = action.payload;
      })
      .addCase(addRole.pending, (state, action: PayloadAction<any>) => {
        state.addRole.isLoading = true;
      })
      .addCase(addRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.addRole.isLoading = false;
        state.addRole.isSuccess = true;
      })
      .addCase(addRole.rejected, (state, action: PayloadAction<any>) => {
        state.addRole.isLoading = false;
        state.addRole.isError = true;
        state.addRole.message = action.payload;
      })
      .addCase(editRole.pending, (state, action: PayloadAction<any>) => {
        state.editRole.isLoading = true;
      })
      .addCase(editRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.editRole.isLoading = false;
        state.editRole.isSuccess = true;
      })
      .addCase(editRole.rejected, (state, action: PayloadAction<any>) => {
        state.editRole.isLoading = false;
        state.editRole.isError = true;
        state.editRole.message = action.payload;
      })
      .addCase(fetchClientRolePermissions.pending, (state, action: PayloadAction<any>) => {
        state.fetchClientRolePermissions.isLoading = true;
      })
      .addCase(fetchClientRolePermissions.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchClientRolePermissions.isLoading = false;
        state.fetchClientRolePermissions.isSuccess = true;
        state.fetchClientRolePermissions.clientRolePermissionsData = action.payload.result;
      })
      .addCase(fetchClientRolePermissions.rejected, (state, action: PayloadAction<any>) => {
        state.fetchClientRolePermissions.isLoading = false;
        state.fetchClientRolePermissions.isError = true;
        state.fetchClientRolePermissions.message = action.payload;
      })

      .addCase(deleteRole.pending, (state, action: PayloadAction<any>) => {
        state.deleteRole.isLoading = true;
      })
      .addCase(deleteRole.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteRole.isLoading = false;
        state.deleteRole.isSuccess = true;
        state.deleteRole.message = action.payload.message;
      })
      .addCase(deleteRole.rejected, (state, action: PayloadAction<any>) => {
        state.deleteRole.isLoading = false;
        state.deleteRole.isError = true;
        state.deleteRole.message = action.payload;
      });
  },
});

export const {
  resetFetchRolesList,
  resetFetchRolePermissions,
  resetAddRole,
  resetFetchClientRolePermissions,
  resetDeleteRole,
} = rolesSlice.actions;
export default rolesSlice.reducer;
