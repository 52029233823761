import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

import unitDepartmentService from "./unitDepartmentService";

interface UnitDepartmentState {
  fetchSiteList: {
    siteListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUnitDepartment: {
    unitDepartmentData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchUnitDepartmentUser: {
    unitDepartmentUserData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };

  fetchBedList: {
    bedListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  deleteBed: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  addBed: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchBedDetails: {
    bedDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateBedDetails: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchNurseSiteList: {
    nurseSiteListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchNurseUnit: {
    nurseUnitListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchNurseList: {
    nurseListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchAssignedNurseList: {
    assignedNurseListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  unAssignNurse: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: UnitDepartmentState = {
  fetchSiteList: {
    siteListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUnitDepartment: {
    unitDepartmentData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchUnitDepartmentUser: {
    unitDepartmentUserData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },

  fetchBedList: {
    bedListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  deleteBed: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  addBed: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchBedDetails: {
    bedDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateBedDetails: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchNurseSiteList: {
    nurseSiteListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchNurseUnit: {
    nurseUnitListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchNurseList: {
    nurseListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchAssignedNurseList: {
    assignedNurseListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  unAssignNurse: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

export const fetchSiteList = createAsyncThunk("unitDepartment/fetchSiteList", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await unitDepartmentService.fetchSiteList(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchUnitDepartment = createAsyncThunk(
  "unitDepartment/fetchUnitDepartment",
  async ({ searchquery, sid }: { searchquery: string; sid: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.fetchUnitDepartment({ searchquery, sid }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addUnitDepartment = createAsyncThunk(
  "unitDepartment/addUnitDepartment",
  async (payload: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.addUnitDepartment(payload, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUnitDepartment = createAsyncThunk(
  "unitDepartment/updateUnitDepartment",
  async ({ unitDepartmentId, payload }: { unitDepartmentId: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.updateUnitDepartment({ unitDepartmentId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteUnitDepartment = createAsyncThunk(
  "unitDepartment/deleteUnitDepartment",
  async (unitId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.deleteUnitDepartment(unitId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchUnitDepartmentUser = createAsyncThunk(
  "unitDepartment/fetchUnitDepartmentUser",
  async (sid: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.fetchUnitDepartmentUser(sid, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch bed list
export const fetchBedList = createAsyncThunk(
  "unitDepartment/fetchBedList",
  async ({ searchquery, unitId, page }: { searchquery: string; unitId: any; page: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.fetchBedList({ searchquery, unitId, page }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to delete bed
export const deleteBed = createAsyncThunk("unitDepartment/deleteBed", async (bedId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await unitDepartmentService.deleteBed(bedId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to add bed
export const addBed = createAsyncThunk("unitDepartment/addBed", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await unitDepartmentService.addBed(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch bed details
export const fetchBedDetails = createAsyncThunk("unitDepartment/fetchBedDetails", async (bedId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await unitDepartmentService.fetchBedDetails(bedId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to update bed details
export const updateBedDetails = createAsyncThunk(
  "unitDepartment/updateBedDetails",
  async ({ bedId, payload }: { bedId: number; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.updateBedDetails({ bedId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchNurseSiteList = createAsyncThunk("unitDepartment/fetchNurseSiteList", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await unitDepartmentService.fetchNurseSiteList(token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const fetchNurseUnit = createAsyncThunk(
  "unitDepartment/fetchNurseUnit",
  async ({ searchquery, sid }: { searchquery: string; sid: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.fetchNurseUnit({ searchquery, sid }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch nurse list
export const fetchNurseList = createAsyncThunk("unitDepartment/fetchNurseList", async (siteId: number, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await unitDepartmentService.fetchNurseList(siteId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to assign nurse
export const assignNurse = createAsyncThunk("unitDepartment/assignNurse", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await unitDepartmentService.assignNurse(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch assigned nurse list and search
export const fetchAssignedNurseList = createAsyncThunk(
  "unitDepartment/fetchAssignedNurseList",
  async ({ unitId, searchQuery }: { unitId: number; searchQuery: string }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.fetchAssignedNurseList({ unitId, searchQuery }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to Unassign Nurse from unit
export const unAssignNurse = createAsyncThunk(
  "unitDepartment/unAssignNurse",
  async ({ unitId, nurseId }: { unitId: number; nurseId: number }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await unitDepartmentService.unAssignNurse({ unitId, nurseId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const unitDepartmentSlice = createSlice({
  name: "unitDepartment",
  initialState,
  reducers: {
    resetFetchSiteList: (state) => {
      state.fetchSiteList = initialState.fetchSiteList;
    },
    resetFetchUnitDepartment: (state) => {
      state.fetchUnitDepartment = initialState.fetchUnitDepartment;
    },
    resetFetchUnitDepartmentUser: (state) => {
      state.fetchUnitDepartment = initialState.fetchUnitDepartment;
    },
    resetFetchBedList: (state) => {
      state.fetchBedList = initialState.fetchBedList;
    },
    resetDeleteBed: (state) => {
      state.deleteBed = initialState.deleteBed;
    },
    resetAddBed: (state) => {
      state.addBed = initialState.addBed;
    },
    resetFetchBedDetails: (state) => {
      state.fetchBedDetails = initialState.fetchBedDetails;
    },
    resetUpdateBedDetails: (state) => {
      state.updateBedDetails = initialState.updateBedDetails;
    },
    resetFetchNurseSiteList: (state) => {
      state.fetchNurseSiteList = initialState.fetchNurseSiteList;
    },
    resetFetchNurseUnit: (state) => {
      state.fetchNurseUnit = initialState.fetchNurseUnit;
    },
    resetFetchNurseList: (state) => {
      state.fetchNurseList = initialState.fetchNurseList;
    },
    resetFetchAssignedNurseList: (state) => {
      state.fetchAssignedNurseList = initialState.fetchAssignedNurseList;
    },
    resetUnAssignNurse: (state) => {
      state.unAssignNurse = initialState.unAssignNurse;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSiteList.pending, (state, action: PayloadAction<any>) => {
        state.fetchSiteList.isLoading = true;
      })
      .addCase(fetchSiteList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchSiteList.isLoading = false;
        state.fetchSiteList.isSuccess = true;
        state.fetchSiteList.siteListData = action.payload.result;
      })
      .addCase(fetchSiteList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchSiteList.isLoading = false;
        state.fetchSiteList.isError = true;
        state.fetchSiteList.message = action.payload;
      })
      .addCase(fetchUnitDepartment.pending, (state, action: PayloadAction<any>) => {
        state.fetchUnitDepartment.isLoading = true;
      })
      .addCase(fetchUnitDepartment.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUnitDepartment.isLoading = false;
        state.fetchUnitDepartment.isSuccess = true;
        state.fetchUnitDepartment.unitDepartmentData = action.payload.result;
      })
      .addCase(fetchUnitDepartment.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUnitDepartment.isLoading = false;
        state.fetchUnitDepartment.isError = true;
        state.fetchUnitDepartment.message = action.payload;
      })
      .addCase(fetchUnitDepartmentUser.pending, (state, action: PayloadAction<any>) => {
        state.fetchUnitDepartmentUser.isLoading = true;
      })
      .addCase(fetchUnitDepartmentUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchUnitDepartmentUser.isLoading = false;
        state.fetchUnitDepartmentUser.isSuccess = true;
        state.fetchUnitDepartmentUser.unitDepartmentUserData = action.payload.result;
      })
      .addCase(fetchUnitDepartmentUser.rejected, (state, action: PayloadAction<any>) => {
        state.fetchUnitDepartmentUser.isLoading = false;
        state.fetchUnitDepartmentUser.isError = true;
        state.fetchUnitDepartmentUser.message = action.payload;
      })
      .addCase(fetchBedList.pending, (state, action: PayloadAction<any>) => {
        state.fetchBedList.isLoading = true;
      })
      .addCase(fetchBedList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchBedList.isLoading = false;
        state.fetchBedList.isSuccess = true;
        state.fetchBedList.bedListData = action.payload.result;
      })
      .addCase(fetchBedList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchBedList.isLoading = false;
        state.fetchBedList.isError = true;
        state.fetchBedList.message = action.payload;
      })
      .addCase(deleteBed.pending, (state, action: PayloadAction<any>) => {
        state.deleteBed.isLoading = true;
      })
      .addCase(deleteBed.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteBed.isLoading = false;
        state.deleteBed.isSuccess = true;
        state.deleteBed.message = action.payload.message;
      })
      .addCase(deleteBed.rejected, (state, action: PayloadAction<any>) => {
        state.deleteBed.isLoading = false;
        state.deleteBed.isError = true;
        state.deleteBed.message = action.payload;
      })
      .addCase(addBed.pending, (state, action: PayloadAction<any>) => {
        state.addBed.isLoading = true;
      })
      .addCase(addBed.fulfilled, (state, action: PayloadAction<any>) => {
        state.addBed.isLoading = false;
        state.addBed.isSuccess = true;
        state.addBed.message = action.payload.message;
      })
      .addCase(addBed.rejected, (state, action: PayloadAction<any>) => {
        state.addBed.isLoading = false;
        state.addBed.isSuccess = false;
        state.addBed.isError = true;
        state.addBed.message = action.payload;
      })
      .addCase(fetchBedDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchBedDetails.isLoading = true;
      })
      .addCase(fetchBedDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchBedDetails.isLoading = false;
        state.fetchBedDetails.isSuccess = true;
        state.fetchBedDetails.bedDetailsData = action.payload.result;
        state.fetchBedDetails.message = action.payload.message;
      })
      .addCase(fetchBedDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchBedDetails.isLoading = false;
        state.fetchBedDetails.isError = true;
        state.fetchBedDetails.message = action.payload;
      })
      .addCase(updateBedDetails.pending, (state, action: PayloadAction<any>) => {
        state.updateBedDetails.isLoading = true;
      })
      .addCase(updateBedDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateBedDetails.isLoading = false;
        state.updateBedDetails.isSuccess = true;
        state.updateBedDetails.message = action.payload.message;
      })
      .addCase(updateBedDetails.rejected, (state, action: PayloadAction<any>) => {
        state.updateBedDetails.isLoading = false;
        state.updateBedDetails.isError = true;
        state.updateBedDetails.message = action.payload;
      })
      .addCase(fetchNurseSiteList.pending, (state, action: PayloadAction<any>) => {
        state.fetchNurseSiteList.isLoading = true;
      })
      .addCase(fetchNurseSiteList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchNurseSiteList.isLoading = false;
        state.fetchNurseSiteList.isSuccess = true;
        state.fetchNurseSiteList.nurseSiteListData = action.payload.result;
      })
      .addCase(fetchNurseSiteList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchNurseSiteList.isLoading = false;
        state.fetchNurseSiteList.isError = true;
        state.fetchNurseSiteList.message = action.payload;
      })
      .addCase(fetchNurseUnit.pending, (state, action: PayloadAction<any>) => {
        state.fetchNurseUnit.isLoading = true;
      })
      .addCase(fetchNurseUnit.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchNurseUnit.isLoading = false;
        state.fetchNurseUnit.isSuccess = true;
        state.fetchNurseUnit.nurseUnitListData = action.payload.result;
      })
      .addCase(fetchNurseUnit.rejected, (state, action: PayloadAction<any>) => {
        state.fetchNurseUnit.isLoading = false;
        state.fetchNurseUnit.isError = true;
        state.fetchNurseUnit.message = action.payload;
      })
      .addCase(fetchNurseList.pending, (state, action: PayloadAction<any>) => {
        state.fetchNurseList.isLoading = true;
      })
      .addCase(fetchNurseList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchNurseList.isLoading = false;
        state.fetchNurseList.isSuccess = true;
        state.fetchNurseList.nurseListData = action.payload.result;
      })
      .addCase(fetchNurseList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchNurseList.isLoading = false;
        state.fetchNurseList.isError = true;
        state.fetchNurseList.message = action.payload;
      })
      .addCase(fetchAssignedNurseList.pending, (state, action: PayloadAction<any>) => {
        state.fetchAssignedNurseList.isLoading = true;
      })
      .addCase(fetchAssignedNurseList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchAssignedNurseList.isLoading = false;
        state.fetchAssignedNurseList.isSuccess = true;
        state.fetchAssignedNurseList.assignedNurseListData = action.payload.result;
      })
      .addCase(fetchAssignedNurseList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchAssignedNurseList.isLoading = false;
        state.fetchAssignedNurseList.isError = true;
        state.fetchAssignedNurseList.message = action.payload;
      })
      .addCase(unAssignNurse.pending, (state, action: PayloadAction<any>) => {
        state.unAssignNurse.isLoading = true;
      })
      .addCase(unAssignNurse.fulfilled, (state, action: PayloadAction<any>) => {
        state.unAssignNurse.isLoading = false;
        state.unAssignNurse.isSuccess = true;
        state.unAssignNurse.message = action.payload.message;
      })
      .addCase(unAssignNurse.rejected, (state, action: PayloadAction<any>) => {
        state.unAssignNurse.isLoading = false;
        state.unAssignNurse.isError = true;
        state.unAssignNurse.message = action.payload;
      });
  },
});

export const {
  resetFetchSiteList,
  resetFetchUnitDepartment,
  resetFetchUnitDepartmentUser,
  resetFetchBedList,
  resetDeleteBed,
  resetAddBed,
  resetFetchBedDetails,
  resetUpdateBedDetails,
  resetFetchNurseSiteList,
  resetFetchNurseUnit,
  resetFetchNurseList,
  resetFetchAssignedNurseList,
  resetUnAssignNurse,
} = unitDepartmentSlice.actions;

export default unitDepartmentSlice.reducer;
