export const countryCodeListData = [
  { label: "India", value: "+91" },
  { label: "US", value: "+1" },
];

export const contactPreferenceData = [
  { label: "First", value: "1",isSelected:false },
  { label: "Second", value: "2",isSelected:false },
  { label: "Third", value: "3" ,isSelected:false},
  { label: "Fourth", value: "4",isSelected:false },
];

// const stateListData = [
//   { name: "Alabama", value: "101" },
//   { name: "California", value: "102" },
//   { name: "Florida", value: "103" },
//   { name: "Georgia", value: "104" },
//   { name: "Nevada", value: "105" },
//   { name: "New York", value: "106" },
//   { name: "Washington", value: "107" },
//   { name: "Wisconsin", value: "108" },
// ];

// const cityListData = [
//   { name: "Los Angeles", value: "1004" },
//   { name: "San Jose", value: "1005" },
//   { name: "San Francisco", value: "1006" },
// ];

// const timeZoneData = ["Africa/Abidjan", "Africa/Accra", "Africa/Addis_Ababa"];

//used in shift page
export const options = [
  { label: "M", value: "monday" },
  { label: "T", value: "tuesday" },
  { label: "W", value: "wednesday" },
  { label: "T", value: "thursday" },
  { label: "F", value: "friday" },
  { label: "S", value: "saturday" },
  { label: "S", value: "sunday" },
];

//used in shift page
export const weekoptions = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

//used in care team
export interface CareTeamOptionsType {
  value: string;
  label: string;
}
export const CareTeamOptions: CareTeamOptionsType[] = [
  { value: "0", label: "Liver" },
  { value: "1", label: "Stroke" },
  { value: "2", label: "Kidney" },
  { value: "3", label: "Call Code" },
  { value: "4", label: "Rapid Response" },
  { value: "5", label: "STEMI" },
  { value: "6", label: "Cardiac" },
  { value: "7", label: "None" },
];
