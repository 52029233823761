import { Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import Table from "antd/es/table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { appDispatch } from "../../../app/store";
import { updatePublicHoliday } from "../../../features/users/usersSlice";

interface DataType {
  key: string;
  name: string;
  date: string;
  notworking: boolean;
  id: number;
  is_working: boolean;
}

interface UserModalTableProps {
  dateRange: any;
  userId: number;
  fetchUserDetailsData: any;
}

const UserModalTable: React.FC<UserModalTableProps> = ({
  dateRange,
  userId,
  fetchUserDetailsData,
}) => {
  const [checkboxState, setCheckboxState] = useState<{
    [key: string]: boolean;
  }>({});
  const dispatch = useDispatch<appDispatch>();

  //function to handle when user toogle on checkbox
  const handleIsWorkingStatus = (record: any, e: any) => {
    const { date, is_working, ref_id, id: public_holiday } = record;

    //to get user id
    const user = userId;
    const toogleIsWorking = e.target.checked;

    const payload = {
      user,
      public_holiday,
      date,
      is_working: toogleIsWorking,
      ref_id,
    };

    if (!e.target.checked) {
      payload.is_working = false;
    }

    dispatch(updatePublicHoliday(payload)).then((data) => {
      if (data?.payload?.isSuccess) {
        setCheckboxState((prevState) => {
          return {
            ...prevState,
            [record.id]: toogleIsWorking,
          };
        });
      } else if (!data?.payload?.isSuccess) {
        toast.error(data?.payload);
      }
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "NOT WORKING",
      dataIndex: "is_working",
      render: (is_working: boolean, record: DataType) => (
        <Checkbox
          checked={checkboxState[record?.id]}
          onChange={(e) => handleIsWorkingStatus(record, e)}
        />
      ),
    },
  ];

  //to initially set the values of checkbox
  useEffect(() => {
    if (fetchUserDetailsData[0]?.public_holidays) {
      const initialCheckboxState: { [key: string]: boolean } = {};
      fetchUserDetailsData[0].public_holidays.forEach((holiday: DataType) => {
        initialCheckboxState[holiday.id] = holiday?.is_working;
      });
      setCheckboxState(initialCheckboxState);
    }
  }, [fetchUserDetailsData]);

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={fetchUserDetailsData[0]?.public_holidays}
      rowClassName={(record, index) =>
        index % 2 === 0 ? "table-row-light" : "table-row-dark"
      }
    />
  );
};

export default UserModalTable;
