import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

const fetchHelpVideoData = async (
  {
    searchquery,
    hide,
    pageNumber,
  }: { searchquery: string; hide: boolean; pageNumber: number },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.HELP.FETCH_HELP_VIDEO_DATA}?search=${searchquery}&is_active=${hide}&page=${pageNumber}`,
    config
  );
  return response.data;
};

const retrieveVideo = async (
  {
    id
  }: { id: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.HELP.RETRIEVE_HELP_VIDEO}?id=${id}`,
    config
  );
  return response.data;
};

const addHelpVideo = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'multipart/form-data'
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.HELP.ADD_HELP_VIDEO}`,
    payload,
    config
  );
  return response.data;
};

const editHelpVideo = async ({payload,id}: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
      'Content-Type': 'multipart/form-data'
    },
  };
  console.log("test api payload",payload)
  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.HELP.EDIT_HELP_VIDEO}${id}/`,
    payload,
    config
  );
  return response.data;
};

const deleteHelpVideo = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
    data: payload,
  };
  const response = await axiosInstance.delete(
    `${API_URL}${API_ENDPOINTS.HELP.DELETE_HELP_VIDEO}`,
    config
  );
  return response.data;
};

const changeHelpVideoOrder = async (
  payload: any,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.post(
    `${API_URL}${API_ENDPOINTS.HELP.CHANGE_HELP_VIDEO_ORDER}`,
    payload,
    config
  );
  return response.data;
};

const helpService = {
  fetchHelpVideoData,
  retrieveVideo,
  addHelpVideo,
  editHelpVideo,
  deleteHelpVideo,
  changeHelpVideoOrder,
};

export default helpService;
