import { useSelector } from "react-redux"
import { RootState } from "../app/store"

const useAuth =()=>{

const {user}= useSelector((state :RootState)=>state.auth)

const isLoggedIn = user ? true :false

return {isLoggedIn,user}

};

export default useAuth;