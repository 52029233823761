import { Checkbox, Col, Form, Input, Divider, Layout, Row, Select, Skeleton, Space, Tag, } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { RootState, appDispatch } from "../../../../../app/store";
import SimpleButton from "../../../../../components/buttons/simpleButton/SimpleButton";
import CustomModal from "../../../../../components/modal/CustomModal";
import { addStatusBoard, fetchStatusBoardList, resetStatusBoardDetails, updateStatusBoard, } from "../../../../../features/statusBoard/statusBoardSlice";
import { fetchBedList, fetchSiteList, fetchUnitDepartment, resetFetchBedList, resetFetchUnitDepartment, } from "../../../../../features/unitDepartment/unitDepartmentSlice";
import "./addStatusBoardCard.scss";

interface AssignNurseProps {
  open: boolean;
  onClose: () => void;
  isEditMode?: any;
  editingStatusId?: any;
  setIsEditMode?: any;
  handleClearFilters: () => void;
  handleClearLastFilterValues: () => void;
  filterSite?: string | any;
  filterUnit?: string | any;
  searchQuery: string
}

const AddStatusBoardCard: FC<AssignNurseProps> = ({
  open,
  onClose,
  isEditMode,
  editingStatusId,
  setIsEditMode,
  handleClearFilters,
  handleClearLastFilterValues,
  filterSite,
  filterUnit,
  searchQuery
}) => {
  const dispatch = useDispatch<appDispatch>();
  const [form] = Form.useForm();

  const { siteListData } = useSelector((state: RootState) => state.unitDepartment.fetchSiteList);
  const { unitDepartmentData, isLoading: isLoadingFetchUnitDepartment } = useSelector((state: RootState) => state.unitDepartment.fetchUnitDepartment);
  const { statusBoardDetailsData, isLoading } = useSelector((state: RootState) => state.statusBoard.fetchStatusBoardDetails);
  const { bedListData, isLoading: isLoadingFetchBedList } = useSelector((state: RootState) => state.unitDepartment.fetchBedList);

  const [selectedSite, setSelectedSite] = useState<number | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<number | null>(null);
  const [site, setSite] = useState<string | null>("");
  const [unit, setUnit] = useState<string | null>("");
  const [selectedOptions, setSelectedOptions] = useState([""]);
  const [selectAllBeds, setSelectAllBeds] = useState(false);



  const handleCancel = () => {
    setSite("");
    setUnit("");
    setSelectedSite(null);
    setSelectedUnit(null);
    setSelectedOptions([]);
    dispatch(resetFetchBedList());
    dispatch(resetFetchUnitDepartment());
    form.setFieldValue("bed", []);
    form.resetFields();
    onClose();
  };

  //to set selected options in checkbox
  useEffect(() => {
    const initialBedIds =
      statusBoardDetailsData?.bed?.map((bed: any) => bed?.id) || [];
    setSelectedOptions(initialBedIds);
  }, [statusBoardDetailsData]);

  useEffect(() => {
    dispatch(fetchSiteList());
  }, []);

  useEffect(() => {
    if (isEditMode && statusBoardDetailsData && statusBoardDetailsData.site) {
      form.setFieldsValue({
        name: statusBoardDetailsData?.name,
        site: statusBoardDetailsData?.site?.id,
        unit: statusBoardDetailsData?.unit?.id,
      });

      const { site: { id, name }, } = statusBoardDetailsData;
      setSelectedSite(id);
      setSite(name);
      const selectedBed = statusBoardDetailsData?.bed?.map((bed: any) => bed?.id
      );
      setSelectedOptions(selectedBed);

      if (id) {
        dispatch(fetchUnitDepartment({ searchquery: "", sid: id }));
      }

      if (statusBoardDetailsData.unit) {
        const defaultUnit = statusBoardDetailsData.unit;
        setSelectedUnit(defaultUnit.id);
        setUnit(defaultUnit.name);

        dispatch(
          fetchBedList({ searchquery: "", unitId: defaultUnit?.id, page: 1 })
        );
      }
    }
  }, [isEditMode, statusBoardDetailsData]);

  useEffect(() => {
    if (isEditMode && statusBoardDetailsData?.bed) {
      const defaultBedValues = statusBoardDetailsData.bed.map((bed: any) => bed?.id);
      form.setFieldsValue({
        bed: defaultBedValues,
      });
    }
  }, [isEditMode, statusBoardDetailsData]);

  useEffect(() => {
    return () => {
      dispatch(resetStatusBoardDetails());
    };
  }, []);

  const handleSiteChange = (siteId: any) => {
    setSelectedSite(siteId);

    // Clear the selected units when the site is changed
    setSelectedUnit(null);
    form.setFieldValue("unit", null);

    // Clear the selected beds when the unit is changed
    setSelectedOptions([]);
    form.setFieldValue("bed", []);

    setSite(siteId);
    if (siteId) {
      dispatch(fetchUnitDepartment({ searchquery: "", sid: siteId }));
    }
  };

  const handleUnitChange = (unitId: any) => {
    setSelectedUnit(unitId);
    setUnit(unitId);
    // checking the current unit is same as the selected unit to edit
    if (statusBoardDetailsData?.unit?.id !== unitId) {
      // Clear the selected beds when the unit is changed
      setSelectedOptions([]);
      form.setFieldValue("bed", []);
    } else if (isEditMode && statusBoardDetailsData?.unit?.id === unitId) {
      const defaultBedValues = statusBoardDetailsData?.bed.map((bed: any) => bed?.id);
      form.setFieldsValue({ bed: defaultBedValues, });
      setSelectedOptions(defaultBedValues);
    }
    const page = 1;
    dispatch(fetchBedList({ searchquery: "", unitId: unitId, page }));
  };

  const handleBedChange = (value: any) => {
    if (value.includes("00")) {
      setSelectedOptions(bedListData.map((bed: any) => bed?.id));
    } else if (!value.includes("00")) {
      setSelectedOptions(value);
    }
  };

  const name = form.getFieldValue("name");

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        const name = values.name;
        const payload = {
          name: name,
          site: selectedSite,
          unit: selectedUnit,
          bed: selectedOptions,
        };

        if (isEditMode === true) {
          dispatch(updateStatusBoard({ statusId: editingStatusId, payload })).then((data) => {
            if (data?.payload?.isSuccess) {
              toast.success(data?.payload?.message);
              dispatch(fetchStatusBoardList({ searchQuery, siteId: filterSite ?? "", unitId: filterUnit ?? "", }));
              handleCancel();
              setIsEditMode(false);
            } else {
              toast.error(data?.payload);
            }
          });
        } else {
          dispatch(addStatusBoard(payload)).then((data) => {
            if (data?.payload?.isSuccess) {
              toast.success(data?.payload?.message);
              dispatch(fetchStatusBoardList({ searchQuery, siteId: "", unitId: "" }));
              handleClearFilters();
              handleClearLastFilterValues();
              onClose();
              setSelectedOptions([]);
              handleCancel();
              form.resetFields();
            } else {
              toast.error(data?.payload);
            }
          });
        }
      })
      .catch((error) => { });
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      form.setFieldValue(
        "bed",
        bedListData?.bed_data?.map((data: any) => data.id)
      );
      setSelectedOptions(bedListData?.bed_data?.map((data: any) => data.id));
    } else {
      form.setFieldValue("bed", []);
      setSelectedOptions([]);
    }
  };

  const tagRender = (props: any) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label?.props?.children ? label?.props?.children : null}
      </Tag>
    );
  };

  const dropdownRender = (menu: any) => (
    <>
      <Space style={{ padding: "5px 12px 4px" }}>
        <Checkbox
          checked={
            selectedOptions?.length === bedListData?.bed_data?.length &&
            bedListData?.bed_data?.length > 0
          }
          onChange={handleSelectAll}
        >
          Select All
        </Checkbox>
      </Space>
      <Divider style={{ margin: "8px 0" }} />
      {menu}
    </>
  );

  const TextWithCheckbox = (props: any) => {
    return (
      <div>
        <Checkbox
          checked={props.checked}
          style={{ margin: "0 .5rem 0 0rem" }}
        />
        {props.children}
      </div>
    );
  };

  return (
    <>
      <CustomModal visible={open} onClose={onClose}>
        <Layout>
          <Row>
            <Col>
              <h2>
                {" "}
                {isEditMode ? "Change Status Board" : "Add Status Board"}{" "}
              </h2>
            </Col>
          </Row>
          <Skeleton active loading={isLoading}>
            <Form
              name="addStatusBoard"
              form={form}
              className="add-status-board-form"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="site"
                    label="Site"
                    rules={[
                      {
                        required: true,
                        message: "Please choose Site",
                      },
                    ]}
                  >

                    <Select
                      placeholder="Select Site"
                      onChange={handleSiteChange}
                      value={site}
                      virtual={false}
                    >
                      {siteListData?.map((site: any) => (
                        <Select.Option key={site.id} value={site.id}>
                          {site.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="unit"
                    label="Unit"
                    rules={[
                      {
                        required: true,
                        message: "Please choose unit",
                      },
                    ]}
                  >

                    <Select
                      placeholder="Select Unit"
                      onChange={handleUnitChange}
                      value={unit}
                      virtual={false}
                      loading={isLoadingFetchUnitDepartment}
                    >
                      {unitDepartmentData
                        ?.filter((unit: any) => unit.type === 2)
                        ?.map((unit: any) => (
                          <Select.Option key={unit.id} value={unit.id}>
                            {unit.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={12}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                  <Form.Item
                    name="bed"
                    label="Bed"
                    rules={[
                      {
                        required: true,
                        message: "Please choose bed",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      style={{
                        width: "100%",
                      }}
                      onChange={handleBedChange}
                      placeholder={"Select Bed"}
                      maxTagCount={"responsive"}
                      value={selectedOptions}
                      virtual={false}
                      tagRender={tagRender}
                      dropdownRender={dropdownRender}
                      loading={isLoadingFetchBedList}
                    >
                      {bedListData?.bed_data?.map((item: any) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          label={item.name}
                        >
                          <TextWithCheckbox
                            checked={selectedOptions?.includes(item?.id)}
                          >
                            {item.name}
                          </TextWithCheckbox>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="end" gutter={[8, 8]} className="sb-btn-section" >
                <Col>
                  <SimpleButton
                    block
                    text="Cancel"
                    color="tertiary"
                    customHtmlType="button"
                    onClick={handleCancel}
                  />
                </Col>
                <Col>
                  <SimpleButton
                    block
                    text="Save"
                    color="primary"
                    onClick={handleSave}
                    customHtmlType="button"
                  />
                </Col>
              </Row>
            </Form>
          </Skeleton>
        </Layout>
      </CustomModal>
    </>
  );
};

export default AddStatusBoardCard;
