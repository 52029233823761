
export const BANNER_IMAGE = "https://d32mj6jazbc1fz.cloudfront.net/assets/images/admin-panel_landing-banner.png";
export const LOGO_ICON = "https://d32mj6jazbc1fz.cloudfront.net/assets/images/logoIcon.svg";
export const ICON_PATH = "https://d32mj6jazbc1fz.cloudfront.net/assets/icons/";
export const VIEW_SITE_URL = "https://customer.thecallcentral.com/login";
export const DEFAULT_PROFILE_IMAGE = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

export const LOGINVALUE = {
    FORGOT_PASSWORD: "forgot-password",
    FORGOT_USERNAME: "forgot-usernmae",
    FORGOT_USERNAME_PATH: "/forgot-username",
    FORGOT_PASSWORD_PATH: "/forgot-password",
    MESSAGES: {
        WELCOME_MESSAGE: "Welcome!",
        LOGINFORM: "Sign in to continue to Call Central",
        FOROTPASSWORD: "Enter email to reset your password",
        FORGOTUSERNAME: "Enter email to know your username",
        RESETPASSWORD: "Reset your password",
        PASSWORD_CONFIRMATION: "Please confirm your password!",
        NEW_PASSWORD_CONDITIONS: "Must include 1 digit, 1 alphabet, 1 special character, and be at least 8 characters long"
    },
};

export const THEMEVALUE = {
    DARK_THEME: "dark-theme",
    LIGHT_THEME: "light-theme",
};


