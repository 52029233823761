import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import careTeamService from "./careTeamService";

interface careTeamState {
  fetchCareTeamList: {
    careTeamListData: any;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    message: string;
  };
  deleteCareTeam: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchScheduleList: {
    scheduleListData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  createCareTeam: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  updateCareTeam: {
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
  fetchCareTeamDetails: {
    careTeamDetailsData: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    message: string;
  };
}

const initialState: careTeamState = {
  fetchCareTeamList: {
    careTeamListData: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: "",
  },
  deleteCareTeam: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchScheduleList: {
    scheduleListData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  createCareTeam: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  updateCareTeam: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
  fetchCareTeamDetails: {
    careTeamDetailsData: [],
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: "",
  },
};

//action to fetch care team list
export const fetchCareTeamList = createAsyncThunk(
  "careTeam/fetchCareTeamList",
  async ({ searchQuery, categoryId, siteId }: { searchQuery: string; categoryId: any; siteId: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await careTeamService.fetchCareTeamList({ searchQuery, categoryId, siteId }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to delete care team
export const deleteCareTeam = createAsyncThunk("careTeam/deleteCareTeam", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await careTeamService.deleteCareTeam(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to fetch schedules list
export const fetchScheduleList = createAsyncThunk("careTeam/fetchScheduleList", async (siteId: string, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await careTeamService.fetchScheduleList(siteId, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to create care team
export const createCareTeam = createAsyncThunk("careTeam/createCareTeam", async (payload: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state.auth.user.token;

    const response = await careTeamService.createCareTeam(payload, token);
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//action to update care team
export const updateCareTeam = createAsyncThunk(
  "careTeam/updateCareTeam",
  async ({ careTeamId, payload }: { careTeamId: string; payload: any }, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await careTeamService.updateCareTeam({ careTeamId, payload }, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//action to fetch care team details
export const fetchCareTeamDetails = createAsyncThunk(
  "careTeam/fetchCareTeamDetails",
  async (careTeamId: string, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state.auth.user.token;

      const response = await careTeamService.fetchCareTeamDetails(careTeamId, token);
      if (response.isSuccess) {
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const careTeamSlice = createSlice({
  name: "careTeam",
  initialState,
  reducers: {
    resetFetchCareTeamList: (state) => {
      state.fetchCareTeamList = initialState.fetchCareTeamList;
    },
    resetDeleteCareTeam: (state) => {
      state.deleteCareTeam = initialState.deleteCareTeam;
    },
    resetFetchScheduleList: (state) => {
      state.fetchScheduleList = initialState.fetchScheduleList;
    },
    resetCreateCareTeam: (state) => {
      state.createCareTeam = initialState.createCareTeam;
    },
    resetUpdateCareTeam: (state) => {
      state.updateCareTeam = initialState.updateCareTeam;
    },
    resetFetchCareTeamDetails: (state) => {
      state.fetchCareTeamDetails = initialState.fetchCareTeamDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCareTeamList.pending, (state, action: PayloadAction<any>) => {
        state.fetchCareTeamList.isLoading = true;
      })
      .addCase(fetchCareTeamList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchCareTeamList.isLoading = false;
        state.fetchCareTeamList.isSuccess = true;
        state.fetchCareTeamList.careTeamListData = action.payload.result;
      })
      .addCase(fetchCareTeamList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchCareTeamList.isLoading = false;
        state.fetchCareTeamList.isError = true;
        state.fetchCareTeamList.message = action.payload;
      })

      .addCase(deleteCareTeam.pending, (state, action: PayloadAction<any>) => {
        state.deleteCareTeam.isLoading = true;
      })
      .addCase(deleteCareTeam.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteCareTeam.isLoading = false;
        state.deleteCareTeam.isSuccess = true;
        state.deleteCareTeam.message = action.payload.message;
      })
      .addCase(deleteCareTeam.rejected, (state, action: PayloadAction<any>) => {
        state.deleteCareTeam.isLoading = false;
        state.deleteCareTeam.isError = true;
        state.deleteCareTeam.message = action.payload;
      })

      .addCase(fetchScheduleList.pending, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = true;
      })
      .addCase(fetchScheduleList.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = false;
        state.fetchScheduleList.isSuccess = true;
        state.fetchScheduleList.scheduleListData = action.payload.result;
      })
      .addCase(fetchScheduleList.rejected, (state, action: PayloadAction<any>) => {
        state.fetchScheduleList.isLoading = false;
        state.fetchScheduleList.isError = true;
        state.fetchScheduleList.message = action.payload;
      })

      .addCase(createCareTeam.pending, (state, action: PayloadAction<any>) => {
        state.createCareTeam.isLoading = true;
      })
      .addCase(createCareTeam.fulfilled, (state, action: PayloadAction<any>) => {
        state.createCareTeam.isLoading = false;
        state.createCareTeam.isSuccess = true;
        state.createCareTeam.message = action.payload.message;
      })
      .addCase(createCareTeam.rejected, (state, action: PayloadAction<any>) => {
        state.createCareTeam.isLoading = false;
        state.createCareTeam.isError = true;
        state.createCareTeam.message = action.payload;
      })

      .addCase(fetchCareTeamDetails.pending, (state, action: PayloadAction<any>) => {
        state.fetchCareTeamDetails.isLoading = true;
      })
      .addCase(fetchCareTeamDetails.fulfilled, (state, action: PayloadAction<any>) => {
        state.fetchCareTeamDetails.isLoading = false;
        state.fetchCareTeamDetails.isSuccess = true;
        state.fetchCareTeamDetails.message = action.payload.message;
        state.fetchCareTeamDetails.careTeamDetailsData = action.payload.result;
      })
      .addCase(fetchCareTeamDetails.rejected, (state, action: PayloadAction<any>) => {
        state.fetchCareTeamDetails.isLoading = false;
        state.fetchCareTeamDetails.isError = true;
        state.fetchCareTeamDetails.message = action.payload;
      })

      .addCase(updateCareTeam.pending, (state, action: PayloadAction<any>) => {
        state.updateCareTeam.isLoading = true;
      })
      .addCase(updateCareTeam.fulfilled, (state, action: PayloadAction<any>) => {
        state.updateCareTeam.isLoading = false;
        state.updateCareTeam.isSuccess = true;
        state.updateCareTeam.message = action.payload.message;
      })
      .addCase(updateCareTeam.rejected, (state, action: PayloadAction<any>) => {
        state.updateCareTeam.isLoading = false;
        state.updateCareTeam.isError = true;
        state.updateCareTeam.message = action.payload;
      });
  },
});

export const {
  resetFetchCareTeamList,
  resetDeleteCareTeam,
  resetFetchScheduleList,
  resetCreateCareTeam,
  resetUpdateCareTeam,
  resetFetchCareTeamDetails,
} = careTeamSlice.actions;

export default careTeamSlice.reducer;
