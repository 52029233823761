import { API_ENDPOINTS, API_URL } from "../../constants";
import axiosInstance from "../../core/apiService/webApiService";

//api call to fetch care team list from server
const fetchCareTeamList = async (
  { searchQuery, categoryId, siteId }: { searchQuery: string; categoryId: any; siteId: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.CARE_TEAM.FETCH_CARE_TEAM_LIST}?q=${searchQuery}&category=${categoryId}&site=${siteId}`,
    config
  );
  return response.data;
};

//api call to delete care team
const deleteCareTeam = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.delete(`${API_URL}${API_ENDPOINTS.CARE_TEAM.DELETE_CARE_TEAM}`, {
    ...config,
    data: payload,
  });
  return response.data;
};

//api call to fetch schedules list
const fetchScheduleList = async (siteId: string, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.CARE_TEAM.FETCH_SCHEDULES}?site_id=${siteId}`,
    config
  );
  return response.data;
};

//api call to  create care team
const createCareTeam = async (payload: any, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.post(`${API_URL}${API_ENDPOINTS.CARE_TEAM.ADD_CARE_TEAM}`, payload, config);
  return response.data;
};

//api call to  update care team
const updateCareTeam = async (
  { careTeamId, payload }: { careTeamId: string; payload: any },
  token: string
): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.put(
    `${API_URL}${API_ENDPOINTS.CARE_TEAM.UPDATE_CARE_TEAM}${careTeamId}/`,
    payload,
    config
  );
  return response.data;
};

//
//api call to fetch care team details
const fetchCareTeamDetails = async (careTeamId: string, token: string): Promise<any> => {
  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const response = await axiosInstance.get(
    `${API_URL}${API_ENDPOINTS.CARE_TEAM.FETCH_CARE_TEAM_DETAILS}${careTeamId}/`,
    config
  );
  return response.data;
};

const careTeamService = {
  fetchCareTeamList,
  deleteCareTeam,
  fetchScheduleList,
  createCareTeam,
  updateCareTeam,
  fetchCareTeamDetails,
};

export default careTeamService;
