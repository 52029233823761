import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import loginService from "./loginService";

interface TermsCondition {
  termsConditionData: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
}

const initialState: TermsCondition = {
  termsConditionData: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//to get terms and condition
export const termsCondition = createAsyncThunk("auth/terms-condition", async (_, thunkAPI) => {
  try {
    const response = await loginService.getTermsCondition();
    if (response.isSuccess) {
      return response;
    } else {
      throw new Error(response.message);
    }
  } catch (error: any) {
    const message =
      (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const termsConditionSlice = createSlice({
  name: "termsCondition",
  initialState,
  reducers: {
    resetTermsCondition: (state) => {
      state.termsConditionData = "";
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(termsCondition.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(termsCondition.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.termsConditionData = action.payload;
      })
      .addCase(termsCondition.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { resetTermsCondition } = termsConditionSlice.actions;
