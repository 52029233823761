import React, { useEffect, useRef, useState } from "react";
import { Button, Checkbox, Col, Skeleton, Slider, Switch } from "antd";
import { AddHelp } from "../addHelp/AddHelp";
import {
  MdDelete,
  MdHideSource,
  MdOutlineDragIndicator,
  MdOutlineWebAsset,
} from "react-icons/md";
import { FaMobileAlt, FaPlayCircle } from "react-icons/fa";
import { BiEdit, BiVolumeMute } from "react-icons/bi";
import { FaCirclePause } from "react-icons/fa6";
import { GoUnmute } from "react-icons/go";
import { PiPictureInPictureLight } from "react-icons/pi";
import { RiFullscreenLine } from "react-icons/ri";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useNavigate } from "react-router-dom";
import "./helpVideo.scss";

interface HelpVideoPropsType {
  video: any;
  handleVideoSelection: any;
  totalVideos: any;
  handleVideoDelete: any;
  selectedVideos: any;
  key: any;
  userPermissions: any;
}

export const HelpVideo = ({
  video,
  handleVideoSelection,
  totalVideos,
  handleVideoDelete,
  selectedVideos,
  key,
  userPermissions,
}: HelpVideoPropsType) => {
  const { setNodeRef, transform, transition, listeners, attributes } =
    useSortable({ id: video?.id });

  //  const [webVideo,mobileVideo] = video

  const [videoToPlay, setVideoToPlay] = useState<any>();

  const [viewToggleValue, setViewToggleValue] = useState<boolean>();

  const styles = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const handleVideoEdit = (videoID: any) => {
    setIsOpenEditModal(true);
  };

  const handleToggle = (value: boolean) => {
    setViewToggleValue(value);
    if (value) {
      setVideoToPlay(video?.data[0]);
    } else {
      setVideoToPlay(video?.data[1]);
    }
  };

  useEffect(() => {
    setVideoToPlay(() => (video?.data[0] ? video?.data[0] : video?.data[1]));
    // setVideoToPlay(()=>video?.web?video?.web:video?.mobile)
    setViewToggleValue(() => (video?.data[0] ? true : false));
    // setViewToggleValue(()=>video?.web? true : false)
  }, [video]);

  return (
    <Col xs={24} sm={12} md={12} lg={8} xl={6} ref={setNodeRef} style={styles}>
      <div className="video-and-action-container">
        <div className="video-section-container">
          <Video
            listeners={listeners}
            attributes={attributes}
            video={videoToPlay}
            handleVideoSelection={handleVideoSelection}
            selectedVideos={selectedVideos}
            userPermissions={userPermissions}
          />
        </div>
        <div className="control-section">
          <div className="view-toggle-btn-container">
            <Switch
              onChange={handleToggle}
              checked={viewToggleValue}
              disabled={!video?.data[1] || !video?.data[0]}
              // disabled={!video?.web||!video?.mobile}
              checkedChildren={
                <>
                  <MdOutlineWebAsset /> <span>Web</span>
                </>
              }
              unCheckedChildren={
                <>
                  <FaMobileAlt /> <span>Mobile</span>
                </>
              }
            />
          </div>
          {!videoToPlay?.is_active && (
            <div className="visibility-section">
              <div className="visibility-icon">
                <MdHideSource />
              </div>
              <p>{"Hidden"}</p>
            </div>
          )}
          <div className="video-update-actions-container">
            {userPermissions?.change && (
              <div className="edit-section">
                <div
                  className="edit-icon"
                  onClick={() => handleVideoEdit(videoToPlay?.id)}
                >
                  <BiEdit />
                </div>
                <AddHelp
                  isEditMode={true}
                  onCancel={handleCloseEditModal}
                  selectedVideo={videoToPlay}
                  totalVideos={totalVideos}
                  isOpen={isOpenEditModal}
                />
              </div>
            )}
            {userPermissions?.delete && (
              <div
                className="delete-section"
                onClick={() => handleVideoDelete([videoToPlay?.id])}
              >
                <div className="delete-icon">
                  <MdDelete />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

interface VideoPropsTypes {
  video: any;
  handleVideoSelection: any;
  listeners: any;
  attributes: any;
  selectedVideos: any;
  userPermissions: any;
}

export const Video = ({
  video,
  handleVideoSelection,
  attributes,
  listeners,
  selectedVideos,
  userPermissions,
}: VideoPropsTypes) => {
  const videoRef: any = useRef();
  const timeLineBarRef: any = useRef();

  const navigate = useNavigate();

  const [isVideoPaused, setIsVideoPaused] = useState<boolean>(true);
  const [durationHours, setDurationHours] = useState<any>(0);
  const [durationMinutes, setDurationMinutes] = useState<any>(0);
  const [durationSeconds, setDurationSeconds] = useState<any>(0);
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [videoDurationPercentage, setVideoDurationPercentage] =
    useState<any>("0");
  const [isMouseOnVideo, setIsMouseOnVideo] = useState<boolean>(true);

  // To format number from single digit(1) to double digit(01)
  const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
    minimumIntegerDigits: 2,
  });

  const handlePlayVideo = () => {
    const video = videoRef?.current;
    video?.play();
    setIsVideoPaused(false);
  };

  const handlePauseVideo = () => {
    const video = videoRef?.current;
    video?.pause();
    setIsVideoPaused(true);
  };

  const numberFormatter = (time: any) => {
    return leadingZeroFormatter.format(time);
  };

  // To handle time duration
  const handleTimeDuration = () => {
    // if it does not have hour the format will be mm:ss
    if (durationHours === 0) {
      return `${numberFormatter(durationMinutes)}:${numberFormatter(
        durationSeconds
      )}`;
      // if it does not have minute the format will be 00:ss
    } else if (durationMinutes === 0) {
      return `00:${numberFormatter(durationSeconds)}`;
      // if it has hour the format will br hh:mm:ss
    } else if (durationHours !== 0) {
      return `${numberFormatter(durationHours)}:${numberFormatter(
        durationMinutes
      )}:${numberFormatter(durationSeconds)}`;
    }
  };

  const isVideoEnded = () => {
    const video: any = videoRef.current;
    return video.currentTime === video.duration;
  };

  // To handle mouse click on time line control bar
  const handleScrubbing = (e: any) => {
    const video: any = videoRef.current;
    const rect = timeLineBarRef?.current?.getBoundingClientRect();
    // To calculate the mouse position for finding the clicked time
    // Then update the current time with the clicked time
    const percent =
      Math.min(Math.max(0, e.clientX - rect.x), rect.width) / rect.width;
    video.currentTime = percent * video.duration;
  };

  const handleFullScreen = () => {
    if (document.fullscreenElement == null) {
      videoRef?.current?.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  // To handle picture in picture mode video pause
  navigator.mediaSession.setActionHandler("pause", () => {
    videoRef?.current?.pause();
  });

  const handlePictureInPicture = () => {
    videoRef?.current?.requestPictureInPicture();
  };

  const handleVolumeSlider = (value: any) => {
    const video: any = videoRef.current;
    video.volume = value / 100;
  };

  const handleVideoTime = (time: number) => {
    setDurationHours(Math.floor(time / 3600));
    setDurationMinutes(Math.floor(time / 60));
    setDurationSeconds(Math.floor(time % 60));
  };

  // To navigate to help page when the user exit from picture in picture mode
  videoRef?.current?.addEventListener("leavepictureinpicture", () => {
    navigate("/help");
  });

  useEffect(() => {
    if (selectedVideos?.includes(video?.id)) {
      videoRef?.current?.pause();
    }
  }, [selectedVideos]);

  return (
    <div
      className="video-container"
      onMouseEnter={() => setIsMouseOnVideo(true)}
      onMouseLeave={() => setIsMouseOnVideo(false)}
    >
      <video
        ref={videoRef}
        src={video?.video_url}
        poster={video?.snapshot_url}
        onTimeUpdate={() => {
          const video: any = videoRef.current;
          handleVideoTime(video?.currentTime);
          setVideoDurationPercentage(video.currentTime / video.duration);
          if (isVideoEnded()) {
            setIsVideoPaused(true);
          }
        }}
        onLoadedData={() => {
          const video: any = videoRef.current;
          handleVideoTime(video?.duration);
          setIsVideoLoaded(true);
        }}
        onLoadedMetadata={() => setIsVideoLoaded(true)}
        width={320}
        height={180}
      ></video>
      {/* Showing skeleton loading while loading the video */}
      {!isVideoLoaded ? (
        <Skeleton.Input active className="skeleton-loader" />
      ) : (
        // To show controls when hover on video and also show controls when it paused
        (isMouseOnVideo || videoRef?.current?.paused) && (
          <div
            className="video-controls-container"
            style={{
              display:
                isMouseOnVideo || videoRef?.current?.paused ? "flex" : "none",
            }}
          >
            {userPermissions?.change && (
              <div
                className="sort-btn-container"
                {...listeners}
                {...attributes}
              >
                <Button
                  style={{ backgroundColor: "#9c9c9c3d" }}
                  icon={<MdOutlineDragIndicator />}
                ></Button>
              </div>
            )}
            {userPermissions?.delete && (
              <div className="video-selection-box-container">
                <Checkbox
                  onChange={(event) => handleVideoSelection(event, video?.id)}
                  checked={selectedVideos?.includes(video?.id)}
                />
              </div>
            )}
            <div className="play-and-pause-icon-container">
              {videoRef?.current?.paused ? (
                <FaPlayCircle onClick={handlePlayVideo} />
              ) : (
                <FaCirclePause onClick={handlePauseVideo} />
              )}
            </div>
            {videoRef?.current?.paused && (
              <div className="video-titile">
                <p>{video?.caption}</p>
              </div>
            )}
            <div className="duration-time">
              <p>{handleTimeDuration()}</p>
            </div>
            <div
              className="progress"
              ref={timeLineBarRef}
              onClick={handleScrubbing}
            >
              <div
                className="progress-bar"
                style={{ width: `${videoDurationPercentage * 100}%` }}
              ></div>
            </div>
            <div className="volume-controls">
              <div
                className="mute-icon"
                onClick={() => {
                  videoRef.current.muted = !videoRef?.current?.muted;
                }}
              >
                {/* switching between mute and unmute button  */}
                {videoRef?.current?.muted || videoRef?.current?.volume === 0 ? (
                  <BiVolumeMute />
                ) : (
                  <GoUnmute />
                )}
              </div>
              {/* volume slider */}
              <Slider
                defaultValue={70}
                className="volume-slider"
                tooltip={{
                  open: false,
                }}
                onChange={handleVolumeSlider}
                disabled={videoRef?.current?.muted}
              />
            </div>
            <div className="picture-in-picture">
              <PiPictureInPictureLight onClick={handlePictureInPicture} />
            </div>
            <div className="fullScreen">
              <RiFullscreenLine onClick={handleFullScreen} />
            </div>
          </div>
        )
      )}
    </div>
  );
};
